import React from 'react'
import anchorme from 'anchorme'
import NameTag from './NameTag'
import _ from 'lodash'
import { Comment as CommentType } from './types'
import { Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'

interface Props {
  comment: CommentType
  indent?: boolean
}

const Comment = ({ comment, indent }: Props) => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <>
      <NameTag comment={comment} />

      <Pane marginLeft={medium || !indent ? 0 : 36} marginTop={theme.spacing.xxs}>
        {comment.content.split('\n').map((paragraph, i) => {
          if (!paragraph) {
            return <br key={`c${comment._id}k${i}`} />
          }
          const content = anchorme(paragraph, {
            truncate: 30,
            attributes: [
              {
                name: 'target',
                value: '_blank',
              },
            ],
          })
          const words = content
            .split(' ')
            .map((word) => {
              if (word.startsWith('href')) {
                return word
              }
              return _.truncate(word, { length: 40, omission: '[...]' })
            })
            .join(' ')

          const html = { __html: words }
          return <Paragraph key={`c${comment._id}k${i}`} size={300} dangerouslySetInnerHTML={html} />
        })}
      </Pane>
    </>
  )
}

export default Comment
