import * as React from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { Button, EnvelopeIcon, Heading, Pane, Spinner, toaster } from 'evergreen-ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { StatusCodes } from 'http-status-codes'
import { config } from '/~/utils/config'
import { auth, Service } from '/fiweb/lib'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useAuthContext } from '/~/utils/AuthContext'
import { getCampaignReferral, removeCampaignReferral } from '/~/utils/referrals'
import { GoogleButton } from '../../GoogleButton'
import { InternationalActions } from '../../InternationalActions'

interface Props {
  emailSelected: () => void
  serviceSignupComplete: () => void
  userExisted: () => void
}

export const SignupMethod = ({ emailSelected, serviceSignupComplete, userExisted }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { spacing } = useCustomTheme()
  const { formatMessage } = useIntl()

  const { refetch, loading, user } = useAuthContext()

  const [submitting, setSubmitting] = React.useState(false)
  const [recentlySigned, setRecentlySigned] = React.useState(false)

  const handleGoogleSignup = async (token: string) => {
    try {
      const res = await auth(config.authHost).signupWithService({
        service: Service.GOOGLE,
        token,
        campaignRef: getCampaignReferral(),
      })

      if (!res.ok) {
        throw new Error('Signup failed')
      }

      removeCampaignReferral()
      await refetch()
      setSubmitting(false)

      if (res.status === StatusCodes.CREATED) {
        return setServiceSignupComplete()
      }
      if (res.status === StatusCodes.OK) {
        return userExisted()
      }
    } catch (_e) {
      toaster.danger(formatMessage({ id: 'authError.registerError' }))
    } finally {
    }
  }

  const setServiceSignupComplete = () => {
    setRecentlySigned(true)
    serviceSignupComplete()
  }

  React.useEffect(() => {
    // If they are logged in, but still, somehow, managed to get to this page (by manually typing in /registrering, for instance)
    const isThisRoute = location.pathname === '/innlogging/registrering'
    if (isThisRoute && user && !submitting && !recentlySigned) {
      navigate('/')
    }
  }, [])

  if (loading) {
    return null
  }

  return (
    <Pane display='flex' flexDirection='column' alignItems='center' height='100%'>
      {submitting ? (
        <Spinner />
      ) : (
        <Pane display='flex' flexDirection='column' alignItems='center'>
          <Heading is='h1' size={500} marginY={spacing.l} textAlign='center'>
            <FormattedMessage id='auth.registerNewUser' />
          </Heading>

          <GoogleButton
            buttonMessage='auth.registerWithGoogle'
            onButtonClicked={() => setSubmitting(true)}
            onClose={() => setSubmitting(false)}
            onError={() => setSubmitting(false)}
            onSuccess={handleGoogleSignup}
          />

          <Button width='100%' size='small' onClick={emailSelected} marginTop={spacing.xs} gap={spacing.xxs}>
            <EnvelopeIcon size={17} />
            <FormattedMessage id='auth.registerWithEmail' />
          </Button>
        </Pane>
      )}

      <Pane marginTop='auto'>
        <InternationalActions actionText={formatMessage({ id: 'register' })} />
      </Pane>
    </Pane>
  )
}
