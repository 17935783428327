import { Pane, Spinner } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useLocation } from 'react-router-dom'
import * as React from 'react'
import { useSignicatSession } from './useSignicatSession'
import { ProviderError } from './utils'
import { SignicatError } from './SignicatError'
import { SignicatProvider } from '/fiweb/lib/auth'
import { config } from '/~/utils/config'
import { auth } from '/fiweb/lib/auth'

export interface SignicatCallbackProps {
  onCallback: (sessionId: string, callbackType: 'success' | 'error' | 'abort') => Promise<ProviderError | null>
}

export const SignicatCallback = ({ onCallback }: SignicatCallbackProps) => {
  const location = useLocation()
  const [error, setError] = React.useState<ProviderError | null>(null)
  const { spacing } = useCustomTheme()
  const basePath = location.pathname.replace(/signicat\/.{0,}/gi, '')

  const { sessionId, callback } = useSignicatSession()

  React.useEffect(() => {
    const runCallback = async () => {
      // Either error happens or the callback should redirect away from this component
      setError(await onCallback(sessionId, callback))
    }

    runCallback()
  }, [])

  const onRetry = async (provider: SignicatProvider) => {
    const res = await auth(config.authHost).obtainSignicatSessionUrl(
      [provider],
      `${window.origin}${basePath}`.replace(/\/$/, ''),
    )

    if (res.ok) {
      const url = await res.text()

      window.location.href = url
    }
  }

  return (
    <Pane width='100%' display='flex' flexDirection='column' alignItems='center' padding={spacing.l}>
      {error ? (
        <SignicatError
          errorText={error.text}
          provider={error.provider}
          tryAgain={error.provider ? () => onRetry(error.provider) : undefined}
          goBack={basePath}
        />
      ) : (
        <Spinner />
      )}
    </Pane>
  )
}
