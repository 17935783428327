import { Pane } from 'evergreen-ui'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { useIsActionBarInView } from '../Context/ActionBarInView'
import { widths } from '../utils'
import { ActionBar } from './ActionBar'
import { breakpoints, useBreakpoint } from '/fiweb/lib'

interface FloaterProps {
  $show: boolean
}

const Floater = styled(Pane)<FloaterProps>`
  ${({ $show, theme: { colors, shadows, spacing } }) => css`
    display: flex;
    justify-content: center;

    position: sticky;
    width: 100%;
    bottom: -76px;
    transition: bottom 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    background-color: ${colors.fiDark10};
    box-shadow: ${shadows[0]};
    padding: ${spacing.xs} ${spacing.s};
    opacity: 0;

    ${
      $show &&
      css`
      bottom: 0px;
      opacity: 1;
      z-index: 2;
    `
    };

    @media ${breakpoints.small} {
      padding: ${spacing.xs} ${spacing.xxs};
    }
  `}
`

export const FloatingActionBar = () => {
  const { medium } = useBreakpoint()
  const [, inView] = useIsActionBarInView()

  return medium ? (
    <Floater $show={!inView}>
      <Pane width='100%' maxWidth={widths.mediumHeaderSectionMax}>
        <ActionBar onlyButtons />
      </Pane>
    </Floater>
  ) : null
}
