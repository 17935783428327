import * as React from 'react'
import { IconProps } from './types'

export const Download = ({ title, ...rest }: IconProps) => (
  <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path d='M9 0V8' stroke='currentColor' strokeWidth='1.5' />
    <path d='M6 5L9 8L12 5' stroke='currentColor' strokeWidth='1.5' />
    <path
      d='M16.5 8V9C16.5 10.1046 15.6046 11 14.5 11H3.5C2.39543 11 1.5 10.1046 1.5 9V8'
      stroke='currentColor'
      strokeWidth='1.5'
    />
  </svg>
)
