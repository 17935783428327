import React, { useEffect, useState } from 'react'
import { Loading } from '/fiweb/components'
import { Navigate, useParams } from 'react-router-dom'
import { useApproveUserForPreorderMutation, useCampaignPreorderWithVisitorQuery } from '/~/types/graphql'
import PreorderLogin from './PreorderLogin'
import { Paragraph } from 'evergreen-ui'
import { useAuthContext } from '/~/utils/AuthContext'
import { Page404 } from '/~/pages'

const PreorderPage = () => {
  const { campaignSlug } = useParams<{ campaignSlug: string }>()
  const [approved, setApproved] = useState(false)
  const [approving, setApproving] = useState(false)
  const [error, setError] = useState(false)
  const { data, loading } = useCampaignPreorderWithVisitorQuery({
    variables: { slug: campaignSlug },
  })
  const [approveUserMutation] = useApproveUserForPreorderMutation()
  const authContext = useAuthContext()
  const user = authContext.user

  const handleApproveUserForPreorder = async () => {
    if (!data?.Campaign?._id) {
      setError(true)
      setApproving(false)
      return
    }
    try {
      const {
        data: { approveUserForPreorder },
      } = await approveUserMutation({
        variables: { campaignId: data.Campaign._id },
      })
      if (approveUserForPreorder) {
        setApproved(true)
      } else {
        setApproved(false)
        setError(true)
      }
    } catch (_e) {
      setError(true)
    } finally {
      setApproving(false)
    }
  }

  const approveIfReady = () => {
    if (!(loading || authContext?.loading || approving || approved || error) && user) {
      setApproving(true)
      handleApproveUserForPreorder()
    }
  }

  useEffect(() => {
    approveIfReady()
  }, [data, authContext?.loading])

  const isApproved = approved || data?.visitorSettings?.approvedForPreorder

  if (loading || authContext.loading || approving) {
    return <Loading />
  }
  if (isApproved) {
    return (
      <Navigate
        to={{
          pathname: `/kampanje/${campaignSlug}`,
          search: window.location.search,
        }}
        replace
      />
    )
  }
  if (!data?.Campaign) {
    return <Page404 />
  }
  if (!user) {
    return <PreorderLogin campaign={data.Campaign} />
  }
  if (error) {
    return <Paragraph>En feil har oppstått, prøv igjen eller ta kontakt med support.</Paragraph>
  }

  return <Loading />
}

export default PreorderPage
