import React from 'react'
import { Link, LinkProps } from 'evergreen-ui'
import styled, { css } from 'styled-components'

const StyledFooterLink = styled(Link)`
  ${({ theme: { colors } }) => css`
    color: white;
    font-size: 16px;
    text-decoration: none;
    line-height: 36px;

    &:hover {
      color: ${colors.fiDark20};
      fill: ${colors.fiDark20};
      text-decoration: underline;
    }
    &:hover {
      span {
        color: ${colors.fiDark20};
        text-decoration: none;
      }
      path {
        stroke: ${colors.fiDark20};
      }
    }
    &.footer-small-link {
      line-height: 20px;
      font-size: 14px;
    }
  `}
`

const FooterLink = ({ children, href = '#', ...rest }: LinkProps) => {
  return (
    <StyledFooterLink href={href} {...rest}>
      {children}
    </StyledFooterLink>
  )
}

export default FooterLink
