import * as React from 'react'
import { Pane, PaneOwnProps, PolymorphicBoxProps } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props {
  vertical?: boolean
  color?: string
}

export const Divider = (props: PolymorphicBoxProps<'div', PaneOwnProps & Props>) => {
  const theme = useCustomTheme()
  const { vertical, color = theme.colors.fiDark20, ...dividerProps } = props
  const borderStyle = `1px solid ${color}`

  if (vertical) {
    return <Pane color={color} borderRight={borderStyle} {...dividerProps} />
  }

  return <Pane boxSizing='border-box' color={color} borderBottom={borderStyle} {...dividerProps} />
}
