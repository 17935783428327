import { ApolloClient, InMemoryCache, ApolloLink, from, split, fromPromise, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from 'apollo-utilities'
import { setOffset } from './utils/timeSync'
import { config } from './utils/config'
import { auth } from '/fiweb/lib/auth'
import { StatusCodes } from 'http-status-codes'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.graphqlSubscriptionUrl,
    retryAttempts: 60,
  }),
)

const httpLink = new HttpLink({ uri: config.graphqlUrl, credentials: 'include' })

const link_ = split(
  (q) => {
    const { query } = q
    const def = getMainDefinition(query)

    return def.kind === 'OperationDefinition' && def.operation === 'subscription'
  },
  wsLink,
  httpLink,
)

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((originalResponse) => {
    const { response } = operation.getContext()

    const shouldRefresh = response?.headers.get('x-should-refresh') === 'true'
    const serverTime = response?.headers.get('Server-time')

    if (shouldRefresh) {
      auth(config.authHost).obtainRefreshToken()
    }

    if (serverTime) {
      setOffset(serverTime)
    }

    return originalResponse
  })
})

const errorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
  if (!config.graphqlErrorLogginDisabled) {
    if (graphQLErrors) {
      console.log('GraphQLError: ', graphQLErrors)
    } else {
      console.log('NetworkError', networkError)
    }
  }

  if (networkError && 'statusCode' in networkError && networkError.statusCode === StatusCodes.UNAUTHORIZED) {
    const { response } = operation.getContext()

    if (response?.headers) {
      const accessTokenExpired = response.headers.get('x-access-expired')

      if (accessTokenExpired) {
        return fromPromise(
          auth(config.authHost)
            .obtainAccessToken()
            .catch((_) => auth(config.authHost).logout()),
        )
          .filter((val) => Boolean(val))
          .flatMap(() => forward(operation))
      }
      auth(config.authHost).logout()
    }
  }
})

const link = from([afterwareLink, errorLink, link_])

const cache = new InMemoryCache({
  typePolicies: {
    SelfReportItem: {
      keyFields: [],
    },
  },
})

export default new ApolloClient({
  link,
  cache,
  name: 'client-web-app',
})
