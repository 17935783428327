import * as React from 'react'
import { Pane } from 'evergreen-ui'
import styled from 'styled-components'
import { TopSection } from './TopSection'
import { JoinTheJourney } from './JoinTheJourney'
import { Process } from './Process'
import { Testimonials } from './Testimonials'
import { SuccessfulIssues } from './SuccessfulIssues'
import { ContactForm } from './ContactForm'
import { DoesItFit } from './DoesItFit'
import InfoMeetingBooker from '/~/components/InfoMeetingBooker'

interface Props {
  countryCode: string
}

const Wrapper = styled(Pane)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1060px;
  margin: auto;
  padding: 0 25px;
`

export const ScandinavianEntrepreneurLandingPage = ({ countryCode }: Props) => {
  return (
    <>
      <Wrapper>
        <TopSection />
      </Wrapper>
      <ContactForm countryCode={countryCode} />
      <Wrapper>
        <Process />
        <JoinTheJourney />
      </Wrapper>
      <Testimonials />
      <Wrapper>
        <SuccessfulIssues />
        <DoesItFit />
        <InfoMeetingBooker />
      </Wrapper>
    </>
  )
}
