import * as React from 'react'
import { Helmet as ReactHelmet } from 'react-helmet'

interface HelmetProps {
  title: string
  description?: string
  url?: string
  children?: React.ReactNode
  imageUrl?: string
  imageHeight?: string
  imageWidth?: string
  siteName?: string
  keywords?: string
  hide?: boolean
  locale?: string
}

export const Helmet = ({
  title,
  description,
  url = `${window.location.origin}`,
  children,
  imageUrl,
  imageHeight,
  imageWidth,
  siteName = 'Folkeinvest.no',
  keywords,
  hide,
  locale = 'no_NO',
}: HelmetProps) => (
  <ReactHelmet>
    <title>{title}</title>
    <meta property='og:title' name='og:title' content={title} />
    <meta property='twitter:title' name='twitter:title' content={title} />

    <meta property='url' name='url' content={url} />
    <meta property='og:url' name='og:url' content={url} />

    {description && <meta property='description' name='description' content={description} />}
    {description && <meta property='og:description' name='og:description' content={description} />}
    {description && <meta property='twitter:description' name='twitter:description' content={description} />}

    <meta property='sitename' name='sitename' content={siteName} />
    <meta property='og:sitename' name='og:sitename' content={siteName} />
    <meta property='og:site_name' content={siteName} />

    <meta property='og:locale' content={locale} />

    {keywords && <meta property='keywords' content={keywords} />}

    {hide && <meta name='robots' content='noindex, nofollow' />}

    {imageUrl && <meta property='image' name='image' content={imageUrl} />}
    {imageUrl && <meta property='og:image' name='og:image' content={imageUrl} />}
    {imageUrl && <meta property='og:image:secure' name='og:image:secure' content={imageUrl} />}
    {imageUrl && <meta property='twitter:image' name='twitter:image' content={imageUrl} />}

    {imageHeight && <meta property='og:image:height' content={imageHeight} />}
    {imageWidth && <meta property='og:image:width' content={imageWidth} />}

    {children}
  </ReactHelmet>
)
