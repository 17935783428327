import * as React from 'react'
import { Heading, Pane, toaster } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import NavButtons from './NavButtonsComponent'
import { ProfileAddressInput as ProfileAddressInputType, useUpdateProfileMutationMutation } from '/~/types/graphql'
import { addressValid } from '/~/utils/checkUserValid'
import { ProfileAddressInput } from '/~/components/account/Profile'
import { FormProvider, useForm } from 'react-hook-form'
import { useAuthContext } from '/~/utils/AuthContext'

interface Props {
  goBack: () => void
  goForward: () => void
}

const AddressStep = ({ goBack, goForward }: Props) => {
  const { user } = useAuthContext()
  const theme = useCustomTheme()

  const [updateProfile] = useUpdateProfileMutationMutation()
  const getDefaultValues = () => ({
    street: user?.addressFields?.street || '',
    postalCode: user?.addressFields?.postalCode || '',
    postalTown: user?.addressFields?.postalTown || '',
    countryCode: user?.addressFields?.countryCode || 'NO',
  })

  const formControl = useForm({ defaultValues: getDefaultValues() })
  const {
    handleSubmit,
    formState: { isDirty },
    getValues,
    reset,
  } = formControl

  React.useEffect(() => {
    if (user) {
      reset(getDefaultValues())
    }
  }, [user])

  const save = async (addressFields: ProfileAddressInputType, event: React.SyntheticEvent) => {
    event.preventDefault()

    if (!addressValid({ addressFields })) {
      toaster.notify('Alle påkrevde felt må være utfylt')
      return null
    }

    try {
      await updateProfile({
        variables: { input: { addressFields } },
      })

      goForward()
    } catch (_err) {
      toaster.danger('Kunne ikke oppdatere profil. Prøv igjen eller kontakt oss på support@folkeinvest.no')
    }
  }

  const nextDisabled = !(isDirty || addressValid({ addressFields: getValues() }))

  return (
    <Pane
      display='flex'
      flexDirection='column'
      maxWidth={380}
      marginTop={theme.spacing.l}
      alignItems='center'
      height='100%'
      width='100%'
    >
      <FormProvider {...formControl}>
        <form onSubmit={handleSubmit(save)}>
          <Heading is='h3'>Adresse</Heading>

          <ProfileAddressInput />
          <Pane marginBottom={theme.spacing.s} />

          <NavButtons onBack={() => goBack()} disabled={nextDisabled} />
        </form>
      </FormProvider>
    </Pane>
  )
}

export default AddressStep
