import styled, { css } from 'styled-components'

export const Blockquote = styled.blockquote`
  ${({ theme: { colors, spacing } }) => css`
    color: ${colors.fiDark70};
    font-style: italic;
    margin-inline-start: ${spacing.m};
    margin-inline-end: ${spacing.m};
    
    > span {
      padding-left: ${spacing.xxs};
      border-left: 2px solid ${colors.fiDark40};
    }
  `}
`
