import { Heading, Pane } from 'evergreen-ui'
import * as React from 'react'
import { usePublicInvestmentOfferData, usePublicInvestmentOfferFlags } from '../Context/PublicInvestmentOfferData'
import { useReservedSubscriptionsSummary } from '../Context/ReservedSubscriptionsSummary'
import { ProgressBarWithSpread } from '/fiweb/components'
import { convertFromOreToNokAndFormatCurrency } from '/fiweb/lib'
import { useCustomTheme } from '/fiweb/lib/theme'
import { RefreshButton } from '../components'

export const TitleAndProgress = () => {
  const { spacing } = useCustomTheme()

  const { title, minSharesToIssue, maxSharesToIssue, pricePerShare } = usePublicInvestmentOfferData()

  const flags = usePublicInvestmentOfferFlags()
  const { userCanPreorder, isOpen, isClosed, isPreview, isReadonly } = flags

  const { progress, summary, refetch } = useReservedSubscriptionsSummary()

  const showProgress = !isReadonly && (isClosed || isOpen || userCanPreorder)

  return (
    <>
      <Heading size={500} marginBottom={spacing.xs} wordWrap='normal'>
        {title}
      </Heading>
      <Pane display='flex' width='100%' justifyContent='space-between'>
        <Heading size={400} marginBottom={spacing.xxs}>
          {isReadonly || isPreview ? ( // PREVIEW-HACK
            '-'
          ) : (
            <>
              {showProgress
                ? convertFromOreToNokAndFormatCurrency(pricePerShare * summary.totalSharesReserved, false)
                : 0}{' '}
              kr
            </>
          )}
        </Heading>
        <RefreshButton onClick={() => refetch()} />
      </Pane>
      <ProgressBarWithSpread
        currencyInNok={false}
        progress={showProgress ? progress.min : 0}
        goal={minSharesToIssue * pricePerShare}
        max={maxSharesToIssue * pricePerShare}
        disabled={isClosed}
        largeText
      />
    </>
  )
}
