import * as React from 'react'
import { Dialog, Pane, Spinner, toaster } from 'evergreen-ui'
import { useBreakpoint } from '/fiweb/lib'
import { ProfileUpdateInput, useAcceptTermsMutationMutation, useUpdateProfileMutationMutation } from '/~/types/graphql'
import CompleteProfileContent from './CompleteProfileContent'
import CompleteProfileInfo from './CompleteProfileInfo'
import CompleteProfileButtons from './CompleteProfileButtons'
import { getMissingProfileFields as getMissingFields, checkOver18, CheckedProfileField } from '/~/utils/checkUserValid'
import { FormProvider, useForm } from 'react-hook-form'
import { useAuthContext } from '/~/utils/AuthContext'

interface Props {
  open: boolean
  onClose: () => void
}

const CompleteProfileDialog = ({ open, onClose }: Props) => {
  const authContext = useAuthContext()
  const user = authContext.user

  const { small } = useBreakpoint()
  const [missingFields, setMissingFields] = React.useState<CheckedProfileField[]>([])

  const [updateProfile] = useUpdateProfileMutationMutation()
  const [acceptTerms] = useAcceptTermsMutationMutation()

  const invalidFields = getMissingFields(user)
  const isValidField = (fieldName: CheckedProfileField) => !invalidFields.includes(fieldName)

  const completedFields = [...missingFields].filter((mf) => isValidField(mf))

  const getDefaultValues = () => ({
    phone: user?.phone || '',
    pepValue: user?.pep === true ? 'yes' : user?.pep === false ? 'no' : undefined || '',
    addressFields: {
      street: user?.addressFields?.street || '',
      postalCode: user?.addressFields?.postalCode || '',
      postalTown: user?.addressFields?.postalTown || '',
      countryCode: user?.addressFields?.countryCode || 'NO',
    },
  })

  const formControl = useForm({ defaultValues: getDefaultValues() })

  const {
    handleSubmit,
    formState: { dirtyFields, isDirty },
    clearErrors,
    reset,
  } = formControl

  React.useEffect(() => {
    if (user) {
      reset(getDefaultValues())
    }
  }, [user])

  React.useEffect(() => {
    setMissingFields(getMissingFields(user))
    return () => {
      setMissingFields([])
    }
  }, [open, user])

  const handleSaveProfile = async (
    formValues: ProfileUpdateInput & { pepValue: string },
    event: React.SyntheticEvent,
  ) => {
    event.preventDefault()

    if (!isDirty) {
      return
    }

    try {
      if (dirtyFields.pepValue) {
        await acceptTerms({ variables: { pep: formValues.pepValue === 'yes' } })
      }
      const input = { ...formValues, pepValue: undefined }
      const { data } = await updateProfile({ variables: { input } })
      if (data?.updateProfile) {
        await authContext.refetch()
      }
    } catch (_err) {
      toaster.danger('Kunne ikke oppdatere profil. Prøv igjen eller kontakt support')
    } finally {
      clearErrors()
    }
  }

  const isUnderEighteen = () => {
    if (user?.birthdate) {
      return !checkOver18(user)
    }

    return false // No birthday yet, no point in showing error
  }

  if (!missingFields) {
    return <Spinner />
  }

  const isDone = missingFields.reduce((acc, val) => {
    if (acc && !completedFields.includes(val)) {
      if (val === 'underaged') {
        return true
      }
      return false
    }
    return acc
  }, true)

  const underEighteen = isUnderEighteen()

  return (
    <FormProvider {...formControl}>
      <Dialog
        shouldCloseOnEscapePress={false}
        preventBodyScrolling
        isShown={open}
        hasHeader={false}
        footer={
          <form onSubmit={handleSubmit(handleSaveProfile)}>
            <CompleteProfileButtons isDone={isDone} onClose={onClose} />
          </form>
        }
        width={650}
        sideOffset={small ? 0 : null}
        topOffset={small ? 0 : '12vmin'}
        minHeightContent={small ? 'calc(100vh - 96px)' : null}
        shouldCloseOnOverlayClick={false}
      >
        <Pane display='flex' flexDirection='column' paddingTop={32}>
          <CompleteProfileInfo isDone={isDone} underEighteen={underEighteen} />
          <CompleteProfileContent completedFields={completedFields} missingFields={missingFields} />
        </Pane>
      </Dialog>
    </FormProvider>
  )
}

export default CompleteProfileDialog
