import * as React from 'react'
import { Button, Dialog, Heading, Pane, Small } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import Markdown from 'markdown-to-jsx'
import { useBreakpoint } from '/fiweb/lib'
import styled, { css } from 'styled-components'

interface Props {
  terms: any
  open: boolean
  onClose: () => void
  acceptTerms?: () => void
}

const StyledMarkdown = styled.div`
  ${({ theme: { spacing } }) => css`
    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 29.05px;
    }
    h2 {
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
      margin-top: ${spacing.xs};
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    hr {
      margin-top: ${spacing.s};
      margin-bottom: ${spacing.s};
      border-bottom: 0px;
      border-left: 0px;
      border-right: 0px;
    }
  `}
`

const Terms = ({ terms, open, onClose, acceptTerms }: Props) => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()
  return (
    <Dialog
      isShown={open}
      shouldCloseOnOverlayClick={false}
      header={<Pane />}
      footer={<Pane />}
      width={800}
      sideOffset={medium ? 0 : null}
      topOffset={medium ? 0 : '12vmin'}
    >
      <Heading is='h4' size={400} tabIndex={0}>
        Betingelser
      </Heading>
      <Small>Versjon: {terms.version}</Small>

      <StyledMarkdown>
        <Markdown children={terms.content} />
      </StyledMarkdown>

      <Pane display='flex' flexFlow='row nowrap' justifyContent='flex-end' width='100%' marginTop={theme.spacing.s}>
        <Button onClick={onClose} size='small' marginRight={theme.spacing.xs}>
          Lukk
        </Button>
        {Boolean(acceptTerms) && (
          <Button onClick={acceptTerms} size='small' appearance='primary'>
            Godta
          </Button>
        )}
      </Pane>
    </Dialog>
  )
}

export default Terms
