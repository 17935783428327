import * as React from 'react'
import { useBreakpoint, breakpoints } from '/fiweb/lib'
import { Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Loading } from '/fiweb/components'
import { useGetTermsQuery } from '/~/types/graphql'
import styled, { css } from 'styled-components'
import Markdown from 'markdown-to-jsx'
import { useLocation } from 'react-router'

const StyledMarkdown = styled(Markdown)`
  ${({ theme: { colors, spacing } }) => css`
    color: ${colors.text.default};
    h1 {
      font-size: 60px;
      font-weight: 700;
      line-height: 71px;
      letter-spacing: -0.2px;
      margin: 0;
      margin-bottom: ${spacing.m};
    }
    h2 {
      font-size: 36px;
      font-weight: 500;
      line-height: 43.57px;
      margin: 0;
      margin-bottom: ${spacing.xxs};
    }
    p,
    li {
      font-size: 18px;
      line-height: 28px;
      margin: 0;
      margin-bottom: ${spacing.xxs};
      letter-spacing: -0.05px;
      font-weight: 400;
    }
    ul {
      list-style: none;
      padding-left: 0;
    }
    hr {
      margin-top: ${spacing.xs};
      margin-bottom: ${spacing.s};
      border-bottom: 0px;
      border-left: 0px;
      border-right: 0px;
    }
    ol {
      margin-bottom: ${spacing.m};
    }

    @media ${breakpoints.medium} {
      h1 {
        font-weight: 800;
        margin-bottom: ${spacing.s};
      }
      p {
        margin-bottom: ${spacing.xs};
      }
      ol {
        margin-bottom: ${spacing.xs};
      }
    }
  `}
`
const RightsPage = () => {
  const { data, loading } = useGetTermsQuery({ variables: { latest: true } })
  const { hash } = useLocation()
  const { large } = useBreakpoint()
  const theme = useCustomTheme()

  const terms = !loading && data ? data.getTerms : { version: 0, content: '' }

  React.useEffect(() => {
    if (!loading && hash) {
      const ele = document.getElementById(hash.replace('#', ''))
      if (ele) {
        window.scrollTo({ top: ele.getBoundingClientRect().top - 100 })
      }
    }
  }, [loading])

  return (
    <Pane width='100%' display='flex' justifyContent='center' marginBottom={large ? theme.spacing.l : theme.spacing.xl}>
      <Pane
        maxWidth={large ? '86.67%' : 800}
        display='flex'
        flexDirection='column'
        alignItems='start'
        marginTop={large ? 0 : theme.spacing.l}
      >
        {loading && (
          <Pane display='flex' width='100%' justifyContent='center'>
            {' '}
            <Loading text='Laster vilkår og rettigheter' />
          </Pane>
        )}
        {!loading && terms && <StyledMarkdown children={terms.content} />}
      </Pane>
    </Pane>
  )
}

export default RightsPage
