import React from 'react'
import Markdown from 'markdown-to-jsx'
import { FetchCampaignQuery } from '/~/types/graphql'
import { Pane, Paragraph, Heading } from 'evergreen-ui'
import styled from 'styled-components'
import PageContainer from './PageContainer'
import Divider from '/~/components/campaign/Campaign/Divider'
import SocialSharing from './SocialSharing'
import { useBreakpoint } from '/fiweb/lib'

import 'react-quill/dist/quill.core.css'
import '/~/static/quill-fonts-extended.css'
import { useCustomTheme } from '/fiweb/lib/theme'
import { VideoWrapper } from '/fiweb/components'

interface Props {
  campaign: FetchCampaignQuery['Campaign']
}

const MarkdownContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  font-family: ${({ theme: { fontFamilies } }) => fontFamilies.display};
  img {
    max-width: 100%;
  }

  .ql-editor {
    padding: 0px;
  }
`

const EditorContent = styled(Pane)`
  p {
    margin-top: 0px;
    -webkit-margin-before: 0px;
    -webkit-margin-after: 0px;
  }
  h2 {
    -webkit-margin-before: 0.5em;
    -webkit-margin-after: 0.5em;
  }
  ul {
    -webkit-margin-before: 0.5em;
    -webkit-margin-after: 0.5em;
  }
`
const Description = (props: Props) => {
  const { campaign } = props
  const { medium } = useBreakpoint()
  const { spacing } = useCustomTheme()
  const markup = { __html: campaign.descriptionHTML }

  return (
    <PageContainer>
      <Heading is='h1' size={600}>
        {campaign.title}
      </Heading>
      <Paragraph size={medium ? 400 : 500}>{campaign.caption}</Paragraph>
      <Divider />
      <SocialSharing campaign={campaign} />
      <Divider />
      {campaign.featuredVideo && (
        <VideoWrapper marginTop={spacing.s} html={campaign.featuredVideo.html} heightConstraintByViewport />
      )}
      {campaign.description && campaign.description.length > 1 && <Markdown children={campaign.description} />}
      {campaign.descriptionHTML && campaign.descriptionHTML.length > 1 && (
        <MarkdownContainer>
          <EditorContent className='ql-editor' dangerouslySetInnerHTML={markup} />
        </MarkdownContainer>
      )}
    </PageContainer>
  )
}

export default Description
