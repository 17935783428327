import { CheckedProfileField } from '/~/utils/checkUserValid'
interface FieldDescription {
  name: CheckedProfileField
  label: string
  doneDescr: string
}

export const statusFields: FieldDescription[] = [
  {
    name: 'address',
    label: 'Adresse',
    doneDescr: 'Fullført',
  },
  {
    name: 'phone',
    label: 'Telefonnummer',
    doneDescr: 'Fullført',
  },
  {
    name: 'terms',
    label: 'Samtykker',
    doneDescr: 'Vilkår og rettigheter',
  },
  {
    name: 'pep',
    label: 'Politisk eksponert person',
    doneDescr: 'Fullført',
  },
  {
    name: 'email',
    label: 'E-post',
    doneDescr: 'Verifisert',
  },
  {
    name: 'bankid',
    label: 'BankID',
    doneDescr: 'Fullført',
  },
]
