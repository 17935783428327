import * as React from 'react'
import { breakpoints } from '/fiweb/lib'
import { Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled from 'styled-components'
import { PaginationComponent } from '/~/components'
import { Loading } from '/fiweb/components'
import PressItem from './PressItem'

const StyledPane = styled(Pane)`
  display: grid;
  width: 100%;
  grid-column-gap: 30px;
  grid-template-columns: repeat(auto-fill, 400px);
  grid-row-gap: 30px;
  max-width: 100%;
  padding-bottom: 60px;
  @media ${breakpoints.medium} {
    grid-template-columns: repeat(1, 1fr);
    max-width: 325px;
  }
`

interface MediaItem {
  title?: string
  description?: string
  imageUrl?: string
  timestamp?: number
  documentUrl?: string
}

interface Props {
  list?: MediaItem[]
  loading?: boolean
}

const MediaView = ({ list, loading }: Props) => {
  const [page, setPage] = React.useState(1)
  const count = list.length
  const perPage = 9
  const theme = useCustomTheme()

  React.useEffect(() => {
    setPage(1)
  }, [list])

  const filteredList = [...list].splice((page - 1) * perPage, perPage)

  return (
    <>
      {loading && (
        <Pane display='flex' width='100%' flexDirection='row' justifyContent='center'>
          <Loading />
        </Pane>
      )}
      <StyledPane>
        {filteredList.map((item, i) => {
          return <PressItem key={i} item={item} />
        })}
      </StyledPane>
      {count > perPage && (
        <PaginationComponent
          className='pagination'
          page={page}
          marginBottom={theme.spacing.l}
          alignSelf='center'
          totalPages={Math.ceil(count / perPage)}
          onNextPage={() => {
            setPage(page + 1)
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
          onPreviousPage={() => {
            setPage(page - 1)
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
          onPageChange={(number) => {
            setPage(number)
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        />
      )}
    </>
  )
}

export default MediaView
