import React from 'react'
import { Text } from 'evergreen-ui'
import styled from 'styled-components'
import { config } from '/~/utils/config'

const hashCode = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

const intToRGB = (i: number) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase()
  return '00000'.substring(0, 6 - c.length) + c
}

const Letter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  line-height: 27px;
  height: 27px;
  width: 27px;
  border-radius: 20px;
`

const Logo = styled.img`
  border: 1px solid ${({ theme: { colors } }) => colors.fiDark10};
  background: #fff;
  height: 27px;
  width: 27px;
  border-radius: 20px;
`

interface Props {
  name: string
  logo?: boolean
}

const CommentAvatar = ({ name, logo }: Props) => {
  return logo ? (
    <Logo alt='Folkeinvest Logo' src={`${config.publicFilesUrl}/icons/apple-icon-114x114.png`} />
  ) : (
    <Letter style={{ backgroundColor: `#${intToRGB(hashCode(name))}` }}>
      <Text color='inherit'>{name.substring(0, 1)}</Text>
    </Letter>
  )
}

export default CommentAvatar
