import * as React from 'react'
import { Button, HelpIcon, Li, Link, Pane, Paragraph, Small, Ul } from 'evergreen-ui'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Divider } from '../Divider'
import { HelpTextsType } from './getHelpText'

interface StyledProps {
  $isDialog?: boolean
}

const Container = styled(Pane)<StyledProps>`
  ${({ $isDialog, theme: { colors, radii, spacing } }) => css`
    min-width: ${$isDialog ? '100%' : '400px'};
    max-width: ${$isDialog ? '100%' : '600px'};
    height: ${$isDialog ? 'auto' : 'fit-content'};
    display: flex;
    flex-direction: column;
    padding: ${spacing.s};
    color: ${colors.white};
    background-color: ${colors.dark};
    border-radius: ${radii[3]};
  `}
`

const CloseButton = styled(Button).attrs({ appearance: 'primary', size: 'small' })<StyledProps>`
  ${({ $isDialog, theme: { colors, spacing } }) => css`
    width: ${$isDialog ? '300px' : 'fit-content'};
    height: fit-content;
    border: solid 1px ${colors.white};
    padding-top: ${$isDialog ? undefined : 0};
    padding-bottom: ${$isDialog ? undefined : 0};
    margin: ${spacing.xs} 0;
  `}
`

interface Props {
  helpTexts?: HelpTextsType
  isDialog?: boolean
  onClose: () => void
  children?: React.ReactNode
}

export const HelpTextContent: React.FC<Props> = ({ children, helpTexts, isDialog, onClose }) => {
  const { fontWeights, spacing } = useCustomTheme()

  return (
    <Container $isDialog={isDialog}>
      {helpTexts ? (
        <>
          <Paragraph size={300} fontWeight={fontWeights.bold} color='inherit' marginBottom={spacing.xs}>
            <HelpIcon marginRight={spacing.xxs} />
            <FormattedMessage id={helpTexts.title} />
          </Paragraph>

          <FormattedMessage
            id={helpTexts.content}
            values={{
              para: (chunks) => (
                <Paragraph size={300} color='inherit' lineHeight='normal'>
                  <Small>{chunks}</Small>
                </Paragraph>
              ),
              ul: (chunks) => <Ul>{chunks}</Ul>,
              li: (chunks) => (
                <Li size={300} color='inherit' lineHeight='normal'>
                  <Small>{chunks}</Small>
                </Li>
              ),
              link: (chunks) => (
                <Link size={300}>
                  <Small>{chunks}</Small>
                </Link>
              ),
              br: <br />,
            }}
          />

          {helpTexts.usedBy && (
            <Paragraph size={300} color='inherit' marginTop={spacing.xs}>
              <Small>
                <FormattedMessage id={helpTexts.usedBy} />
              </Small>
            </Paragraph>
          )}
        </>
      ) : (
        children
      )}

      <Pane
        display='flex'
        flexDirection='column'
        alignItems={isDialog ? 'center' : 'flex-start'}
        justifyContent='flex-end'
        flexGrow={1}
      >
        {isDialog && <Divider width='100%' marginY={spacing.xs} />}

        <CloseButton onClick={onClose} $isDialog={isDialog}>
          <FormattedMessage id='close' />
        </CloseButton>
      </Pane>
    </Container>
  )
}
