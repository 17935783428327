import * as React from 'react'
import { Button, Pane, Text, WarningSignIcon } from 'evergreen-ui'
import storage from '/~/services/storageFactory'

const LocalStorageNotSupported = () => {
  const [open, setOpen] = React.useState(!storage.isSupported())

  if (!open) {
    return null
  }

  return (
    <Pane display='flex' flexDirection='column' alignItems='center'>
      <WarningSignIcon />
      <Text size={300} color='error' textAlign='center'>
        <b>ADVARSEL</b>
      </Text>
      <Text size={300} textAlign='center'>
        Din nettleser støtter ikke informasjonskapsler!
      </Text>
      <Text size={300} textAlign='center'>
        Dette vil medføre ustabilitet ved bruk av siden og man kan oppleve å bli logget ut automatisk.
      </Text>
      <Text size={300} textAlign='center'>
        Er nettleseren i privatmodus?
      </Text>
      <Button appearance='primary' size='small' onClick={() => setOpen(false)}>
        Ignorer advarsel
      </Button>
    </Pane>
  )
}

export default LocalStorageNotSupported
