import * as React from 'react'
import { useLocation } from 'react-router'

interface Props {
  url?: string
}

const knownPaths = [
  {
    localPath: '/blogg',
    externalPath: 'https://blogg.folkeinvest.no',
  },
  {
    localPath: '/hjelpesenter',
    externalPath: 'https://hjelpesenter.folkeinvest.no',
  },
  {
    localPath: '/podcast',
    externalPath: 'https://podcast.folkeinvest.no/podcast',
  },
  {
    localPath: '/folkelekene',
    externalPath: 'https://folkelekene.folkeinvest.no',
  },
] as const

export const RedirectExternal = ({ url }: Props) => {
  const location = useLocation()
  React.useEffect(() => {
    const match = knownPaths.find((path) => path.localPath === location.pathname)
    if (url || match) {
      window.location.replace(url || match.externalPath)
    }
  }, [url, location.pathname])
  return null
}
