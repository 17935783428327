import { Button, Checkbox, Link, Pane, PaneProps, Paragraph } from 'evergreen-ui'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { Caption } from '/fiweb/components'
import { MitIdLogo, SwedishBankIdLogo } from '/fiweb/components/Icons'
import { SignicatProvider } from '/fiweb/lib/auth'
import { useCustomTheme } from '/fiweb/lib/theme'

const SignicatButton = styled(Button).attrs({ size: 'small' })<{ $accepted: boolean }>`
  font-size: 12px;
  min-width: 150px;
  ${(props) =>
    !props.$accepted &&
    css`
      filter: grayscale(100%);
      background: ${props.theme.colors.fiDark20};
      border-color: ${props.theme.colors.fiDark40};
    `}
  color: ${({ theme: { colors } }) => colors.fiDark70};
  > svg {
    margin-left: 6px;
  }
`

interface Props {
  onToggle: (toggle: boolean) => void
  startVerificationProcess: (provider: SignicatProvider) => void
}

export const InternationalBankID = ({ onToggle, startVerificationProcess, ...paneProps }: Props & PaneProps) => {
  const [show, setShow] = React.useState(false)
  const { spacing } = useCustomTheme()
  const [accepted, setAccepted] = React.useState(false)

  React.useEffect(() => {
    onToggle(!show)
  }, [show])

  return (
    <Pane display='flex' flexDirection='column' rowGap={spacing.xs} {...paneProps}>
      {show && (
        <Pane display='flex' flexDirection='column' alignSelf='flex-start' rowGap={spacing.xs} marginTop={spacing.xs}>
          <Paragraph size={300}>Verifiser med Dansk MitID eller Svensk BankID.</Paragraph>
          <Caption>
            <i>
              <b>
                NB: Ved å verifisere med svensk eller dansk bank-id, vil dette bli eneste påloggingsmulighet i
                fremtiden. Det vil ikke være mulig å logge inn med passord eller google. Du må bekrefte at dette er
                forstått før du kan fortsette.
              </b>
            </i>
          </Caption>
          <Checkbox
            checked={accepted}
            onChange={() => setAccepted(!accepted)}
            label='Jeg forstår at fremtidige innlogginger må utføres med BankID'
          />
          <Pane display='flex' flexFlow='row wrap'>
            <SignicatButton
              $accepted={accepted}
              onClick={() => startVerificationProcess(SignicatProvider.DANISH_MIT_ID)}
              disabled={!accepted}
            >
              <MitIdLogo width={56} />
            </SignicatButton>
            <SignicatButton
              $accepted={accepted}
              onClick={() => startVerificationProcess(SignicatProvider.SWEDISH_BANK_ID)}
              disabled={!accepted}
            >
              <SwedishBankIdLogo />
            </SignicatButton>
          </Pane>
        </Pane>
      )}
      <Caption is={Link} cursor='pointer' size={300} onClick={() => setShow(!show)}>
        {show ? 'Norsk statsborger?' : 'Svensk eller dansk statsborger?'}
      </Caption>
    </Pane>
  )
}
