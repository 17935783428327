import * as React from 'react'
import { Button, Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useUpdateProfileMutationMutation } from '/~/types/graphql'

interface Props {
  onClose: () => void
}

export const AllreadyRegistered = ({ onClose }: Props) => {
  const [updateProfile] = useUpdateProfileMutationMutation()
  const theme = useCustomTheme()

  const gotoInvestments = async () => {
    try {
      await updateProfile({ variables: { input: { isInvestor: true } } })
    } catch (_err) {
      /* Do nothing */
    }

    window.location.href = '/kampanjer'
  }

  const gotoCampaignCreation = async () => {
    try {
      await updateProfile({
        variables: { input: { isEntrepreneur: true } },
      })
    } catch (_err) {
      /* Do nothing */
    }

    window.location.href = '/sok-kapital-kontakt'
  }

  return (
    <Pane display='flex' flexDirection='column' maxWidth={380} marginTop={theme.spacing.l} alignItems='center'>
      <Heading is='h3'>Profil finnes allerede</Heading>

      <Paragraph size={300} textAlign='center'>
        Vi har logget deg inn.
      </Paragraph>
      <Paragraph size={300} textAlign='center' marginBottom={theme.spacing.m}>
        Hvordan vil du fortsette?
      </Paragraph>

      <Button
        onClick={gotoInvestments}
        size='small'
        maxWidth={244}
        width='100%'
        appearance='primary'
        marginBottom={theme.spacing.xs}
      >
        Se investeringstilbud
      </Button>

      <Button
        onClick={gotoCampaignCreation}
        size='small'
        maxWidth={244}
        width='100%'
        appearance='primary'
        marginBottom={theme.spacing.xs}
      >
        Søk kapital
      </Button>

      <Button onClick={onClose} size='small' maxWidth={244} width='100%' appearance='primary'>
        Lukk registreringen
      </Button>
    </Pane>
  )
}
