import { IconButton, RefreshIcon, Tooltip } from 'evergreen-ui'
import * as React from 'react'
import styled, { css } from 'styled-components'

const StyledIconButton = styled(IconButton)`
${({ theme }) => css`

  border: none;
  &:not([disabled]):hover {
    background-color: ${theme.colors.fiBlue20};
    border: none;
    box-shadow: none;
    outline: none;
  }
`}`

interface Props {
  onClick: () => void
  tooltip?: string
}

export const RefreshButton = ({ onClick, tooltip }: Props) => {
  return (
    <Tooltip content={tooltip ?? 'Oppfrisk'}>
      <StyledIconButton size='small' icon={RefreshIcon} onClick={onClick} />
    </Tooltip>
  )
}
