import * as React from 'react'
import { Paragraph, Small, Strong, Spinner } from 'evergreen-ui'
import { useAuthContext } from '/~/utils/AuthContext'

const ProfileEmailStatus = () => {
  const { user, loading, startPolling, stopPolling } = useAuthContext()

  React.useEffect(() => {
    startPolling(1000)
    return () => stopPolling()
  }, [])

  React.useEffect(() => {
    if (user?.emailVerified) {
      stopPolling()
    }
  }, [user.emailVerified])

  if (!user) {
    return null
  }
  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <Paragraph size={300}>
        <Strong size={300}>E-post</Strong>
      </Paragraph>
      <Paragraph size={300}>
        <Small>Følg anvisningene sendt til:</Small>
      </Paragraph>
      <Paragraph size={300}>
        <Strong size={300}>
          <Small>{user.email}</Small>
        </Strong>
      </Paragraph>
    </>
  )
}

export default ProfileEmailStatus
