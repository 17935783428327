import * as React from 'react'
import { Pane, Paragraph, Strong } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import IconCheck from '/~/static/icons/IconCheck.svg'

interface Props {
  hidden?: boolean
  title: string
  description: string
}

const StatusDoneComponent = ({ hidden, title, description }: Props) => {
  const theme = useCustomTheme()
  return (
    <Pane marginBottom={theme.spacing.xs} height={74} width='100%'>
      <Pane width='100%' height='100%' borderRadius={2} backgroundColor='rgba(122, 166, 104, 0.15)' hidden={hidden}>
        <Pane display='flex' flexFlow='row nowrap' alignItems='center' justifyContent='space-between' height='100%'>
          <Pane marginLeft={18}>
            <Paragraph size={300}>
              <Strong size={300}>{title}</Strong>
            </Paragraph>
            <Paragraph size={300}>{description}</Paragraph>
          </Pane>

          <img src={IconCheck} alt='OK ikon' width='20' style={{ marginRight: '30px' }} />
        </Pane>
      </Pane>
    </Pane>
  )
}

export default StatusDoneComponent
