import * as React from 'react'
import { IconDirection, IconProps } from './types'

interface CTAArrowProps extends IconProps {
  direction: IconDirection
}

export const CTAArrow = ({ direction, title, ...rest }: CTAArrowProps) => (
  <svg
    width='16'
    height='auto'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    transform={`rotate(${direction})`}
    {...rest}
  >
    <title>{title || ''}</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.735392 4.85597C1.91448 2.00941 4.69219 0.153418 7.77327 0.153427C11.9788 0.157286 15.3871 3.56559 15.391 7.77113C15.391 10.8522 13.535 13.6299 10.6884 14.809C7.84189 15.9881 4.56536 15.3364 2.3867 13.1577C0.208046 10.979 -0.443693 7.70252 0.735392 4.85597ZM7.13744 11.7489L7.32543 11.9369C7.44386 12.0558 7.60523 12.122 7.77309 12.1205C7.94094 12.119 8.1011 12.0499 8.21735 11.9288L11.7788 8.231C12.021 7.97352 12.0226 7.57242 11.7824 7.31305L8.28151 3.61748C8.16693 3.49611 8.00805 3.42635 7.84116 3.42416C7.67426 3.42196 7.5136 3.4875 7.39586 3.60582L7.20833 3.79336C6.96077 4.04727 6.94983 4.44878 7.1832 4.71579L8.64312 6.34979C8.87642 6.61001 8.78086 6.82312 8.43181 6.82312H4.30419C4.13579 6.82303 3.97426 6.88988 3.85518 7.00896C3.7361 7.12803 3.66925 7.28956 3.66934 7.45797L3.6671 8.08832C3.667 8.25673 3.73386 8.41826 3.85294 8.53733C3.97201 8.65641 4.13354 8.72326 4.30194 8.72317L8.4188 8.72138C8.76785 8.72138 8.86027 8.93224 8.62473 9.18932L7.1177 10.8318C6.88185 11.0951 6.89048 11.4961 7.13744 11.7489Z'
      fill='#0D7DC2'
    />
  </svg>
)
