import * as React from 'react'
import { PaneProps, TextInput } from 'evergreen-ui'
import PhoneInput from 'react-phone-number-input'
import ControlledPhoneInput from 'react-phone-number-input/react-hook-form'
import { useIntl } from 'react-intl'
import 'react-phone-number-input/style.css'
import nbLabels from 'react-phone-number-input/locale/nb.json'
import { Control, RegisterOptions } from 'react-hook-form'
import { CountryCode } from 'libphonenumber-js'
import { InputWrapper, InputWrapperProps } from './InputWrapper'
import styled, { css } from 'styled-components'

type FormHookRules = Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>

interface ComponentProps {
  name: string
  value?: string
  placeholder?: string
  disabled?: boolean
  onChange?: (e: React.ChangeEvent) => void
  hookForm?: {
    control: Control<any, object>
    rules: FormHookRules
  }
  required?: boolean
}

type Props = ComponentProps & PaneProps & InputWrapperProps

const sharedStyle = css`
  border: 1px solid rgb(171, 173, 179);
  input {
    border: none;
  }
  /* 
    For some reason, 'select' won't work. 
    Hopefully the classname of this component stays put 
  */
  .PhoneInputCountry {
    padding-left: 13px;
    padding-right: 13px;
    margin: 0px;
    border-right: 1px solid rgb(171, 173, 179);
  }
`

const StyledControlledPhoneInput = styled(ControlledPhoneInput)`
  ${sharedStyle}
  background: ${({ disabled, theme: { colors } }) => (disabled ? colors.fiDark10 : 'white')};
`
const StyledPhoneInput = styled(PhoneInput)`
  ${sharedStyle}
  background: ${({ disabled, theme: { colors } }) => (disabled ? colors.fiDark10 : 'white')};
`

export const PhoneField = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  disabled,
  error,
  layout,
  caption,
  hookForm,
  required,
  prefix,
  suffix,
  ...paneProps
}: Props) => {
  const intl = useIntl()
  const uid = React.useMemo(() => `phone-field-label-${new Date().getTime()}`, [])

  const commonProps = {
    international: true,
    labels: nbLabels,
    defaultCountry: intl.formatMessage({ id: 'countryCode' }) as CountryCode,
    inputComponent: TextInput,
    name,
    placeholder,
    disabled,
  }

  return (
    <InputWrapper
      uid={uid}
      error={error}
      label={label}
      required={required}
      caption={caption}
      layout={layout}
      prefix={prefix}
      suffix={suffix}
      {...paneProps}
    >
      {hookForm ? (
        <StyledControlledPhoneInput
          {...commonProps}
          control={hookForm.control}
          rules={hookForm.rules}
          style={{ width: '100%' }}
        />
      ) : (
        <StyledPhoneInput
          {...commonProps}
          onChange={(newValue: string) => (onChange ? onChange({ target: { value: newValue } } as any) : undefined)}
          value={value}
        />
      )}
    </InputWrapper>
  )
}
