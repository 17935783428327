import moment from 'moment'

const dateString = 'DD-MM-YYYY'
const timeString = 'HH:mm'
const dateTimeString = `${dateString} ${timeString}`

// Date formatter
const df = (timestamp) => {
  if (!timestamp) {
    return 'Ugyldig tidspunkt'
  }
  const mom = moment(timestamp)
  return mom.format(dateString)
}

// Time formatter
const tf = (timestamp) => {
  if (!timestamp) {
    return 'Ugyldig tidspunkt'
  }
  const mom = moment(timestamp)
  return mom.format(timeString)
}

// Date AND Time formatter
const dtf = (timestamp) => {
  if (!timestamp) {
    return 'Ugyldig tidspunkt'
  }
  const mom = moment(timestamp)
  return mom.format(dateTimeString)
}

// Number Formatter
const nf = (nr, digits = 2) => {
  if (!Intl) {
    return `${Number(nr).toFixed(digits)}`
  }
  return new Intl.NumberFormat('nb-NO', {
    style: 'decimal',
    useGrouping: true,
    maximumFractionDigits: digits,
  }).format(nr)
}

// Forced decimal formatter
const ndf = (nr, digits = 2) => {
  if (!Intl) {
    return `${Number(nr).toFixed(digits)}`
  }
  return new Intl.NumberFormat('nb-NO', {
    style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(nr)
}

// Currency Formatter
const cf = (nr, digits = 2) => {
  if (!Intl) {
    return `kr ${Number(nr).toFixed(digits)}`
  }

  return new Intl.NumberFormat('nb-NO', {
    style: 'currency',
    currency: 'NOK',
    minimumFractionDigits: digits,
  }).format(nr)
}

const getFileSizeString = (nr) => {
  if (nr === undefined || nr === null) {
    return 'Ukjent'
  }
  if (nr < 1000) {
    return `${nf(nr.toFixed(2))} B`
  }
  if (nr < 1000000) {
    return `${nf((nr / 1000).toFixed(2))} KB`
  }
  if (nr < 1000000000) {
    return `${nf((nr / 1000000).toFixed(2))} MB`
  }
  return `${nf(Math.floor(nr / 1000000000))} GB`
}

export { getFileSizeString, cf, nf, ndf, df, tf, dtf, dateString, timeString, dateTimeString }
