import * as React from 'react'
import { useSetReservationFeedbackComment } from './useSetComment.mutation'
import { Button, HeartIcon, Pane, Paragraph, TextInputField } from 'evergreen-ui'

interface Props {
  reservationID: string
  score: number
}

const Comment = ({ reservationID, score }: Props) => {
  const { setReservationFeedbackComment, data, loading } = useSetReservationFeedbackComment(reservationID)
  const [comment, setComment] = React.useState('')

  return data ? (
    <Pane display='flex' alignItems='center'>
      <Paragraph marginRight={18}>Takk for din tilbakemelding!</Paragraph>
      <HeartIcon size={48} color='danger' />
    </Pane>
  ) : (
    <Pane>
      <TextInputField
        inputHeight={50}
        label={score < 3 ? 'Vil du fortelle oss hvordan vi kan bli bedre?' : 'Har du noen innspill?'}
        value={comment}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setComment(event.target.value)}
      />
      <Pane width='100%' display='flex' justifyContent='flex-end'>
        <Button isLoading={loading} size='small' onClick={() => setReservationFeedbackComment(comment)}>
          Send innspill
        </Button>
      </Pane>
    </Pane>
  )
}

export default Comment
