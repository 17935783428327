import * as React from 'react'
import ExpediencyDialog from '/~/components/Expediency'
import { Button, Heading, InfoSignIcon, Link, Pane, Paragraph, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useAuthContext } from '/~/utils/AuthContext'

interface Props {
  disabled?: boolean
}

const ExpediencyStatus = ({ disabled }: Props) => {
  const [showDialog, setShowDialog] = React.useState(false)
  const theme = useCustomTheme()
  const { user, refetch } = useAuthContext()

  const { expediency } = user

  const handleSignedExpediency = async () => {
    await refetch()
    setShowDialog(false)
  }

  const status = !expediency
    ? 'missing'
    : !expediency?.result
      ? expediency?.outdatedApproval
        ? 'outdatedApproval'
        : 'failed'
      : 'passed'

  const multipleAttempts = expediency?.totalAttempts > 1
  let errorText = null
  let statusText = ''
  let buttonText = null
  if (status === 'passed') {
    return (
      <React.Fragment>
        <Heading is='h5' size={400}>
          Hensiktsmessighet
        </Heading>
        <Paragraph>Hensiktsmessighetstest bestått. Folkeinvest AS ser ingen grunn til fraråding.</Paragraph>
      </React.Fragment>
    )
  }
  switch (status) {
    case 'outdatedApproval':
      statusText = 'Test ikke bestått med oppdaterte krav'
      errorText = 'Grunnet endringer i krav for bestått hensiktsmessighetstest ber vi deg ta testen på nytt.'
      buttonText = 'Ta testen på nytt'
      break
    case 'missing':
      statusText = 'Test ikke gjennomført'
      errorText =
        'Du har ikke gjennomført hensiktsmessighetstest. Uten denne kan du ikke tegne unoterte aksjer på folkeinvest.no.'
      buttonText = 'Gå til test (2 minutter)'
      break
    case 'failed':
      statusText = multipleAttempts ? 'Test fortsatt ikke bestått' : 'Test ikke bestått'
      errorText = multipleAttempts
        ? 'Dine besvarelser på hensiktsmessighetstesten fører til at vi fortsatt fraråder deg å gjennomføre handelen. Søk ytterligere kompetanse ved å klikke på lenken over.'
        : 'Din besvarelse på hensiktsmessighetstesten fører til at vi fraråder deg å gjennomføre handelen. Søk ytterligere kompetanse ved å klikke på lenken over.'
      buttonText = 'Ta testen på nytt'
      break
    default:
  }

  return (
    <Pane>
      <Heading is='h5' size={400}>
        Hensiktsmessighet
      </Heading>
      <Paragraph>
        <em>
          &laquo;Verdipapirforetak plikter å vurdere investeringstilbudets hensiktsmessighet for hver enkelt
          investor&raquo;
        </em>
      </Paragraph>
      <Link
        href='https://hjelpesenter.folkeinvest.no/hensiktsmessighetstest'
        target='_blank'
        rel='noopener'
        marginTop={theme.spacing.xxs}
        display='inline-block'
      >
        <InfoSignIcon marginRight={6} />
        Informasjon om hensiktsmessighet
      </Link>

      <Pane marginTop={theme.spacing.xs}>
        <Paragraph marginBottom={theme.spacing.xxs}>
          Status: <b>{statusText}</b>
        </Paragraph>
        <Text color='danger'>{errorText}</Text>
      </Pane>

      <Button
        marginTop={theme.spacing.s}
        appearance='primary'
        size='small'
        onClick={() => setShowDialog(true)}
        disabled={disabled}
      >
        {buttonText}
      </Button>

      <ExpediencyDialog open={showDialog} onSign={handleSignedExpediency} onClose={() => setShowDialog(false)} />
    </Pane>
  )
}

export default ExpediencyStatus
