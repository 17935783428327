import * as React from 'react'
import { ThemeProvider as MUIThemeProvider, Theme } from '@mui/material/styles'
import moment from 'moment'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { AuthContextProvider } from './utils/AuthContext'
import { ApolloProvider } from '@apollo/client'
import client from './apollo'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { ThemeProvider } from 'evergreen-ui'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { muiTheme } from '/~/utils/muiTheme'
import { clientTheme } from '/fiweb/lib/theme'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import { IntlProvider } from 'react-intl'
import { noNb } from '/fiweb/lib/i18n/no-nb'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { config } from '/~/utils/config'
import { TimeSyncProvider } from '/~/utils/TimeSyncProvider'

import 'typeface-exo'
import 'moment/dist/locale/nb'
import 'dayjs/locale/nb'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  type DefaultTheme = Theme
}

dayjs.extend(customParseFormat)
dayjs.locale('nb')
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Oslo')

moment.defineLocale('shortrel', {
  parentLocale: 'nb',
  relativeTime: {
    future: 'om %s',
    past: '%s siden',
    s: 'noen s',
    ss: '%d s',
    m: 'ett min',
    mm: '%d min',
    h: 'en t',
    hh: '%d t',
    d: 'en d',
    dd: '%d d',
    w: 'en u',
    ww: '%d u',
    M: 'en mnd',
    MM: '%d mnd',
    y: '1 år',
    yy: '%d år',
  },
})
moment.locale('nb')

const Providers: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <MUIThemeProvider theme={muiTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <GoogleOAuthProvider clientId={config.googleClientId}>
            <AuthContextProvider>
              <IntlProvider messages={noNb} locale='no-Nb'>
                <ThemeProvider value={clientTheme as any}>
                  <StyledThemeProvider theme={clientTheme}>
                    <TimeSyncProvider>{children}</TimeSyncProvider>
                  </StyledThemeProvider>
                </ThemeProvider>
              </IntlProvider>
            </AuthContextProvider>
          </GoogleOAuthProvider>
        </LocalizationProvider>
      </MUIThemeProvider>
    </ApolloProvider>
  )
}

export default Providers
