import dayjs from 'dayjs'
import { Pane, Text } from 'evergreen-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import { usePublicInvestmentOfferData, usePublicInvestmentOfferFlags } from '../Context/PublicInvestmentOfferData'
import { useReservedSubscriptionsSummary } from '../Context/ReservedSubscriptionsSummary'
import { DisplayTime } from '/fiweb/components'
import { useBreakpoint, breakpoints, convertFromOreToNokAndFormatCurrency } from '/fiweb/lib'
import { useCustomTheme } from '/fiweb/lib/theme'

const Stats = styled(Pane)`
  ${({ theme: { spacing } }) => css`
    display: flex;
    justify-content: space-between;
    padding: 0 ${spacing.xs};

    @media ${breakpoints.medium} {
      flex-wrap: wrap;
      row-gap: ${spacing.xs};
      padding: 0;
    }
  `}
`

const Stat = styled(Pane)`
  display: flex;
  flex-direction: column;
  width: fit-content;

  @media ${breakpoints.medium} {
    width: 50%;
  }
`

const StatLabel = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme: { colors } }) => colors.fiDark70};
`

const StatValue = styled(Text).attrs({ size: 300, fontWeight: 600 })`
  color: ${({ theme: { colors } }) => colors.fiDark};
`

export const CurrentStats = () => {
  const { colors } = useCustomTheme()
  const { medium } = useBreakpoint()
  const { dates, pricePerShare } = usePublicInvestmentOfferData()
  const { progress, summary } = useReservedSubscriptionsSummary()
  const { isPreview, isReadonly } = usePublicInvestmentOfferFlags() // PREVIEW-HACK

  const beforeOpening = dates ? dayjs().isBefore(dayjs(dates.open)) : true
  const beforeClosing = dates ? dayjs().isBefore(dayjs(dates.close)) : true

  return (
    <Stats>
      <Stat>
        <StatLabel color={colors.fiDark}>
          <FormattedMessage
            id='investmentOfferPage.stats.currentProgressLabel'
            values={{ status: progress.min < 100 ? 'min' : 'max' }}
          />
        </StatLabel>
        <StatValue title={`${(progress.min < 100 ? progress.min : progress.max).toFixed(2)}%`}>
          {/* PREVIEW-HACK */}
          {isPreview || isReadonly ? '-' : <>{Math.floor(progress.min < 100 ? progress.min : progress.max)} %</>}
        </StatValue>
      </Stat>
      <Stat>
        <StatLabel color={colors.fiDark}>
          <FormattedMessage
            id='investmentOfferPage.stats.currentNumberOfSharesLabel'
            values={{ size: medium ? 'full' : '' }}
          />
        </StatLabel>
        {/* PREVIEW-HACK */}
        <StatValue>{isPreview || isReadonly ? '-' : <>{summary.subscriptionsCount}</>}</StatValue>
      </Stat>
      <Stat>
        <StatLabel color={colors.fiDark}>
          <FormattedMessage id='investmentOfferPage.stats.pricePerShareLabel' values={{ size: medium ? 'full' : '' }} />
        </StatLabel>
        <StatValue>{convertFromOreToNokAndFormatCurrency(pricePerShare, false)} kr</StatValue>
      </Stat>
      <Stat>
        <StatLabel color={colors.fiDark}>
          <FormattedMessage
            id='investmentOfferPage.stats.timeToLabel'
            values={{ time: beforeOpening ? 'notopen' : beforeClosing ? 'open' : '' }}
          />
        </StatLabel>
        <StatValue>
          {isReadonly ? (
            '-'
          ) : dates && (beforeOpening || beforeClosing) ? (
            <DisplayTime date={beforeOpening ? dayjs(dates.open).toDate() : dayjs(dates.close).toDate()} />
          ) : dates?.close ? (
            dayjs(dates.close).format('DD.MM.YYYY')
          ) : (
            '-'
          )}
        </StatValue>
      </Stat>
    </Stats>
  )
}
