import * as React from 'react'
import { Button, Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useUpdateProfileMutationMutation } from '/~/types/graphql'
import { useLocation } from 'react-router-dom'

interface Props {
  onClose: () => void
}

const SignupDone = ({ onClose }: Props) => {
  const location = useLocation()
  const state = location.state as { fromPreorder?: string }
  const [updateProfile] = useUpdateProfileMutationMutation()
  const theme = useCustomTheme()

  const fromPreorder = state?.fromPreorder

  const gotoInvestments = async () => {
    try {
      await updateProfile({ variables: { input: { isInvestor: true } } })
    } catch (_err) {
      /* Do nothing */
    }

    window.location.href = '/kampanjer'
  }

  const goToPredorder = async () => {
    try {
      await updateProfile({ variables: { input: { isInvestor: true } } })
    } catch (_err) {
      /* Do nothing */
    }

    window.location.href = `/kampanje/${fromPreorder}/forhandstegning`
  }

  const gotoCampaignCreation = async () => {
    try {
      await updateProfile({
        variables: { input: { isEntrepreneur: true } },
      })
    } catch (_err) {
      /* Do nothing */
    }

    window.location.href = '/sok-kapital-kontakt'
  }

  return (
    <Pane display='flex' flexDirection='column' maxWidth={380} marginTop={theme.spacing.l} alignItems='center'>
      <Heading is='h3'>Gratulerer!</Heading>

      <Paragraph size={300} textAlign='center' marginBottom={theme.spacing.xs}>
        Du har nå fullført profilen og kan både investere og søke kapital til ditt selskap.
      </Paragraph>

      {!fromPreorder && (
        <Paragraph size={300} textAlign='center' marginBottom={theme.spacing.m}>
          Hvordan vil du fortsette?
        </Paragraph>
      )}

      {fromPreorder ? (
        <Button
          onClick={goToPredorder}
          size='small'
          maxWidth={244}
          width='100%'
          appearance='primary'
          marginTop={theme.spacing.s}
        >
          Til investeringstilbud
        </Button>
      ) : (
        <>
          <Button
            onClick={gotoInvestments}
            size='small'
            maxWidth={244}
            width='100%'
            appearance='primary'
            marginBottom={theme.spacing.xs}
          >
            Se investeringstilbud
          </Button>

          <Button
            onClick={gotoCampaignCreation}
            size='small'
            maxWidth={244}
            width='100%'
            appearance='primary'
            marginBottom={theme.spacing.xs}
          >
            Søk kapital
          </Button>
          <Button onClick={onClose} size='small' maxWidth={244} width='100%'>
            Lukk registreringen
          </Button>
        </>
      )}
    </Pane>
  )
}

export default SignupDone
