import React from 'react'
import _ from 'lodash'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useCheckForNewerCampaignsQuery } from '/~/types/graphql'
import { Button, CrossIcon, IconButton, InfoSignIcon, Pane, Paragraph } from 'evergreen-ui'
import styled from 'styled-components'
import { useCustomTheme } from '/fiweb/lib/theme'

const MessageBox = styled(Pane)`
  border: 1px solid ${({ theme }) => theme.colors.fiDark20};
  background-color: white;
  display: flex;
  border-radius: 5px;
  box-shadow: 1px 1px 10px ${({ theme }) => theme.colors.fiDark40};
  flex-direction: column;
  width: 450px;
  max-width: 90vw;
  padding: 25px 10px;
  box-sizing: border-box;
  position: fixed;
  top: 60%;
  z-index: 5;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ButtonRow = styled(Pane)`
  margin-top: 25px;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing.xxs};
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  & > * {
    margin: 0 10px;
  }
`

type Props = {
  campaignId: string
}

export const NewerCampaignExistsWarning = ({ campaignId }: Props) => {
  const [hidden, setHidden] = React.useState(false)
  const { data, error, loading } = useCheckForNewerCampaignsQuery({ variables: { campaignId } })
  const location = useLocation()
  const { campaignSlug } = useParams<{ campaignSlug: string }>()
  const { colors, spacing } = useCustomTheme()

  if (error || loading || !data.getSlugForNewestCampaign || hidden) {
    return null
  }

  const newUrl = `${location.pathname.replace(campaignSlug, data?.getSlugForNewestCampaign)}`

  return (
    <MessageBox>
      <Pane marginY={spacing.xs} display='flex' alignItems='flex-start'>
        <InfoSignIcon size={40} color={colors.fiBlue40} marginRight={spacing.xs} />
        <Pane>
          <Paragraph>Du ser nå en gammel emisjonsside for dette selskapet.</Paragraph>
          <Paragraph>Ønsket du kanskje å lese det nyeste tilbudet?</Paragraph>
        </Pane>
      </Pane>
      <ButtonRow>
        <Button size='small' color='secondary' onClick={() => setHidden(true)}>
          Nei takk
        </Button>
        <Button size='small' appearance='primary' is={Link} to={newUrl}>
          Vis meg nyeste
        </Button>
      </ButtonRow>
      <IconButton
        border='none'
        icon={CrossIcon}
        position='absolute'
        top={0}
        right={0}
        color={colors.fiDark70}
        onClick={() => setHidden(true)}
        size='large'
      />
    </MessageBox>
  )
}
