import * as React from 'react'
import { Button, ButtonProps, toaster } from 'evergreen-ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { useGoogleLogin } from '@react-oauth/google'
import { Message } from '/fiweb/lib/i18n/types'
import { GoogleIcon } from '/fiweb/components/Icons'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props {
  buttonMessage?: Message
  disabled?: boolean
  ignoreIcon?: boolean
  onButtonClicked?: () => void
  onClose?: () => void
  onError?: () => void
  onSuccess: (token: string) => void
}

export const GoogleButton = ({
  buttonMessage = 'auth.loginWithGoogle',
  disabled,
  ignoreIcon,
  onButtonClicked,
  onClose,
  onError,
  onSuccess,
  ...rest
}: Props & ButtonProps) => {
  const { formatMessage } = useIntl()
  const { spacing } = useCustomTheme()

  const [initFailed, setInitFailed] = React.useState(false)

  const errorHandler = () => {
    toaster.danger(formatMessage({ id: 'authError.loginError' }))

    if (onError) {
      onError()
    }
  }

  const initError = (userClosed: boolean) => {
    if (userClosed) {
      onClose()
    } else {
      setInitFailed(true)
      errorHandler()
    }
  }

  const login = useGoogleLogin({
    flow: 'implicit',
    onSuccess: (tokenResponse) => onSuccess(tokenResponse.access_token),
    onError: () => errorHandler(),
    onNonOAuthError: (nonOAuthError) => initError(nonOAuthError.type === 'popup_closed'),
  })

  const clickHandler = () => {
    if (onButtonClicked) {
      onButtonClicked()
    }

    login()
  }

  return (
    <Button size='small' onClick={clickHandler} disabled={disabled || initFailed} gap={spacing.xxs} {...rest}>
      {!ignoreIcon && <GoogleIcon width='19px' height='19px' />}

      <FormattedMessage id={buttonMessage} />
    </Button>
  )
}
