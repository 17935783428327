import * as React from 'react'
import { useBreakpoint, breakpoints } from '/fiweb/lib'
import { Pane, Heading, Paragraph, Image } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled from 'styled-components'
import { config } from '/~/utils/config'

const imageBase = `${config.publicFilesUrl}`

const StyledImage = styled(Image)`
  width: 600px;
  order: 2;
  border-radius: 10px 10px 30px 10px;
  @media ${breakpoints.large} {
    width: 100%;
  }
  margin-top: 20px;
`

export const Caplist = () => {
  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()
  return (
    <Pane
      display='flex'
      paddingX={large ? 25 : 0}
      width='100%'
      maxWidth={1010}
      flexDirection={large ? 'column' : 'row'}
      justifyContent='space-between'
      alignItems={large ? 'flex-start' : 'center'}
      marginBottom={medium ? theme.spacing.l : theme.spacing.xl}
    >
      <Pane
        maxWidth={large ? '100%' : 320}
        marginTop={medium ? theme.spacing.xs : theme.spacing.s}
        order={large ? 3 : 1}
      >
        <Heading size={600} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          Smartere generalforsamlinger med Caplist
        </Heading>
        <Paragraph size={medium ? 400 : 500} marginBottom={theme.spacing.s}>
          Folkeinvest har tatt i bruk Caplist som forenkler og kvalitetssikrer generalforsamlinger og legger til rette
          for at aksjonærer enklere kan engasjere seg og holde seg oppdatert på selskapet
        </Paragraph>
      </Pane>
      <StyledImage src={`${imageBase}/people-in-chairs.jpg`} />
    </Pane>
  )
}
