import * as React from 'react'
import { Link, Pane, Paragraph, Text, Heading } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled from 'styled-components'
import { nf } from '/~/utils/formatters'
import { FetchCampaignQuery } from '/~/types/graphql'
import ReserveShares from './ReserveShares'
import { campaignTimeStatus, useCampaignStatus } from './utils'
import useSummarySubQuery from '/~/hooks/useSummarySubQuery'
import { useBreakpoint } from '/fiweb/lib'
import dayjs from 'dayjs'
import { Divider, ProgressBarWithSpread } from '/fiweb/components'
import { useServerTimeSync } from '/~/utils/TimeSyncProvider'

const InfoPane = styled(Pane)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  margin-bottom: 8px;
`

const SmallText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

interface Props {
  campaign: FetchCampaignQuery['Campaign']
  showKeyInfoOnMount?: boolean
  hideHeader?: boolean
}

const KeyInformation = ({ campaign, showKeyInfoOnMount, hideHeader }: Props) => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()
  const { serverTimeDayjsObject, serverTimeOffset } = useServerTimeSync()

  const [showKeyInfo, setShowKeyInfo] = React.useState(!!showKeyInfoOnMount)

  const { preorderApproved, acceptsPreorders, isClosed } = useCampaignStatus({ campaign, serverTimeDayjsObject })
  const showFollow = acceptsPreorders && !preorderApproved
  /**
   * OLD CODE
   */
  const { summary } = useSummarySubQuery(campaign._id, preorderApproved)
  // const {raisingSharesMaximum, remainingShares} = campaign
  // For old campaigns, we need to calculate some share info
  let raisingSharesMaximum = campaign.raisingSharesMaximum
  let remainingShares = 0
  const minimumShareInvestment = campaign.minimumShareInvestment
  const maximumShareInvestment = campaign.maximumShareInvestment

  // Check the maximum amount of shares value
  if (!raisingSharesMaximum) {
    // Check that we have what we need to calculate it
    if (campaign.pricePerShare && campaign.raisingAmountMaximum) {
      raisingSharesMaximum = Math.floor(Number(campaign.raisingAmountMaximum) / Number(campaign.pricePerShare))
    } else {
      // Else null it
      raisingSharesMaximum = null
    }
  }

  // Calculate the remaining shares if we have what we need
  if (raisingSharesMaximum && summary) {
    remainingShares = raisingSharesMaximum - summary.shares
  }

  // For older campaigns, some values aren't set like they are now. Check them
  // Company value
  let companyValue = campaign.companyValue

  if (!companyValue) {
    companyValue = Number(campaign.pricePerShare) * Number(campaign.numberOfCurrentShares)
  }
  /**
   * OLD CODE END
   */

  const progress = acceptsPreorders && !preorderApproved ? 0 : (summary.shares / campaign.raisingSharesMinimum) * 100
  const success = progress >= 100
  const financedTitle = success ? 'Fulltegnet' : 'Minstemål'
  let financed = '0%'
  if (!acceptsPreorders || preorderApproved) {
    if (!success) {
      financed = `${Math.floor(progress)} %`
    } else {
      financed = `${Math.floor((summary.shares / campaign.raisingSharesMaximum) * 100)} %`
    }
  }

  const campaignIsFrozen = campaign?.status === 'stopped'

  const reservationCount = acceptsPreorders && !preorderApproved ? '0' : nf(summary.count)
  const pricePerShare = `${nf(campaign.pricePerShare)} kr`

  // This is a hook, requires to be called each render
  const calculatedTimeStatus = campaignTimeStatus({ campaign, short: true, serverTimeDayjsObject, serverTimeOffset })
  const timeStatus = campaign.status === 'published' ? calculatedTimeStatus : ['Åpner', '-']

  const keyInfo = [
    { label: financedTitle, value: financed },
    { label: medium ? 'Antall tegninger' : 'Ant. tegninger', value: reservationCount },
    { label: medium ? 'Pris per aksje' : 'Pris pr. aksje', value: pricePerShare },
    { label: timeStatus[0], value: timeStatus[1] },
  ]

  const disclaimer = () => {
    if (campaignIsFrozen) {
      return 'Dette tilbudet er for øyeblikket låst og det vil ikke være mulig å reservere aksjer'
    }
    if (isClosed) {
      return 'Emisjonen er avsluttet og det er derfor ikke mulig å tegne seg for aksjer.'
    }

    if (showFollow && campaign.status === 'published') {
      return `Denne emisjonen åpner om ${timeStatus[1]}, følg emisjonen for å få varsel ved åpning.`
    }

    if (showFollow) {
      return 'Følg emisjonen for å få varsel ved åpning.'
    }
    return 'Før du kjøper aksjer, bør du forstå risikoen dette innebærer. Ikke invester mer enn det du kan tape.'
  }

  const getProgressbarTitle = () => {
    if (
      !preorderApproved &&
      (['draft'].includes(campaign?.status) ||
        !campaign?.startDate ||
        dayjs(campaign?.startDate).isAfter(serverTimeDayjsObject))
    ) {
      return 'Ikke åpnet'
    }
    if (campaignIsFrozen) {
      return 'Ikke tilgjengelig'
    }
    return `${nf(summary.total)} kr`
  }
  const getKeyInfoRow = () => {
    if (!medium) {
      return (
        <Pane display='flex' flexDirection='row' justifyContent='space-between'>
          {keyInfo.map((key) => (
            <Pane key={key.label} display='flex' flexDirection='column' width='fit-content'>
              <SmallText color={theme.colors.fiDark}>{key.label}</SmallText>
              <Text size={300} fontWeight={600} color={theme.colors.fiDark}>
                {key.value}
              </Text>
            </Pane>
          ))}
        </Pane>
      )
    }

    return (
      <Pane display='grid' gridTemplateColumns='auto auto' justifyContent='space-between' rowGap={theme.spacing.xs}>
        {keyInfo.map((key) => (
          <Pane display='flex' flexDirection='column' key={key.label}>
            <SmallText color={theme.colors.fiDark}>{key.label}</SmallText>
            <Text size={300} fontWeight={600} color={theme.colors.fiDark}>
              {key.value}
            </Text>
          </Pane>
        ))}
      </Pane>
    )
  }

  return (
    <>
      <Heading size={600} marginBottom={theme.spacing.xxs}>
        {getProgressbarTitle()}
      </Heading>
      <ProgressBarWithSpread
        progress={progress}
        goal={campaign.raisingAmountMinimum}
        max={campaign.raisingAmountMaximum}
        disabled={isClosed}
        currencyInNok
        largeText
      />
      {medium && !hideHeader && (
        <Pane
          display='flex'
          width='100%'
          justifyContent='space-between'
          cursor={isClosed ? 'initial' : 'pointer'}
          paddingY='18px'
          onClick={() => !isClosed && setShowKeyInfo(!showKeyInfo)}
        >
          <Heading size={400} fontWeight={600}>
            {campaign.companyName}
          </Heading>
          {!(hideHeader || isClosed) && <Link>{showKeyInfo ? 'Skjul aksjeinfo' : 'Vis aksjeinfo'}</Link>}
        </Pane>
      )}

      {!(medium && !showKeyInfo && !isClosed) && (
        <Pane
          display='flex'
          flexDirection='column'
          backgroundColor={theme.colors.fiDark10}
          paddingX='30px'
          paddingY='20px'
          marginTop={theme.spacing.xs}
          marginBottom={medium ? theme.spacing.xs : 0}
          borderRadius='5px'
        >
          {getKeyInfoRow()}

          <Divider marginTop={theme.spacing.xxs} marginBottom={theme.spacing.xs} />

          <InfoPane>
            <Paragraph size={300}>Verdsettelsen:</Paragraph>
            <Paragraph size={300}>{nf(companyValue)} kr</Paragraph>
          </InfoPane>
          <InfoPane>
            <Paragraph size={300}>Aksjer for salg:</Paragraph>
            <Paragraph size={300}>
              {!Number.isNaN(raisingSharesMaximum) ? `${nf(raisingSharesMaximum)}` : 'Ikke oppgitt'}
            </Paragraph>
          </InfoPane>
          <InfoPane>
            <Paragraph size={300}>Gjenstående aksjer:</Paragraph>
            <Paragraph size={300}>
              {!Number.isNaN(remainingShares) ? `${nf(remainingShares)}` : 'Ikke oppgitt'}
            </Paragraph>
          </InfoPane>
          <InfoPane>
            <Paragraph size={300}>Minste tegning:</Paragraph>
            <Paragraph size={300} wordBreak='break-word' textAlign='end'>
              <Text size={300} whiteSpace='nowrap'>
                {nf(minimumShareInvestment)} {minimumShareInvestment !== 1 ? 'aksjer' : 'aksje'}
              </Text>
              &nbsp;
              <Text size={300} whiteSpace='nowrap'>
                ({nf(campaign?.minimumInvestment)} kr)
              </Text>
            </Paragraph>
          </InfoPane>
          <InfoPane>
            <Paragraph size={300}>Maks tegning:</Paragraph>
            <Paragraph size={300} wordBreak='break-word' textAlign='end'>
              <Text size={300} whiteSpace='nowrap'>
                {nf(maximumShareInvestment)} {maximumShareInvestment !== 1 ? 'aksjer' : 'aksje'}
              </Text>
              &nbsp;
              <Text size={300} whiteSpace='nowrap'>
                ({nf(campaign?.maximumInvestment)} kr)
              </Text>
            </Paragraph>
          </InfoPane>
        </Pane>
      )}
      {!medium && (
        <Pane display='flex' marginTop={theme.spacing.xs}>
          {!(campaignIsFrozen || isClosed) ? <ReserveShares campaign={campaign} /> : null}
          <Text fontSize='14px' lineHeight='20px' width='330px' marginLeft={isClosed ? 0 : 15}>
            {disclaimer()}
          </Text>
        </Pane>
      )}
    </>
  )
}

export default KeyInformation
