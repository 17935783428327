import React from 'react'

export const NavLogo = ({ inverted = false, small = false, height = 33 }) => {
  const svgHeight = height
  const baseCss = `
    .st0{
        ${small ? 'display:none;' : 'display:block'}
    }
    `
  const normalCss = `
    .st0{fill:#2D3340;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#2D3340;}
	.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#7AA668;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#0D7DC2;}
    `
  const invertedCss = `
    .st0{fill:#D1CCC0;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#D1CCC0;}
	.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#7C5A91;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#C78442;}
    `

  return (
    <svg
      height={svgHeight}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 221 33'
    >
      <title>Folkeinvest Logo</title>
      <desc>Folkeinvest sin logotype.</desc>
      <style type='text/css'>
        {baseCss}
        {inverted ? invertedCss : normalCss}
      </style>
      <path
        className='st0'
        d='M110.2,9.2c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.6-0.4,1.1v19.7c0,0.4,0.1,0.8,0.4,1.1
	c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.7,0.5-1.1V10.8c0-0.4-0.2-0.8-0.5-1.1C111,9.4,110.6,9.2,110.2,9.2z'
      />
      <path
        className='st0'
        d='M128.9,8.6c-1.6,0-3.2,0.4-4.7,1.3c-1.3,0.7-2.3,1.6-3.1,2.6v-1.7c0-0.4-0.2-0.8-0.5-1.1
	c-0.3-0.3-0.6-0.5-1.1-0.5c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.6-0.4,1.1v19.7c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4
	c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.7,0.5-1.1V17.7c0-1.1,0.3-2.1,1-3c0.7-1,1.6-1.7,2.6-2.3c1.1-0.6,2.3-0.9,3.5-0.9
	c1.9,0,3.3,0.6,4.2,1.7c0.9,1.1,1.4,2.6,1.4,4.5v12.8c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4
	c0.3-0.3,0.5-0.7,0.5-1.1V17.6c0-2.7-0.7-4.9-2-6.5C133.8,9.4,131.7,8.6,128.9,8.6z'
      />
      <path
        className='st0'
        d='M160.7,9c-0.3-0.2-0.6-0.4-1-0.4c-0.3,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.4,0.4-0.6,0.7L151,27l-7.3-17.3
	c-0.1-0.3-0.3-0.5-0.6-0.7c-0.2-0.2-0.5-0.3-0.8-0.3c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.4,0.6-0.4,1c0,0.1,0,0.3,0.1,0.5
	c0.1,0.1,0.1,0.2,0.1,0.2l8.7,20.3c0.1,0.3,0.3,0.6,0.6,0.8c0.2,0.1,0.5,0.2,0.8,0.2c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.1,0.8-0.3
	c0.3-0.2,0.5-0.4,0.6-0.7l8.6-20.2c0.1-0.3,0.2-0.6,0.2-0.8C161.2,9.6,161.1,9.3,160.7,9z'
      />
      <path
        className='st0'
        d='M178.9,9.8c-1.6-0.8-3.2-1.3-5-1.3c-1.8,0-3.6,0.5-5.2,1.5c-1.6,1-2.9,2.4-3.9,4.2c-1,1.8-1.4,4-1.4,6.4
	c0,2.3,0.5,4.4,1.5,6.2c1,1.8,2.4,3.1,4.1,4.1c1.8,1,3.8,1.5,6.1,1.5c1.6,0,3.2-0.4,4.6-1.1c1.5-0.7,2.7-1.7,3.6-2.9
	c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.1-0.7-0.4-0.9c-0.5-0.5-1.2-0.6-2,0.1c-0.6,0.9-1.5,1.6-2.6,2.2c-1.1,0.6-2.3,0.9-3.7,0.9
	c-1.8,0-3.3-0.4-4.6-1.2c-1.3-0.8-2.3-1.9-3-3.2c-0.7-1.3-1-2.8-1-4.4h16.4c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.7,0.5-1.1
	c0-2.2-0.5-4.1-1.5-5.7C181.8,11.9,180.5,10.7,178.9,9.8z M173.9,11.6c1.1,0,2.2,0.3,3.3,0.8c1.1,0.5,2,1.2,2.7,2.2
	c0.7,0.9,1.1,2,1.3,3.3v0.1h-14.5c0.4-2,1.3-3.6,2.6-4.7C170.7,12.2,172.3,11.6,173.9,11.6z'
      />
      <path
        className='st0'
        d='M201.4,20.4c-1-0.5-2.3-0.9-3.8-1.3c-1.9-0.5-3.4-1-4.4-1.6c-0.9-0.6-1.4-1.4-1.5-2.5c0-1.2,0.4-2.1,1.3-2.7
	c1-0.6,2.1-0.9,3.4-0.9c1.1,0,2.1,0.2,3,0.6c0.9,0.4,1.7,1,2.4,1.8c0.3,0.3,0.6,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.3
	c0.3-0.3,0.5-0.7,0.5-1.1c0-0.5-0.2-0.8-0.5-1c-0.9-1.1-2.1-1.9-3.4-2.3c-1.3-0.5-2.6-0.8-3.9-0.8c-1.5,0-2.9,0.3-4.1,0.8
	c-1.2,0.5-2.1,1.3-2.8,2.3c-0.7,1-1.1,2.1-1.1,3.3c0,2,0.7,3.5,2.2,4.5c1.4,1,3.3,1.8,5.8,2.4c1.7,0.4,3,1,4,1.6
	c0.9,0.6,1.3,1.4,1.3,2.6c0,1.2-0.5,2.1-1.5,2.7c-1,0.6-2.2,0.9-3.5,0.9c-2.8,0-5-1.1-6.7-3.4c-0.3-0.3-0.7-0.5-1.1-0.5
	c-0.3,0-0.6,0.1-0.8,0.3c-0.4,0.2-0.7,0.6-0.7,1.1c0,0.3,0.1,0.7,0.4,1.1l0,0c2.1,2.7,5.1,4.1,8.7,4.1c2.3,0,4.2-0.6,5.8-1.7
	c1.6-1.2,2.4-2.8,2.4-4.7c0-1.4-0.3-2.6-1-3.5C203.3,21.6,202.5,20.9,201.4,20.4z'
      />
      <path
        className='st0'
        d='M218.9,12.1c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.2-0.7-0.5-1c-0.3-0.3-0.6-0.5-1-0.5h-4.2V4.6
	c0-0.4-0.2-0.8-0.5-1.1C214,3.2,213.6,3,213.2,3c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.6-0.4,1.1v4.7h-3c-0.4,0-0.8,0.1-1.1,0.4
	c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.8,0.4,1c0.3,0.3,0.7,0.4,1.1,0.4h3v14.6c0,1.7,0.3,2.9,0.9,3.7c0.6,0.8,1.3,1.2,1.9,1.4
	c0.6,0.1,1.4,0.2,2.2,0.2c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,0.8-0.8,0.8-1.2c0-0.4-0.1-0.7-0.4-0.9c-0.2-0.3-0.5-0.4-0.8-0.4
	c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.5,0.1-0.6,0.1c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.4-0.6-1.1-0.6-2.1V12.1H218.9z'
      />
      <path className='st0' d='M110.2,2.4c-1.1,0-2,0.9-2,2s0.9,2,2,2c1.1,0,2-0.9,2-2S111.3,2.4,110.2,2.4z' />
      <path
        className='st0'
        d='M39.5,10.2c-1.7-1-3.7-1.5-5.8-1.5c-2.1,0-4.1,0.5-5.8,1.5c-1.7,1-3.1,2.5-4.2,4.3c-1,1.8-1.5,3.9-1.5,6.1
	c0,2.3,0.5,4.3,1.5,6.1c1,1.8,2.4,3.2,4.1,4.2l0,0c1.8,1,3.7,1.5,5.8,1.5c2.1,0,4.1-0.5,5.8-1.5c1.8-1,3.2-2.4,4.2-4.2
	c1-1.8,1.5-3.8,1.5-6.1c0-2.3-0.5-4.3-1.5-6.1C42.7,12.6,41.3,11.2,39.5,10.2z M40.5,20.6c0,1.4-0.3,2.7-0.9,3.9v0
	c-0.6,1.1-1.4,2-2.5,2.7c-1,0.6-2.2,0.9-3.4,0.9c-1.3,0-2.4-0.3-3.5-0.9c-1-0.6-1.8-1.5-2.5-2.7c-0.6-1.1-0.9-2.4-0.9-3.9
	c0-1.5,0.3-2.8,0.9-3.9c0.6-1.2,1.4-2,2.5-2.7c1-0.6,2.2-1,3.5-1c1.3,0,2.4,0.3,3.4,1c1,0.6,1.9,1.5,2.5,2.7
	C40.2,17.8,40.5,19.1,40.5,20.6z'
      />
      <path
        className='st0'
        d='M52.4,0c-0.7,0-1.2,0.2-1.7,0.7c-0.5,0.5-0.7,1-0.7,1.7v27.3c0,0.7,0.2,1.2,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7
	s1.2-0.2,1.7-0.7c0.5-0.5,0.7-1,0.7-1.7V2.4c0-0.7-0.2-1.2-0.7-1.7C53.7,0.2,53.1,0,52.4,0z'
      />
      <path
        className='st0'
        d='M71.5,19.4l7-6.3l0,0c0.6-0.5,0.9-1.1,0.9-1.8c0-0.5-0.2-1.1-0.7-1.5c-0.9-1-2.2-1-3.3,0l-9.8,9V2.4
	c0-0.7-0.2-1.2-0.7-1.7C64.6,0.2,64,0,63.3,0c-0.7,0-1.3,0.2-1.7,0.7c-0.4,0.5-0.6,1-0.6,1.7v27.3c0,0.7,0.2,1.2,0.7,1.7
	c0.5,0.5,1,0.7,1.7,0.7c0.7,0,1.2-0.2,1.7-0.7c0.5-0.5,0.7-1,0.7-1.7v-5.1l2.2-1.9l8.1,8.6c0.4,0.5,1,0.8,1.7,0.8
	c0.6,0,1.1-0.2,1.6-0.6c0.5-0.5,0.8-1,0.8-1.7c0-0.6-0.2-1.2-0.7-1.7L71.5,19.4z'
      />
      <path
        className='st0'
        d='M102.6,21.3c0.5-0.4,0.7-1,0.7-1.6c0-2.1-0.5-4-1.5-5.7c-1-1.7-2.3-3-3.9-4c-1.6-1-3.4-1.4-5.3-1.4
	c-1.9,0-3.7,0.5-5.3,1.5c-1.6,1-3,2.4-4,4.3l0,0c-1,1.8-1.5,4-1.5,6.4c0,2.2,0.5,4.2,1.4,6c1,1.8,2.4,3.2,4.2,4.2
	c1.8,1,3.9,1.5,6.2,1.5c1.5,0,2.9-0.3,4.3-0.9c1.4-0.6,2.5-1.3,3.4-2.1c0.6-0.6,1-1.2,1-1.9c0-0.5-0.2-1-0.6-1.4
	c-0.7-0.7-1.7-0.9-2.9-0.2l0,0c-0.6,0.6-1.3,1.1-2.2,1.5c-0.8,0.4-1.9,0.6-3.2,0.6c-1.9,0-3.5-0.6-4.8-1.8c-1.2-1.1-1.9-2.6-2.2-4.3
	h14.5C101.6,21.9,102.1,21.7,102.6,21.3z M98.4,17.8H86.7c0.4-1.6,1.1-2.7,2.1-3.5c1.1-0.8,2.4-1.3,3.9-1.3c1.4,0,2.7,0.5,3.8,1.4
	C97.6,15.3,98.2,16.4,98.4,17.8z'
      />
      <path
        className='st1'
        d='M20.8,5.8c0,1.7-1.3,3-3,3H5.6C3.2,8.8,1.2,10,0,11.9V6.2c0-1.8,1.5-3.4,3.4-3.4h0.2l0,0l0,0h14.3
	C19.5,2.8,20.8,4.2,20.8,5.8z'
      />
      <path
        className='st2'
        d='M18,17.8c0,1.7-1.3,3-3,3H6.7v0H3.3c-1.3,0-2.5,0.6-3.3,1.5v-7c0.1-3,2.5-5.5,5.6-5.5h1.1v5H15
	C16.7,14.9,18,16.2,18,17.8z'
      />
      <path
        className='st3'
        d='M6.7,21.9v7.2c0,1.8-1.5,3.4-3.4,3.4C1.5,32.5,0,31,0,29.1v-3.9c0-0.2,0-0.5,0.1-0.7c0.3-1.5,1.7-2.6,3.3-2.6
	H6.7z'
      />
    </svg>
  )
}

export default NavLogo
