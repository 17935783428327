import * as React from 'react'
import { Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import { FormattedMessage } from 'react-intl'

import { SuccessfulCampaignsList } from '/~/components/'

export const SuccessfulIssues = () => {
  const { spacing } = useCustomTheme()
  const { small, large } = useBreakpoint()

  return (
    <Pane marginBottom={large ? spacing.m : spacing.l}>
      <Heading is='h2' size={600} textAlign='initial'>
        <FormattedMessage id='successfulIssues.heading' />
      </Heading>
      <Paragraph size={small ? 400 : 500} marginBottom={large ? '35px' : '50px'} textAlign='initial'>
        <FormattedMessage id='entrepreneurLanding.successfulIssues.subtitle' />
      </Paragraph>
      <SuccessfulCampaignsList />
    </Pane>
  )
}
