import { Button, Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useBreakpoint } from '/fiweb/lib'

const MoreInformation = () => {
  const navigate = useNavigate()
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Pane
      width='100%'
      backgroundColor='white'
      display='flex'
      flexDirection='column'
      alignItems='center'
      height={411}
      paddingY={medium ? 40 : 120}
    >
      <Heading size={medium ? 500 : 600} marginTop={medium ? theme.spacing.l : 0}>
        Andre henvendelser
      </Heading>

      <Paragraph size={medium ? 400 : 500} marginBottom={medium ? theme.spacing.xs : theme.spacing.m}>
        Trenger du mer informasjon?
      </Paragraph>
      <Pane
        display='flex'
        flexDirection={medium ? 'column' : 'row'}
        alignItems='center'
        marginBottom={medium ? theme.spacing.l : theme.spacing.xl}
      >
        <Button
          appearance='primary'
          width={medium ? 'fit-content' : 182}
          marginBottom={medium ? theme.spacing.xs : 0}
          marginRight={medium ? 0 : 22}
          onClick={() => navigate('/kontakt-oss')}
        >
          Ta Kontakt
        </Button>
        <Button width={medium ? 'fit-content' : 282} onClick={() => navigate('/statistikk')}>
          Se på statistikksiden{' '}
        </Button>
      </Pane>
    </Pane>
  )
}

export default MoreInformation
