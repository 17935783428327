import moment from 'moment'
import { ProfileAddressInput } from '../types/graphql'
import { isValidPhoneNumber } from 'libphonenumber-js'

interface CheckedUser {
  birthdate?: string
  addressFields?: ProfileAddressInput
  acceptedLatestTerms?: boolean
  phone?: string
  pep?: boolean
  emailVerified?: boolean
  bankidverified?: boolean
}

export type CheckedProfileField = 'bankid' | 'email' | 'address' | 'terms' | 'underaged' | 'phone' | 'pep'

export const checkOver18 = (user: CheckedUser) => {
  if (!user) {
    return false
  }
  const birthdate = user.birthdate
  if (!birthdate) {
    return false
  }
  const eighteen = moment().subtract(18, 'years')
  const birth = moment(birthdate, 'YYYY-MM-DD')
  const days = moment(eighteen).diff(birth, 'days')
  const is_legal = days > 0
  return is_legal
}

export const addressValid = (user: CheckedUser) => {
  if (!user?.addressFields) {
    return false
  }

  const { countryCode, postalCode, postalTown, street } = user.addressFields
  return !!countryCode && !!postalCode && !!street && !!postalTown
}

export const checkLatestTerms = (user: CheckedUser) => {
  if (!user) {
    return false
  }
  return user.acceptedLatestTerms
}

export const isPhoneValid = (user: CheckedUser) => {
  if (!user?.phone) {
    return false
  }
  return isValidPhoneNumber(user.phone)
}

export const reportedPep = (user: CheckedUser) => {
  if (user.pep === true || user.pep === false) {
    return true
  }
  return false
}

export const isUserValid = (user: CheckedUser) => {
  if (!user) {
    return false
  }
  return (
    user.emailVerified &&
    user.bankidverified &&
    checkOver18(user) &&
    addressValid(user) &&
    checkLatestTerms(user) &&
    isPhoneValid(user)
  )
}

export const getMissingProfileFields = (user: CheckedUser): CheckedProfileField[] => {
  if (!user) {
    return []
  }
  const missing = []
  if (!user.bankidverified) {
    missing.push('bankid')
  }
  if (!user.emailVerified) {
    missing.push('email')
  }
  if (!addressValid(user)) {
    missing.push('address')
  }
  if (!checkLatestTerms(user)) {
    missing.push('terms')
  }
  if (!checkOver18(user)) {
    missing.push('underaged')
  }
  if (!isPhoneValid(user)) {
    missing.push('phone')
  }
  if (!reportedPep(user)) {
    missing.push('pep')
  }
  return missing
}
