import * as React from 'react'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { useBreakpoint } from '/fiweb/lib'
import { Pane, Heading, Paragraph, Button, Link } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props {
  archived?: boolean
  expired?: boolean
}

export const Page404: React.FC<Props> = ({ archived, expired }) => {
  const navigate = useNavigate()
  const { small, medium, large } = useBreakpoint()
  const theme = useCustomTheme()
  const radiusCircle = medium ? 250 : large ? 300 : 350
  const radiusCorner = medium ? 0 : large ? 150 : 250

  return (
    <Pane width='100%' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
      <Pane
        width='100%'
        height={medium ? '557px' : '637px'}
        display='grid'
        gridTemplateColumns=' repeat(5, 1fr)'
        gridGap={0}
      >
        <Pane
          gridColumn={small ? '1/5' : '1/4'}
          gridRow='1'
          borderBottomLeftRadius={radiusCorner}
          borderBottomRightRadius={radiusCircle}
          borderTopRightRadius={radiusCircle}
          background={
            'linear-gradient(149.5deg, rgba(122, 166, 104, 0.2) 19.59%, rgba(122, 166, 104, 0.1) 62%), rgba(122, 166, 104, 0.07);'
          }
          opacity={0.3}
        />
        <Pane
          gridColumn={small ? '2/7' : '3/7'}
          gridRow='1'
          borderBottomLeftRadius={radiusCircle}
          borderTopLeftRadius={radiusCircle}
          borderTopRightRadius={radiusCorner}
          background={
            'linear-gradient(149.5deg, rgba(13, 125, 194, 0.2) 19.59%, rgba(13, 125, 194, 0.1) 62%), rgba(13, 125, 194, 0.07);'
          }
          opacity={0.3}
        />
      </Pane>
      {expired ? (
        <Pane
          position='relative'
          top={medium ? '-500px' : '-500px'}
          display='flex'
          flexDirection='column'
          alignItems='start'
          opacity={1}
        >
          <Heading size={700} marginBottom={theme.spacing.s}>
            Ugyldig lenke
          </Heading>
          <Paragraph size={400} maxWidth={medium ? '325px' : 'fit-content'} marginBottom={theme.spacing.s}>
            Forhåndsvisning uten innlogging for dette tilbudet krever midlertidig tilgang
            <br />
            Lenken som ble benyttet er ugyldig/har gått ut på dato.
            <br />
            Du må be om en ny lenke for å kunne se forhåndsvisning.
          </Paragraph>
          <Button size='small' appearance='primary' onClick={() => navigate('/')} width={medium ? 325 : 'fit-content'}>
            Til forsiden
          </Button>
        </Pane>
      ) : archived ? (
        <Pane
          position='relative'
          top={medium ? '-500px' : '-500px'}
          display='flex'
          flexDirection='column'
          alignItems='start'
          opacity={1}
        >
          <Heading size={700} marginBottom={theme.spacing.s}>
            Arkivert
          </Heading>
          <Paragraph size={400} maxWidth={medium ? '325px' : 'fit-content'} marginBottom={theme.spacing.s}>
            Dette investeringstilbudet er arkivert og er ikke åpent for allmenheten.
            <br />
            Synlighet av avsluttede emisjoner er definert i våre{' '}
            <Link is={RouterLink} to={{ pathname: '/vilkar-rettigheter', hash: '4-bruk-av-plattform---folkeinvestno' }}>
              vilkår
            </Link>
          </Paragraph>
          <Button size='small' appearance='primary' onClick={() => navigate(-1)} width={medium ? 325 : 'fit-content'}>
            Gå tilbake
          </Button>
        </Pane>
      ) : (
        <Pane
          position='relative'
          top={medium ? '-500px' : '-500px'}
          left={medium ? '0' : large ? '-200px' : '-250px'}
          display='flex'
          flexDirection='column'
          alignItems='start'
          opacity={1}
        >
          <Heading size={800} marginBottom={theme.spacing.s}>
            404
          </Heading>
          <Paragraph size={500} width={medium ? '325px' : 'fit-content'} marginBottom={theme.spacing.s}>
            Det var leit... <br />
            Siden ser ut til å ikke eksistere.
          </Paragraph>
          <Button appearance='primary' onClick={() => navigate(-1)} width={medium ? 325 : 'fit-content'}>
            Gå tilbake
          </Button>
        </Pane>
      )}
    </Pane>
  )
}
