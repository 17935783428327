import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import Feedback from './Feedback'
import { FetchCampaignQuery } from '/~/types/graphql'
import { Button, Dialog, Link, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'

interface Props {
  campaign: FetchCampaignQuery['Campaign']
}

const Thanks = ({ campaign }: Props) => {
  const location = useLocation()
  const state = location.state as { reservationID?: string }
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  const reservationID = state?.reservationID

  return (
    <Dialog isShown title='Gratulerer med din tegning!' hasClose={false} hasFooter={false}>
      <Paragraph size={300}>En kvittering er sendt til din epostadresse.</Paragraph>
      <br />
      <Paragraph size={300}>Du kan finne info om alle dine tegninger på din profilside.</Paragraph>
      <Paragraph size={300}>Denne tegningen vil også stå oppført under emisjonens tegningsliste.</Paragraph>

      {reservationID ? <Feedback reservationID={reservationID} /> : null}

      {campaign.isHidden ? null : (
        <>
          <Paragraph marginTop={theme.spacing.s} marginBottom={12} fontWeight={500}>
            Før du går videre
          </Paragraph>
          <Paragraph size={300}>
            Som ny eier i {campaign.title} ønsker du å <b>hjelpe</b> selskapet.
          </Paragraph>
          <Paragraph size={300}>
            Det første du kan gjøre er å fortelle dine venner om denne emisjonen. Med det øker sannsynligheten for at{' '}
            {campaign.title} får inn nok penger, og at din investering blir lønnsom.
          </Paragraph>
        </>
      )}

      <Pane
        display='flex'
        flexDirection={medium ? 'column-reverse' : 'row'}
        justifyContent='flex-end'
        alignItems={medium ? 'initial' : 'baseline'}
        marginTop={theme.spacing.s}
        marginBottom={theme.spacing.xs}
      >
        <Pane display='flex' justifyContent='center'>
          <Link
            is={RouterLink}
            to={{ pathname: `/kampanje/${campaign.slug}`, search: location.search }}
            cursor='pointer'
          >
            Til emisjonen
          </Link>
        </Pane>
        <Button
          size='small'
          appearance='primary'
          is={RouterLink}
          to='/konto'
          marginLeft={medium ? 0 : 18}
          marginBottom={medium ? theme.spacing.xs : 0}
        >
          Til Profil
        </Button>
      </Pane>
    </Dialog>
  )
}

export default Thanks
