import { Text } from 'evergreen-ui'
import styled, { css } from 'styled-components'

export const Caption = styled(Text)`
  ${({ color, fontWeight, theme: { colors } }) => css`
    font-size: 14px;
    line-height: 20px;
    font-weight: ${fontWeight || 'normal'};
    color: ${color || colors.dark};
  `}
`
