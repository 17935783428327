import styled, { css } from 'styled-components'

export const TextButton = styled.button`
  ${({ theme: { colors } }) => css`
    padding: 0;
    border: none;
    background: none;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.text.link};
    cursor: pointer;
    text-decoration: underline;
    &:disabled {
      color: ${colors.fiDark40};
    }
  `}
`
