import * as React from 'react'
import { IconProps } from './types'

export const Cross = ({ title, ...rest }: IconProps) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path d='M1.49536 16.5045L16.5047 1.49512' stroke='currentColor' strokeWidth='1.5' />
    <path d='M1.49536 1.49512L16.5047 16.5045' stroke='currentColor' strokeWidth='1.5' />
  </svg>
)
