import * as React from 'react'
import { IconProps } from './types'

export const Coins = ({ title, ...rest }: IconProps) => (
  <svg width='94' height='94' viewBox='0 0 94 94' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.006 16.9812C47.5849 16.9812 61.0247 13.3972 61.0247 8.97619C61.0247 4.55515 47.5849 0.971191 31.006 0.971191C14.4271 0.971191 0.987244 4.55515 0.987244 8.97619C0.987244 13.3972 14.4271 16.9812 31.006 16.9812Z'
      stroke='#7AA668'
    />
    <path
      d='M61.0087 8.97607V20.9836C61.0087 25.4063 47.5724 28.9886 30.99 28.9886C14.4076 28.9886 0.971252 25.4063 0.971252 20.9836V8.97607'
      stroke='#7AA668'
    />
    <path
      d='M61.0087 20.9836V32.9911C61.0087 37.4139 47.5724 40.9961 30.99 40.9961C14.4076 40.9961 0.971252 37.4139 0.971252 32.9911V20.9836'
      stroke='#7AA668'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63.026 69.0137C79.6049 69.0137 93.0448 65.4297 93.0448 61.0087C93.0448 56.5876 79.6049 53.0037 63.026 53.0037C46.4471 53.0037 33.0073 56.5876 33.0073 61.0087C33.0073 65.4297 46.4471 69.0137 63.026 69.0137Z'
      stroke='#7AA668'
    />
    <path
      d='M93.0288 61.0088V73.0163C93.0288 77.4391 79.5924 81.0213 63.01 81.0213C46.4277 81.0213 32.9913 77.4391 32.9913 73.0163V61.0088'
      stroke='#7AA668'
    />
    <path
      d='M93.0288 73.0161V85.0236C93.0288 89.4464 79.5924 93.0286 63.01 93.0286C46.4277 93.0286 32.9913 89.4464 32.9913 85.0236V73.0161'
      stroke='#7AA668'
    />
    <path
      d='M61.0087 32.9912V44.9987C61.0087 49.4215 47.5724 53.0037 30.99 53.0037C14.4076 53.0037 0.971252 49.4215 0.971252 44.9987V32.9912'
      stroke='#7AA668'
    />
    <path d='M0.971252 44.9988V57.0063C0.971252 61.429 14.4117 65.0113 30.99 65.0113H32.9913' stroke='#7AA668' />
    <path d='M0.971252 57.0061V69.0136C0.971252 73.4364 14.4117 77.0186 30.99 77.0186H32.9913' stroke='#7AA668' />
    <path d='M61.0248 44.9988V53.0038' stroke='#7AA668' />
  </svg>
)
