import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Pane, Paragraph, Link } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import face from '/~/static/images/face.svg'
import { useLocation } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'

const AccessDenied = () => {
  const location = useLocation()
  const state = location.state as { redirectToAfterAuth?: string }

  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Pane display='flex' flexDirection='column' alignItems='center' height='100vh'>
      <Pane
        display='flex'
        flexDirection='column'
        alignItems='center'
        maxWidth={1260}
        justifyContent='center'
        marginTop={large ? theme.spacing.l : theme.spacing.xl}
      >
        <Pane is='h2' marginBottom={theme.spacing.m} textAlign='center'>
          Siden er ikke tilgjengelig
        </Pane>
        <Pane
          display='flex'
          justifyContent='center'
          maxWidth={medium ? '210.92px' : 320}
          maxHeight={medium ? '154.02' : 233}
          marginBottom={theme.spacing.m}
        >
          <img src={face} alt='face icon' />
        </Pane>
        <Paragraph size={400} textAlign='center' width={medium ? '86.5%' : '63.4%'}>
          Du må være logget inn for å få tilgang.{' '}
          <Link is={RouterLink} to={{ pathname: '/innlogging' }} state={state} size={300}>
            Logg inn
          </Link>{' '}
          eller{' '}
          <Link is={RouterLink} to={{ pathname: '/innlogging/registrering' }} state={state} size={300}>
            registrer deg
          </Link>
        </Paragraph>
      </Pane>
    </Pane>
  )
}

export default AccessDenied
