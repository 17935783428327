import * as React from 'react'
import { IconDirection, IconProps } from './types'

interface ChevronProps extends IconProps {
  direction: IconDirection
}

export const Chevron = ({ direction, title, ...rest }: ChevronProps) => (
  <svg
    width='17'
    height='12'
    viewBox='0 0 17 12'
    preserveAspectRatio='true'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
    style={{ transform: `rotate(${direction - 90}deg)`, ...rest.style }}
  >
    <title>{title || ''}</title>
    <path
      d='M8.4799 11.7137C8.01121 11.7143 7.56498 11.5087 7.25524 11.1494L0.306093 3.08231C-0.150711 2.52633 -0.0893722 1.69959 0.444247 1.22026C0.977866 0.74092 1.78858 0.784314 2.27021 1.31799L8.34674 8.37238C8.38036 8.41156 8.42889 8.43402 8.4799 8.43402C8.5309 8.43402 8.57943 8.41156 8.61306 8.37238L14.6896 1.31799C14.9947 0.946655 15.4728 0.773216 15.9389 0.864787C16.405 0.956359 16.7859 1.29858 16.9344 1.75901C17.0828 2.21945 16.9754 2.72587 16.6537 3.08231L9.70667 11.1465C9.3964 11.5066 8.94957 11.7132 8.4799 11.7137Z'
      fill='currentColor'
    />
  </svg>
)
