import * as React from 'react'
import { Checkbox, Pane, toaster } from 'evergreen-ui'
import styled from 'styled-components'
import { useEditEmailPreferencesMutation, EmailPreferences as EmailPreferencesType } from '/~/types/graphql'
import { AuthContextType } from '/~/utils/AuthContext/AuthContextProvider'

const Wrapper = styled(Pane)`
  // Hack for broken evergreen theme / checkbox
  label > div {
    margin-top: 3px;
  }
`

interface Props {
  user?: AuthContextType['user'] | EmailPreferencesType
  disabled?: boolean
  enableFlashMessages?: boolean
}

export const NewsletterPreferences = ({ user, disabled, enableFlashMessages }: Props) => {
  const [editPreferences] = useEditEmailPreferencesMutation()
  const [checked, setChecked] = React.useState(!!user?.receiveNewsletter)

  const handleChange = async ({ name, value }) => {
    try {
      await editPreferences({
        variables: {
          input: { email: user?.email, [name]: value },
        },
      })

      setChecked(value)

      if (enableFlashMessages) {
        toaster.success('Innstilling oppdatert')
      }
    } catch (_err) {
      if (enableFlashMessages) {
        toaster.danger('Feil under oppdatering, prøv igjen eller kontakt support')
      }
    }
  }

  return (
    <Wrapper>
      <Checkbox
        checked={checked}
        disabled={disabled || !user}
        onChange={(e) => handleChange({ name: 'receiveNewsletter', value: e.target.checked })}
        label='Samtykke til å motta annen informasjon om Folkeinvest AS og tjenestetilbudet'
      />
    </Wrapper>
  )
}
