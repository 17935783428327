import * as React from 'react'
import { Button, Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props {
  goForward: () => void
  onClose: () => void
}

const Registered = ({ goForward, onClose }: Props) => {
  const theme = useCustomTheme()

  return (
    <Pane display='flex' flexDirection='column' maxWidth={380} marginTop={theme.spacing.l} alignItems='center'>
      <Heading is='h3'>Gratulerer!</Heading>

      <Paragraph size={300} textAlign='center'>
        Din profil er nå opprettet. Om du planlegger å investere eller hente kapital til ditt selskap, anbefaler vi å
        fullføre profilen, det tar kun noen minutter.
      </Paragraph>

      <Paragraph size={300} textAlign='center' marginTop={theme.spacing.s}>
        Om du ønsker å fullføre senere kan du lukke registreringen.
      </Paragraph>

      <Button
        onClick={goForward}
        marginTop={theme.spacing.m}
        size='small'
        appearance='primary'
        maxWidth={244}
        width='100%'
      >
        Fullfør profil
      </Button>

      <Button onClick={onClose} marginTop={theme.spacing.xs} size='small' maxWidth={244} width='100%'>
        Lukk registreringen
      </Button>
    </Pane>
  )
}

export default Registered
