import { noNb } from './i18n/no-nb'
import { createIntl, createIntlCache } from 'react-intl'

const cache = createIntlCache()
export const intl = createIntl(
  {
    locale: 'nb',
    messages: noNb,
  },
  cache,
)
