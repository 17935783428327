import * as React from 'react'
import EmailSignup from './EmailSignup'
import SignupMethod from './SignupMethod'
import { Heading, Button, Pane, Paragraph, Spinner } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import {
  AddressStep,
  AllreadyRegistered,
  BankidStep,
  PepStep,
  PhoneStep,
  TermsStep,
  VerifyEmailStep,
  SignupDone,
  Registered,
  UserReturned,
} from './Steps'
import { getUserStep } from './signupUtils'
import { useLocation } from 'react-router-dom'
import { useAuthContext } from '/~/utils/AuthContext'
import { AUTH_CONTAINER_HEIGHT } from '../constants'
import { Nationality } from '/~/types/graphql'
import { gtmEventSignup } from '/~/utils/googleTagManager'

interface Props {
  onClose?: () => void
  approvePreorder: () => Promise<void>
  setSubmitting: (toggle: boolean) => void
}

interface LocationStateProps {
  fromEmailVerification: boolean
  fromPreorder?: string
  fromSignicatSignup?: boolean
}

const Signup = (props: Props) => {
  const location = useLocation()
  const state = location.state as LocationStateProps
  const { user, loading } = useAuthContext()
  const theme = useCustomTheme()

  const [userCreated, setUserCreated] = React.useState(!!user)
  const [registerEmail, setRegisterEmail] = React.useState(false)
  const [userStep, setUserStep] = React.useState(0)
  const [userReturned, setUserReturned] = React.useState(false)

  React.useEffect(() => {
    if (!loading) {
      setUserCreated(!!user)
      setUserStep(getUserStep(user))

      if (!state?.fromEmailVerification) {
        setUserReturned(!!user)
      }
    }
    if (state?.fromSignicatSignup) {
      setUserReturned(false)
      setUserCreated(true)
      setUserStep(getUserStep(user))
    }
  }, [loading])

  const handleGoBack = () => {
    if (userStep <= 0) {
      setRegisterEmail(false)
    } else {
      setUserStep(userStep - 1)
    }
  }

  const handleUserExisted = () => {
    setUserCreated(true)
    setRegisterEmail(false)
    setUserStep(-1)
  }

  const signupComplete = (signupMethod: 'google' | 'email') => {
    gtmEventSignup(signupMethod)

    props.approvePreorder()
    setUserCreated(true)
    setRegisterEmail(false)
  }

  const handleClose = () => {
    if (props.onClose) {
      props.onClose()
    } else {
      let redirect = '/konto'

      if (!user) {
        redirect = '/'
      }
      if (state?.fromPreorder) {
        redirect = `/kampanje/${state.fromPreorder}/forhandstegning`
      } else {
        if (user?.isInvestor) {
          redirect = '/kampanjer'
        }
        if (user?.isEntrepreneur) {
          redirect = '/sok-kapital-kontakt'
        }
      }

      window.location.href = redirect
    }
  }

  const getStep = () => {
    switch (userStep) {
      case -1:
        // User tried to register from service, but was allready registered
        return <AllreadyRegistered onClose={handleClose} />
      case 0:
        return <Registered onClose={handleClose} goForward={() => setUserStep(userStep + 1)} />
      case 1:
        return <TermsStep goBack={handleGoBack} goForward={() => setUserStep(userStep + 1)} />
      case 2:
        return <PepStep goBack={handleGoBack} goForward={() => setUserStep(userStep + 1)} />
      case 3:
        return <AddressStep goBack={handleGoBack} goForward={() => setUserStep(userStep + 1)} />
      case 4:
        return <PhoneStep goBack={handleGoBack} goForward={() => setUserStep(userStep + 1)} />
      case 5:
        return (
          <VerifyEmailStep
            goBack={handleGoBack}
            goForward={() =>
              setUserStep(
                !user?.nationality || user?.nationality === Nationality.NORWEGIAN ? userStep + 1 : userStep + 2,
              )
            }
          />
        )
      case 6:
        return <BankidStep goBack={handleGoBack} goForward={() => setUserStep(userStep + 1)} onClose={handleClose} />
      case 7:
        return <SignupDone onClose={handleClose} />
      default:
        // Should never get here... Show an error I guess?
        return (
          <Pane display='flex' flexDirection='column' alignItems='center' marginTop={theme.spacing.l}>
            <Heading is='h3'>Beklager</Heading>

            <Paragraph size={300} textAlign='center' marginBottom={theme.spacing.m}>
              Noe gikk galt, prøv igjen eller kontakt oss på support@folkeinvest.no.
            </Paragraph>

            <Button onClick={handleClose} size='small' appearance='primary'>
              Lukk registreringen
            </Button>
          </Pane>
        )
    }
  }

  const renderComponent = () => {
    if (loading) {
      return <Spinner />
    }

    if (userReturned) {
      return <UserReturned resumeRegistration={() => setUserReturned(false)} onClose={handleClose} />
    }

    if (!userCreated) {
      if (registerEmail) {
        return (
          <EmailSignup
            setSubmitting={props.setSubmitting}
            onComplete={() => signupComplete('email')}
            goBack={handleGoBack}
          />
        )
      }

      return (
        <SignupMethod
          emailSelected={() => setRegisterEmail(true)}
          serviceSignupComplete={() => signupComplete('google')}
          userExisted={handleUserExisted}
        />
      )
    }

    return getStep()
  }

  return (
    <Pane
      display='flex'
      flexDirection='column'
      alignItems='center'
      paddingX={10}
      height={registerEmail ? 'fit-content' : AUTH_CONTAINER_HEIGHT}
      overflowY='auto'
    >
      {renderComponent()}
    </Pane>
  )
}

export default Signup
