import * as CSS from 'csstype'
import styled, { css } from 'styled-components'
import * as React from 'react'

interface StackProps {
  children: React.ReactNode
  gap: number
  style?: any
  className?: string
  flexWrap?: CSS.Properties['flexWrap']
  justifyContent: CSS.Properties['justifyContent']
  alignItems: CSS.Properties['alignItems']
  flexDirection?: CSS.Properties['flexDirection']
  separator?: React.ReactNode
}

const Wrapper = styled.div<StackProps>`
  ${({ alignItems, flexDirection, flexWrap, gap, justifyContent }) => css`
    display: flex;
    align-items: ${alignItems};
    justify-content: ${justifyContent};
    flex-direction: ${flexDirection ?? 'row'};
    flex-flow: row ${flexWrap ?? 'nowrap'};

    .child:not(:last-child) {
      margin-right: ${gap}px;
    }
    .child {
      ${flexWrap !== 'nowrap' ? `margin-bottom: ${gap}px` : null};
    }
  `}
`

const Stack = (props: Partial<StackProps>) => {
  const {
    gap = 12,
    alignItems = 'center',
    justifyContent = 'flex-start',
    className,
    style,
    flexWrap,
    children,
    flexDirection,
    separator,
  } = props

  if (!children) {
    return null
  }

  if (children.constructor !== Array) {
    return <div style={style}>{children}</div>
  }

  const kids = (children as React.ReactNode[]).flat().filter((child) => !!child)

  return (
    <Wrapper
      className={className}
      style={style}
      gap={gap}
      alignItems={alignItems}
      justifyContent={justifyContent}
      flexDirection={flexDirection}
      flexWrap={flexWrap}
    >
      {kids.map((child, i) => [
        <div key={i} className='child'>
          {child}
        </div>,
        separator && child && i !== kids.length - 1 && (
          <span key={`${i}-sep`} style={{ opacity: 0.4, marginRight: gap }}>
            {separator}
          </span>
        ),
      ])}
    </Wrapper>
  )
}

export default Stack
