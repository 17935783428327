import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Heading, Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Helmet, StatisticsView } from '/~/components'
import { TableauSection } from './TableauSection'
import { VideoSection } from './VideoSection'
import { Headline } from '/fiweb/components'
import { ReportsSection } from './ReportsSection'
import { config } from '/~/utils/config'

export const Statistic = () => {
  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Pane
      width='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
      marginTop={medium ? 0 : theme.spacing.xs}
    >
      <Helmet
        title='Folkeinvest: Statistikk og analyse'
        description='Finn en oversikt over statistikk og analyse med løpende oppdatering av tilrettelagt kapital, registrerte investorer og antall emisjoner.'
        url={`${window.location.origin}/statistikk`}
        imageUrl={`${config.publicFilesUrl}/meta/statistics.png`}
        keywords='Invester i aksjer, invester i startups, investere, hente kapital, finansiering av startups'
      />

      <Pane
        width={medium ? 325 : large ? 700 : '100%'}
        maxWidth={1010}
        marginBottom={medium ? theme.spacing.s : theme.spacing.m}
      >
        <Heading
          is='h1'
          size={medium ? 600 : large ? 700 : 800}
          marginBottom={medium ? theme.spacing.s : theme.spacing.l}
          textAlign='center'
        >
          Statistikk i Folkeinvest
        </Heading>
        <Headline textAlign='center'>
          Løpende oppdatering av tilrettelagt kapital, registrerte investorer og antall emisjoner.
        </Headline>
      </Pane>

      <StatisticsView marginBottom={medium ? theme.spacing.m : theme.spacing.xl} />
      {/* <VideoSection />
      <TableauSection /> */}
      <ReportsSection />
    </Pane>
  )
}
