import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { auth, Service } from '/fiweb/lib'
import { config } from '/~/utils/config'
import { StatusCodes } from 'http-status-codes'
import { UNKNOWN_ERROR } from '../constants'
import { SignicatCallback, SignicatCallbackProps } from './SignicatCallback'
import { getSignicatAuthError } from './utils'

export const SignicatLoginCallback = () => {
  const navigate = useNavigate()

  const onCallback: SignicatCallbackProps['onCallback'] = async (sessionId, callback) => {
    const signIn = async () => {
      const res = await auth(config.authHost).loginWithService({
        service: Service.SIGNICAT,
        token: sessionId,
      })

      if (!res.ok) {
        const resError = await getSignicatAuthError(res)
        switch (res.status) {
          case StatusCodes.NOT_FOUND: {
            return {
              text: 'Bruker ikke funnet, vennligst gå til registrering av bruker',
              provider: resError?.provider,
            }
          }

          default: {
            return { text: UNKNOWN_ERROR, provider: resError?.provider }
          }
        }
      }
      navigate('/konto', { replace: true })
    }

    switch (callback) {
      case 'success':
      case 'error': {
        return await signIn()
      }

      case 'abort': {
        navigate('/innlogging')
        break
      }
    }
  }

  return <SignicatCallback onCallback={onCallback} />
}
