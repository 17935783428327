import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from './AuthContext'
import Loading from '../components/common/Loading'

export default (ComposedComponent: (props: any) => JSX.Element, redirectTo = '/ingen-tilgang') => {
  const { loading, user } = useAuthContext()

  const Authenticate = (props: any) => {
    if (loading) {
      return <Loading />
    }

    return user ? (
      <ComposedComponent {...props} />
    ) : (
      <Navigate
        to={redirectTo}
        state={{
          redirectToAfterAuth: window.location.pathname,
        }}
        replace
      />
    )
  }

  return Authenticate
}
