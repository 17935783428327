import { Select, Text, Pane } from 'evergreen-ui'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import countries from 'i18n-iso-countries'
import noLocale from 'i18n-iso-countries/langs/nb.json'
import { ControlledTextInput, InputWrapper } from '/fiweb/components'
import { breakpoints } from '/fiweb/lib'
import { GoogleAddressLookup, validGoogleFields } from './GoogleAddressLookup'
import { ProfileAddressInput as ProfileAddressInputType } from '/~/types/graphql'
import styled from 'styled-components'

const StyledWrapper = styled(Pane)`
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${({ theme: { spacing } }) => spacing.xs};

  @media ${breakpoints.small} {
    display: flex;
    flex-direction: column;
  }
`

interface Props {
  formFieldPrefix?: string
}

export const ProfileAddressInput = ({ formFieldPrefix }: Props) => {
  const formContext = useFormContext()
  countries.registerLocale(noLocale)
  const prefix = formFieldPrefix ? `${formFieldPrefix}.` : ''

  React.useEffect(() => {
    if (!formContext) {
      console.error('Form Context is missing in ProfileAddressInput. Wrapped FormProvider is required.')
    }
  }, [])
  const countryObj = countries.getNames('nb', { select: 'official' })

  if (!formContext) {
    return null
  }

  const countryList = Object.keys(countryObj)
    .map((key) => ({ key, value: countryObj[key] }))
    .sort((a, b) => a.value?.localeCompare(b.value))

  const getLabel = (text: React.ReactNode) => <Text size={300}>{text}</Text>

  const handleFillFormWithGoogleData = (googleData?: ProfileAddressInputType) => {
    for (const key in googleData) {
      if (validGoogleFields.find((o) => o.key === key)) {
        formContext.setValue(`${prefix}${key}`, googleData[key], { shouldDirty: true })
      }
    }
  }

  return (
    <StyledWrapper>
      <GoogleAddressLookup
        name={`${prefix}street`}
        gridColumn='span 2'
        onAddressSelect={handleFillFormWithGoogleData}
      />
      <ControlledTextInput
        name={`${prefix}postalCode`}
        required
        control={formContext?.control}
        wrapperProps={{ label: getLabel('Postnummer') }}
        textInputProps={{ autoComplete: 'new-password' }}
      />
      <ControlledTextInput
        name={`${prefix}postalTown`}
        required
        control={formContext?.control}
        wrapperProps={{ label: getLabel('Poststed') }}
        textInputProps={{ autoComplete: 'new-password' }}
      />

      <InputWrapper required label={getLabel('Land')}>
        <Select required background='white' {...formContext.register(`${prefix}countryCode`)}>
          {countryList.map((country) => (
            <option key={country.key} value={country.key}>
              {country.value}
            </option>
          ))}
        </Select>
      </InputWrapper>
    </StyledWrapper>
  )
}
