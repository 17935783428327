import * as React from 'react'
import { Pane } from 'evergreen-ui'
import { config } from '/~/utils/config'
import { Helmet } from '/~/components'
import { AboutUsEmployees } from './AboutUsEmployees'
import { AboutUsPartners } from './AboutUsPartners'
import { AboutUsCareer } from './AboutUsCareer'
import { AboutUsFolkeinvest } from './AboutUsFolkeinvest'

export const AboutUs = () => (
  <Pane width='100%' display='flex' flexDirection='column' alignItems='center'>
    <Helmet
      title='Våre eksperter hjelper deg'
      description='Folkeinvest har erfaring fra et hundretalls gjennomførte suksessfulle emisjoner. Solid team med lang erfaring.'
      url={`${window.location.origin}/om-oss`}
      imageUrl={`${config.publicFilesUrl}/meta/about-us.png`}
      keywords='Invester i aksjer, invester i startups, investere, hente kapital, finansiering av startups'
    />

    <Pane
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      maxWidth={1260}
      marginX={25}
    >
      <AboutUsFolkeinvest />
      <AboutUsEmployees />
      <AboutUsCareer />
    </Pane>

    <AboutUsPartners />
  </Pane>
)
