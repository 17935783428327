import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Radio } from 'evergreen-ui'
import styled from 'styled-components'
import { Message } from '../../lib/i18n/types'

export const StyledRowRadioButton = styled(Radio)`
  display: inline-block;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #abadb3;
  border-radius: 4px;
  min-width: 70px;
  > input:checked + div {
    background: lightblue;
  }
  > input:hover {
    opacity: 0;
  }
  > div {
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 0;
    > svg {
      display: none;
    }
  }
  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
  &:not(:first-child):not(:last-child) {
    border-radius: 0px;
    border-left: none;
  }
`

interface RowRadioButtonProps {
  disabled?: boolean
  label: Message
  value: string
  name: string
  labelKey: string
  onChange: (value: string) => void
}

export const RowRadioButton = ({ disabled, onChange, label, labelKey, value, name }: RowRadioButtonProps) => {
  return (
    <StyledRowRadioButton
      name={name}
      disabled={disabled}
      label={<FormattedMessage id={label} />}
      value={labelKey}
      display='inline-block'
      checked={value === labelKey}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value)
      }}
    />
  )
}
