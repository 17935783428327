import * as React from 'react'
import styled, { css } from 'styled-components'
import { Card, Heading, Link, Paragraph, Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { InvestorRelationsEvent } from '/~/types/graphql'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { useBreakpoint } from '/fiweb/lib'
import { getCalendarIcs } from './icsUtils'
import { CTAArrow, Calendar, IconDirection } from '/fiweb/components/Icons'

const StyledCard = styled(Card)`
  ${({ theme: { radii, spacing } }) => css`
    background-color: #ffffff;
    border-radius: ${radii[5]};
    filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.15));
    margin-top: 40px;
    padding: ${spacing.s};
    height: 244px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`

interface Props {
  event: InvestorRelationsEvent
}

export const EventCard = ({ event: { dateOfEvent, endDateOfEvent, title, description } }: Props) => {
  const theme = useCustomTheme()
  const { large } = useBreakpoint()

  const saveIcs = () => {
    const calendarEvent = getCalendarIcs({ dateOfEvent, endDateOfEvent, title, description })
    const blob = new Blob([calendarEvent])
    saveAs(blob, `${title}.ics`)
  }

  return (
    <StyledCard width={large ? 325 : 400}>
      <Pane>
        <Paragraph
          color='danger'
          fontSize='14px'
          fontWeight={600}
          lineHeight='20px'
          display='flex'
          alignItems='center'
          marginBottom={theme.spacing.xxs}
        >
          <Calendar style={{ marginRight: 10 }} />
          {dayjs(dateOfEvent).format('DD.MM.YYYY kl. HH:mm')}
        </Paragraph>
        <Pane display='flex' flexFlow='row nowrap' alignItems='center' marginBottom={theme.spacing.xxs}>
          <Pane>
            <Heading size={300} marginTop={10} marginBottom={0}>
              {title}
            </Heading>
            <Paragraph marginBottom={theme.spacing.xxs} size={300}>
              {description?.replace(/(.{100})..+/, '$1...')}
            </Paragraph>
          </Pane>
        </Pane>
      </Pane>
      <Pane display='flex' alignItems='center' marginBottom={theme.spacing.s} position='absolute' bottom={0}>
        <Link cursor='pointer' onClick={() => saveIcs()}>
          Legg til i kalender
        </Link>
        <CTAArrow direction={IconDirection.RIGHT} style={{ marginLeft: 10 }} />
      </Pane>
    </StyledCard>
  )
}
