import React from 'react'
import { Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useBreakpoint } from '/fiweb/lib'
import styled from 'styled-components'
import { config } from '/~/utils/config'

const fallbackImage = `${config.publicFilesUrl}/podcast-group.png`

const anchorProps = {
  download: true,
  rel: 'external',
  target: '_blank',
  'data-ga-click': 'Repository, download, zip, location: repo overview',
}

const StyledCard = styled(Pane)`
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
  color: inherit;
  &:hover {
    box-shadow: 0px 0px 20px 3px #00000012;
  }
`

const PressItem = ({ item }) => {
  const getStringDate = (timestamp) => {
    return dayjs(timestamp).format('DD. MMMM YYYY')
  }
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <>
      <StyledCard
        width={medium ? 325 : 400}
        height={medium ? 605 : 586}
        backgroundColor='white'
        display='flex'
        flexDirection='column'
        alignItems='start'
        borderRadius={10}
        marginBottom={medium ? theme.spacing.m : theme.spacing.l}
        is='a'
        textDecoration='none'
        href={item.documentUrl}
        {...anchorProps}
      >
        <img
          src={item?.imageUrl || fallbackImage}
          onError={({ currentTarget }) => {
            currentTarget.src = fallbackImage
          }}
          alt='article'
          width='100%'
          height={medium ? 193 : 238}
          style={{ borderRadius: '10px 10px 0px 0px' }}
        />
        <Pane
          display='flex'
          flexDirection='column'
          alignItems='start'
          padding={medium ? 24 : 30}
          overflow='hidden'
          height={medium ? 605 - 193 : 586 - 238}
          boxSizing='border-box'
        >
          <Heading size={400} marginBottom={medium ? theme.spacing.xxs : theme.spacing.xs}>
            {item?.title || item?.description}{' '}
          </Heading>
          <Paragraph
            fontSize={18}
            fontWeight={600}
            lineHeight='21.78px'
            color='muted'
            marginBottom={medium ? theme.spacing.xxs : theme.spacing.xs}
          >
            {getStringDate(item?.timestamp)}
          </Paragraph>
          <Paragraph size={300}>{_.truncate(item?.description, { length: 180 })}</Paragraph>
        </Pane>
      </StyledCard>
    </>
  )
}

export default PressItem
