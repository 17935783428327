import * as React from 'react'
import { Button, Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import { FormattedMessage, useIntl } from 'react-intl'
import { Testimonial } from '/~/components'
import { Divider } from '/fiweb/components'

export const TopSection = () => {
  const { spacing } = useCustomTheme()
  const { small, large } = useBreakpoint()
  const intl = useIntl()

  const elementRef = React.useRef<HTMLSpanElement>()
  const scrollToElement = () => {
    const element = elementRef?.current
    if (!element) {
      return
    }
    const y = element.getBoundingClientRect().top + window.pageYOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  return (
    <>
      <Pane display='flex' flexDirection={large ? 'column' : 'row'} width='100%' maxWidth={1010} paddingY={spacing.l}>
        <Pane
          display='flex'
          flexDirection='column'
          alignItems={large ? 'center' : 'flex-start'}
          justifyContent='space-between'
          width={large ? '100%' : '50%'}
          marginBottom={large ? spacing.m : 0}
        >
          <Heading is='h1' textAlign={large ? 'center' : 'initial'} fontSize='58px' lineHeight='67px' maxWidth={575}>
            <FormattedMessage id='entrepreneurLanding.heading' />
          </Heading>
          <Paragraph
            size={small ? 400 : 500}
            whiteSpace='pre-wrap'
            textAlign={large ? 'center' : 'initial'}
            marginBottom={spacing.m}
            maxWidth={400}
          >
            <FormattedMessage id='entrepreneurLanding.subtitle' />
          </Paragraph>
          <Button
            width={small ? '100%' : 300}
            appearance='primary'
            onClick={intl.locale !== 'no-Nb' ? scrollToElement : () => window.open('/sok-kapital-kontakt')}
          >
            <FormattedMessage id='entrepreneurLanding.ctaButton' />
          </Button>
        </Pane>
        <Pane width={large ? '100%' : '50%'}>
          <Testimonial
            text={intl.formatMessage({ id: 'entrepreneurLanding.testimonial.text' })}
            title={intl.formatMessage({ id: 'entrepreneurLanding.testimonial.title' })}
            display='flex'
            flexFlow='column'
            alignItems='center'
            width='100%'
          />
        </Pane>
      </Pane>
      <Pane marginTop={large ? spacing.l : '160px'} marginBottom={spacing.l}>
        <Divider />
        <Paragraph textAlign='center' size={large ? 400 : 500} marginTop={spacing.s} marginBottom={spacing.m}>
          <FormattedMessage id='entrepreneurLanding.joinTheJourney' />
        </Paragraph>
        <Divider />
        <span ref={elementRef} />
      </Pane>
    </>
  )
}
