import * as React from 'react'
import { IconProps } from './types'

export const DownloadCloud = ({ title, ...rest }: IconProps) => (
  <svg width='17' height='19' viewBox='0 0 17 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <rect x='1' width='15' height='19' fill='none' />
    <path d='M8.51123 10.4963V16.5001' stroke='currentColor' strokeWidth='1.5' />
    <path d='M11.0129 13.9983L8.51133 16.4999L6.00977 13.9983' stroke='currentColor' strokeWidth='1.5' />
    <path
      d='M11.5133 11.4968H12.7641C14.1643 11.4992 15.4088 10.6051 15.8534 9.27736C16.2979 7.94963 15.8427 6.48639 14.7234 5.64515C13.6041 4.80392 12.072 4.77356 10.9203 5.56981C10.4447 3.03769 8.12392 1.28074 5.55775 1.51009C2.99159 1.73944 1.01916 3.88009 1.00014 6.45641C0.981114 9.03273 2.92171 11.2023 5.48421 11.4695'
      stroke='currentColor'
      strokeWidth='1.5'
    />
  </svg>
)
