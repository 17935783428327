import * as React from 'react'
import styled from 'styled-components'
import { Pane, Paragraph, Tab, Tablist } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { PublicDocumentSection } from '/~/types/graphql'

const TabParagraph = styled(Paragraph)`
  font-weight: 600;
  margin-bottom: 0px;
`

interface Props {
  availableYears: number[]
  selectedReport: PublicDocumentSection
  setSelectedReport: (input: PublicDocumentSection) => void
  selectedYear: number
  setSelectedYear: (input: number) => void
}

export const ReportsNagivation = ({
  availableYears,
  selectedReport,
  setSelectedReport,
  selectedYear,
  setSelectedYear,
}: Props) => {
  const theme = useCustomTheme()

  return (
    <Pane width='100%' display='flex' flexFlow='row wrap' justifyContent='space-between' marginBottom={theme.spacing.l}>
      <Tablist marginTop={theme.spacing.xxs} marginRight={theme.spacing.xs}>
        <Tab
          isSelected={selectedReport === PublicDocumentSection.QUARTERLYREPORT}
          onSelect={() => setSelectedReport(PublicDocumentSection.QUARTERLYREPORT)}
          marginRight={theme.spacing.xs}
        >
          <TabParagraph size={300} color={selectedReport === PublicDocumentSection.QUARTERLYREPORT ? null : 'muted'}>
            Kvartalsrapporter
          </TabParagraph>
        </Tab>
        <Tab
          isSelected={selectedReport === PublicDocumentSection.FIREPORT}
          onSelect={() => setSelectedReport(PublicDocumentSection.FIREPORT)}
        >
          <TabParagraph size={300} color={selectedReport === PublicDocumentSection.FIREPORT ? null : 'muted'}>
            FI rapporter
          </TabParagraph>
        </Tab>
      </Tablist>

      {availableYears?.length > 0 && (
        <Tablist marginTop={theme.spacing.xxs}>
          {availableYears.map((year) => (
            <Tab
              key={`tab-year-${year}`}
              isSelected={selectedYear === year}
              onSelect={() => setSelectedYear(year)}
              marginRight={theme.spacing.xs}
            >
              <TabParagraph size={300} color={selectedYear === year ? null : 'muted'}>
                {year}
              </TabParagraph>
            </Tab>
          ))}
        </Tablist>
      )}
    </Pane>
  )
}
