import React from 'react'
import Typography from '@mui/material/Typography'
import { config } from '/~/utils/config'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    marginTop: 100,
    marginBottom: 100,
  },
  img: {
    width: 64,
    height: 48,
  },
  text: {
    whiteSpace: 'nowrap',
  },
}))

interface Props {
  text?: string
}

const Loading = ({ text = 'Laster inn' }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <img src={`${config.publicFilesUrl}/loading2.gif`} className={classes.img} alt='loading' />
      <Typography variant='h5' className={classes.text}>
        {text}
      </Typography>
    </div>
  )
}

export default Loading
