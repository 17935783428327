import * as React from 'react'
import { IconDirection, IconProps } from './types'

interface ArrowProps extends IconProps {
  direction: IconDirection
}

export const Arrow = ({ direction, title, ...rest }: ArrowProps) => (
  <svg
    width='13'
    height='15'
    viewBox='0 0 13 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
    style={{ transform: `rotate(${direction}deg)`, ...rest.style }}
  >
    <title>{title || ''}</title>
    <path
      d='M12.7525 8.88264C12.7529 9.2137 12.6107 9.52889 12.3622 9.74768L6.78272 14.6562C6.39819 14.9789 5.82639 14.9356 5.49486 14.5587C5.16334 14.1817 5.19335 13.6091 5.56246 13.2689L10.4415 8.9767C10.4686 8.95295 10.4841 8.91867 10.4841 8.88264C10.4841 8.84661 10.4686 8.81233 10.4415 8.78858L5.56246 4.4964C5.19335 4.15619 5.16334 3.58354 5.49486 3.20662C5.82639 2.82969 6.39819 2.78637 6.78272 3.10903L12.3602 8.0161C12.6093 8.23526 12.7521 8.55088 12.7525 8.88264Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.74227e-08 8.88257C1.35705e-07 8.33028 0.447715 7.88257 1 7.88257L10 7.88257C10.5523 7.88257 11 8.33028 11 8.88257C11 9.43485 10.5523 9.88257 10 9.88257L1 9.88257C0.447716 9.88257 3.91405e-08 9.43485 8.74227e-08 8.88257Z'
      fill='currentColor'
    />
  </svg>
)
