import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Paragraph, Strong } from 'evergreen-ui'

interface Props {
  content: string
}

export const IngressText = ({ content }: Props) => {
  const { medium } = useBreakpoint()

  return medium ? (
    <Paragraph size={400} textAlign='center'>
      {content}
    </Paragraph>
  ) : (
    <Strong fontSize={28} lineHeight='39px' textAlign='center' fontWeight={500} display='table-cell'>
      {content}
    </Strong>
  )
}
