import { Button, Link, Pane, Spinner, Text, WarningSignIcon } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import * as React from 'react'
import { auth, SignicatProvider } from '/fiweb/lib/auth'
import { useAuthContext } from '/~/utils/AuthContext'
import { config } from '/~/utils/config'
import { InternationalBankID } from './InternationalBankID'
import { Caption } from '/fiweb/components'
import { useIntl } from 'react-intl'

interface Props {
  centered?: boolean
  column?: boolean
  buttonWidth?: number
}

export const BankIDVerification = ({ centered, column, buttonWidth }: Props) => {
  const { user } = useAuthContext()
  const { spacing } = useCustomTheme()
  const [currentSignicatProvider, setCurrentSignicatProvider] = React.useState<SignicatProvider | null>(null)
  const [waitingForResult, setWaitingForResult] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const [hidden, setHidden] = React.useState(false)
  const intl = useIntl()
  const windowRef = React.useRef<Window>()

  const getSignicatBankIdUrl = async (provider: SignicatProvider) => {
    try {
      const res = await auth(config.authHost).obtainSignicatSessionUrl(
        [provider],
        `${window.origin}/bankid-verifisering`,
      )
      if (res.ok) {
        const url = await res.text()
        return url
      }
    } catch {}
    setError(intl.formatMessage({ id: 'somethingWentWrong' }))
  }

  const continueVerificationProcess = async (provider: SignicatProvider, windowReference: Window) => {
    const url = await getSignicatBankIdUrl(provider)
    windowReference.location = url
  }

  const startVerificationProcess = (provider: SignicatProvider) => {
    setCurrentSignicatProvider(provider)
    setWaitingForResult(true)
    const windowReference = window.open(undefined, '_blank')
    windowRef.current = windowReference
    continueVerificationProcess(provider, windowReference)
  }

  const handleOpenUrlInCurrentWindow = async () => {
    try {
      localStorage.setItem('current-bankid-window-path', window.location.pathname)
    } catch {}

    if (windowRef?.current) {
      windowRef.current?.close()
    }
    window.location.href = await getSignicatBankIdUrl(currentSignicatProvider)
  }

  React.useEffect(() => {
    if (user.bankidverified) {
      setWaitingForResult(false)
    }
  }, [user])

  return waitingForResult ? (
    <Pane
      width='100%'
      display='flex'
      flexDirection='column'
      alignItems={centered ? 'center' : undefined}
      paddingY={spacing.m}
      rowGap={spacing.s}
    >
      <Pane display='flex' gap={spacing.xs} alignItems='center'>
        <Spinner />
        <Link cursor='pointer' onClick={() => setWaitingForResult(false)}>
          Avbryt
        </Link>
      </Pane>
      <Caption>
        Et nytt vindu skal være åpent med BankID-verifisering.
        <br />
        <br />
        Ble det ikke åpnet et nytt vindu?
        <br />
        <Link is={Caption} cursor='pointer' onClick={handleOpenUrlInCurrentWindow}>
          Gjennomfør verifisering i denne fanen
        </Link>
      </Caption>
    </Pane>
  ) : (
    <Pane>
      {!hidden && (
        <Pane
          display='flex'
          flexFlow={column ? 'column' : 'row nowrap'}
          justifyContent={centered ? 'center' : undefined}
          marginTop={spacing.xs}
          marginBottom={column ? spacing.s : undefined}
          {...(column ? { rowGap: spacing.xs } : { columnGap: spacing.xs })}
        >
          <Button
            disabled={user.bankidverified || !user?.emailVerified}
            onClick={() => startVerificationProcess(SignicatProvider.NORWEGIAN_BANK_ID)}
            size='small'
            appearance='primary'
            width={buttonWidth}
          >
            BankID
          </Button>
        </Pane>
      )}
      {user?.emailVerified && config.signicatInternationalEnabled && (
        <InternationalBankID
          onToggle={(toggle: boolean) => setHidden(!toggle)}
          startVerificationProcess={startVerificationProcess}
          alignItems={column ? 'center' : 'flex-start'}
        />
      )}
      {error && (
        <Text color='danger' textAlign='center'>
          {error}
        </Text>
      )}
      {!user?.emailVerified && (
        <Caption display='flex' alignItems='center' gap={spacing.xxs} color='danger'>
          <WarningSignIcon />
          <i>Epost-adresse må være verifisert før dette steget kan gjennomføres.</i>
        </Caption>
      )}
    </Pane>
  )
}
