import * as React from 'react'
import { Link, useLocation, matchPath } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Pane, Select, Text } from 'evergreen-ui'
import { useBreakpoint } from '/fiweb/lib'

const TabsContainer = styled.div`
  width: 100%;
  height: 70px;
  background: ${({ theme: { colors } }) => colors.fiDark10};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 1;
  top: 80px;
`

interface TabLinkProps {
  $active: boolean
}

const TabLink = styled(Link)<TabLinkProps>`
  ${({ $active, theme: { colors } }) => css`
    color: ${colors.text.default};
    text-decoration: none;
    border-bottom: ${$active ? `2px solid ${colors.fiBlue}` : '2px solid transparent'};
  `}
`

interface Props {
  slug: string
}

const Tabs = ({ slug }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { medium } = useBreakpoint()

  const basePath = `/kampanje/${slug}`

  const isBase = !!matchPath({ path: basePath, end: true }, location.pathname)

  const search = location.search

  const tabList = [
    { label: 'Beskrivelse', to: 'beskrivelse', base: true },
    { label: 'Emisjon', to: 'data' },
    { label: 'Diskusjon', to: 'diskusjon' },
    { label: 'Selskap', to: 'selskap' },
    { label: 'Dokumenter', to: 'dokumenter' },
    { label: 'Tegningsliste', to: 'tegninger' },
  ]

  const handleTabChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    const tab = tabList.find((t) => t.to === value)

    navigate({
      pathname: `${basePath}/${tab.to}`,
      search,
    })
  }

  return (
    <TabsContainer>
      {medium ? (
        <Select
          backgroundColor='#FFF'
          width='calc(100% - 50px)'
          flex='initial' // needs to reset because of wonky evergreen styling
          onChange={handleTabChange}
          value={
            tabList.find(
              (tab) =>
                (tab.base && isBase) ||
                !!matchPath(
                  {
                    path: `${basePath}/${tab.to}`,
                    end: true,
                  },
                  location.pathname,
                ),
            )?.to || 'beskrivelse'
          }
        >
          {tabList.map((tab) => (
            <option key={tab.to} value={tab.to}>
              {tab.label}
            </option>
          ))}
        </Select>
      ) : (
        <Pane display='flex' width='100%' maxWidth='1000px' justifyContent='space-between' paddingX='40px'>
          {tabList.map((tab) => {
            const pathname = `${basePath}/${tab.to}`

            const match = !!matchPath(
              {
                path: pathname,
                end: true,
              },
              location.pathname,
            )

            return (
              <TabLink
                key={tab.to}
                to={{
                  pathname,
                  search,
                }}
                $active={match || (tab.base && isBase)}
              >
                <Text>{tab.label}</Text>
              </TabLink>
            )
          })}
        </Pane>
      )}
    </TabsContainer>
  )
}
export default Tabs
