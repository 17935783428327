import * as React from 'react'
import { Heading, Pane, Paragraph, Strong } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import { FormattedMessage } from 'react-intl'
import { ProcessView } from './ProcessView'
import { Underline30 } from '/fiweb/components/Icons'

export const Process = () => {
  const { colors, spacing } = useCustomTheme()
  const { small } = useBreakpoint()

  return (
    <Pane width='100%' marginTop={spacing.l} marginBottom={spacing.l}>
      <Heading is='h2' size={600}>
        <FormattedMessage id='entrepreneurLanding.processHeading' />
      </Heading>
      <Paragraph size={small ? 400 : 500} marginBottom={spacing.m}>
        <FormattedMessage
          id='entrepreneurLanding.processSubheading'
          values={{
            grul: (chunks) => (
              <Strong size={small ? 400 : 500} position='relative'>
                {chunks}
                <Underline30
                  color={colors.fiGreen}
                  style={{
                    position: 'absolute',
                    objectFit: 'cover',
                    width: 'calc(100% + 4px)',
                    left: '0px',
                    right: 0,
                    height: '22%',
                    bottom: 1,
                  }}
                />
              </Strong>
            ),
          }}
        />
      </Paragraph>
      <Pane display='flex' justifyContent='center' width='100%'>
        <ProcessView cardCustomMarginBottom='16px' cardCustomSize={{ medium: 325, small: 325 }} />
      </Pane>
    </Pane>
  )
}
