import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import { FetchCampaignQuery } from '/~/types/graphql'
import MemberList from './MemberList'
import { Link, Pane, Paragraph, Heading } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import Owners from './Owners'
import PageContainer from '../PageContainer'

interface ComponentProps {
  campaign: FetchCampaignQuery['Campaign']
}

type Props = ComponentProps

const Company = ({ campaign }: Props) => {
  let url = campaign.companyURL
  if (url && !url.match(/^[a-zA-Z]+:\/\//)) {
    url = `http://${url}`
  }
  const theme = useCustomTheme()

  return (
    <>
      <PageContainer>
        <Heading is='h1' size={600}>
          Selskapet
        </Heading>

        <Paragraph>{campaign.companyName}</Paragraph>

        <Paragraph>{campaign.companyAddress}</Paragraph>

        <Paragraph>
          {campaign.companyPostcode} {campaign.companyCity}
        </Paragraph>

        <Paragraph>Org.nr: {campaign.companyNumber}</Paragraph>

        {campaign.companyFoundedDate && (
          <Paragraph>Stiftet {moment(campaign.companyFoundedDate).format('YYYY.MM.DD')}</Paragraph>
        )}

        {url && (
          <Paragraph>
            Hjemmeside:{' '}
            <Link href={url} target='_blank'>
              {url}
            </Link>
          </Paragraph>
        )}

        <Pane marginY={theme.spacing.xs}>
          <Owners campaign={campaign} />
        </Pane>
      </PageContainer>

      <Pane width='100%' maxWidth='1200px' overflow='hidden' paddingBottom='60px'>
        <MemberList members={campaign.team} memberType='team' title='Team' />

        <MemberList members={campaign.board} memberType='board' title='Styret' />

        <MemberList members={campaign.partners} memberType='partner' title='Partnere' />
      </Pane>
    </>
  )
}

export default Company
