import styled, { css } from 'styled-components'

export default styled.hr<{ color?: string }>`
  ${({ color, theme }) => css`
    color: ${color || theme.colors.fiDark20};
    border-color: ${color || theme.colors.fiDark20};
    border-top: 0px;
    margin-bottom: ${theme.spacing.xs};
    margin-top: ${theme.spacing.xs};
  `}
`
