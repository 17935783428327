import React from 'react'
import {
  Heading,
  Link,
  ListItem,
  OrderedList as EvergreenOrderedList,
  Pane,
  Paragraph as EvergreenParagraph,
  Strong,
  UnorderedList as EvergreenUnorderedList,
} from 'evergreen-ui'
import styled, { css } from 'styled-components'
import { useBreakpoint } from '/fiweb/lib'

const StyledContainer = styled(Pane)`
${({ theme }) => css`
  display: flex;
  justify-content: center;
  margin-bottom: ${theme.spacing.l};
  padding-top: ${theme.spacing.xs};
  padding-bottom: ${theme.spacing.xs};
  box-sizing: border-box;
  max-width: 100%;
  overflow: auto;
  padding-left: ${theme.spacing.xs};
  padding-right: ${theme.spacing.xs};
`}`

const Paragraph = styled(EvergreenParagraph)`
  ${({ $indent, $noMarginBottom, theme: { spacing } }) => css`
    margin-bottom: ${$noMarginBottom ? 0 : spacing.s};
    margin-left: ${$indent ? spacing.s : 0};
  `}
`

const OrderedList = styled(EvergreenOrderedList)`
  ${({ theme: { spacing } }) => css`
    margin-bottom: ${spacing.s};
    margin-left: ${spacing.l};
  `}
`

const UnorderedList = styled(EvergreenUnorderedList)`
  ${({ theme: { spacing } }) => css`
    margin-bottom: ${spacing.s};
    margin-left: ${spacing.l};
  `}
`

const TableGrid = styled(Pane)`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacing.m};
    border: 1px solid black;
    min-width: 600px;
  `}
`

const TableGridRow = styled(Pane)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`

const TableGridCell = styled(EvergreenParagraph)`
    padding: 5px;
    border: 1px solid black;
    min-width: 200px;
`

export const PrivacyPage = () => {
  const { medium } = useBreakpoint()

  return (
    <StyledContainer>
      <Pane maxWidth='100%' width={800} display='flex' flexDirection='column'>
        <Heading is='h1' size={medium ? 600 : 700}>
          Personvernerklæring
        </Heading>

        <Heading>1. Innledning</Heading>
        <Paragraph>
          I Folkeinvest tar vi personvern på alvor. I personvernerklæringen finner du informasjon om hvordan vi
          behandler dine personopplysninger og hva formålet for behandlingen er. Du finner også informasjon om hvem
          denne informasjonen eventuelt deles med. Det er viktig for oss at du vet hva slags personopplysninger vi
          behandler, slik at du kan ivareta dine rettigheter etter personvernlovgivningen.
        </Paragraph>
        <Paragraph>
          Folkeinvest behandler alle personopplysninger i samsvar med personopplysningsloven og EUs personvernforordning
          (GDPR).
        </Paragraph>
        <Paragraph>
          Når du benytter Folkeinvest sine tjenester, bekrefter du at du er kjent med vår personvernserklæring og at vi
          behandler dine personopplysninger.
        </Paragraph>
        <br />

        <Heading>2. Behandlingsansvarlig</Heading>
        <Paragraph $noMarginBottom>Folkeinvest AS</Paragraph>
        <Paragraph $noMarginBottom>Beddingen 14</Paragraph>
        <Paragraph>7014 Trondheim</Paragraph>
        <Paragraph $noMarginBottom>Tlf: +47 462 80 188</Paragraph>
        <Paragraph $noMarginBottom>
          E-post: <Link href='mailto:personvernombud@folkeinvest.no'>personvernombud@folkeinvest.no</Link>
        </Paragraph>
        <Paragraph>Org. nr: NO 916 545 061 MVA</Paragraph>
        <br />

        <Heading>3. Kontakt</Heading>
        <Paragraph>
          Dersom du har spørsmål til denne erklæring, hvordan Folkeinvest behandler personopplysninger om deg eller du
          trenger hjelp til å utøve dine rettigheter overfor Folkeinvest kan du ta kontakt på epost{' '}
          <Link href='mailto:personvernombud@folkeinvest.no'>personvernombud@folkeinvest.no</Link> eller{' '}
          <Link href='mailto:post@folkeinvest.no'>post@folkeinvest.no</Link>.
        </Paragraph>
        <br />

        <Heading>4. Behandling av personopplysninger for å kunne levere Folkeinvest sine tjenester</Heading>
        <Paragraph>Personopplysninger er opplysninger og vurderinger som kan knyttes til deg som person.</Paragraph>
        <Paragraph $noMarginBottom>Formålet med Folkeinvest sin behandling av personopplysninger er å:</Paragraph>
        <UnorderedList>
          <ListItem>Overholde de lovkrav som stilles Folkeinvest som verdipapirforetak</ListItem>
          <ListItem>
            Bistå utstedere med tilrettelegging av emisjoner innenfor de krav aksjeloven stiller til utsteder
          </ListItem>
          <ListItem>Tilrettelegge for investering</ListItem>
          <ListItem>Oppfylle de avtaleforpliktelser Folkeinvest har mot deg som bruker av våre tjenester</ListItem>
        </UnorderedList>
        <Paragraph>
          Med behandling av personopplysninger menes i denne sammenheng enhver bruk av personopplysninger, som
          innsamling, registrering, sammenstilling, lagring og utlevering, eller en kombinasjon av slike bruksmåter.
        </Paragraph>
        <Paragraph $noMarginBottom>Vi behandler personopplysninger i forbindelse med:</Paragraph>
        <UnorderedList>
          <ListItem>Registrering av brukerprofil</ListItem>
          <ListItem>Registrering av utstederkunde og gjennomføring av emisjon</ListItem>
          <ListItem>Investeringer</ListItem>
          <ListItem>Deltagelse i diskusjonsforum</ListItem>
          <ListItem>Elektronisk kommunikasjon</ListItem>
          <ListItem>E-postutsendelser</ListItem>
          <ListItem>Forbedring av brukeropplevelsen</ListItem>
          <ListItem>Markedsføring og kommunikasjon</ListItem>
          <ListItem>Brukeradferd</ListItem>
          <ListItem>Profilering og segmentering</ListItem>
          <ListItem>Kundesupport</ListItem>
          <ListItem>Håndtering av klager</ListItem>
          <ListItem>Ved søknad om jobb hos oss</ListItem>
          <ListItem>Påmelding til arrangementer eller andre aktiviteter</ListItem>
        </UnorderedList>
        <Paragraph>
          Vi samler inn opplysningene enten direkte fra deg, eller ved hjelp av tredjepartsløsninger.
        </Paragraph>
        <Paragraph>
          Folkeinvest deler ikke personopplysninger med tredjepart, med unntak av de spesifiserte funksjonelle behovene
          beskrevet i avsnittet om databehandlingsavtaler og lovpålagte opplysninger som er påkrevd for å gjennomføre en
          emisjon.
        </Paragraph>
        <br />

        <Heading size={400}>4.1 Registrering av brukerprofil</Heading>
        <Paragraph>
          Dersom du beslutter å registrere en brukerprofil gjennom Folkeinvest sine tjenester, vil personopplysninger
          bli samlet. Brukerprofil må opprettes av både investorkunder og utstederkunder, og er personlig.
        </Paragraph>
        <Paragraph $noMarginBottom>Følgende personopplysninger vil innhentes, registreres og behandles:</Paragraph>
        <UnorderedList>
          <ListItem>E-post</ListItem>
          <ListItem>
            Fullt navn (hentes fra BankID ved autentisering med BankID eller tilsvarende godkjente
            autentiseringstjenester fra andre land)
          </ListItem>
          <ListItem>
            Fullt personnummer eller d-nummer (hentes fra BankID eller tilsvarende ved digital autentisering)
          </ListItem>
          <ListItem>Adresse, gate, postnummer og poststed</ListItem>
          <ListItem>Telefonnummer</ListItem>
          <ListItem>Selskaper som kontohaver kan investere på vegne av (frivillig)</ListItem>
          <ListItem>Samtykker</ListItem>
          <ListItem>PEP, dersom du krysser av for det ved opprettelse av brukerprofil</ListItem>
        </UnorderedList>
        <Paragraph>
          Denne utvalget av informasjon er helt nødvendig informasjon for å kunne levere tjenesten, og inngå en avtale
          om opprettelse av brukerprofil. Dette har hjemmel i GDPR artikkel 6 nr. 1 bokstav b).
        </Paragraph>
        <Paragraph>
          Alle kunder kan når som helst slette sin profil, og personopplysningene som er lagret på kontoen vil bli
          slettet. Eventuelle tegninger/aksjekjøp som er foretatt vil bli bevart i systemet iht. lovkrav knyttet til
          dette. Der en kundes art eller handlingsmønster utløser Folkeinvest sin plikt til nærmere undersøkelser iht.
          Hvitvaskingsloven, vil Folkeinvest innhente nødvendige opplysninger for å oppfylle sine forpliktelser.
        </Paragraph>
        <Paragraph>
          Alle samtykker, samt versjonshistorikk, blir loggført. Hvilken versjon den enkelte profil har godkjent er
          synlig på Folkeinvest sine nettsider under «Min konto» og fanen «Personalia».
        </Paragraph>
        <br />

        <Heading size={400}>4.2 Registrering av utstederkunde og gjennomføring av emisjon</Heading>
        <Paragraph>
          Dersom du beslutter å påbegynne registrering av en utstederkunde med hensikt om å gjennomføre en
          kapitalutvidelse, vil Folkeinvest hente ut informasjon om selskapet du administererer og knytte
          personinformasjon til den enkelte utstederkunde.
        </Paragraph>
        <Paragraph>
          Ved opprettelse av kapitalutvidelse vil det bli gjennomført AML-sjekk av personen som påstarter prosessen, og
          resultatet av denne sjekken vil bli lagret.
        </Paragraph>
        <Paragraph>
          De kapitalsøkende selskap som ønsker å gjennomføre emisjonskampanje signerer en kundeavtale som omfatter en
          Databehandleravtale med Folkeinvest AS.
        </Paragraph>
        <Paragraph>
          Kapitalsøkende selskap er selv ansvarlig for å innhente eventuelle godkjenninger for den informasjon,
          dokumenter og bilder som legges ut om sitt eget selskap, og som kan identifisere personer involvert i
          selskapet og emisjonen.
        </Paragraph>
        <br />

        <Heading size={400}>4.3 Investeringer</Heading>

        <Heading size={300}>4.3.1 Gjennomførte investeringer</Heading>
        <Paragraph>For å kunne gjennomføre en investering må en brukerprofil registreres, ref. kapittel 4.1.</Paragraph>
        <Paragraph $noMarginBottom>Ved gjennomføring av investering lagres følgende data:</Paragraph>
        <UnorderedList>
          <ListItem>Beløp</ListItem>
          <ListItem>Antall aksjer</ListItem>
          <ListItem>Resultat på gjennomført hensiktsmessighetstest</ListItem>
          <ListItem>Tidspunkt for tegning</ListItem>
          <ListItem>Personnummer</ListItem>
          <ListItem>Nasjonalitet</ListItem>
          <ListItem>De godkjenninger man krysser av for i forbindelse med gjennomføring av tegning</ListItem>
          <ListItem>Informasjon om det dersom tegner er eier, team-medlem eller i utstederselskapets styre</ListItem>
          <ListItem>Informasjon om det er en valideringstegning</ListItem>
          <ListItem>Informasjon om hvem det ble tegnet på vegne av: </ListItem>
          <UnorderedList>
            <ListItem>Navn (enten navn tilknyttet brukerprofil eller selskapsnavn)</ListItem>
            <ListItem>Fødselsdato eller organisasjonsnummer</ListItem>
            <ListItem>VPS-kontonummer for de emisjonene hvor dette etterspørres</ListItem>
            <ListItem>Brukernavn (navn på innlogget bruker)</ListItem>
            <ListItem>Adresse (gate, postnummer, poststed og land)</ListItem>
            <ListItem>Epost for innlogget bruker</ListItem>
            <ListItem>Informasjon om det er selskap eller privatperson</ListItem>
            <ListItem>Telefonnummer</ListItem>
          </UnorderedList>
        </UnorderedList>
        <Paragraph>
          Ved gjennomført investering får alle investorer anledning til å gi tilbakemelding gjennom score og kommentar,
          hvor denne informasjonen blir lagret.
        </Paragraph>
        <br />

        <Heading size={300}>4.3.2 AML-sjekk ved tegning</Heading>
        <Paragraph>
          Det gjennomføres AML-sjekk første gang en bruker tegner aksjer, samt hvis det er mer enn 3 måneder siden det
          ble gjennomført en AML-sjekk sist, hvor denne sjekken går mot sanksjonslister og pep-lister. Informasjon fra
          denne sjekken lagres.
        </Paragraph>
        <br />

        <Heading size={300}>4.3.3 Trekking av tegning</Heading>
        <Paragraph>
          Investorer som har i de fleste tilfeller anledning til å trekke tegningen frem til 48 timer før
          tegningsvinduet lukker. Det må oppgis en begrunnelse ved trekking. Dato for trekking og tilhørende begrunnelse
          lagres.
        </Paragraph>
        <Paragraph>
          Trekking av tegning er ikke tilgjengelig for valideringstegninger, styremedlemmer, teammedlemmer og eiere.
        </Paragraph>
        <br />

        <Heading size={300}>4.3.4 Sletting av tegning</Heading>
        <Paragraph>
          Dersom utstederselskap avviser en tegning og denne blir slettet vil dato for avvisning og tilhørende
          begrunnelse lagres.
        </Paragraph>
        <br />

        <Heading size={300}>4.3.5 Tegningslister</Heading>
        <Paragraph>
          Enhver utstederkunde med en offentlig tilgengelig kapitalutvidelse, vil gjennom sin emisjon generere en
          tegningsliste etter hvert som investorer investerer i det aktuelle selskap. Tegningslistene er offentlig
          informasjon for enhver registrert brukerkonto som er innlogget på folkeinvest.no.
        </Paragraph>
        <Paragraph $noMarginBottom>Tegningslistene inneholder:</Paragraph>
        <UnorderedList>
          <ListItem>Navn på tegner (personnavn med fornavn forkortet til initial, eller firmanavn)</ListItem>
          <ListItem>Antall aksjer tegnet av den enkelte entitet</ListItem>
          <ListItem>Hvorvidt den enkelte tegning er en forhånstegning eller ikke</ListItem>
        </UnorderedList>
        <Paragraph>Disse opplysningene hentes fra profilen til de som har tegnet aksjer.</Paragraph>
        <br />

        <Heading size={300}>4.3.6 Ved godkjent kapitalutvidelse</Heading>
        <Paragraph>
          Dersom tegningsperioden har resultert i en vellykket emisjon, formidles en utvidet tegningsliste til utsteder.
          Utstederselskapet er avhengig av denne informasjonen for registrering av kapitalutvidelsen, utarbeide ny
          revidert aksjeeierbok, samt sende inn aksjonærregisteroppgave til skattemyndighetene ved årets slutt.
        </Paragraph>
        <Paragraph $noMarginBottom>Informasjonen som formidles er:</Paragraph>
        <UnorderedList>
          <ListItem>Navn/firmanavn</ListItem>
          <ListItem>Adresse</ListItem>
          <ListItem>Personnummer eller organisasjonsnummer</ListItem>
          <ListItem>Navn på kontaktperson dersom det er et selskap</ListItem>
          <ListItem>E-post</ListItem>
          <ListItem>Telefonnummer</ListItem>
          <ListItem>Antall aksjer</ListItem>
          <ListItem>Tegningstidspunkt</ListItem>
          <ListItem>Utstederselskapets emisjonskontonummer, slik at dette kan legges inn i GF-protokollen</ListItem>
        </UnorderedList>
        <br />

        <Heading size={400}>4.4 Deltagelse i diskusjonsforum</Heading>
        <Paragraph>
          For åpne investeringsmuligheter er det diskusjonsforum. Dette er kun tilgjengelig for de med brukerprofiler.
          Hvis du stiller eller besvarer spørsmål i diskusjonsforumet på en emisjon, vil fullt navn være synlig. Alle
          emisjoner som lukker med suksess vil være mulig å besøke i et år etter at den ble avsluttet, og følgelig vil
          også innlegg i diskusjonsforumet være tilgjengelig for å lese for innloggede brukere i et år.
        </Paragraph>
        <br />

        <Heading size={400}>4.5 Elektronisk kommunikasjon</Heading>
        <Paragraph>
          Vi behandler dine personopplysninger som navn, e-post og telefonnummer for å sende ut informasjon og
          kommunisere med deg vedrørende de av våre tjenester du benytter deg av, eksempelvis utsendelse av
          betalingsinformasjon.
        </Paragraph>
        <Paragraph>
          Ved oppfølging av brukerstøttesamtaler, ved mistanke om teknisk feil eller mistanke om ulovlig handling, vil
          Folkeinvest kunne ta kontakt per e-post eller telefon for å avklare situasjonen.
        </Paragraph>
        <br />

        <Heading size={400}>4.6 E-postutsendelser</Heading>
        <Paragraph>
          For markedsføring og informasjonsformål sender vi ut ulike typer e-poster, herunder både nyhetsbrev og
          virksomhetskritiske e-poster. Vi sender også e-post til de som har valgt å følge en kapitalutvidelse.
        </Paragraph>
        <br />

        <Heading size={300}>4.6.1 Virksomhetskritisk e-post</Heading>
        <Paragraph $noMarginBottom>
          <em>Virksomhetskritisk e-post grad 1:</em>
        </Paragraph>
        <Paragraph>
          For å sette investorene i stand til å benytte tjenesten, sendes e-poster som brukeren er avhengig av.
        </Paragraph>
        <Paragraph>
          Mottak av virksomhetskritiske e-poster grad 1 kan ikke unngås, da de er kritiske for å levere Folkeinvest sin
          tjeneste. Dette kan feks. være e-poster med informasjon om betaling av en investering.
        </Paragraph>
        <Paragraph $noMarginBottom>
          <em>Virksomhetskritisk e-post grad 2:</em>
        </Paragraph>
        <Paragraph>
          For å sette investorene i stand til å kunne investere sender vi informasjon om nye kapitalutvidelser som blir
          publisert via e-post.
        </Paragraph>
        <Paragraph>
          Mottak av virksomhetskritiske e-poster grad 2 kan justeres i intervaller. Kunder har ulikt behov for intervall
          for informasjon. Folkeinvest anbefaler ukentlig oppdatering, og dine innstillinger settes derav automatisk på
          denne frekvensen. Hver kunde kan selv velge informasjonsfrekvens. Merk at dersom du velger
          informasjonsfrekvens sjeldnere enn maks én gang per måned, vil du kunne miste informasjon om
          investeringsmuligheter.
        </Paragraph>
        <br />

        <Heading size={300}>4.6.2 Nyhetsbrev</Heading>
        <Paragraph>
          Formålet med nyhetsbrev er å kunne gi informasjon om nyheter, samt aktuelle produkter eller tjenester
          foretaket leverer.
        </Paragraph>
        <Paragraph>Vi innhenter alltid ditt samtykke før du blir satt på liste for mottak av nyhetsbrev.</Paragraph>
        <Paragraph>
          Ved slikt samtykke vil vi behandle navn, adresse, epost og telefonnummer. Du vil forbli på slike lister til du
          selv aktivt velger å trekke tilbake samtykket. Du kan trekke tilbake ditt samtykke til enhver tid.
        </Paragraph>
        <Paragraph>
          Det rettslige grunnlaget for behandlingen er samtykke etter GDPR artikkel 6 nr. 1 bokstav a).
        </Paragraph>
        <br />

        <Heading size={300}>4.6.3 Følge en kapitalutvidelse</Heading>
        <Paragraph>
          Som bruker av tjenesten, kan du «følge en kapitalutvidelse». Hvis du følger en kapitalutvidelse aksepterer du
          at du vil kunne motta både automatisk genererte e-poster, men også personlige e-poster fra Folkeinvest og den
          aktuelle utstederkunde.
        </Paragraph>
        <Paragraph>Vi innhenter alltid ditt samtykke i denne prosessen.</Paragraph>
        <Paragraph>
          Ved slikt samtykke vil vi behandle navn, e-post og telefonnummer. Du vil forbli på slike lister til du selv
          aktivt velger å trekke tilbake samtykket. Du kan trekke tilbake ditt samtykke til enhver tid.
        </Paragraph>
        <Paragraph>
          Det rettslige grunnlaget for behandlingen er samtykke etter GDPR artikkel 6 nr. 1 bokstav a).
        </Paragraph>
        <br />

        <Heading size={400}>4.7 Forbedring av brukeropplevelsen</Heading>
        <Paragraph>
          Folkeinvest henter inn informasjon om hvordan våre tjenester benyttes, herunder hvor lenge din økt varte,
          hvilke sider av tjenesten du har besøkt og til hvilken tid, samt hvilken funksjonalitet du har benyttet.
          Dersom du er innlogget på våre tjenester har vi anledning til å knytte dine aktiviteter på tjenesten til din
          brukerprofil.
        </Paragraph>
        <Paragraph>
          Vi samler også inn informasjon om enheten(e) du benytter for å få tilgang til tjenestene våre. Dette kan være
          maskinvare- og programvareinformasjon, IP-adresse, enhets-ID, enhetsspesifikke innstillinger (f.eks. språk,
          tidssone, nettlesertype, varslingsinnstillinger), hvilken side du kom fra da du entret våre tjenester og
          identifikatorer knyttet til informasjonskapsler eller andre teknologier som kan identifisere enheten eller
          nettleseren din.
        </Paragraph>
        <br />

        <Heading size={400}>4.8 Markedsføring og kommunikasjon</Heading>
        <Paragraph>
          For å kunne gi deg relevant markedsføring av foretakets produkter og tjenester, bruker vi kontaktopplysningene
          du har gitt oss ved å bruke tjenestene våre, samt opplysninger om ditt bruksmønster for å kunne følge deg opp
          i vår markedsføring og kommunikasjon.
        </Paragraph>
        <Paragraph>
          Basert på informasjon om våre eksisterende brukere kan vi nå ut til potensielle nye brukere med relevant
          markedsføring.
        </Paragraph>
        <Paragraph>
          Dersom du har et eksisterende kundeforhold med oss, er behandlingen basert på vår berettigede interesse i å
          tilby best mulig tjeneste g kommunikasjon rundt våre tjenester. Behandlingen er basert på GDPR artikkel 6
          (1)(f).
        </Paragraph>
        <Paragraph>
          Dersom du ikke har et eksisterende kundeforhold med oss, vil vi be om samtykke til digital markedsføring.
          Behandlingen er da basert på GDPR artikkel 6 nr. 1 bokstav a).
        </Paragraph>
        <br />

        <Heading size={300}>4.8.1 Sosiale plattformer</Heading>
        <Paragraph>
          Folkeinvest bruker flere ulike sosiale plattformer for å kommunisere med våre kunder og andre interessenter. I
          denne forbindelse vil tilgjengelige personopplysninger behandles ifm. eventuelle henvendelser via disse
          plattformene.
        </Paragraph>
        <Paragraph>
          Det rettslige grunnlaget for behandlingen er vår berettigede interesse i å kunne tilby en god kundeopplevelse
          og svare på henvendelser fra våre kunder og er basert på GDPR artikkel 6 nr. 1 bokstav f).
        </Paragraph>
        <br />

        <Heading size={400}>4.9 Brukeradferd</Heading>
        <Paragraph>
          Brukeradferd logges og analyseres på nettsidene for å kunne forbedre tjenestene og den helhetlige
          brukeropplevelsen. Dessuten setter dette oss i sand til å finne uregelmessigheter, samt gi deg relevant
          informasjon til riktig tid.
        </Paragraph>
        <Paragraph>
          På nettsidene våre benytter vi informasjonskapsler til å samle statistikk om hvordan nettsidene brukes.
          Cookie-erklæringen vår kan du lese{' '}
          <Link href='/cookies' target='_blank'>
            her
          </Link>
          .
        </Paragraph>
        <Paragraph $noMarginBottom>
          Informasjonen som behandles av Folkeinvest, kan deles inn i følgende fire kategorier:
        </Paragraph>
        <OrderedList>
          <ListItem>
            <Strong>Nødvendige:</Strong> Denne informasjonen er helt nødvendig for at nettsidene skal fungere som de
            skal. Det er ikke mulig å velge bort behandling av denne typen informasjon.
          </ListItem>
          <ListItem>
            <Strong>Funksjonelle:</Strong> Den funksjonelle informasjonen sørger for at nettsidene tilpasses dine valg.
          </ListItem>
          <ListItem>
            <Strong>Analyse/statistikk:</Strong> Vi gjør en del informasjonsinnsamling som setter oss i stand til å
            analysere brukere og deres aktivitet i våre tjenester. Dette gir oss innsikt til å forbedre tjenestene og
            brukeropplevelsen til den enkelte.
          </ListItem>
          <ListItem>
            <Strong>Markedsføring:</Strong> Vi samler inn en del informasjon som benyttes for å kunne gi deg relevant
            kommunikasjon og markedsføring i ulike kanaler. Dette setter oss i stand til å gi deg relevante annonser via
            egne og tredjepartskanaler.
          </ListItem>
        </OrderedList>
        <br />

        <Heading size={400}>4.10 Profilering og segmentering</Heading>
        <Paragraph>
          For å kunne gi brukere av våre tjenester relevant og tilpasset informasjon, samt markedsføre produkter og
          tjenester behandler vi personopplysninger om deg. For å få til dette benytter vi oss av profilering og
          segmentering av brukere.
        </Paragraph>
        <Paragraph>
          Behandlingen består av å samle inn informasjon om våre brukere, for deretter å plassere brukerne inn i en
          bestemt profil eller et bestemt segment. Folkeinvest vil deretter tilpasse annonser, informasjon og rådgivning
          til den spesifikke profilen/segmentet.
        </Paragraph>
        <Paragraph $noMarginBottom>
          Når vi utvikler profiler og segmenter til kommunikasjon, markedsføring og analyseformål, bruker vi følgende
          informasjon:
        </Paragraph>
        <UnorderedList>
          <ListItem>Opplysninger du har gitt oss i forbindelse med kjøp av produktene og tjenestene våre.</ListItem>
          <ListItem>Opplysninger du deler med oss når du bruker tjenestene våre.</ListItem>
          <ListItem>Opplysninger om din brukeradferd når du benytter tjenestene våre.</ListItem>
        </UnorderedList>
        <Paragraph>
          Vi bruker opplysningene dine for å sikre at vi tilbyr deg mest mulig relevante og tilpassede tjenester og
          tilbud.
        </Paragraph>
        <Paragraph>
          Behandlingen er nødvendig for å kunne gi informasjon, rådgivning og markedsføring tilpasset våre brukere. Vi
          har vurdert at vi har en berettiget interesse i å sørge for at Folkeinvest fremstår som relevant for kunden
          jf. GDPR artikkel 6 nr. 1 bokstav f).
        </Paragraph>
        <br />

        <Heading size={400}>4.11 Kundesupport</Heading>
        <Paragraph>
          Alle har mulighet til å ta kontakt med kundesupport hos Folkeinvest, enten via epost eller gjennom
          chat-løsning for kundesupport som er tilgjengelig på folkeinvest.no. Chat-henvendelser lagres hos leverandør i
          opptil 24 måneder.
        </Paragraph>
        <br />

        <Heading size={400}>4.12 Håndtering av klager</Heading>
        <Paragraph>
          Alle har anledning til å melde inn klager til Folkeinvest AS. For eventuelle kundeklager oppbevares
          dokumentasjon vedrørende dette i minst 5 år fra tidspunktet klagebehandlingsprosedyrene ble avsluttet. Alle
          kundeklager registreres i et eget register for kundeklager. Folkeinvest AS må årlig rapportere antall klager
          og avgjørelser i klagesakene til Finanstilsynet.
        </Paragraph>
        <br />

        <Heading size={400}>4.13 Ved søknad om jobb hos oss</Heading>
        <Paragraph>
          Ved jobbsøking, søker kandidat gjennom ekstern leverandør. Det er oppgitt i brukervilkår til den valgte
          plattform hvor lenge informasjon om jobbsøkeren vil lagres. Folkeinvest lagrer søknader til søknadsprosessen
          er over, og vil utbe særskilt samtykke dersom søknaden lagres lenger.
        </Paragraph>
        <br />

        <Heading size={400}>4.14 Påmelding til arrangementer eller andre aktiviteter</Heading>
        <Paragraph>
          Formålet med registreringen er å gi Folkeinvest nødvendig informasjon over deltagere, samt å kunne
          tilrettelegge for de deltagere som har behov for dette, eksempelvis ved matallergi. Innhentet informasjon kan
          variere noe, men vil ha navn, epostadresse, mobilnummer som et minimum, og noen ganger informasjon som
          selskap, rolle og eventuelle behov for tilrettelegging som obligatoriske felter. Informasjonen lagres til
          maksimalt et år etter gjennomført arrangement.
        </Paragraph>
        <br />

        <Heading>5. Lagringstid</Heading>
        <Paragraph>
          Vi vil slette eller anonymisere personopplysninger om deg når formålet med den enkelte behandling av oppfylt,
          med mindre opplysningene skal eller kan oppbevares utover dette som følge av lovgivningen.
        </Paragraph>
        <Paragraph>
          Dette betyr for eksempel at personopplysninger som vi behandler på grunnlag av ditt samtykke slettes, hvis du
          trekker ditt samtykke.
        </Paragraph>
        <Paragraph>
          Personopplysninger vi behandler for å oppfylle en avtale med deg slettes når avtalen er oppfylt og alle
          plikter som følger av avtaleforholdet er oppfylt.
        </Paragraph>
        <br />

        <Heading>6. Rettigheter</Heading>
        <Paragraph>
          Alle som spør, har rett på grunnleggende informasjon om hvordan Folkeinvest behandler personopplysninger.
          Dersom du er registrert i et av systemene våre har du rett på innsyn i dine egne personopplysninger. Du kan
          også be om retting eller sletting av mangelfulle eller uriktige opplysninger.
        </Paragraph>
        <Paragraph>
          Du finner, og kan rette, opplysninger under «Min konto» på folkeinvest.no. Ved behov utover dette, se kapittel
          6.1-6.5.
        </Paragraph>
        <Paragraph>
          <Strong>Du kan utøve dine rettigheter</Strong> ved å sende oss e-post til:
        </Paragraph>
        <Paragraph $indent>
          <Link href='mailto:post@folkeinvest.no'>post@folkeinvest.no</Link>
        </Paragraph>
        <Paragraph>eller brev til:</Paragraph>
        <Paragraph $indent $noMarginBottom>
          Folkeinvest AS
        </Paragraph>
        <Paragraph $indent $noMarginBottom>
          Beddingen 14
        </Paragraph>
        <Paragraph $indent>7014 Trondheim</Paragraph>
        <Paragraph>Du kan også kontakte oss per:</Paragraph>
        <Paragraph $indent>
          <Link href='mailto:personvernombud@folkeinvest.no'>personvernombud@folkeinvest.no</Link>
        </Paragraph>
        <Paragraph>Du har krav på svar uten ugrunnet opphold, og senest innen én måned.</Paragraph>
        <br />

        <Heading size={400}>6.1 Innsyn</Heading>
        <Paragraph>
          Under «Min konto», under fanen «Personalia» kan du se hvilke personopplysninger du har registrert i din bruker
          og hva som automatisk er hentet inn etter godkjent digital autentisering.
        </Paragraph>
        <Paragraph>
          Du har rett til å få bekreftelse på om personopplysninger om deg behandles, og om det er tilfellet, innsyn i
          personopplysningene som behandles. Du kan be om en kopi av alle opplysninger vi behandler om deg.
        </Paragraph>
        <Paragraph>
          <Link
            href='https://www.datatilsynet.no/rettigheter-og-plikter/den-registrertes-rettigheter/rett-til-innsyn/'
            target='_blank'
          >
            Les mer
          </Link>{' '}
          om Innsyn på Datatilsynets nettsider.
        </Paragraph>
        <br />

        <Heading size={400}>6.2 Retting/korrigering</Heading>
        <Paragraph>Du kan be oss rette eller supplere opplysninger som er feilaktige eller misvisende.</Paragraph>
        <Paragraph>
          <Link
            href='https://www.datatilsynet.no/rettigheter-og-plikter/den-registrertes-rettigheter/rett-til-retting/'
            target='_blank'
          >
            Les mer
          </Link>{' '}
          om Retting på Datatilsynets nettsider.
        </Paragraph>
        <br />

        <Heading size={400}>6.3 Sletting</Heading>
        <Paragraph>
          Mulighet for sletting av din informasjon er lokalisert under «Min konto». Informasjon om tegninger foretatt av
          deg vil bli liggende i vårt system for kontroll og ettersyn, med de detaljer som lovverket krever i forhold
          til aksjeeierboken. I henhold til personopplysningsloven § 28 skal opplysninger som ikke lenger er nødvendig
          ut fra det formål de er lagret for, slettes. Vi lagrer personopplysninger om deg i henhold til den til enhver
          tid gjeldende lovgivning.
        </Paragraph>
        <Paragraph>I gitte situasjoner kan du be oss slette opplysninger om deg selv.</Paragraph>
        <Paragraph>
          <Link
            href='https://www.datatilsynet.no/rettigheter-og-plikter/den-registrertes-rettigheter/rett-til-sletting/'
            target='_blank'
          >
            Les mer
          </Link>{' '}
          om Sletting på Datatilsynets nettsider.
        </Paragraph>
        <br />

        <Heading size={400}>6.4 Begrensning</Heading>
        <Paragraph>I noen situasjoner kan du også be oss begrense behandlingen av opplysninger om deg.</Paragraph>
        <Paragraph>
          <Link
            href='https://www.datatilsynet.no/rettigheter-og-plikter/den-registrertes-rettigheter/rett-til-begrensning/'
            target='_blank'
          >
            Les mer
          </Link>{' '}
          om Begrensning på Datatilsynets nettsider.
        </Paragraph>
        <br />

        <Heading size={400}>6.5 Klage på vår behandling av personopplysninger</Heading>
        <Paragraph>
          Du kan klage på vår behandling av personopplysninger. Dette kan rettes til{' '}
          <Link href='mailto:klage@folkeinvest.no'>klage@folkeinvest.no</Link>. Det er også mulig å ta kontakt per{' '}
          <Link href='mailto:personvernombud@folkeinvest.no'>personvernombud@folkeinvest.no</Link>.
        </Paragraph>
        <Paragraph>
          Du kan også henvende deg til Datatilsynet for å klage på vår behandling av personopplysninger.
        </Paragraph>
        <br />

        <Heading>7. Behandlingsansvarlig og databehandleravtaler</Heading>
        <Paragraph>
          For å levere våre tjenester, må Folkeinvest dele enkelte av dine personopplysninger med leverandører. Dette er
          eksempelvis serverhosting-leverandører og kundesupport-systemer.Disse omtales i det videre som
          «databehandlere»
        </Paragraph>
        <Paragraph>
          Databehandlere som utfører arbeid på vegne av Folkeinvest skal ikke utgjøre risiko for brudd i sikkerheten for
          dine personopplysninger. For å sikre at dine personvernrettigheter er ivaretatt, har Folkeinvest en
          databehandleravtale med alle sine leverandører, som er påkrevd dersom disse på lovlig grunnlag skal behandle
          personopplysninger. Databehandleravtalen stiller relevante krav til leverandørenes kunnskap, ressurser og
          sikkerhetsnivå for behandlingen av de aktuelle personopplysningene.
        </Paragraph>
        <Paragraph>
          Underleverandører av Folkeinvest har ingen bestemmelsesrett over hvordan personopplysningene skal behandles,
          men opptrer kun på instruks fra Folkeinvest.
        </Paragraph>
        <Paragraph>
          Databehandleravtalen fastsetter hensikten med og varigheten av behandlingen, behandlingens formål og art,
          typen personopplysninger og kategorier av registrerte samt alle kunders og egne rettigheter og plikter.
        </Paragraph>
        <Paragraph>
          Den behandlingsansvarlige er ansvarlig for skade som er oppstått som følge av brudd på personvernlovgivningen,
          og databehandler er ansvarlig dersom de har opptrådt i strid med pålegg.
        </Paragraph>
        <Paragraph>
          I enkelte tilfeller må personopplysningene overføres og behandles av databehandlere som er etablert i USA
          eller andre land utenfor EU. Når det deles opplysninger med aktører utenfor EU, vil Folkeinvest implementere
          tiltak for å beskytte dine personopplysninger.
        </Paragraph>
        <Paragraph>
          Dersom hele eller deler av Folkeinvest sin virksomhet selges, kan dine opplysninger overføres.
        </Paragraph>
        <br />

        <Heading size={400}>
          7.1 Oversikt over databehandlere og andre behandlingsansvarlige vi overfører personopplysninger til
        </Heading>
        <Pane maxWidth='100%' overflowX='auto'>
          <TableGrid>
            <TableGridRow>
              <TableGridCell>
                <Strong>Databehandler</Strong>
              </TableGridCell>
              <TableGridCell>
                <Strong>Type personopplysninger</Strong>
              </TableGridCell>
              <TableGridCell>
                <Strong>Formål med behandlingen</Strong>
              </TableGridCell>
            </TableGridRow>
            <TableGridRow>
              <TableGridCell>Atlas</TableGridCell>
              <TableGridCell>Informasjon du har gitt oss ved bruk av våre tjenester.</TableGridCell>
              <TableGridCell>
                Databaseleverandør, all emisjonsdata og brukerdata er lagret og kryptert her, men er ikke tilgjengelig
                for tredjepart
              </TableGridCell>
            </TableGridRow>
            <TableGridRow>
              <TableGridCell>Google LLC (Ads, Analytics, BigQuery)</TableGridCell>
              <TableGridCell>Geografisk lokasjon, enhets- og nettleserinformasjon og bruksmønster</TableGridCell>
              <TableGridCell>Statistikk, analyse og markedsføring - etter samtykke.</TableGridCell>
            </TableGridRow>
            <TableGridRow>
              <TableGridCell>Hubspot</TableGridCell>
              <TableGridCell>E-post-adresse og fullt navn</TableGridCell>
              <TableGridCell>Utsendelse av e-poster iht. samtykke.</TableGridCell>
            </TableGridRow>
            <TableGridRow>
              <TableGridCell>META Platforms Inc.</TableGridCell>
              <TableGridCell>Fullt navn, adresse, e-post-adresse, alder, kjønn og bruksmønster.</TableGridCell>
              <TableGridCell>Kommunikasjon og markedsføring (etter samtykke), samt support.</TableGridCell>
            </TableGridRow>
            <TableGridRow>
              <TableGridCell>Schibsted ASA</TableGridCell>
              <TableGridCell>E-post adresser delt i henhold til samtykke</TableGridCell>
              <TableGridCell>Annonseringstjenester</TableGridCell>
            </TableGridRow>
            <TableGridRow>
              <TableGridCell>SendGrid</TableGridCell>
              <TableGridCell>E-post-adresse og fullt navn</TableGridCell>
              <TableGridCell>Utsendelse av e-poster iht. samtykke.</TableGridCell>
            </TableGridRow>
            <TableGridRow>
              <TableGridCell>Signicat</TableGridCell>
              <TableGridCell>Personnummer, fullt navn</TableGridCell>
              <TableGridCell>
                Leverandør av BankID, signeringstjeneste og andre nordiske identifiseringstjenester
              </TableGridCell>
            </TableGridRow>
            <TableGridRow>
              <TableGridCell>Slack</TableGridCell>
              <TableGridCell>Forenklet tegningsdata og møtebookingsdata</TableGridCell>
              <TableGridCell>Varslinger i forbindelse med arbeidsprosesser</TableGridCell>
            </TableGridRow>
          </TableGrid>
        </Pane>

        <Heading>8. Annet</Heading>
        <Paragraph>
          All overføring av data ved interaksjon mellom deg og Folkeinvest, skjer gjennom kryptert forbindelse i
          nettleser (https). Nødvendige tekniske sikkerhetstiltak er gjort for å forhindre angrep og at data kommer på
          avveie.
        </Paragraph>
        <br />

        <Heading>9. Versjonslogg</Heading>
        <Paragraph>Denne personvernserklæringen ble sist oppdatert:</Paragraph>
        <Paragraph $indent>29.06.2023</Paragraph>
      </Pane>
    </StyledContainer>
  )
}
