import * as React from 'react'
import { breakpoints } from '/fiweb/lib'
import { Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled from 'styled-components'

const StatisticTitle = styled(Paragraph)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
`
const StatisticValue = styled(Paragraph)`
  font-size: 18px;
  line-height: 24px;
  white-space: nowrap;
  font-weight: 600;

  @media ${breakpoints.large} {
    font-size: 16px;
  }
  @media ${breakpoints.small} {
    font-size: 16px;
  }
`

interface Props {
  label: string
  value?: any
  textColor?: string
  alignValue?: string
  mobileView?: boolean
}

const StatisticSection = ({ label, value, textColor, alignValue, mobileView }: Props) => {
  const theme = useCustomTheme()
  return (
    <Pane display='flex' flexDirection='column' overflow='hidden'>
      <StatisticTitle color={textColor || theme.colors.fiDark70} marginBottom={mobileView ? 2 : 3}>
        {label}
      </StatisticTitle>
      <StatisticValue color={textColor || theme.colors.fiDark70} alignSelf={alignValue || 'flex-start'}>
        {value}
      </StatisticValue>
    </Pane>
  )
}

export default StatisticSection
