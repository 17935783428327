import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import Description from './Description'
import EmissionInformation from './EmissionInformation'
import Comments from './Comments'
import Investments from './Investments'
import { FetchCampaignQuery } from '/~/types/graphql'
import Company from './Company'
import Documents from './Documents'
import NotSignedIn from './NotSignedIn'
import { useAuthContext } from '/~/utils/AuthContext'

interface Props {
  campaign: FetchCampaignQuery['Campaign']
}

const Router = ({ campaign }: Props) => {
  const { user, loading } = useAuthContext()
  const signedIn = !loading && user

  const requireLogin = (element: React.ReactNode, pageTitle: string) => {
    if (!signedIn) {
      return <NotSignedIn pageTitle={pageTitle} />
    }
    return element
  }

  return (
    <Routes>
      {['/', 'beskrivelse'].map((path) => (
        <Route key={path} path={path} element={<Description campaign={campaign} />} />
      ))}
      <Route path='data' element={requireLogin(<EmissionInformation campaign={campaign} />, 'Emisjon')} />
      <Route path='diskusjon' element={requireLogin(<Comments campaignId={campaign?._id} />, 'Diskusjon')} />
      <Route path='selskap' element={requireLogin(<Company campaign={campaign} />, 'Selskap')} />
      <Route path='dokumenter' element={requireLogin(<Documents campaign={campaign} />, 'Dokumenter')} />
      <Route path='tegninger' element={requireLogin(<Investments campaign={campaign} />, 'Tegningsliste')} />
    </Routes>
  )
}

export default Router
