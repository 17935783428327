import React from 'react'
import { Pagination, PaginationProps } from 'evergreen-ui'
import styled, { css } from 'styled-components'

const StyledPagination = styled(Pagination)`
  ${({ theme: { colors } }) => css`
    .pagination li {
      background-color: white;
      margin-right: 8px;
      max-width: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
    }

    .pagination button {
      min-width: fit-content;
      width: 40px;
      height: 40px;
      padding: 0;
      border-radius: 2px;
      font-size: 18px;
      border: 1px solid ${colors.fiDark10};
      margin: 0;
    }
    .pagination button:hover {
      width: 100%;
      border-radius: 2px;
    }

    [aria-current='true'] {
      background-color: ${colors.fiDark70};
      width: 100%;
      color: ${colors.fiDark10};
      border: none;
    }

    [aria-current='true']:hover {
      background-color: ${colors.fiDark70};
      width: 100%;
      border-radius: 2px;
      color: ${colors.fiDark10};
    }

    [aria-current='true']:hover path {
      fill: ${colors.fiDark10};
    }
  `}
`

const PaginationComponent = ({ ...rest }: PaginationProps) => {
  return <StyledPagination {...rest} />
}

export default PaginationComponent
