import * as React from 'react'
import { Heading, Paragraph, Pane, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { DisplayTime } from '/fiweb/components'
import { CardDataType } from './CardData'
import { SmallerButton } from '../SmallerButton'
import { CardPaddedPane } from '../CampaignCardTemplate'

const UpcomingData = ({
  timestamp,
  followerCount = 0,
  visitorIsFollowing = false,
  mobileView,
  campaignId,
  emissionProcessId,
  disableFollowButton,
  campaignToolMode,
  followDialog,
  preorderButton,
}: CardDataType) => {
  const [open, setOpen] = React.useState(false)
  const [followDisabled, setFollowDisabled] = React.useState(false)
  const theme = useCustomTheme()

  const handleClose = (toggle: boolean) => {
    setFollowDisabled(toggle)
    setOpen(false)
  }

  if (!timestamp) {
    return null
  }

  return (
    <CardPaddedPane $mobileView={mobileView}>
      {mobileView ? (
        <Pane display='flex' flexDirection='column' marginBottom={theme.spacing.s}>
          <Pane
            display='flex'
            flexDirection='row'
            alignItems='baseline'
            justifyContent='space-between'
            height={24}
            marginBottom={theme.spacing.xs}
          >
            <Heading is='h5' fontSize={16} lineHeight='22px' fontWeight='normal'>
              Åpner om <DisplayTime date={timestamp} />
            </Heading>
            <Text color='muted' fontWeight={400} fontSize={16} lineHeight='22px'>
              {followerCount} følgere
            </Text>
          </Pane>
          {!campaignToolMode && (
            <SmallerButton
              width='100%'
              disabled={disableFollowButton || followDisabled}
              borderRadius={30}
              size='large'
              fontSize={17}
              onClick={() => setOpen(true)}
            >
              {followDisabled || visitorIsFollowing ? 'Fulgt' : 'Følg emisjon'}
            </SmallerButton>
          )}
        </Pane>
      ) : (
        <Pane display='flex' flexDirection='column' marginBottom={theme.spacing.xs}>
          <Heading
            is='h5'
            fontSize={18}
            lineHeight='22px'
            fontWeight={600}
            height={22}
            marginBottom={theme.spacing.xxs}
          >
            Åpner om <DisplayTime date={timestamp} />
          </Heading>
          <Pane display='flex' flexFlow='row nowrap' justifyContent='space-between' alignItems='center'>
            <Paragraph fontSize={16} color='muted' fontWeight={600}>
              {followerCount} følgere
            </Paragraph>
            {!campaignToolMode && (
              <SmallerButton
                disabled={disableFollowButton || followDisabled}
                borderRadius={40}
                size='large'
                fontSize={17}
                onClick={() => setOpen(true)}
              >
                {followDisabled || visitorIsFollowing ? 'Fulgt' : 'Følg emisjon'}
              </SmallerButton>
            )}
          </Pane>
        </Pane>
      )}
      {preorderButton}
      {followDialog?.({ open, onClose: handleClose, campaignId, emissionProcessId })}
    </CardPaddedPane>
  )
}

export default UpcomingData
