import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Pane, Heading, Button, UnorderedList, ListItem } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { LargeInfoCard } from '/~/components/Cards'
import { FormattedMessage, useIntl } from 'react-intl'

export const DoesItFit = () => {
  const theme = useCustomTheme()
  const intl = useIntl()
  const { small, medium, large } = useBreakpoint()

  const requirements = intl.messages['entrepreneurLanding.requirements']
  const accessToList = intl.messages['entrepreneurLanding.accessToList']

  return (
    <LargeInfoCard
      marginBottom={medium ? theme.spacing.m : theme.spacing.l}
      marginTop={medium ? theme.spacing.m : theme.spacing.l}
      maxWidth='100%'
      header={intl.formatMessage({ id: 'entrepreneurLanding.doesItFitHeading' })}
      text={intl.locale !== 'no-Nb' ? intl.formatMessage({ id: 'entrepreneurLanding.doesItFitSubtitle' }) : undefined}
    >
      <Pane marginBottom={theme.spacing.m}>
        <UnorderedList paddingLeft={theme.spacing.s}>
          {requirements.map((node, i) => (
            <ListItem key={`requirement-${i}`}>{node}</ListItem>
          ))}
        </UnorderedList>
      </Pane>

      {!!accessToList.length && (
        <>
          <Heading size={500} color={theme.colors.text.default}>
            <FormattedMessage id='entrepreneurLanding.accessToHeading' />
          </Heading>

          <Pane marginBottom={theme.spacing.m}>
            <UnorderedList paddingLeft={theme.spacing.s}>
              {accessToList.map((node, i) => (
                <ListItem key={i}>{node}</ListItem>
              ))}
            </UnorderedList>
          </Pane>
        </>
      )}

      {intl.locale !== 'no-Nb' ? null : (
        <Button
          marginTop={theme.spacing.xs}
          width={small ? '100%' : 'auto'}
          appearance='primary'
          size={large ? 'medium' : 'small'}
          onClick={() => window.open('/sok-kapital-kontakt')}
        >
          <FormattedMessage id='entrepreneurLanding.doesItFitCTAButton' />
        </Button>
      )}
    </LargeInfoCard>
  )
}
