import * as React from 'react'
import { Label, LabelProps, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props {
  id?: string
  layout?: 'vertical' | 'horizontal'
  required?: boolean
  label: React.ReactNode
}

export const FormLabel = ({ id, layout, required, label, ...labelProps }: Props & LabelProps) => {
  const { spacing } = useCustomTheme()

  return (
    <Label
      id={id}
      position='relative'
      {...(layout === 'vertical' ? { display: 'block', marginBottom: spacing.xxs } : { marginRight: '12px' })}
      {...labelProps}
    >
      {required && (
        <Text color='danger' position='absolute' left={-12} top={-4}>
          *
        </Text>
      )}
      {label}
    </Label>
  )
}
