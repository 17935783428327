import { Pane } from 'evergreen-ui'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { ActionBar } from '../ActionBar'
import { TitleAndProgress } from '../Header/TitleAndProgress'
import { widths } from '../utils'
import { KeyInformation } from './KeyInformation'
import { EnhancedDialog } from '/fiweb/components'
import { breakpoints } from '/fiweb/lib'
import { useCustomTheme } from '/fiweb/lib/theme'

const KeyInformationSpacer = styled(Pane)`
  margin: ${({ theme: { spacing } }) => spacing.s} 0;
  overflow: scroll;
`

const GrayBar = styled(Pane)`
  ${({ theme: { colors, spacing } }) => css`
    background-color: ${colors.fiDark10};
    padding: ${spacing.xs} ${spacing.s};
    display: flex;
    justify-content: center;
    width: 100%;

    @media ${breakpoints.small} {
      padding: ${spacing.xs} ${spacing.xxs};
    }
  `}
`

const ActionBarSection = styled(Pane)`
  max-width: ${widths.mediumHeaderSectionMax};
  width: 100%;
`

const DialogContent = styled(Pane)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

interface Props {
  isShown: boolean
  onClose: () => void
}

export const KeyInformationDialog = ({ isShown, onClose }: Props) => {
  const { spacing } = useCustomTheme()

  return (
    <EnhancedDialog
      isShown={isShown}
      onCloseComplete={onClose}
      fullscreen
      preventBodyScrolling
      contentContainerProps={{
        padding: 0,
      }}
    >
      <DialogContent>
        <Pane padding={spacing.m} overflowY='scroll'>
          <TitleAndProgress />
          <KeyInformationSpacer>
            <KeyInformation />
          </KeyInformationSpacer>
        </Pane>
        <GrayBar>
          <ActionBarSection>
            <ActionBar renderDialog={false} onlyButtons onCancel={onClose} />
          </ActionBarSection>
        </GrayBar>
      </DialogContent>
    </EnhancedDialog>
  )
}
