import * as React from 'react'
import { Button, Checkbox, Heading, Link, Pane, Paragraph, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import countries from 'i18n-iso-countries'
import noLocale from 'i18n-iso-countries/langs/nb.json'
import { useAuthContext } from '/~/utils/AuthContext'

interface Props {
  onNext: () => void
  onPrevious: () => void
  onAbort: () => void
  confirmProfile: (confirmed: boolean) => void
  disabled: boolean
}

const ProfileDisplay = ({ onNext, onPrevious, confirmProfile, disabled }: Props) => {
  const { user } = useAuthContext()
  countries.registerLocale(noLocale)
  const [profileConfirmed, setProfileConfirmed] = React.useState(false)
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  if (!user) {
    return <Paragraph>Bruker ikke logget inn</Paragraph>
  }
  const { name, birthdate, email, phone, addressFields } = user
  const country = countries.getName(addressFields?.countryCode, 'nb')

  const data = [
    { label: 'Navn:', value: name },
    { label: 'Fødselsdato:', value: birthdate },
    { label: 'Adresse:', value: addressFields?.street },
    { label: 'Postnummer:', value: addressFields?.postalCode },
    { label: 'Poststed:', value: addressFields?.postalTown },
    { label: 'Land:', value: country },
    { label: 'Epost:', value: email },
    { label: 'Telefonnummer:', value: phone },
  ]

  const next = () => {
    confirmProfile(profileConfirmed)
    onNext()
  }

  return (
    <Pane>
      <Heading is='h5' size={400}>
        Din profil
      </Heading>
      <Pane display='grid' gridTemplateColumns='1fr 1fr'>
        {data.map((d) => (
          <React.Fragment key={d.label}>
            <Text fontWeight={600}>{d.label}</Text>
            <Text>{d.value}</Text>
          </React.Fragment>
        ))}
      </Pane>
      <Pane>
        <Checkbox
          checked={profileConfirmed}
          onChange={(e) => setProfileConfirmed(e.target.checked)}
          label='Jeg bekrefter at disse opplysningene stemmer *'
        />
        <Text size={300}>* Må bekreftes for å fortsette</Text>
      </Pane>
      <Pane
        display='flex'
        alignItems={medium ? 'initial' : 'baseline'}
        flexDirection={medium ? 'column-reverse' : 'row'}
        justifyContent='flex-end'
        marginTop={theme.spacing.s}
        marginBottom={theme.spacing.xs}
      >
        <Pane display='flex' justifyContent='center'>
          <Link cursor='pointer' onClick={onPrevious}>
            Tilbake
          </Link>
        </Pane>
        <Button
          size='small'
          appearance='primary'
          onClick={next}
          marginLeft={medium ? 0 : 18}
          marginBottom={medium ? theme.spacing.xs : 0}
          disabled={!profileConfirmed || disabled}
        >
          Neste
        </Button>
      </Pane>
    </Pane>
  )
}

export default ProfileDisplay
