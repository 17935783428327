import * as React from 'react'
import { IconDirection, IconProps } from './types'

interface ThinChevronProps extends IconProps {
  direction: IconDirection
}

export const ThinChevron = ({ direction, title, ...rest }: ThinChevronProps) => (
  <svg
    width='12'
    height='20'
    viewBox='0 0 12 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ transform: `rotate(${direction}deg)` }}
    {...rest}
  >
    <title>{title || ''}</title>
    <path
      d='M0.746094 1.26239L10.5633 10.1365C10.6468 10.2122 10.6946 10.3207 10.6946 10.4348C10.6946 10.5488 10.6468 10.6574 10.5633 10.7331L0.746094 19.6072'
      stroke='currentColor'
    />
  </svg>
)
