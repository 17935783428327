import * as React from 'react'
import { LabelProps, Pane, PaneProps, Text, TextProps } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Caption } from '..'
import { FormLabel } from './FormLabel'

type ReducedPaneProps = Omit<PaneProps, 'label' | 'prefix' | 'suffix' | 'required'>

export interface InputWrapperProps extends ReducedPaneProps {
  label?: React.ReactNode | null
  caption?: React.ReactNode
  layout?: 'horizontal' | 'vertical'
  error?: React.ReactNode | null
  prefix?: React.ReactNode | null
  suffix?: React.ReactNode | null
  required?: boolean
  prefixProps?: TextProps
  suffixProps?: TextProps
  labelProps?: LabelProps
  uid?: string
}

type Props = { children: React.ReactNode } & InputWrapperProps

export const InputWrapper = ({
  label,
  caption,
  error,
  layout = 'vertical',
  prefix,
  suffix,
  prefixProps,
  suffixProps,
  required,
  children,
  uid,
  labelProps,
  ...paneProps
}: Props) => {
  const replacementUid = React.useMemo(() => `input-field-label-${new Date().getTime()}`, [])
  const { spacing } = useCustomTheme()

  return (
    <Pane marginBottom={spacing.xs} {...paneProps}>
      {label && (
        <FormLabel id={uid || replacementUid} layout={layout} required={required} label={label} {...labelProps} />
      )}

      {caption && (
        <Caption width='90%' display='block' marginBottom={spacing.xxs}>
          {caption}
        </Caption>
      )}
      <Pane display='flex' alignItems='center'>
        {prefix && (
          <Text size={300} color='dark' marginRight={spacing.xxs} {...prefixProps}>
            {prefix}
          </Text>
        )}
        {children}
        {suffix && (
          <Text whiteSpace='nowrap' size={300} color='dark' marginLeft={spacing.xxs} {...suffixProps}>
            {suffix}
          </Text>
        )}
      </Pane>
      <Caption display='block' color='danger'>
        {error}
      </Caption>
    </Pane>
  )
}
