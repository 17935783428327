class StorageFactory {
  inMemoryStorage = {}

  isSupported = () => {
    try {
      const testKey = '_local_storage_test_string_'
      localStorage.setItem(testKey, testKey)
      localStorage.getItem(testKey)
      localStorage.removeItem(testKey)
      return true
    } catch (_e) {
      return false
    }
  }

  clear = (key: string) => {
    if (!key) {
      return null
    }
    if (this.isSupported()) {
      localStorage.removeItem(key)
    } else {
      delete this.inMemoryStorage[key]
    }
  }

  get = (key: string) => {
    if (this.isSupported()) {
      const item = localStorage.getItem(key)
      try {
        if (item) {
          return JSON.parse(item)
        }
      } catch (_e) {
        /* No work! */
      }
      return item
    }
    return this.inMemoryStorage[key]
  }

  set = (key: string, value: any) => {
    if (!key) {
      return null
    }
    if (this.isSupported()) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      this.inMemoryStorage[key] = value
    }
  }
}

const storage = new StorageFactory()

export default storage
