import * as React from 'react'
import { Pane, SelectField, toaster } from 'evergreen-ui'
import styled from 'styled-components'
import { useEditEmailPreferencesMutation, EmailPreferences as EmailPreferencesType } from '/~/types/graphql'
import { AuthContextType } from '/~/utils/AuthContext/AuthContextProvider'

const Wrapper = styled(Pane)`
  // Hackaround for evergreen
  select {
    background-color: white;
  }
`

interface Props {
  user?: AuthContextType['user'] | EmailPreferencesType
  disabled?: boolean
  enableFlashMessages?: boolean
}

export const CampaignNotificationsPreferences = ({ user, disabled, enableFlashMessages }: Props) => {
  const [editPreferences] = useEditEmailPreferencesMutation()
  const [selected, setSelected] = React.useState(user?.notifyInterval || 'never')

  const intervalOptions = [
    { label: 'Per emisjon', value: 'all' },
    { label: 'Ukentlig', value: 'weekly' },
    { label: 'Månedlig', value: 'monthly' },
    { label: 'Aldri', value: 'never' },
  ]

  const handleChange = async ({ name, value }) => {
    try {
      await editPreferences({
        variables: {
          input: { email: user?.email, [name]: value },
        },
      })

      setSelected(value)

      if (enableFlashMessages) {
        toaster.success('Innstilling oppdatert')
      }
    } catch (err) {
      console.log('Error updating emial preferences: ', err)
      if (enableFlashMessages) {
        toaster.danger('Feil under oppdatering, prøv igjen eller kontakt support')
      }
    }
  }

  return (
    <Wrapper>
      <SelectField
        inputHeight={50}
        width='fit-content'
        marginBottom={0}
        disabled={disabled || !user}
        value={selected}
        onChange={(e) => handleChange({ name: 'notifyInterval', value: e.target.value })}
        label='Innstilling for oppdatering om investeringsmuligheter'
      >
        {intervalOptions.map((o, i) => (
          <option key={i} value={o.value}>
            {o.label}
          </option>
        ))}
      </SelectField>
    </Wrapper>
  )
}
