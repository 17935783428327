import { ListItem, Pane, Text, UnorderedList } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import * as React from 'react'
import { SelfreportItem } from '/~/types/graphql'

interface Props {
  item: SelfreportItem
}

export const RadioWithDescriptionItem = ({ item }: Props) => {
  const theme = useCustomTheme()
  const { values, options, id, answerDetails } = item
  const detailsVisible = values?.[0] === options?.[1]
  const answer = answerDetails || 'Detaljer mangler'

  return (
    <Pane>
      <UnorderedList marginTop={0} marginBottom={theme.spacing.xxs} paddingLeft={20}>
        {values?.map((value, i) => (
          <ListItem key={`${id}_${i}`}>
            <Text>{value}</Text>
          </ListItem>
        ))}
      </UnorderedList>
      {detailsVisible && <Text>Svar: {answer}</Text>}
    </Pane>
  )
}
