import * as React from 'react'
import { IconProps } from './types'

export const MitIdLogo = ({ title, ...rest }: IconProps) => (
  <svg viewBox='0 0 61 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <g clipPath='url(#clip0_316_79603)'>
      <path
        d='M19.2 -2.4248e-06C19.556 -2.4248e-06 19.904 0.105566 20.2 0.303352C20.496 0.501139 20.7267 0.78226 20.863 1.11117C20.9992 1.44007 21.0349 1.80199 20.9654 2.15116C20.896 2.50033 20.7245 2.82106 20.4728 3.07279C20.2211 3.32452 19.9003 3.49596 19.5512 3.56541C19.202 3.63486 18.8401 3.59922 18.5112 3.46298C18.1823 3.32674 17.9011 3.09603 17.7034 2.80002C17.5056 2.50402 17.4 2.156 17.4 1.8C17.3987 1.56325 17.4443 1.32859 17.5343 1.1096C17.6243 0.890616 17.7568 0.691659 17.9243 0.524248C18.0917 0.356838 18.2906 0.224302 18.5096 0.134313C18.7286 0.0443241 18.9633 -0.00132879 19.2 -2.4248e-06ZM40.4 -2.4248e-06C41.1516 -2.4248e-06 41.8863 0.222864 42.5112 0.640413C43.1361 1.05796 43.6231 1.65144 43.9107 2.3458C44.1984 3.04016 44.2736 3.80421 44.127 4.54134C43.9804 5.27847 43.6184 5.95556 43.087 6.487C42.5556 7.01844 41.8785 7.38036 41.1413 7.52698C40.4042 7.67361 39.6402 7.59835 38.9458 7.31074C38.2514 7.02313 37.658 6.53607 37.2404 5.91116C36.8229 5.28626 36.6 4.55157 36.6 3.8C36.6 2.79217 37.0004 1.82563 37.713 1.11299C38.4256 0.400354 39.3922 -2.4248e-06 40.4 -2.4248e-06ZM20.7 4.9V15.8H17.7V4.9H20.7ZM2.9 0.799998L7.5 7.9L12 0.799998H14.9V15.8H11.8V6.1L7.5 12.5H7.4L3.1 6.1V15.8H0V0.799998H2.9ZM40.4 9C44 9 46.9 11 47.3 15.8H33.6C34 11 36.9 9 40.4 9ZM51.9 0.199998C58.3 0.199998 61 3.7 61 8C61 12.3 58.3 15.8 51.9 15.8H49.3V0.199998H51.9ZM27.1 1.9V4.9H29.5V7.3H27.1V12.1C27.1 13 27.6 13.3 28.4 13.3C28.8685 13.3376 29.3337 13.1945 29.7 12.9V15.6C29.0591 15.8287 28.3798 15.9306 27.7 15.9C25.5 15.9 24.1 14.8 24.1 12.4V7.3H22.4V4.9H24.1V1.9H27.1Z'
        fill='#2D3340'
      />
    </g>
    <defs>
      <clipPath id='clip0_316_79603'>
        <rect width='61' height='15.9' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
