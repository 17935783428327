import * as React from 'react'
import { Heading, Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { ProgressBarWithSpread } from '/fiweb/components'
import { CardDataType } from './CardData'
import { numberFormatter } from '/fiweb/lib/formatters'
import { DataSection } from './DataSection'
import { CardPaddedPaneWrapper, CardPaddedPane } from '../CampaignCardTemplate'

const Finished = ({
  reservedAmountInNok = 0,
  reservedPercent = 0,
  reservedPercentOfMax = 0,
  investorCount = 0,
  mobileView,
  raisingAmountMinimum,
  raisingAmountMaximum,
  cardDataFromOldApi,
}: CardDataType) => {
  const theme = useCustomTheme()

  return (
    <Pane display='flex' flexDirection='column'>
      <CardPaddedPane $mobileView={mobileView}>
        <Heading size={500}>{numberFormatter(reservedAmountInNok)} kr</Heading>

        <ProgressBarWithSpread
          currencyInNok={cardDataFromOldApi}
          goal={raisingAmountMinimum}
          max={raisingAmountMaximum}
          progress={reservedPercent}
          inactive
        />
      </CardPaddedPane>

      <CardPaddedPaneWrapper marginTop={theme.spacing.s}>
        <CardPaddedPane $mobileView={mobileView}>
          <DataSection
            investorCount={investorCount}
            reservedPercent={reservedPercent}
            reservedPercentOfMax={reservedPercentOfMax}
            mobileView={mobileView}
            finished
          />
        </CardPaddedPane>
      </CardPaddedPaneWrapper>
    </Pane>
  )
}

export default Finished
