import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { auth, Service } from '/fiweb/lib'
import { config } from '/~/utils/config'
import { StatusCodes } from 'http-status-codes'
import { UNKNOWN_ERROR } from '../constants'
import { getSignicatAuthError } from './utils'
import { SignicatCallback, SignicatCallbackProps } from './SignicatCallback'

export const SignicatSignupCallback = () => {
  const navigate = useNavigate()

  const onCallback: SignicatCallbackProps['onCallback'] = async (sessionId, callback) => {
    const signIn = async () => {
      const res = await auth(config.authHost).signupWithService({
        service: Service.SIGNICAT,
        token: sessionId,
      })

      if (!res.ok) {
        const resError = await getSignicatAuthError(res)
        switch (res.status) {
          case StatusCodes.BAD_REQUEST: {
            if (resError && resError?.field === 'email') {
              const params = new URLSearchParams()
              params.set('sessionId', sessionId)

              if (resError?.value) {
                params.set('email', resError?.value)
              }

              navigate(`/innlogging/registrering/epost-kreves?${params.toString()}`, { replace: true })
              break
            }
            return { text: UNKNOWN_ERROR, provider: resError?.provider }
          }
          case StatusCodes.NOT_FOUND: {
            return {
              text: 'Bruker ikke funnet, vennligst gå til registrering av bruker',
              provider: resError?.provider,
            }
          }

          case StatusCodes.CONFLICT: {
            return {
              text: 'Bruker allerede registrert, gå til innlogging',
              provider: resError?.provider,
            }
          }

          default: {
            return { text: UNKNOWN_ERROR, provider: resError?.provider }
          }
        }
      }
      return { text: UNKNOWN_ERROR }
    }

    switch (callback) {
      case 'success':
      case 'error': {
        return await signIn()
      }

      case 'abort': {
        navigate('/innlogging/registrering')
        break
      }
    }
  }

  return <SignicatCallback onCallback={onCallback} />
}
