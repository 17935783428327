import { useLocation, useParams } from 'react-router-dom'

/**
 * Hook to get the signicat callback type present in the url
 * & session id present as a query parameter
 */
export const useSignicatSession = () => {
  const { callback } = useParams<{ callback: 'error' | 'success' | 'abort' }>()

  const location = useLocation()

  const params = new URLSearchParams(location.search)

  return {
    callback,
    sessionId: params.get('sessionId'),
  }
}
