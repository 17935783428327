import { config } from '/~/utils/config'

const imageBase = `${config.publicFilesUrl}/aboutus`

interface FolkeinvestEmployee {
  imageUrl: string
  name: string
  title: string
  location?: string
}

export const employees: Array<FolkeinvestEmployee> = [
  {
    imageUrl: `${imageBase}/team/amalie-holt.jpg`,
    name: 'Amalie Holt',
    title: 'CMO',
    location: 'Oslo',
  },
  {
    imageUrl: `${imageBase}/team/andreas-brunvoll.jpg`,
    name: 'Andreas Brunvoll',
    title: 'COO',
    location: 'Molde',
  },
  {
    imageUrl: `${imageBase}/team/elise-melhus.jpg`,
    name: 'Elise Melhus',
    title: 'Senior Tilrettelegger',
    location: 'Trondheim',
  },
  {
    imageUrl: `${imageBase}/team/ida-nordahl.jpg`,
    name: 'Ida Nordahl',
    title: 'CPO',
    location: 'Trondheim',
  },
  {
    imageUrl: `${imageBase}/team/jorgen-aspenes.jpg`,
    name: 'Jørgen Aspenes',
    title: 'Senior tilrettelegger',
    location: 'Trondheim',
  },
  {
    imageUrl: `${imageBase}/team/jorgen-axelsen.jpg`,
    name: 'Jørgen Axelsen',
    title: 'Team Lead/Tilrettelegger',
    location: 'Oslo',
  },
  {
    imageUrl: `${imageBase}/team/karim-benounis.jpg`,
    name: 'Karim Arntsen Benounis',
    title: 'CFO',
    location: 'Trondheim',
  },
  {
    imageUrl: `${imageBase}/team/magne-holan.jpg`,
    name: 'Magne Holan',
    title: 'Utvikler',
    location: 'Trondheim',
  },
  {
    imageUrl: `${imageBase}/team/maria-olsen.jpg`,
    name: 'Maria Olsen',
    title: 'Tilrettelegger',
    location: 'Trondheim',
  },
  {
    imageUrl: `${imageBase}/team/marius-hogli-aasarod.jpg`,
    name: 'Marius Høgli Aasarød',
    title: 'Forretningsanalytiker',
    location: 'Trondheim',
  },
  {
    imageUrl: `${imageBase}/team/martin-berre.jpg`,
    name: 'Martin Berre',
    title: 'Tilrettelegger',
    location: 'Trondheim',
  },
  {
    imageUrl: `${imageBase}/team/michael-persson.jpg`,
    name: 'Michael Persson',
    title: 'UX/Design',
    location: 'Trondheim',
  },
  {
    imageUrl: `${imageBase}/team/oda-froisland.jpg`,
    name: 'Oda Frøisland',
    title: 'Markedsansvarlig',
    location: 'Trondheim',
  },
  {
    imageUrl: `${imageBase}/team/ole-kristian-aarskog.jpg`,
    name: 'Ole Kristian Aarskog',
    title: 'Utvikler',
    location: 'Trondheim',
  },
  {
    imageUrl: `${imageBase}/team/oyvind-fries.jpg`,
    name: 'Øyvind Fries',
    title: 'CEO',
    location: 'Trondheim',
  },
]

export const board: Array<FolkeinvestEmployee> = [
  {
    imageUrl: `${imageBase}/board/finn-haugan.jpg`,
    name: 'Finn Haugan',
    title: 'Styreleder',
  },
  {
    imageUrl: `${imageBase}/board/havard-olstad.jpg`,
    name: 'Håvard Olstad',
    title: 'Styremedlem',
  },
  {
    imageUrl: `${imageBase}/board/janicke-lovaas.jpg`,
    name: 'Janicke Løvaas',
    title: 'Styremedlem',
  },
  {
    imageUrl: `${imageBase}/board/johan-woo-kvandal.jpg`,
    name: 'Johan Woo Kvandal',
    title: 'Styremedlem',
  },
  {
    imageUrl: `${imageBase}/board/maren-kamp.jpg`,
    name: 'Maren Kamp',
    title: 'Styremedlem',
  },
  {
    imageUrl: `${imageBase}/board/sverre-konrad-nilsen.jpg`,
    name: 'Sverre Konrad Nilsen',
    title: 'Nestleder',
  },
]
