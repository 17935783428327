declare global {
  interface Window {
    HubSpotConversations?: any
    hsConversationsOnReady?: any
  }
}

export const hubSpotChatController = (isChatDisabled: boolean) => {
  const onConversationsAPIReady = () => {
    if (isChatDisabled) {
      window.HubSpotConversations.widget.remove()
    }
  }

  if (window.HubSpotConversations) {
    onConversationsAPIReady()
  } else {
    // This callback will be called once the external API has been initialized.
    window.hsConversationsOnReady = [onConversationsAPIReady]
  }
}
