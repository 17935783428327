interface LoginProps {
  email: string
  password: string
  persistentLogin?: boolean
}

interface SignupProps {
  email: string
  password: string
  name?: string
  campaignRef?: string
}

interface LoginWithServiceProps {
  service: Service
  token: string
}

type VerifyWithServiceProps = LoginWithServiceProps

interface SignupWithServiceProps extends LoginWithServiceProps {
  campaignRef?: string
  email?: string
}

interface TwoFactorProps {
  userId: string
  token: string
}

export interface RequestDeleteUserDataResponse {
  customerAgreements: number
  activeReservations: number
  canBeDeleted: boolean
}
interface DeleteUserResponse {
  campaigns: number
  commentSubscriptions: number
  emailReminders: number
  users: number
  companyUsers: number
  archivedId: string | null
}
// Must match the enums in auth/lib/services/signicat.ts
export enum SignicatProvider {
  NORWEGIAN_BANK_ID = 'nbid',
  SWEDISH_BANK_ID = 'sbid',
  DANISH_MIT_ID = 'mitid',
}

export enum Service {
  GOOGLE = 'google',
  SIGNICAT = 'signicat',
}

const postReqInit: RequestInit = {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
}

export const auth = (authEndpoint: string) => {
  const login = async (loginProps: LoginProps) => {
    return await fetch(`${authEndpoint}/login`, {
      ...postReqInit,
      body: JSON.stringify(loginProps),
    })
  }

  const signup = async (signupProps: SignupProps) => {
    return await fetch(`${authEndpoint}/signup`, {
      ...postReqInit,
      body: JSON.stringify(signupProps),
    })
  }

  const loginWithService = async (loginProps: LoginWithServiceProps) => {
    return await fetch(`${authEndpoint}/login-with-service`, {
      ...postReqInit,
      body: JSON.stringify(loginProps),
    })
  }

  const signupWithService = async (signupProps: SignupWithServiceProps) => {
    return await fetch(`${authEndpoint}/signup-with-service`, {
      ...postReqInit,
      body: JSON.stringify(signupProps),
    })
  }

  const verifyWithService = async (verifyProps: VerifyWithServiceProps) => {
    return await fetch(`${authEndpoint}/verify-with-service`, {
      ...postReqInit,
      body: JSON.stringify(verifyProps),
    })
  }

  const logout = async (setSessionItem = false) => {
    if (setSessionItem) {
      try {
        sessionStorage.setItem('user-invoked-logout', 'true')
      } catch (_e) {
        // Silently fail if cookies are disabled (fixes embed crash problem)
      }
    }

    return await fetch(`${authEndpoint}/logout`, { credentials: 'include' })
  }

  const obtainRefreshToken = async () => {
    return await fetch(`${authEndpoint}/refresh-token`, { credentials: 'include' })
  }

  const obtainAccessToken = async () => {
    return await fetch(`${authEndpoint}/access-token`, { credentials: 'include' })
  }

  const twoFactor = async (twoFactorProps: TwoFactorProps) => {
    return await fetch(`${authEndpoint}/two-factor`, {
      ...postReqInit,
      body: JSON.stringify(twoFactorProps),
    })
  }

  const obtainSignicatSessionUrl = async (allowedProviders: SignicatProvider[], redirect: string) => {
    return await fetch(
      `${authEndpoint}/session/${Service.SIGNICAT}?allowedProviders=${allowedProviders.join(
        ',',
      )}&redirect=${encodeURIComponent(redirect)}`,
      {
        method: 'GET',
      },
    )
  }

  const requestDeleteUserData = async (body?: { userId: string }) => {
    const res = await fetch(`${authEndpoint}/request-delete-user-data`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body ? JSON.stringify(body) : null,
    })
    if (res.ok) {
      return (await res.json()) as RequestDeleteUserDataResponse
    }
    throw Error(`Bad response from server: ${res.status}`)
  }

  const deleteUser = async (body?: { userId: string }) => {
    const res = await fetch(`${authEndpoint}/delete-user`, {
      ...postReqInit,
      body: JSON.stringify(body),
    })
    if (res.ok) {
      return (await res.json()) as DeleteUserResponse
    }
    throw Error(`Bad response from server: ${res.status}`)
  }

  return {
    login,
    loginWithService,
    logout,
    obtainRefreshToken,
    obtainAccessToken,
    signup,
    signupWithService,
    verifyWithService,
    twoFactor,
    obtainSignicatSessionUrl,
    deleteUser,
    requestDeleteUserData,
  }
}
