import * as React from 'react'
import { CampaignCardTemplateType, CardTypeEnum } from '../types'
import ComingSoon from './ComingSoon'
import Draft from './Draft'
import Finished from './Finished'
import Ongoing from './Ongoing'
import Upcoming from './Upcoming'

export type CardDataType = Omit<
  CampaignCardTemplateType,
  'clickNavigatesTo' | 'title' | 'caption' | 'imageUrl' | 'stickerType'
> & { parentRef?: React.RefObject<HTMLInputElement> }

const CardData = (props: CardDataType) => {
  switch (props.cardType) {
    case CardTypeEnum.COMING_SOON:
      return <ComingSoon {...props} />
    case CardTypeEnum.UPCOMING:
      return <Upcoming {...props} />
    case CardTypeEnum.ONGOING:
      return <Ongoing {...props} />
    case CardTypeEnum.FINISHED:
    case CardTypeEnum.ARCHIVED:
      return <Finished {...props} />
    case CardTypeEnum.DRAFT:
      return <Draft />
    default:
      return null
  }
}

export default CardData
