import * as React from 'react'
import { Button, Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import NavButtons from './NavButtonsComponent'
import StatusDoneComponent from './StatusDoneComponent'
import { useAuthContext } from '/~/utils/AuthContext'
import { BankIDVerification } from '/~/components/BankIDVerification/BankIDVerification'

interface Props {
  goBack: () => void
  goForward: () => void
  onClose: () => void
}

const BankidStep = ({ goBack, goForward, onClose }: Props) => {
  const small = useBreakpoint()
  const { user } = useAuthContext()
  const theme = useCustomTheme()

  if (!user?.email) {
    return (
      <Pane
        display='flex'
        flexDirection='column'
        maxWidth={380}
        width='100%'
        marginTop={theme.spacing.l}
        alignItems='center'
        paddingX={small ? 20 : 0}
      >
        <Heading is='h3'>BankID</Heading>

        <Paragraph size={300} textAlign='center' marginBottom={theme.spacing.s}>
          Grunnet en feil kan vi ikke verifisere BankID for din profil nå. Du kan gjøre dette når som helst på
          profilsiden din.
        </Paragraph>

        <Button onClick={onClose} marginY={theme.spacing.xxs} size='small' appearance='primary'>
          Lukk registreringen
        </Button>
      </Pane>
    )
  }

  return (
    <Pane
      display='flex'
      flexDirection='column'
      maxWidth={380}
      width='100%'
      marginTop={theme.spacing.l}
      alignItems='center'
      paddingX={small ? 20 : 0}
      height='100%'
    >
      <Heading is='h3'>BankID</Heading>

      {!user.bankidverified ? (
        <Pane marginBottom={theme.spacing.xs}>
          <BankIDVerification centered column buttonWidth={244} />
        </Pane>
      ) : (
        <Pane width='100%' marginY={theme.spacing.s} paddingX={small ? 20 : 0}>
          <StatusDoneComponent hidden={!user?.bankidverified} title='BankID' description='Verifisert' />
        </Pane>
      )}

      <Paragraph size={300} textAlign='center'>
        For å tegne aksjer kreves engangsidentifikasjon gjennom BankID.
      </Paragraph>
      <Paragraph size={300} textAlign='center' marginBottom={theme.spacing.xs}>
        Informasjonen sikrer regelverksetterlevelse.
      </Paragraph>

      <NavButtons onSave={goForward} onBack={goBack} disabled={!user.bankidverified} />
    </Pane>
  )
}

export default BankidStep
