import * as React from 'react'
import _ from 'lodash'
import { Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useSelfreportItemsInCampaignQuery } from '/~/types/graphql'
import { Loading } from '/fiweb/components'
import { SelfreportItem } from './SelfreportItem'

interface Props {
  campaignId: string
}

const Selfreport = ({ campaignId }: Props) => {
  const { data, loading } = useSelfreportItemsInCampaignQuery({
    variables: { campaignId },
    fetchPolicy: 'no-cache',
  })
  const theme = useCustomTheme()

  if (loading) {
    return <Loading />
  }

  const { selfreportlist } = data?.Campaign || {}

  return (
    <Pane marginTop={theme.spacing.l}>
      <Heading is='h3' size={500}>
        Egenmelding
      </Heading>
      <Paragraph>
        Egenmeldingen representerer et ekstrakt som har til hensikt å svare ut forhold mange lesere historisk har vist
        interesse for.
      </Paragraph>
      <br />
      {selfreportlist?.map((item, i) => (
        <SelfreportItem key={item.id} item={item} no={i + 1} />
      ))}
    </Pane>
  )
}

export default Selfreport
