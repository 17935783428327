import * as React from 'react'
import { IconProps } from './types'

interface AlertCircleProps extends IconProps {
  flipped?: boolean
}

export const AlertCircle = ({ flipped, title, ...rest }: AlertCircleProps) => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
    style={flipped ? { transform: 'rotate(180deg)', ...rest.style } : { ...rest.style }}
  >
    <title>{title || ''}</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.6134 24.2057C17.1801 24.1637 19.6272 23.1133 21.4256 21.2815C23.2438 19.4713 24.2475 16.9994 24.2058 14.434C24.2088 11.9284 23.2144 9.52467 21.4421 7.75346C19.6698 5.98225 17.2655 4.98927 14.7599 4.99374L14.5886 4.99374C9.24265 5.04874 4.95024 9.42101 4.99378 14.767C4.98781 17.3037 6.0053 19.7355 7.81594 21.5121C9.62658 23.2887 12.0773 24.2599 14.6134 24.2057ZM15.8005 10.9639C15.8122 11.2809 15.6958 11.5894 15.4777 11.8197C15.2595 12.0501 14.9578 12.183 14.6406 12.1886L14.619 12.1886C13.9626 12.1874 13.4243 11.6679 13.3998 11.0119C13.3879 10.6948 13.5042 10.3862 13.7224 10.1558C13.9407 9.92538 14.2424 9.79251 14.5597 9.78714L14.5814 9.78714C15.2375 9.78922 15.7752 10.3083 15.8005 10.9639ZM15.4004 19.0025L15.4004 14.1995C15.4004 13.7574 15.042 13.399 14.5999 13.399C14.1578 13.399 13.7994 13.7574 13.7994 14.1995L13.7994 19.0025C13.7994 19.4446 14.1578 19.803 14.5999 19.803C15.042 19.803 15.4004 19.4446 15.4004 19.0025Z'
      fill='currentColor'
    />
    <mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='4' y='4' width='21' height='21'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.6134 24.2057C17.1801 24.1637 19.6272 23.1133 21.4256 21.2815C23.2438 19.4713 24.2475 16.9994 24.2058 14.434C24.2088 11.9284 23.2144 9.52467 21.4421 7.75346C19.6698 5.98225 17.2655 4.98927 14.7599 4.99374L14.5886 4.99374C9.24265 5.04874 4.95024 9.42101 4.99378 14.767C4.98781 17.3037 6.0053 19.7355 7.81594 21.5121C9.62658 23.2887 12.0773 24.2599 14.6134 24.2057ZM15.8005 10.9639C15.8122 11.2809 15.6958 11.5894 15.4777 11.8197C15.2595 12.0501 14.9578 12.183 14.6406 12.1886L14.619 12.1886C13.9626 12.1874 13.4243 11.6679 13.3998 11.0119C13.3879 10.6948 13.5042 10.3862 13.7224 10.1558C13.9407 9.92538 14.2424 9.79251 14.5597 9.78714L14.5814 9.78714C15.2375 9.78922 15.7752 10.3083 15.8005 10.9639ZM15.4004 19.0025L15.4004 14.1995C15.4004 13.7574 15.042 13.399 14.5999 13.399C14.1578 13.399 13.7994 13.7574 13.7994 14.1995L13.7994 19.0025C13.7994 19.4446 14.1578 19.803 14.5999 19.803C15.042 19.803 15.4004 19.4446 15.4004 19.0025Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0)' />
  </svg>
)
