import * as React from 'react'
import { IconProps } from './types'

export const BellOn = ({ title, ...rest }: IconProps) => {
  return (
    <svg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <title>{title || ''}</title>
      <path d='M8 5C6.73904 5.69993 5.6997 6.73927 5 8' stroke='currentColor' strokeWidth='2' />
      <path d='M7 1C4.09534 1.82566 1.82519 4.0958 1 7' stroke='currentColor' strokeWidth='2' />
      <path d='M5 24H26' stroke='currentColor' strokeWidth='2' />
      <path
        d='M26 24C26 24 23.9 22.2011 23.9 15.7355C23.9 10.5702 20.3132 6 15.5 6C10.6868 6 7.1 10.5702 7.1 15.7355C7.1 22.2355 5 24 5 24'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path d='M17 28C17 29.1046 16.1046 30 15 30C13.8954 30 13 29.1046 13 28' stroke='currentColor' strokeWidth='2' />
      <path d='M15 6V3' stroke='currentColor' strokeWidth='2' />
      <path
        d='M22 5C22.8351 5.34957 23.6024 5.7834 24.2802 6.28936C24.9562 6.79722 25.535 7.37292 26 8'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path d='M24 1C26.9047 1.82566 29.1748 4.0958 30 7' stroke='currentColor' strokeWidth='2' />
    </svg>
  )
}
