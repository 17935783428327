import React from 'react'
import {
  useFollowInvestmentOfferMutation,
  useInvestmentOfferFollowerInformationQuery,
  useUnfollowInvestmentOfferMutation,
} from '/~/types/issuer-graphql'
import { client } from '/~/lib/issuer-client/client'
import { normalizeSingleNewApiCampaignCard } from '/~/utils/campaignCard'
import { ActualFollowDialog } from './ActualFollowDialog'
import { useLocation } from 'react-router-dom'
import { gtmEventAddToWishList } from '/~/utils/googleTagManager'

interface Props {
  open: boolean
  onClose: (didFollow: boolean) => void
  emissionProcessId: string
}

export const NewApiFollowDialogWrapper = ({ open, onClose, emissionProcessId }: Props) => {
  const { data, loading, refetch } = useInvestmentOfferFollowerInformationQuery({
    variables: { emissionProcessId },
    skip: !client,
    client,
  })
  const location = useLocation()

  const [register] = useFollowInvestmentOfferMutation({ client })
  const [unRegister] = useUnfollowInvestmentOfferMutation({ client })

  const cardData = normalizeSingleNewApiCampaignCard(data?.publishedCampaignCardByEmissionProcessId)
  const visitor = data?.investmentOfferVisitor

  const { acceptsPreorderOffers, approvedForPreorder, isFollowing } = visitor || {}

  const title = cardData?.title
  const coverImageUrl = cardData?.imageUrl

  const handleRegister = async (email: string, preorderChecked: boolean) => {
    await register({
      variables: { emissionProcessId, follower: { acceptsPreorderOffer: preorderChecked, email } },
    })

    gtmEventAddToWishList({
      title,
      id: emissionProcessId,
      pricePerShare: (data?.publishedCampaignCardByEmissionProcessId?.publicInvestmentOffer?.pricePerShare || 0) / 100,
      fromCampaignPage: location.pathname.includes('/investeringstilbud/'),
    })
    await refetch?.()
  }

  const handleUnRegister = async () => {
    await unRegister({ variables: { emissionProcessId } })
    await refetch?.()
  }

  if (loading || !cardData) {
    return null
  }

  return (
    <ActualFollowDialog
      acceptsPreorderOffers={acceptsPreorderOffers}
      approvedForPreorder={approvedForPreorder}
      isFollowing={isFollowing}
      onClose={onClose}
      open={open}
      onRegister={handleRegister}
      onUnregister={handleUnRegister}
      title={title}
      coverImageUrl={coverImageUrl}
    />
  )
}
