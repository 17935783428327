import * as React from 'react'
import { Link, Paragraph, Small, Strong, Spinner, toaster } from 'evergreen-ui'
import { useAuthContext } from '/~/utils/AuthContext'
import { useAcceptTermsMutationMutation, useGetTermsQuery } from '/~/types/graphql'
import Terms from '/~/components/auth/Signup/Steps/Terms'

const ProfileTermsStatus = () => {
  const { user } = useAuthContext()

  const [openTerms, setOpenTerms] = React.useState(false)
  const [acceptTerms] = useAcceptTermsMutationMutation()
  const { data, loading } = useGetTermsQuery({ variables: { latest: true } })

  const handleAcceptTerms = async () => {
    setOpenTerms(false)
    try {
      await acceptTerms({
        variables: { termsVersion: data?.getTerms?.version },
      })
    } catch (_err) {
      toaster.danger('Kunne ikke oppdatere profil. Prøv igjen eller kontakt support')
    }
  }

  if (!user) {
    return null
  }
  if (loading || !data?.getTerms) {
    return <Spinner />
  }

  return (
    <>
      <Paragraph size={300}>
        <Strong size={300}>Samtykker</Strong>
      </Paragraph>
      <Paragraph size={300}>
        <Small>Vilkår og rettigheter</Small>
      </Paragraph>
      <Paragraph size={300}>
        <Small>En ny versjon av vilkår og rettigheter må godkjennes:</Small>
      </Paragraph>

      <Link size={300} onClick={() => setOpenTerms(true)} cursor='pointer'>
        <Small>Les her</Small>
      </Link>

      <Terms
        open={openTerms}
        terms={data.getTerms}
        onClose={() => setOpenTerms(false)}
        acceptTerms={handleAcceptTerms}
      />
    </>
  )
}

export default ProfileTermsStatus
