import dayjs, { Dayjs } from 'dayjs'
import * as React from 'react'

interface Props {
  date: Date | number
}

export const useTimeTicker = (
  timestamp: Date | number | string | Dayjs,
  options: { interval?: number; stopAfterPass?: boolean; serverTimeOffset?: number } = {
    interval: 500,
    stopAfterPass: true,
    serverTimeOffset: 0,
  },
) => {
  const date = dayjs(timestamp)
  const [time, setTime] = React.useState(date.fromNow(true))

  React.useEffect(() => {
    setTime(date.fromNow(true))

    const timer = setInterval(() => {
      if (options.stopAfterPass) {
        if (!date.isBefore(dayjs(Date.now() + (options.serverTimeOffset ?? 0)))) {
          setTime(date.fromNow(true))
        } else {
          clearInterval(timer)
        }
      } else {
        setTime(date.fromNow(true))
      }
    }, options?.interval || 500)
    return () => clearInterval(timer)
  }, [date])

  return time
}

export const DisplayTime = ({ date: timestamp }: Props) => {
  const time = useTimeTicker(timestamp)

  return <span title={dayjs(timestamp).format('DD.MM.YYYY HH:mm')}>{time}</span>
}
