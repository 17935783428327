import * as React from 'react'
import { Button, Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { config } from '/~/utils/config'
import { useNavigate } from 'react-router'

const StyledPane = styled(Pane)`
  ${({ theme: { colors } }) => css`
    width: 100%;
    background-color: ${colors.fiDark};
    p,
    span,
    strong,
    b,
    h1,
    h2,
    h3,
    h4,
    label {
      color: ${colors.fiDark10};
    }
  `}
`

const backgroundImage = `${config.publicFilesUrl}/landing-page-entrepreneur-flipped.jpg`

const StyledBackground = styled(Pane)`
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
`

const StyledFadeLeft = styled(Pane)`
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 98%, rgba(0, 0, 0, 0) 100%);
`
interface Props {
  countryCode: string
}

export const ContactForm = (_props: Props) => {
  const theme = useCustomTheme()
  const { large } = useBreakpoint()
  const navigate = useNavigate()

  const inputFields = (
    <Pane
      maxWidth={300}
      display='flex'
      flexDirection='column'
      marginX={25}
      paddingBottom={large ? theme.spacing.xs : theme.spacing.l}
      justifyContent='center'
      textAlign='initial'
      minHeight={large ? 400 : 680}
      backgroundColor={theme.colors.fiDark}
    >
      <Heading width={350} size={600} textAlign={large ? 'initial' : 'initial'}>
        <FormattedMessage id='entrepreneurLanding.contactFormHeading' />
      </Heading>

      <Paragraph width={300} size={400} textAlign={large ? 'initial' : 'initial'}>
        <FormattedMessage id='entrepreneurLanding.contactFormSubheading' />
      </Paragraph>

      <Pane color='white' width={300} marginTop={theme.spacing.m}>
        <Button appearance='invertedPrimary' size='large' onClick={() => navigate('/bli-kontaktet?navOrigin=kaffe')}>
          Meld interesse
        </Button>
      </Pane>
    </Pane>
  )

  if (large) {
    return (
      <Pane
        display='flex'
        alignItems='center'
        justifyContent='center'
        marginTop={theme.spacing.l}
        width='100%'
        backgroundColor={theme.colors.fiDark}
      >
        <StyledPane display='flex' justifyContent='center'>
          {inputFields}
        </StyledPane>
      </Pane>
    )
  }

  return (
    <Pane
      display='flex'
      justifyContent='center'
      marginTop={theme.spacing.l}
      width='100%'
      backgroundColor={theme.colors.fiDark}
    >
      <StyledPane
        display='flex'
        flexDirection='row'
        justifyContent='flex-end'
        paddingRight={theme.spacing.l}
        width={1440}
        position='relative'
      >
        <StyledBackground
          top={0}
          left={0}
          zIndex={0}
          width='100%'
          backgroundImage={`url(${backgroundImage})`}
          backgroundPosition='center'
          backgroundSize='cover'
        />

        <StyledFadeLeft paddingTop={theme.spacing.m} width='60%' height='100%'>
          {inputFields}
        </StyledFadeLeft>
      </StyledPane>
    </Pane>
  )
}
