import * as React from 'react'
import { breakpoints } from '/fiweb/lib'
import { Heading, Pane } from 'evergreen-ui'
import styled, { css } from 'styled-components'
import { EmployeeCard } from '/fiweb/components/Cards'
import { AboutUsMemberSection, useGetAllAboutUsMembersQuery } from '/~/types/graphql'
import { board as fallbackBoard, employees as fallbackEmployees } from './employees'

const StyledPane = styled(Pane)`
  ${({ theme: { spacing } }) => css`
    display: grid;
    width: 100%;
    max-width: 100%;
    grid-template-columns: repeat(auto-fill, 285px);
    grid-column-gap: 30px;
    justify-items: center;
    grid-row-gap: ${spacing.s};
    margin-bottom: 80px;
    @media ${breakpoints.large} {
      width: initial;
      grid-template-columns: repeat(2, 285px);
    }
    @media ${breakpoints.medium} {
      margin-bottom: ${spacing.xs};
      padding: 25px;
      grid-template-columns: 1fr;
    }
  `}
`

export const AboutUsEmployees = () => {
  const { data, loading } = useGetAllAboutUsMembersQuery()

  const members = data?.getAboutUsMembers?.members || []
  let employees = members.filter((m) => m.section === AboutUsMemberSection.TEAM)
  let board = members.filter((m) => m.section === AboutUsMemberSection.BOARD)
  if (!loading) {
    if (employees.length === 0) {
      employees = fallbackEmployees.map((m, i) => ({ _id: m.name, index: i, ...m }))
    }
    if (board.length === 0) {
      board = fallbackBoard.map((m, i) => ({ _id: m.name, index: i, ...m }))
    }
  }

  return (
    <Pane marginBottom={80} width='100%' display='flex' flexDirection='column' alignItems='center'>
      <Heading is='h2' size={700} width='100%'>
        Ansatte
      </Heading>
      <StyledPane>
        {employees.map((employee) => (
          <EmployeeCard key={employee._id} {...employee} />
        ))}
      </StyledPane>
      <Heading is='h2' size={700} width='100%'>
        Styret
      </Heading>
      <StyledPane>
        {board.map((boardmember) => (
          <EmployeeCard key={boardmember._id} {...boardmember} />
        ))}
      </StyledPane>
    </Pane>
  )
}
