import { Heading, Pane } from 'evergreen-ui'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { usePublicInvestmentOfferData, usePublicInvestmentOfferFlags } from '../Context/PublicInvestmentOfferData'
import { KeyInformation } from '../KeyInformation'
import { widths } from '../utils'
import { ActionBar } from '../ActionBar'
import { breakpoints, useBreakpoint } from '/fiweb/lib'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useIsActionBarInView } from '../Context/ActionBarInView'
import { TitleAndProgress } from './TitleAndProgress'
import { useIntl } from 'react-intl'
import { Loading } from '/fiweb/components'

const WideWrapper = styled(Pane)`
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: ${() => widths.wide};
  flex-wrap: wrap;
  margin-bottom: ${({ theme: { spacing } }) => spacing.l};

  @media ${breakpoints.medium} {
    margin-bottom: 0;
  }
`

const CoverImageConstrictor = styled(Pane)`
  ${({ theme: { spacing } }) => css`
    min-width: 315px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 0;
    padding: 0;

    @media ${breakpoints.large} {
      padding: 0 60px;
      width: 100%;
      margin-bottom: ${spacing.s};
    }
    @media ${breakpoints.medium} {
      padding: 0 25px;
    }
    @media ${breakpoints.small} {
      padding: 0 ${spacing.xxs};
    }
  `}
`

const CoverImage = styled(Pane)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 430px;
  max-width: 630px;
  border: 1px solid ${({ theme: { colors } }) => colors.fiDark10};
  border-radius: 5px;
  border-bottom-right-radius: 30px;

  @media ${breakpoints.medium} {
    max-height: 325px;
    max-width: ${widths.mediumHeaderSectionMax};
  }

  > img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

const KeyInformationContainer = styled(Pane)`
  max-width: 630px;
  min-width: 538px;
  width: 50%;
  margin: 0 auto;
  padding-left: 60px;

  @media (max-width: ${widths.wide}) {
    padding: 0 30px;
  }

  @media ${breakpoints.large} {
    width: 100%;
  }
  @media ${breakpoints.medium} {
    padding: 0;
    max-width: ${widths.mediumHeaderSectionMax};
    min-width: initial;
  }

  @media ${breakpoints.small} {
    padding: 0 ${({ theme: { spacing } }) => spacing.xxs};
  }
`

const GraySection = styled(Pane)`
  ${({ theme: { colors, radii, spacing } }) => css`
    background-color: ${colors.fiTint};
    padding: ${spacing.xs} 0;
    border-radius: ${radii[3]};
    margin-top: ${spacing.xxs};
  `}
`

export const Header = () => {
  const intl = useIntl()
  const { spacing, colors } = useCustomTheme()
  const { medium } = useBreakpoint()
  const [actionBarContainerRef] = useIsActionBarInView()

  const { loading, presentation } = usePublicInvestmentOfferData()
  const { isPreview } = usePublicInvestmentOfferFlags() // PREVIEW-HACK

  if (loading && !presentation) {
    return <Loading />
  }

  return (
    <>
      {isPreview && ( // PREVIEW-HACK
        <Pane
          width='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
          padding={spacing.xxs}
          background={colors.fiBlue70}
          position='sticky'
          top={80}
          zIndex={10}
          marginBottom={spacing.s}
        >
          <Heading size={400} color='white' margin={0}>
            *** Forhåndsvisning ***
          </Heading>
        </Pane>
      )}
      <WideWrapper>
        <CoverImageConstrictor>
          <CoverImage>
            <img
              alt={intl.formatMessage(
                { id: 'investmentOfferPage.coverImageAlt' },
                { fileName: presentation?.coverImage?.cropped.name },
              )}
              src={presentation?.coverImage?.cropped.url}
            />
          </CoverImage>
        </CoverImageConstrictor>
        <KeyInformationContainer>
          <TitleAndProgress />

          {!medium && (
            <GraySection>
              <KeyInformation />
            </GraySection>
          )}
          <Pane ref={actionBarContainerRef} marginY={spacing.xs}>
            <ActionBar />
          </Pane>
        </KeyInformationContainer>
      </WideWrapper>
    </>
  )
}
