import React from 'react'
import { Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton } from 'react-share'
import { EmailIcon, FacebookIcon, LinkedInIcon, TwitterIcon } from '/fiweb/components/Icons'
import { useBreakpoint } from '/fiweb/lib'

interface SoMeCampaign {
  title?: string
  slug?: string
}

interface Props {
  campaign: SoMeCampaign
}

const SocialSharing = ({ campaign }: Props) => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()
  if (!(campaign.slug && campaign.title)) {
    return null
  }
  const mailBody = `Hei, \n\n${campaign.title} har en kampanje på folkeinvest.no som jeg tror kan interessere deg.\nDu finner den her: https://folkeinvest.no/kampanje/${campaign.slug}`

  return (
    <Pane display='flex' flexDirection={medium ? 'column' : 'row'} alignItems={medium ? 'flex-start' : 'center'}>
      <Paragraph fontWeight={600} marginRight={medium ? 0 : 12} marginBottom={medium ? theme.spacing.xxs : 0}>
        Del kampanjen med dine venner:
      </Paragraph>
      <Pane display='flex' columnGap={40} width={medium ? '100%' : 'initial'}>
        <FacebookShareButton url={`https://folkeinvest.no/kampanje/${campaign.slug}`} style={{ textAlign: 'center' }}>
          <FacebookIcon height={32} />
        </FacebookShareButton>
        <TwitterShareButton
          url={`https://folkeinvest.no/kampanje/${campaign.slug}`}
          title='Jeg heier på denne gjengen!'
          via='https://folkeinvest.no'
          hashtags={['folkeinvest', 'invester']}
        >
          <TwitterIcon height={32} />
        </TwitterShareButton>
        <LinkedinShareButton url={`https://folkeinvest.no/kampanje/${campaign.slug}`}>
          <LinkedInIcon height={32} />
        </LinkedinShareButton>
        <EmailShareButton
          url={`https://folkeinvest.no/kampanje/${campaign.slug}`}
          subject='Dette er kanskje interessant for deg?'
          body={mailBody}
        >
          <EmailIcon />
        </EmailShareButton>
      </Pane>
    </Pane>
  )
}

export default SocialSharing
