import * as React from 'react'
import { Heading, Pane, Paragraph, Text, Link } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { CardDataType } from './CardData'
import { CardPaddedPane } from '../CampaignCardTemplate'
import { SmallerButton } from '../SmallerButton'

const ComingSoon = ({
  followerCount = 0,
  visitorIsFollowing = false,
  websiteUrl,
  mobileView,
  slug,
  campaignId,
  emissionProcessId,
  disableFollowButton,
  parentRef,
  followDialog,
  preorderButton,
}: CardDataType) => {
  const [open, setOpen] = React.useState(false)
  const [followDisabled, setFollowDisabled] = React.useState(false)
  const reffy = React.useRef<HTMLInputElement>(null)
  const theme = useCustomTheme()

  const handleClose = (toggle: boolean) => {
    setOpen(false)
    setFollowDisabled(toggle)
  }

  React.useEffect(() => {
    // TODO: This component should not need to know the current pathname
    // It should also not be responsible for scrolling parent view
    const isOnCampaingsOverview = window.location.pathname === '/kampanjer'
    const search = new URLSearchParams(window.location.search)
    const match = search.get('kommer-snart')
    if (match && isOnCampaingsOverview && match === slug) {
      if (parentRef?.current) {
        parentRef.current.scrollIntoView()
      }
      setOpen(true)
    }
  }, [window.location.search])

  return (
    <CardPaddedPane $mobileView={mobileView} marginBottom={theme.spacing.xs}>
      {mobileView ? (
        <Pane ref={reffy} display='flex' flexDirection='column' marginBottom={theme.spacing.xs}>
          <Pane
            display='flex'
            flexDirection='row'
            alignItems='baseline'
            justifyContent='space-between'
            marginBottom={theme.spacing.xs}
          >
            <Heading is='h5' fontSize={16} lineHeight='22px' fontWeight='normal'>
              Kommer
            </Heading>
            <Text color='muted' fontWeight={400} fontSize={16} lineHeight='22px'>
              {followerCount} følgere
            </Text>
          </Pane>
          {!!followDialog && (
            <SmallerButton
              width='100%'
              disabled={disableFollowButton || followDisabled}
              borderRadius={30}
              size='large'
              fontSize={17}
              onClick={() => setOpen(true)}
            >
              {followDisabled || visitorIsFollowing ? 'Fulgt' : 'Følg emisjon'}
            </SmallerButton>
          )}
        </Pane>
      ) : (
        <Pane ref={reffy} display='flex' flexDirection='column' marginBottom={theme.spacing.xs}>
          <Heading
            is='h5'
            fontSize={18}
            lineHeight='22px'
            fontWeight={600}
            height={22}
            marginBottom={theme.spacing.xxs}
          >
            Kommer
          </Heading>
          <Pane display='flex' flexFlow='row nowrap' justifyContent='space-between' alignItems='center'>
            <Paragraph fontSize={16} color='muted' fontWeight={600}>
              {followerCount} følgere
            </Paragraph>
            {!!followDialog && (
              <SmallerButton
                disabled={disableFollowButton || followDisabled}
                borderRadius={40}
                size='large'
                fontSize={17}
                onClick={() => setOpen(true)}
              >
                {followDisabled || visitorIsFollowing ? 'Fulgt' : 'Følg emisjon'}
              </SmallerButton>
            )}
          </Pane>
        </Pane>
      )}
      {preorderButton}
      {websiteUrl && (
        <Link href={websiteUrl} target='_blank' fontWeight={600} fontSize={16}>
          Se nettside
        </Link>
      )}
      {followDialog?.({ open, onClose: handleClose, campaignId, emissionProcessId })}
    </CardPaddedPane>
  )
}

export default ComingSoon
