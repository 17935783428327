import * as React from 'react'
import { Heading, Pane } from 'evergreen-ui'
import { FetchCampaignQuery } from '/~/types/graphql'
import Member from './Member'

type MemberList = FetchCampaignQuery['Campaign']['partners']

interface Props {
  members: MemberList
  memberType: 'partner' | 'team' | 'board'
  title: string
}

const Partners = ({ members, memberType, title }: Props) => {
  if (!members || members.length === 0) {
    return null
  }

  return (
    <Pane maxWidth='1260px' width='100%' paddingY='20px'>
      <Heading is='h3' textAlign='center'>
        {title}
      </Heading>

      <Pane
        display='grid'
        gridTemplateColumns='repeat(auto-fill, 285px)'
        rowGap='60px'
        columnGap='40px'
        justifyContent='center'
      >
        {members.map((member, i) => {
          return <Member key={i + member.name} member={member} memberType={memberType} />
        })}
      </Pane>
    </Pane>
  )
}

export default Partners
