import { createTheme, Theme } from '@mui/material/styles'
import createPalette, { Palette } from '@mui/material/styles/createPalette'
import { blue, lightBlue, red, orange, green, blueGrey } from '@mui/material/colors'

export type AppTheme = Theme & {
  palette: Palette & {
    tetriary: { main: string }
    quarternary: { main: string }
    background: Palette['background'] & { grey: string; expansion: string; hover: string }
    text: Palette['text'] & { tetriary: string; white: string }
    border: {
      default: string
      selected: string
      hover: string
    }
  }
  dimensions: {
    breakpoint: {
      sm: number
      md: number
      lg: number
      xl: number
    }
    default: {
      width: number
      verticalSpacing: number
    }
    navbar: {
      height: number
    }
    footer: {
      height: number
    }
    margins: {
      sm: number
    }
  }
}

export const muiTheme = createTheme({
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiPaper: {
      elevation24: {
        boxShadow: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        color: 'white',
        backgroundColor: blueGrey[600],
        border: `1px solid ${blueGrey[200]}`,
        fontSize: 16,
        fontFamily: 'Arial',
        boxShadow: `0 0 1px ${blueGrey[800]}`,
      },
    },
  },
  typography: {
    fontFamily: ['Inter', 'Helvetica Neue', 'Arial'].join(','),
    caption: {
      display: 'block',
    },
  },
  dimensions: {
    breakpoint: {
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
    default: {
      width: 1440,
      verticalSpacing: 80,
    },
    navbar: {
      height: 50,
    },
    footer: {
      height: 350,
    },
    margins: {
      sm: 30,
    },
  },

  palette: createPalette({
    primary: {
      main: '#e8443a',
    },
    secondary: {
      main: blueGrey[900],
    },
    tetriary: {
      main: '#4AB85E',
    },
    quarternary: {
      main: '#FEBE2A',
      lightest: '#fef2cd',
    },
    text: {
      primary: blueGrey[900],
      secondary: blueGrey[900],
      tetriary: blueGrey[800],
      quarternary: blueGrey[600],
      white: '#FFFFFF',
    },
    disabled: blueGrey[200],

    accent: {
      ...blue,
      A400: '#00e677',
    },
    error: red,
    background: {
      paper: '#fff',
      default: '#fafafa',
      grey: '#F5F7F8',
      expansion: '#eaeaea',
      beige: orange[50],
      black: blueGrey[900],
      darkGrey: blueGrey[300],
      hover: lightBlue[50],
    },
    border: {
      default: blueGrey[900],
      selected: green[500],
      hover: lightBlue[600],
    },
  } as any),
} as any)
