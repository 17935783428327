import * as React from 'react'
import { useGetServerTimeQuery } from '/~/types/graphql'
import dayjs, { Dayjs } from 'dayjs'

interface TimeSyncContextValues {
  serverTimeDayjsObject: Dayjs
  serverTimeOffset: number
  serverTime?: number
}

const TimeSyncContext = React.createContext<TimeSyncContextValues>({} as TimeSyncContextValues)

export const TimeSyncProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { data } = useGetServerTimeQuery()
  const serverTime = data?.getServerTime as number | undefined

  const value: TimeSyncContextValues = {
    serverTimeDayjsObject: dayjs(serverTime),
    serverTime,
    serverTimeOffset: Date.now() - (serverTime ?? 0),
  }

  return <TimeSyncContext.Provider value={value}>{children}</TimeSyncContext.Provider>
}

export const useServerTimeSync = () => {
  return React.useContext(TimeSyncContext)
}
