import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { PhoneField } from '/fiweb/components'
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'
import { useIntl } from 'react-intl'
import { Text } from 'evergreen-ui'

export const ProfilePhoneInput = () => {
  const intl = useIntl()
  const formControl = useFormContext()
  React.useEffect(() => {
    if (!formControl) {
      console.error('Form Context is missing in ProfilePhoneInput. Wrapped FormProvider is required.')
    }
  }, [])
  if (!formControl) {
    return null
  }
  const {
    control,
    formState: { errors },
  } = formControl

  const getErrorString = () => {
    if (errors.phone) {
      if (errors.phone.type === 'validate') {
        return intl.formatMessage({ id: 'forms.errors.enterValidPhoneNumber' })
      }
      return intl.formatMessage({ id: 'forms.errors.fieldIsRequired' })
    }
    return undefined
  }

  return (
    <PhoneField
      name='phone'
      gridColumn='span 2'
      required
      hookForm={{
        control,
        rules: {
          required: true,
          validate: (val) => {
            if (!val) {
              return false
            }
            return isValidPhoneNumber(val, intl.formatMessage({ id: 'countryCode' }) as CountryCode)
          },
        },
      }}
      label={<Text size={300}>{intl.formatMessage({ id: 'forms.labels.phoneNumber' })}</Text>}
      error={getErrorString()}
    />
  )
}
