import * as React from 'react'
import { getCampaignReferral, removeCampaignReferral } from '/~/utils/referrals'
import { Heading, Pane, Paragraph, toaster } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import EmailSignupForm from './EmailSignupForm'
import { useAuthContext } from '/~/utils/AuthContext'
import { auth } from '/fiweb/lib'
import { config } from '/~/utils/config'
import { StatusCodes } from 'http-status-codes'

interface Props {
  onComplete: () => void
  goBack: () => void
  setSubmitting: (toggle: boolean) => void
}

const EmailSignup = ({ onComplete, goBack, setSubmitting }: Props) => {
  const { refetch } = useAuthContext()
  const [working, setWorking] = React.useState(false)
  const theme = useCustomTheme()

  const registerUser = async (email: string, password: string) => {
    setWorking(true)

    try {
      const campaignRef = getCampaignReferral()
      setSubmitting(true)
      const result = await auth(config.authHost).signup({ email, password, campaignRef })

      if (result.status === StatusCodes.CONFLICT) {
        throw new Error('Denne epostadressen er allerede registrert')
      }

      removeCampaignReferral()
      await refetch()

      setWorking(false)
      onComplete()
    } catch (err) {
      if (err.toString().includes('Denne epostadressen er allerede registrert')) {
        toaster.danger('Det finnes allerede en konto på denne e-posten')
      } else {
        toaster.danger('Kunne ikke registrere bruker')
      }
      setWorking(false)
    }
  }

  return (
    <Pane display='flex' flexDirection='column' maxWidth={380} marginTop={theme.spacing.l} alignItems='center'>
      <Heading is='h1'>Registrere ny profil</Heading>

      <Paragraph size={300} textAlign='center' marginBottom={theme.spacing.s}>
        Du mottar en epost for å verifisere at dette er deg, følg anvisningene i eposten.
      </Paragraph>

      <Paragraph size={300} textAlign='center' marginBottom={theme.spacing.s}>
        Verifiseringen kan gjøres etter du er ferdig med å registrere profilen.
      </Paragraph>

      <EmailSignupForm working={working} goBack={goBack} registerUser={registerUser} />
    </Pane>
  )
}

export default EmailSignup
