import * as React from 'react'
import { Image, Pane, PaneProps } from 'evergreen-ui'
import styled from 'styled-components'
import { PlayButton } from '/fiweb/components/Icons'

const StyledPane = styled(Pane)`
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
`

interface Props {
  thumbnail: string
  url: string
  videoPaneProps?: PaneProps
}

export const Video = ({ thumbnail, url, videoPaneProps }: Props) => {
  const [showVideo, setShowVideo] = React.useState(false)
  const [autoplay, setAutoplay] = React.useState(false)

  const handleClick = (broken: boolean) => {
    setAutoplay(!broken)
    setShowVideo(true)
  }

  if (!showVideo) {
    return (
      <StyledPane {...videoPaneProps} position='relative'>
        <Image
          src={thumbnail}
          width='100%'
          height='100%'
          alt='Video thumbnail'
          style={{ objectFit: 'cover' }}
          onError={() => handleClick(true)}
        />

        <Pane
          position='absolute'
          top={0}
          left={0}
          width='100%'
          height='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          cursor='pointer'
          onClick={() => handleClick(false)}
        >
          <PlayButton />
        </Pane>
      </StyledPane>
    )
  }

  return (
    <StyledPane {...videoPaneProps}>
      <iframe
        width='100%'
        height='100%'
        src={`${url}${autoplay ? '?autoplay=1' : ''}`}
        title='Iframe embedded video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </StyledPane>
  )
}
