import * as React from 'react'
import { Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { CardPaddedPane } from '../CampaignCardTemplate'
import { useBreakpoint } from '/fiweb/lib'

const Draft = () => {
  const theme = useCustomTheme()
  const { medium } = useBreakpoint()

  return (
    <CardPaddedPane $mobileView={medium}>
      <Pane
        display='flex'
        flexDirection='column'
        height='100%'
        alignItems='center'
        justifyContent='center'
        marginBottom={medium ? theme.spacing.m : theme.spacing.xl}
      >
        <Paragraph color={theme.colors.fiDark70} fontSize='16x' lineHeight='24px'>
          Autogenerert data basert på emisjonens status vil vises her
        </Paragraph>
      </Pane>
    </CardPaddedPane>
  )
}

export default Draft
