export const formatGraphQLError = (error) => {
  let newError = null
  if (error?.message) {
    newError = error.message.toString()
  } else {
    newError = error.toString()
  }

  const returnError = newError.replace(/GraphQL error: /g, '')
  return returnError.replace(/Error: /g, '')
}
