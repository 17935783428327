import * as React from 'react'
import { IconProps } from './types'

export const FacebookIcon = ({ title, ...rest }: IconProps) => (
  <svg viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path
      d='M14.1006 24.4597H3.4509C2.7729 24.4597 2.22363 23.9102 2.22363 23.2324V3.45089C2.22363 2.77298 2.77299 2.22363 3.4509 2.22363H23.2325C23.9103 2.22363 24.4597 2.77298 24.4597 3.45089V23.2324C24.4597 23.9102 23.9102 24.4597 23.2325 24.4597H17.5662V15.8487H20.4566L20.8893 12.4928H17.5662V10.3503C17.5662 9.3787 17.8361 8.71658 19.2293 8.71658L21.0064 8.71581V5.71428C20.6991 5.67339 19.6442 5.58201 18.4169 5.58201C15.8547 5.58201 14.1006 7.14593 14.1006 10.0179V12.4928H11.2029V15.8487H14.1006V24.4597Z'
      fill='currentColor'
    />
  </svg>
)
