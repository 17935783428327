import * as React from 'react'
import { Pane, PaneOwnProps, PaneProps } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { BoxComponent } from 'ui-box'

export interface ProgressBarProps {
  progress: number
  goal?: number
  max?: number
  disabled?: boolean
  inactive?: boolean
  progressBarProps?: PaneProps
}

export const ProgressBar: BoxComponent<PaneOwnProps & ProgressBarProps, 'p'> = ({
  progress,
  disabled,
  goal,
  max,
  inactive,
  progressBarProps,
}) => {
  const theme = useCustomTheme()

  let overRaised = 0
  if (progress > 100 && goal && max) {
    const minMaxDiff = max - goal
    overRaised = (goal * (progress - 100)) / minMaxDiff
  }

  return (
    <Pane
      width='100%'
      height={10}
      backgroundColor={
        inactive
          ? overRaised
            ? theme.colors.fiDark20
            : theme.colors.fiTint
          : disabled
            ? theme.colors.fiTint
            : overRaised
              ? theme.colors.fiGreen40
              : theme.colors.fiGreen20
      }
      borderRadius={5}
      overflow='hidden'
      {...progressBarProps}
    >
      <Pane
        width={overRaised ? `${overRaised}%` : `${progress}%`}
        height='100%'
        transition='width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        borderRadius={5}
        backgroundColor={
          inactive
            ? overRaised
              ? theme.colors.fiDark70
              : theme.colors.fiGreen70
            : disabled
              ? theme.colors.fiDark10
              : overRaised
                ? theme.colors.fiGreen
                : theme.colors.fiGreen70
        }
      />
    </Pane>
  )
}
