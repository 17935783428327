import { Button, Heading, Pane, Paragraph, Spinner, Strong, Text, TextInput, toaster } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { StatusCodes } from 'http-status-codes'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { AUTH_CONTAINER_HEIGHT, UNKNOWN_ERROR } from '../constants'
import { auth, emailRegex, Service } from '/fiweb/lib'
import { useAuthContext } from '/~/utils/AuthContext'
import { config } from '/~/utils/config'
import { getCampaignReferral } from '/~/utils/referrals'

interface FormValues {
  email: string
}

export const SignicatEmailSignup = () => {
  const [error, setError] = React.useState<string | null>(null)

  const authContext = useAuthContext()
  const theme = useCustomTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const sessionId = params.get('sessionId')
  const email = params.get('email')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { email } })

  const [submitting, setSubmitting] = React.useState(false)

  if (!sessionId) {
    toaster.danger(UNKNOWN_ERROR)

    navigate('/innlogging/registrering', { replace: true })
  }

  const onSubmit = async (values: FormValues) => {
    setSubmitting(true)

    try {
      const campaignRef = getCampaignReferral()

      const res = await auth(config.authHost).signupWithService({
        service: Service.SIGNICAT,
        token: sessionId,
        campaignRef,
        email: values.email,
      })

      if (res.ok) {
        await authContext.refetch()
        navigate({ pathname: '/innlogging/registrering' }, { state: { fromSignicatSignup: true }, replace: true })
      } else {
        switch (res.status) {
          case StatusCodes.CONFLICT: {
            setError('Bruker med oppgitt epost finnes allerede. Kontakt support')
            break
          }

          default: {
            setError(UNKNOWN_ERROR)
          }
        }
      }
    } catch (_e) {}

    setSubmitting(false)
  }

  return (
    <Pane
      is='form'
      display='flex'
      flexDirection='column'
      maxWidth={380}
      height={AUTH_CONTAINER_HEIGHT}
      width='100%'
      marginX='auto'
      paddingY={theme.spacing.l}
      paddingX={theme.spacing.xs}
      alignItems='center'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Heading is='h3'>Registrere ny profil</Heading>

      <Paragraph size={300} textAlign='center' marginBottom={theme.spacing.s}>
        Du mottar en epost for å verifisere at dette er deg, følg anvisningene i eposten.
      </Paragraph>

      <Paragraph size={300} textAlign='center' marginBottom={theme.spacing.s}>
        Verifiseringen kan gjøres etter du er ferdig med å registrere profilen.
      </Paragraph>

      <Pane width='100%'>
        <Paragraph size={300}>
          <Text color='danger'>
            <sup>*</sup>
          </Text>
          <Strong size={300}>E-post adresse</Strong> (påkrevd)
        </Paragraph>

        <TextInput
          width='98%'
          size='large'
          marginX={8}
          isInvalid={!!errors.email}
          type='email'
          {...register('email', { required: true, pattern: emailRegex })}
        />
      </Pane>

      {error && (
        <Pane width='100%' marginTop={theme.spacing.s}>
          <Text color='danger' textAlign='center'>
            {error}
          </Text>
        </Pane>
      )}

      <Pane marginTop='auto' display='flex' flexDirection='column' alignItems='center'>
        {submitting ? (
          <Spinner marginBottom={theme.spacing.l} />
        ) : (
          <Button size='small' appearance='primary' width={244} marginBottom={theme.spacing.xs}>
            Registrer
          </Button>
        )}

        <Button
          size='small'
          width={244}
          onClick={() => navigate('/innlogging/registrering')}
          marginBottom={theme.spacing.l}
        >
          Tilbake
        </Button>
      </Pane>
    </Pane>
  )
}
