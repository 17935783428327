import React from 'react'
import { Heading, Text, Pane, Paragraph, Link } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import {
  FetchCampaignQuery,
  useGetPublicCampaignDocumentDataQuery,
  GetPublicCampaignDocumentDataQuery,
} from '/~/types/graphql'
import Divider from '/~/components/campaign/Campaign/Divider'
import { useBreakpoint } from '/fiweb/lib'
import { Download } from '/fiweb/components/Icons'
import PageContainer from '../PageContainer'

type Campaign = FetchCampaignQuery['Campaign']
type OptionalFilesData = GetPublicCampaignDocumentDataQuery['Campaign']['filesOptionalData']
type RequiredFilesData = GetPublicCampaignDocumentDataQuery['Campaign']['filesRequiredData']
type DocumentType = (OptionalFilesData | RequiredFilesData)[number]['file']

const DocList = ({ doc }: { doc: DocumentType }) => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()
  if (doc) {
    return (
      <>
        <Pane display='flex' justifyContent='space-between' width='100%'>
          <Pane display='flex' flexDirection={medium ? 'column' : 'row'}>
            <Text size={300} fontWeight={600} width='300px' marginRight={theme.spacing.s}>
              {doc.title}
            </Text>
            <Paragraph size={300} width='305px' wordBreak='break-word'>
              {doc.originalName}
            </Paragraph>
          </Pane>
          <Link
            is='a'
            size={300}
            display='flex'
            alignItems='center'
            href={doc.url}
            download
            target='_blank'
            rel='noreferrer'
          >
            <Download style={{ marginRight: 6 }} />
            Last ned
          </Link>
        </Pane>
        <Divider />
      </>
    )
  }
  return null
}

interface Props {
  campaign: Campaign
}

const Documents = ({ campaign }: Props) => {
  const { data } = useGetPublicCampaignDocumentDataQuery({ variables: { campaignId: campaign?._id } })

  const reqList = data?.Campaign?.filesRequiredData || []
  const optList = data?.Campaign?.filesOptionalData || []

  const renderList = (arr: OptionalFilesData | RequiredFilesData) => (
    <Pane>
      <Divider />
      {arr
        .filter((o) => !o.hidden && !!o.file)
        .map((doc) => (
          <DocList key={doc?.file?.id} doc={{ ...(doc?.file || {}), title: doc?.title }} />
        ))}
    </Pane>
  )
  return (
    <PageContainer>
      <Heading is='h1' size={600}>
        Dokumenter
      </Heading>
      {renderList(reqList)}
      <br />
      <br />
      {optList.length > 0 && (
        <>
          <Heading is='h3'>Tilleggsdokumenter</Heading>
          {renderList(optList)}
        </>
      )}
    </PageContainer>
  )
}

export default Documents
