import React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import packageJson from '/~/../package.json'
import { Pane, Paragraph, Button, Dialog, Link, Heading } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import PropTypes from 'prop-types'

const clearCacheAndReload = async () => {
  try {
    const keys = await caches.keys()
    await Promise.all(keys.map((name) => caches.delete(name)))
  } catch (e) {
    console.log(e)
    /* Stuff went horribly wrong when accessing browser cache */
  }
  window.location.reload()
}

const ServerIsDown = ({ show }) => {
  if (!show) {
    return null
  }
  const [oldVersion, setOldVersion] = React.useState(false)
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  React.useEffect(() => {
    const checkVersion = async () => {
      const file = '/metadata.json'
      const fetchRes = await fetch(file)
      const metadata = await fetchRes.json()
      if (packageJson.version !== metadata.version) {
        setOldVersion(true)
        try {
          const versionItem = window.localStorage.getItem('version')
          let local = null
          if (versionItem) {
            local = JSON.parse(versionItem)
          }
          if (!local || (local && local !== metadata.version)) {
            window.localStorage.setItem('version', JSON.stringify(metadata.version))
            clearCacheAndReload()
          }
        } catch (_e) {}
      }
    }
    checkVersion()
  }, [])

  const renderContactInfo = () => (
    <>
      <Paragraph size={300}>
        Epost:{' '}
        <Link fontSize={16} lineHeight='24px' href='mailto:support@folkeinvest.no'>
          support@folkeinvest.no
        </Link>{' '}
        <br />
        Telefon: (+47) 462 80 188
      </Paragraph>
    </>
  )

  const title = 'En feil oppsto under henting av data'
  let description = (
    <>
      <Paragraph size={300}>
        Hei, det kan se ut til at det har oppstått problemer med å laste ned informasjon fra våre servere. Forsøk å
        oppfriske siden ved å klikke på knappen under. Dersom dette ikke fungerer, prøv igjen om 5 minutter. Hvis
        problemet vedvarer, ta kontakt på:
        <br />
        <br />
      </Paragraph>
      {renderContactInfo()}
    </>
  )

  if (oldVersion) {
    description = (
      <>
        <Paragraph size={300}>
          Folkeinvest.no har utført vedlikehold på nettsiden. Du må utføre en (hard) oppfriskning av vinduet for å kunne
          fortsette. Du kan gjøre dette ved å trykke på knappen under. Hvis dette ikke hjelper, ta kontakt med support
          på:
        </Paragraph>
        {renderContactInfo()}
      </>
    )
  }
  return (
    <Dialog
      isShown={!!show}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      hasHeader={false}
      hasFooter={false}
      contentContainerProps={{ padding: theme.spacing.l }}
    >
      <Pane display='flex' flexDirection='column'>
        <Heading size={500} maxWidth={299}>
          {title}
        </Heading>
        {description}
        <Button
          size='medium'
          appearance='primary'
          minWidth={171}
          marginTop={medium ? theme.spacing.xs : theme.spacing.s}
          onClick={clearCacheAndReload}
          alignSelf={medium ? 'center' : 'flex-end'}
        >
          Oppfrisk siden
        </Button>
      </Pane>
    </Dialog>
  )
}

ServerIsDown.propTypes = {
  show: PropTypes.bool,
}

export default ServerIsDown
