import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Pane, Heading, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { SmallInfoCard } from '/~/components/Cards'
import { IngressText } from '/~/components/IngressText'

export const AboutUsFolkeinvest = () => {
  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()

  const headerSize = medium ? 600 : large ? 700 : 800

  return (
    <Pane
      width='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
      marginTop={medium ? 0 : theme.spacing.l}
    >
      <Heading is='h1' size={headerSize} marginBottom={theme.spacing.s}>
        Om Folkeinvest
      </Heading>

      <IngressText
        content='Folkeinvest gir alle muligheten til å delta i verdiskapingen, som gründer, investor eller
          heiagjeng. Vi legger til rette for at du kan investere i det du tror på.'
      />

      <Pane
        display='flex'
        flexFlow='row wrap'
        justifyContent='space-around'
        alignItems='center'
        width='100%'
        marginTop={medium ? theme.spacing.m : theme.spacing.xl}
      >
        <SmallInfoCard
          width='100%'
          maxWidth={600}
          maxHeight={600}
          paddingY={medium ? 40 : 110}
          paddingX={medium ? 40 : 100}
          marginBottom={medium ? theme.spacing.m : theme.spacing.l}
          heading='Tjenester'
        >
          <Paragraph size={medium ? 400 : 500} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
            Vi ønsker å demokratisere aksjehandel og gi flere muligheten til å investere i gode oppstart- og
            vekstselskap. Norge er avhengig av et sunt næringsliv og det skal vi bygge sammen!
          </Paragraph>
          <Paragraph size={medium ? 400 : 500}>
            Folkeinvest tilrettelegger for kapitalinnhenting uten å stille garanti for beløpet.
          </Paragraph>
        </SmallInfoCard>

        <SmallInfoCard
          backgroundColor={theme.colors.fiBlue10}
          width='100%'
          maxWidth={600}
          maxHeight={600}
          paddingY={medium ? 40 : 110}
          paddingX={medium ? 40 : 110}
          marginBottom={medium ? theme.spacing.m : theme.spacing.l}
          borderBottomRightRadius={medium ? '30px' : '10px'}
          borderTopLeftRadius={medium ? '10px' : '30px'}
          heading='Ansatte & styret'
        >
          <Paragraph size={medium ? 400 : 500}>
            Vi er en engasjert gjeng i Folkeinvest, vi jobber for at du skal bli presentert for de beste mulighetene i
            det norske markedet. Vi kommer fra ulike bakgrunner og har ulike måter å se verden på, det er en styrke.
            Mangfold i meninger og kompetanse skaper verdi for oss og dere.
          </Paragraph>
        </SmallInfoCard>
      </Pane>
    </Pane>
  )
}
