import React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Card, Heading, Paragraph, PaneProps, Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props extends PaneProps {
  imageUrl?: string
  name?: string
  title?: string
  location?: string
}

export const EmployeeCard = ({
  imageUrl,
  width = 285,
  height,
  children: _children,
  name,
  title,
  location,
  ...rest
}: Props) => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Card
      display='flex'
      flexDirection='column'
      width={medium ? '100%' : width}
      maxWidth={width}
      rowGap={theme.spacing.xxs}
      {...rest}
    >
      <Pane backgroundColor='#D5DCD5'>
        <Pane
          width='100%'
          height={height || (medium ? '356px' : '312px')}
          borderRadius={5}
          backgroundImage={`url(${imageUrl})`}
          backgroundSize='cover'
          backgroundRepeat='no-repeat'
          backgroundPosition='center top'
        />
      </Pane>

      <Heading margin={0} size={500}>
        {name}
      </Heading>
      <Pane>
        <Paragraph size={400}>{title}</Paragraph>
        <Paragraph size={400}>{location}</Paragraph>
      </Pane>
    </Card>
  )
}
