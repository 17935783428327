import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Link as RouterLink } from 'react-router-dom'
import { FetchCampaignQuery, useAddInvestmentsPageVisitorMutation } from '/~/types/graphql'
import { Pane, Paragraph, Text, Heading, Tooltip } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { AlertCircle, SortDirectionArrow } from '/fiweb/components/Icons'
import Divider from '/~/components/campaign/Campaign/Divider'
import PageContainer from './PageContainer'
import { useBreakpoint } from '/fiweb/lib'
import { useAuthContext } from '/~/utils/AuthContext'

const Direction = ({ sortDirection, sortField, field }) => {
  if (field === sortField) {
    return (
      <span style={{ marginLeft: 12 }}>
        <SortDirectionArrow style={{ transform: `rotate(${sortDirection ? '180deg' : '0deg'})` }} />
      </span>
    )
  }
  return null
}

interface Props {
  campaign: FetchCampaignQuery['Campaign']
}
const Investments = ({ campaign }: Props) => {
  const [addInvestmentPageVisitor] = useAddInvestmentsPageVisitorMutation()
  const [sort, setSort] = React.useState<{ sortField: string; sortDirection: string | boolean }>({
    sortField: 'createdAt',
    sortDirection: false,
  })
  const { user } = useAuthContext()
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  React.useEffect(() => {
    addInvestmentPageVisitor({ variables: { campaignId: campaign?._id } })
  }, [])

  const { sortField, sortDirection } = sort

  const reservations = React.useMemo(() => {
    return [...(campaign.reservationsList || [])].map((reservation) => {
      return {
        ...reservation,
        notes: reservation.createdAt < campaign.startDate ? 'Forhåndstegning' : null,
      }
    })
  }, [campaign])

  if (!campaign) {
    return null
  }

  const campaignDone = campaign.closeDate && moment(campaign.closeDate).isBefore()
  const failedCampaign = campaignDone && campaign.fundedStatus !== 'success'

  if (!user?.bankidverified) {
    return (
      <PageContainer>
        <Heading is='h2'>Tegningsliste</Heading>

        <Paragraph>En oversikt over tegninger som er foretatt i denne emisjonen.</Paragraph>

        <Paragraph>
          For å kunne se tegningslisten, må profilen din være verifisert med <b>BankID</b>
        </Paragraph>

        <Text>
          <RouterLink to='/konto'>Gå til Min Profil</RouterLink>
        </Text>
      </PageContainer>
    )
  }

  const toggleSortDirection = (newSortField: string) => {
    setSort({
      sortField: newSortField,
      sortDirection: sortField === newSortField ? !sort.sortDirection : false,
    })
  }

  return (
    <PageContainer>
      <Heading is='h1' size={600}>
        Tegningsliste
      </Heading>

      <Paragraph>En oversikt over tegninger som er foretatt i denne emisjonen.</Paragraph>

      <Paragraph>Aksjonærlister er offentlig informasjon.</Paragraph>

      <Pane
        display='grid'
        gridTemplateColumns={medium ? '45% 1fr 1fr 40px' : '333px 1fr 1fr 40px'}
        paddingBottom='12px'
        paddingTop='40px'
      >
        <Text cursor='pointer' onClick={() => toggleSortDirection('signee.name')} size={300} fontWeight={600}>
          Navn
          <Direction {...sort} field='signee.name' />
        </Text>
        <Text
          cursor='pointer'
          onClick={() => toggleSortDirection('createdAt')}
          textAlign={medium ? 'center' : 'left'}
          size={300}
          fontWeight={600}
        >
          Når
          <Direction {...sort} field='createdAt' />
        </Text>
        <Text
          cursor='pointer'
          onClick={() => toggleSortDirection('shares')}
          size={300}
          fontWeight={600}
          textAlign={medium ? 'right' : 'center'}
        >
          Tegnet aksjer
          <Direction {...sort} field='shares' />
        </Text>
      </Pane>
      <Divider style={{ margin: 0 }} />
      {reservations
        .sort((a, b) => {
          if (sortField === 'signee.name') {
            const na = a.signee.name.toUpperCase()
            const nb = b.signee.name.toUpperCase()

            if (sortDirection) {
              return na > nb ? -1 : 1
            }
            return na > nb ? 1 : -1
          }
          if (sortDirection) {
            return a[sortField] - b[sortField]
          }
          return b[sortField] - a[sortField]
        })
        .map((reservation) => (
          <React.Fragment key={reservation._id}>
            <Pane
              height='60px'
              display='grid'
              alignItems='center'
              gridTemplateColumns={medium ? '45% 1fr 1fr 40px' : ' 333px 1fr 121px 40px'}
            >
              <Text size={300} paddingRight={20}>
                {failedCampaign ? 'Investor' : reservation.signee.name}
              </Text>
              <Text textAlign={medium ? 'center' : 'left'} size={300}>
                {moment(reservation.createdAt)
                  .locale(medium ? 'shortrel' : 'nb')
                  .fromNow(medium)}
              </Text>
              <Text size={300} textAlign={medium ? 'right' : 'center'}>
                {reservation.shares}
              </Text>
              <Pane display='flex' height={30}>
                {reservation.notes && (
                  <Tooltip
                    content={<Text color='white'>{reservation.notes}</Text>}
                    statelessProps={{ borderRadius: '2px', background: theme.colors.fiDark40 }}
                  >
                    {/* Tooltip needs to wrap a proper html element, svg isn't good enough */}
                    <div>
                      <AlertCircle color={theme.colors.fiDark70} />
                    </div>
                  </Tooltip>
                )}
              </Pane>
            </Pane>
            <Divider style={{ margin: 0 }} />
          </React.Fragment>
        ))}
    </PageContainer>
  )
}

export default Investments
