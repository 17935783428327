import dayjs, { Dayjs } from 'dayjs'
import { FetchCampaignQuery, usePreorderPermissionQuery } from '/~/types/graphql'
import { useTimeTicker } from '/fiweb/components/DisplayTime'
import { useAuthContext } from '/~/utils/AuthContext'
import React from 'react'

type Campaign = FetchCampaignQuery['Campaign']

export const useCampaignStatus = ({
  campaign,
  serverTimeDayjsObject,
}: { campaign: Campaign; serverTimeDayjsObject: Dayjs }) => {
  const { user } = useAuthContext()
  const accepts =
    campaign.status === 'preallocating' ||
    (campaign.status === 'published' && campaign.startDate && dayjs(campaign.startDate).isAfter(serverTimeDayjsObject))

  const { data, loading, refetch } = usePreorderPermissionQuery({
    variables: { campaignId: campaign._id },
    skip: !accepts,
  })

  React.useEffect(() => {
    if (user && data && !data.visitorSettings?.approvedForPreorder) {
      refetch()
    }
  }, [user])

  return {
    loading,
    preorderApproved: accepts ? data?.visitorSettings.approvedForPreorder : false,
    acceptsPreorders: accepts,
    userHasEmailReminder: data ? data.visitorSettings.hasEmailReminder : false,
    isClosed: campaign.closeDate ? serverTimeDayjsObject.isAfter(campaign.closeDate) : false,
  }
}

export const campaignTimeStatus = ({
  campaign,
  short,
  serverTimeDayjsObject,
  serverTimeOffset,
}: { campaign: Campaign; short?: boolean; serverTimeDayjsObject: Dayjs; serverTimeOffset: number }) => {
  const end = dayjs(campaign.closeDate)
  const start = dayjs(campaign.startDate)

  /**
   * Please don't punish us for this
   * Just to make sure we always render the same amount of hooks
   */
  const time = useTimeTicker(start.isAfter(serverTimeDayjsObject) ? start : end, { serverTimeOffset })

  if (start.isAfter(serverTimeDayjsObject)) {
    return ['Åpner om', time]
  }
  if (end.isAfter(serverTimeDayjsObject)) {
    return ['Lukkes om', time]
  }
  return [short ? 'Avsluttet' : 'Emisjonen ble avsluttet', end.format(`DD.MM.YYYY${short ? '' : ' HH:mm'}`)]
}
