import * as React from 'react'
import { Pane, Paragraph, PaneProps } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { BoxComponent } from 'ui-box'
import { convertFromOreToNokAndFormatCurrency, formatCurrency } from '/fiweb/lib/formatters'
import styled from 'styled-components'
import { ProgressBar, ProgressBarProps } from './ProgressBar'

const SmallText = styled(Paragraph)`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`

const LargeText = styled(Paragraph)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`

interface Props extends PaneProps, ProgressBarProps {
  largeText?: boolean
  currencyInNok?: boolean
}

export const ProgressBarWithSpread: BoxComponent<Props> = ({
  progress,
  goal,
  max,
  largeText,
  currencyInNok,
  inactive,
  ...rest
}) => {
  const theme = useCustomTheme()
  const minSpread =
    progress >= 100
      ? `${currencyInNok ? formatCurrency(goal, false) : convertFromOreToNokAndFormatCurrency(goal, false)} kr`
      : '0 kr'
  const maxSpread =
    progress >= 100
      ? `${currencyInNok ? formatCurrency(max, false) : convertFromOreToNokAndFormatCurrency(max, false)} kr`
      : `${currencyInNok ? formatCurrency(goal, false) : convertFromOreToNokAndFormatCurrency(goal, false)} kr`

  const Component = largeText ? LargeText : SmallText

  return (
    <>
      <ProgressBar progress={progress} goal={goal} max={max} inactive={inactive} progressBarProps={rest} />

      <Pane
        width='100%'
        display='flex'
        flexFlow='row nowrap'
        justifyContent='space-between'
        marginTop={theme.spacing.xxs}
      >
        <Component>{minSpread}</Component>
        <Component>{maxSpread}</Component>
      </Pane>
    </>
  )
}
