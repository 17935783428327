import { Heading, Pane, PaneProps, Paragraph } from 'evergreen-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Message } from '/fiweb/lib/i18n/types'
import { useCustomTheme } from '/fiweb/lib/theme'

interface CustomLinkProps {
  pathname: string
  messageId: Message
}

const CustomLink = ({ pathname, messageId }: CustomLinkProps) => {
  const { colors } = useCustomTheme()
  return (
    <Paragraph is={Link} color={colors.fiBlue} to={pathname} state={{ redirectToAfterAuth: window.location.pathname }}>
      <FormattedMessage id={messageId} />
    </Paragraph>
  )
}

interface Props {
  title?: Message
  paneProps?: PaneProps
}

export const MustBeLoggedIn = ({ title, paneProps }: Props) => {
  const { spacing } = useCustomTheme()
  return (
    <Pane display='flex' flexDirection='column' alignItems='flex-start' {...paneProps}>
      <Heading size={300}>
        <FormattedMessage id={title ?? 'mustBeLoggedIn'} />
      </Heading>
      <Pane display='flex' flexDirection='column' alignItems='inherit' gap={spacing.xs}>
        <CustomLink pathname='/innlogging' messageId='logIn' />
        <Paragraph>
          <FormattedMessage id='or' />
        </Paragraph>
        <CustomLink pathname='/innlogging/registrering' messageId='signUp' />
      </Pane>
    </Pane>
  )
}
