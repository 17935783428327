import * as React from 'react'
import { IconProps } from './types'

export const KeyboardArrow = ({ title, ...rest }: IconProps) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path
      d='M19.0445 11.0394H9.1356C9.04718 11.0394 8.9755 10.9677 8.9755 10.8793V8.94015C8.97545 8.68117 8.81942 8.44772 8.58016 8.34862C8.34089 8.24953 8.06549 8.30429 7.88234 8.48738L4.82251 11.5472C4.57251 11.7973 4.57251 12.2027 4.82251 12.4527L7.88234 15.5126C8.00241 15.6327 8.16527 15.7002 8.3351 15.7002C8.68879 15.7002 8.9755 15.4135 8.9755 15.0598V13.1207C8.9755 13.0323 9.04718 12.9606 9.1356 12.9606H19.0445C19.575 12.9606 20.0051 12.5305 20.0051 12C20.0051 11.4695 19.575 11.0394 19.0445 11.0394Z'
      fill='currentColor'
    />
    <mask id='mask0' mask='alpha' maskUnits='userSpaceOnUse' x='4' y='8' width='17' height='8'>
      <path
        d='M19.0445 11.0394H9.1356C9.04718 11.0394 8.9755 10.9677 8.9755 10.8793V8.94015C8.97545 8.68117 8.81942 8.44772 8.58016 8.34862C8.34089 8.24953 8.06549 8.30429 7.88234 8.48738L4.82251 11.5472C4.57251 11.7973 4.57251 12.2027 4.82251 12.4527L7.88234 15.5126C8.00241 15.6327 8.16527 15.7002 8.3351 15.7002C8.68879 15.7002 8.9755 15.4135 8.9755 15.0598V13.1207C8.9755 13.0323 9.04718 12.9606 9.1356 12.9606H19.0445C19.575 12.9606 20.0051 12.5305 20.0051 12C20.0051 11.4695 19.575 11.0394 19.0445 11.0394Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0)' />
  </svg>
)
