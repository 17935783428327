import { Image, Pane, Strong, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { successfulIssues } from './successfulIssues'
import { useBreakpoint } from '/fiweb/lib'
import { Message } from '/fiweb/lib/i18n/types'

const labelNames = [
  'client' as const,
  'country' as const,
  'category' as const,
  'type' as const,
  'size' as const,
  'year' as const,
  'investors' as const,
]

const StyledPane = styled(Pane)`
  display: grid;
  justify-items: center;
  column-gap: 60px;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1010px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

export const SuccessfulCampaignsList = () => {
  const { medium } = useBreakpoint()
  const { spacing } = useCustomTheme()

  return (
    <StyledPane rowGap={medium ? 32 : 60}>
      {successfulIssues.map((campaign) => (
        <Pane display='flex' flexDirection='column' key={campaign.client}>
          <Image
            src={campaign?.imageUrl}
            width={220}
            height={220}
            marginBottom={spacing.s}
            alt='campaign image'
            borderRadius='50%'
          />
          {labelNames.map((lbl) => (
            <Pane key={lbl} display='flex' flexDirection='row'>
              <FormattedMessage id={`successfulIssues.${lbl}Label`}>
                {(txt) => (
                  <Strong fontSize='14px' lineHeight='20px' marginRight='0.5rem'>
                    {txt}:
                  </Strong>
                )}
              </FormattedMessage>

              {['type', 'category', 'country', 'size'].includes(lbl) ? (
                <FormattedMessage
                  id={lbl === 'size' ? 'successfulIssues.sizeCurrency' : (campaign[lbl] as Message)}
                  values={{
                    size: campaign[lbl],
                  }}
                >
                  {(txt) => (
                    <Text fontSize='14px' lineHeight='20px'>
                      {txt}
                    </Text>
                  )}
                </FormattedMessage>
              ) : (
                <Text fontSize='14px' lineHeight='20px'>
                  {campaign[lbl]}
                </Text>
              )}
            </Pane>
          ))}
        </Pane>
      ))}
    </StyledPane>
  )
}
