import * as React from 'react'
import InfoDisplay from './InfoDisplay'
import QuestionsDisplay from './QuestionsDisplay'
import SummaryDisplay from './SummaryDisplay'
import ProfileDisplay from './ProfileDisplay'
import { getList, hasPassed } from './questions'
import { Dialog, DialogProps, Heading, IconButton, Pane, toaster } from 'evergreen-ui'
import { useSignExpediencyMutation, useSubmitExpediencyMutation } from '/~/types/graphql'
import { useBreakpoint } from '/fiweb/lib'
import { Cross } from '/fiweb/components/Icons'

interface SignProps {
  testId: string
  testResult: Record<string, boolean>
}

interface Props {
  onSign: () => void
  onClose: () => void
  open: boolean
}

const ExpediencySteps = ({
  onAbort,
  onSign,
}: {
  onAbort: () => void
  onSign: (results: SignProps) => Promise<void>
}) => {
  const [step, setSteps] = React.useState(0)
  const [saving, setSaving] = React.useState(false)
  const [result, setResult] = React.useState<Record<string, boolean>>({})
  const [testId, setTestId] = React.useState<string | null>(null)
  const [profileConfirmed, setProfileConfirmed] = React.useState(false)

  const [submit] = useSubmitExpediencyMutation()

  const onChange = (key: string, value: boolean) => {
    setResult({ ...result, [key]: value })
  }

  const generateSubmitInput = () => {
    const response = {
      _id: testId,
      fields: [],
      profileConfirmed,
    }

    const list = Object.keys(getList(result))

    for (const key of Object.keys(result)) {
      if (list.includes(key)) {
        response.fields.push({ id: key, answer: result[key] })
      }
    }

    return response
  }

  const handleSign = async () => {
    setSaving(true)

    await onSign({ testId, testResult: result })
  }

  const save = () => {
    setSaving(true)
  }

  const updateDatabase = async () => {
    try {
      const input = generateSubmitInput()
      const res = await submit({
        variables: { input },
        fetchPolicy: 'no-cache',
      })

      setSaving(false)
      setTestId(res.data.submitExpediency._id)
    } catch (e) {
      console.log('Errored while saving expediency results: ', e)
      toaster.danger('En feil oppsto, kontakt support')
      setSaving(false)
    }
  }

  React.useEffect(() => {
    if (saving) {
      updateDatabase()
    }
  }, [saving])

  const props = {
    onNext: () => setSteps(step + 1),
    onPrevious: () => setSteps(step - 1),
    onAbort: () => {
      setSteps(0)
      onAbort()
    },
    disabled: saving,
  }

  switch (step) {
    case 0:
      return <InfoDisplay {...props} />
    case 1:
      return <QuestionsDisplay testResult={result} onChange={onChange} save={save} {...props} />
    case 2:
      return <ProfileDisplay confirmProfile={() => setProfileConfirmed(true)} {...props} />
    case 3:
      return <SummaryDisplay testResult={result} onSign={handleSign} {...props} />
    default:
      return null
  }
}

const ExpediencyDialog = ({ open, onSign, onClose }: Props) => {
  const { medium } = useBreakpoint()
  const [sign] = useSignExpediencyMutation()

  const dialogProps: DialogProps = medium
    ? { topOffset: 0, sideOffset: 0, minHeightContent: '100vh', contentContainerProps: { padding: 25 } }
    : { contentContainerProps: { padding: 50, paddingTop: 32 } }

  const handleSign = async ({ testId, testResult }: SignProps) => {
    try {
      const result = await sign({
        variables: {
          approved: hasPassed(testResult),
          _id: testId,
        },
      })

      if (result.data.signExpediency) {
        toaster.notify('Hensiktsmessighetstest gjennomført')
      } else {
        toaster.danger('Hensiktsmessighetstesten ble ikke signert, kontakt support')
      }

      onSign()
    } catch (e) {
      console.log('Errored while saving expediency results: ', e)
      toaster.danger('En feil oppsto, kontakt support')
    }
  }

  return (
    <Dialog onCloseComplete={onClose} hasFooter={false} hasHeader={false} isShown={open} {...dialogProps}>
      {({ close }) => (
        <>
          <Pane width='100%' display='flex' justifyContent='flex-end'>
            <IconButton size='small' appearance='minimal' icon={<Cross />} onClick={close} />
          </Pane>
          <Heading is='h3' size={500}>
            Hensiktsmessighetstest
          </Heading>
          {open && <ExpediencySteps onAbort={close} onSign={handleSign} />}
        </>
      )}
    </Dialog>
  )
}

export default ExpediencyDialog
