import * as React from 'react'
import { Pane, PaneProps } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import { useIntl } from 'react-intl'
import { SmallInfoCard } from '/~/components/Cards'

export interface ProcessLabelProps {
  raised: string
  raisedCurrentYear: string
  investors: string
  campaigns: string
  campaignsCurrentYear: string
  companies: string
  subtitle: string
}

interface Props {
  labels?: ProcessLabelProps
  cardCustomMarginBottom?: string
  cardCustomSize?: {
    small: number
    medium: number
  }
}

export const ProcessView = ({
  labels: _labels,
  cardCustomMarginBottom: _cardCustomMarginBottom,
  cardCustomSize,
  ...rest
}: Props & PaneProps) => {
  const { small, medium, large } = useBreakpoint()
  const theme = useCustomTheme()
  const intl = useIntl()
  const steps = intl.messages['entrepreneurLanding.process']

  return (
    <Pane
      display='flex'
      flexDirection={small ? 'column' : 'row'}
      justifyContent='space-between'
      flexWrap='wrap'
      width={medium ? 325 : large ? 700 : '100%'}
      maxWidth={1010}
      {...rest}
    >
      {steps.map((step, index) => (
        <SmallInfoCard
          key={`requirement-${index}`}
          width={medium ? cardCustomSize?.medium ?? 325 : cardCustomSize?.small ?? 310}
          backgroundColor={index === 0 ? theme.colors.fiBlue10 : theme.colors.fiGreen10}
          marginBottom={theme.spacing.l}
          padding={theme.spacing.m}
          heading={step.heading}
          title={step.title}
          text={step.text}
        />
      ))}
    </Pane>
  )
}
