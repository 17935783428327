import * as React from 'react'
import packageJson from '/~/../package.json'
import { Pane, Paragraph, Button, CornerDialog, Heading, Card } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useLocation } from 'react-router'
import { useAppVersionChecker, useBreakpoint } from '/fiweb/lib'

const VersionChecker = () => {
  const theme = useCustomTheme()
  const { medium } = useBreakpoint()
  const location = useLocation()
  const hasOutdatedIndex = useAppVersionChecker()
  const [show, setShow] = React.useState(false)

  const hasOutdatedVersion = async () => {
    try {
      const file = '/metadata.json'
      const fetchRes = await fetch(file)
      const metadata = await fetchRes.json()
      return packageJson.version !== metadata.version
    } catch (_e) {
      return false
    }
  }

  const clearCacheAndReload = async () => {
    try {
      const keys = await caches.keys()
      await Promise.all(keys.map((name) => caches.delete(name)))
    } catch (e) {
      console.log(e)
    }
    window.location.reload()
  }

  React.useEffect(() => {
    const checkforOutdated = async () => {
      const hasOutdated = await hasOutdatedVersion()

      if (hasOutdatedIndex || hasOutdated) {
        setShow(true)
      }
    }

    checkforOutdated()
  }, [location.pathname, hasOutdatedIndex])

  const renderContent = () => {
    return (
      <>
        <Heading size={300}>Folkeinvest har utført vedlikehold på nettsiden.</Heading>
        <Paragraph size={300}>Vennligst oppfrisk vinduet.</Paragraph>

        <Button
          size='small'
          appearance='primary'
          minWidth={122}
          marginTop={theme.spacing.xs}
          onClick={clearCacheAndReload}
          alignSelf='flex-end'
        >
          Oppfrisk
        </Button>
      </>
    )
  }

  if (!show) {
    return null
  }

  if (medium) {
    return (
      <Card
        background='white'
        position='fixed'
        bottom={50}
        left={0}
        width='calc(100% - 40px)'
        marginX={20}
        zIndex={99}
        padding={20}
        boxShadow='1px 0px 10px #BCC2BA'
      >
        <Pane display='flex' flexDirection='column' paddingY={20}>
          {renderContent()}
        </Pane>
      </Card>
    )
  }

  return (
    <CornerDialog isShown hasFooter={false} hasClose={false} containerProps={{ zIndex: theme.zIndices.toaster }}>
      <Pane display='flex' flexDirection='column'>
        {renderContent()}
      </Pane>
    </CornerDialog>
  )
}

export default VersionChecker
