import * as React from 'react'
import { Heading, Link, Pane, Paragraph, Radio, toaster } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import NavButtons from './NavButtonsComponent'
import { useAcceptTermsMutationMutation } from '/~/types/graphql'
import { useAuthContext } from '/~/utils/AuthContext'

interface Props {
  goBack: () => void
  goForward: () => void
}

const PepStep = ({ goBack, goForward }: Props) => {
  const { user } = useAuthContext()
  const theme = useCustomTheme()

  const [pep, setPep] = React.useState(false)
  const [chosen, setChosen] = React.useState(false)
  const [acceptTerms] = useAcceptTermsMutationMutation()

  React.useEffect(() => {
    if (user?.pep === true || user?.pep === false) {
      setPep(user.pep)
      setChosen(true)
    }
  }, [])

  const handlePep = ({ target }) => {
    setPep(target.name === 'yes')
    setChosen(true)
  }

  const save = async () => {
    if (!chosen) {
      toaster.notify('Svar må være avgitt')
      return null
    }

    try {
      await acceptTerms({ variables: { pep } })

      goForward()
    } catch (_err) {
      toaster.danger('Kunne ikke oppdatere profil. Prøv igjen eller kontakt oss på support@folkeinvest.no')
    }
  }

  return (
    <Pane
      display='flex'
      flexDirection='column'
      maxWidth={380}
      marginTop={theme.spacing.l}
      alignItems='center'
      height='100%'
    >
      <Heading is='h3' textAlign='center'>
        Er du en politisk eksponert person? *
      </Heading>

      <Pane display='flex' flexFlow='row nowrap'>
        <Radio name='yes' checked={chosen ? pep : false} label='Ja' onChange={handlePep} size={16} />
        <Radio name='no' checked={chosen ? !pep : false} label='Nei' onChange={handlePep} marginLeft={30} size={16} />
      </Pane>

      <Paragraph size={300} textAlign='center' marginTop={theme.spacing.s} marginBottom={theme.spacing.xs}>
        Politisk eksponerte personer (PEP) er personer som har hatt høystående posisjoner i samfunnet, eller har levd i
        nær tilknytning til slike personer.
      </Paragraph>

      <Link
        rel='external'
        href='https://lovdata.no/dokument/NL/lov/2018-06-01-23/KAPITTEL_1#%C2%A72'
        target='_blank'
        size={300}
        fontWeight={600}
        marginBottom={theme.spacing.m}
      >
        Link til lovtekst ( § 2 f )
      </Link>

      <NavButtons onSave={save} onBack={goBack} disabled={!chosen} />
    </Pane>
  )
}

export default PepStep
