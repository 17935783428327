import * as React from 'react'
import { useBreakpoint, breakpoints } from '/fiweb/lib'
import { Pane, Heading, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled from 'styled-components'
import { config } from '/~/utils/config'
import { ManyDots } from '/fiweb/components/Icons'

const imageBase = `${config.publicFilesUrl}`

const StyledImage = styled(Pane)`
  min-height: 865px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${imageBase}/beach.jpg);
  width: 100%;
  @media ${breakpoints.large} {
    display: block;
  }
  margin-top: 50px;
`
const StyledWrapper = styled(Pane)`
  position: relative;
  height: 625px;
  width: 100%;
  margin-top: -326px;
  display: flex;
  justify-content: center;
  @media ${breakpoints.large} {
    margin-left: 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  @media ${breakpoints.medium} {
    top: -50px;
  }
`
const StyledCard = styled(Pane)`
  position: relative;
  max-width: 630px;
  width: 65%;
  max-height: 891px;
  height: fit-content;
  background-color: white;
  padding: 40px 40px 60px 40px;
  border-radius: 10px 10px 30px 10px;
  margin-right: 90px;
  @media ${breakpoints.large} {
    position: absolute;
    margin-right: 0px;
    margin-top: ${({ theme: { spacing } }) => spacing.xl};
    padding: 40px 30px;
    top: 100px;
  }
  @media ${breakpoints.medium} {
    top: 270px;
    width: 90%;
  }
`

const DotsWrapper = styled(Pane)`
  position: relative;
  left: 25px;
  top: 30px;
  max-width: 464px;
  svg {
    circle {
      fill: #f5f5f5;
    }
  }
  @media ${breakpoints.large} {
    left: 10px;
    top: 10px;
  }
  @media ${breakpoints.medium} {
    max-width: 325px;
  }
`

export const Sustainability = () => {
  const [extraMargin, setExtraMargin] = React.useState<boolean>()
  const [elementHeight, setElementHeight] = React.useState<number>()
  const { small, medium, large } = useBreakpoint()
  const theme = useCustomTheme()

  const ref = React.useRef<HTMLDivElement>()

  const onSizeChange = () => {
    if (large && elementHeight > 632) {
      setExtraMargin(true)
    } else {
      setExtraMargin(false)
    }
  }

  React.useEffect(() => {
    setElementHeight(ref?.current?.clientHeight)
    window.addEventListener('resize', () => {
      setElementHeight(ref?.current?.clientHeight)
    })

    return () => {
      window.removeEventListener('resize', () => {
        onSizeChange()
      })
    }
  }, [])

  return (
    <StyledImage marginBottom={small ? elementHeight - 470 : extraMargin ? elementHeight - 470 : theme.spacing.xl}>
      <DotsWrapper>
        <ManyDots width='auto' height='auto' max-width={medium ? 325 : 'auto'} />
      </DotsWrapper>
      <StyledWrapper>
        <Pane maxWidth={468} position='relative' top={medium ? -310 : large ? -450 : 0} marginLeft={21}>
          <Heading size={medium ? 800 : 900} color='white'>
            Bærekraft
          </Heading>
          <Paragraph size={medium ? 400 : 500} color='white'>
            Bærekraft har blitt et sentralt tema for investorer når de vurderer potensielle investeringer, og
            bærekraftige investeringsstrategier skyter fart.
          </Paragraph>
        </Pane>
        <StyledCard ref={ref}>
          <Paragraph size={large ? 300 : 400}>
            Vi er overbevist om at selskaper som forstår og utnytter bærekraft i sin forretningsstrategi, oppnår bedre
            resultater på lengre sikt enn selskaper som ikke gjør det. Bærekraftige investeringer er dermed avgjørende
            for å oppnå best mulig risikojustert avkastning for folkeinvestorene.
          </Paragraph>
          <Paragraph size={large ? 300 : 400} marginTop={theme.spacing.s}>
            Folkeinvest vet at det er mange som ønsker å bidra til en bærekraftig utvikling, og samtidig at mange
            bedrifter som jobber for å utgjøre en forskjell, møter hindringer på grunn av mangel på tilgang til
            risikokapital. Vårt bidrag til oppnåelse av bærekraftsmålene er først og fremst å sikre økt tilgang til
            finansielle tjenester for små og mellomstore bedrifter, slik at disse igjen kan utvikle teknologi og
            tjenester for bedre miljø, økonomi og sosiale forhold.
          </Paragraph>
          <Paragraph size={large ? 300 : 400} marginTop={theme.spacing.s}>
            Gjennom Folkeinvest har vi også som mål å sikre rettferdig tilgang til kapital og til å påvirke fremtidens
            løsninger og arbeidsplasser, ved å tilby en arena for direkte påvirkning gjennom engasjement og investering
            som er tilgjengelig for alle.
          </Paragraph>
        </StyledCard>
      </StyledWrapper>
    </StyledImage>
  )
}
