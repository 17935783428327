import { CampaignCardDataFragment } from '../types/graphql'
import { PublishedCampaignCardFragment } from '../types/issuer-graphql'
import { ClientCampaignCard } from '/fiweb/components/CampaignCard'
import { CardTypeEnum } from '/fiweb/components/CampaignCard/types'

export const normalizeSingleOldApiCampaignCard = (card: CampaignCardDataFragment): ClientCampaignCard => {
  if (!card) {
    return null
  }
  const { dates, reservationsCount, recentReservationsCount, state, ...rest } = card
  return {
    ...rest,
    ...(dates?.publish &&
      dates.open &&
      dates.close && {
        dates: {
          publish: new Date(dates.publish),
          open: new Date(dates.open),
          close: new Date(dates.close),
        },
      }),
    preorderSlug: rest.slug,
    state: state ? CardTypeEnum[state] : CardTypeEnum.DRAFT,
    fromNewApi: false,
    recentSubscriptionsCount: recentReservationsCount,
    subscriptionsCount: reservationsCount,
  }
}

export const normalizeOldApiCampaignCards = (cards: Array<CampaignCardDataFragment>) => {
  const result: Array<ClientCampaignCard> = (cards || []).map((card) => normalizeSingleOldApiCampaignCard(card))
  return result
}

export const normalizeSingleNewApiCampaignCard = (card: PublishedCampaignCardFragment) => {
  if (!card) {
    return null
  }
  const { image, state, publicInvestmentOffer, ...rest } = card
  const {
    dates,
    emissionProcessId,
    id,
    followerCount,
    recentSubscriptionsCount,
    subscriptionsCount,
    recentPageViewsCount,
    finishedSuccessfully,
    slug,
    preorderSlug,
  } = publicInvestmentOffer || {}
  return {
    ...rest,
    emissionProcessId,
    id,
    followerCount,
    recentSubscriptionsCount,
    subscriptionsCount,
    recentPageViewsCount,
    finishedSuccessfully,
    slug,
    preorderSlug,
    imageUrl: (image?.url || '') as string,
    ...(dates && {
      dates: {
        publish: new Date(dates.publish),
        open: new Date(dates.open),
        close: new Date(dates.close),
      },
    }),
    state: state ? CardTypeEnum[state] : CardTypeEnum.DRAFT,
    fromNewApi: true,
  }
}

export const normalizeNewApiCampaignCards = (cards: Array<PublishedCampaignCardFragment>) => {
  const result: Array<ClientCampaignCard> = (cards || []).map((card) => normalizeSingleNewApiCampaignCard(card))
  return result
}
