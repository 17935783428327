import * as React from 'react'
import { IconProps } from './types'

export const Bell = ({ title, ...rest }: IconProps) => (
  <svg width='29' height='30' viewBox='0 0 29 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path d='M4 23H25' stroke='currentColor' strokeWidth='2' />
    <path
      d='M25 23C25 23 22.9 21.2011 22.9 14.7355C22.9 9.57025 19.3132 5 14.5 5C9.6868 5 6.1 9.57025 6.1 14.7355C6.1 21.2355 4 23 4 23'
      stroke='currentColor'
      strokeWidth='2'
    />
    <path d='M16 27C16 28.1046 15.1046 29 14 29C12.8954 29 12 28.1046 12 27' stroke='currentColor' strokeWidth='2' />
    <path d='M14 5V2' stroke='currentColor' strokeWidth='2' />
  </svg>
)
