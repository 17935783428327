import * as React from 'react'
import MediaView from './MediaView'
import { useGetAllPressReleasesQuery } from '/~/types/graphql'

const PressReleases = () => {
  const { data, loading } = useGetAllPressReleasesQuery()
  const list = data?.getAllPressReleases || []
  return <MediaView list={list} loading={loading} />
}

export default PressReleases
