import * as React from 'react'
import { Link, Pane, Paragraph } from 'evergreen-ui'
import { useBreakpoint } from '/fiweb/lib'

const VPSInfo = () => {
  const { medium } = useBreakpoint()

  return (
    <Pane
      display='flex'
      flexDirection={medium ? 'column' : 'row'}
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      width='100%'
    >
      <Paragraph fontSize={14}>Denne emisjonen benytter seg av VPS</Paragraph>
      <Link
        fontSize={14}
        marginLeft={medium ? 0 : 6}
        href='https://hjelpesenter.folkeinvest.no/hva-er-en-vps-konto'
        target='_blank'
        rel='noopener'
      >
        Hva er VPS?
      </Link>
    </Pane>
  )
}
export default VPSInfo
