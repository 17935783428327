import * as React from 'react'
import { IconProps } from './types'

export const NorwegianBankIdLogo = ({ title, ...rest }: IconProps) => (
  <svg width='98' height='15' viewBox='0 0 98 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <g clipPath='url(#clip0_430_82390)'>
      <path
        d='M5.25 2.14286H1.05C0.49 2.14286 0 1.64286 0 1.07143C0 0.5 0.49 0 1.05 0H5.25C5.81 0 6.3 0.5 6.3 1.07143C6.3 1.64286 5.81 2.14286 5.25 2.14286Z'
        fill='#39134C'
      />
      <path
        d='M5.25 10.7143H1.05C0.49 10.7143 0 10.2143 0 9.64286C0 9.07143 0.49 8.57143 1.05 8.57143H5.25C5.81 8.57143 6.3 9.07143 6.3 9.64286C6.3 10.2143 5.81 10.7143 5.25 10.7143Z'
        fill='#39134C'
      />
      <path
        d='M5.25 15H1.05C0.49 15 0 14.5 0 13.9286C0 13.3571 0.49 12.8571 1.05 12.8571H5.25C5.81 12.8571 6.3 13.3571 6.3 13.9286C6.3 14.5 5.81 15 5.25 15Z'
        fill='#39134C'
      />
      <path
        d='M13.65 6.42857H9.44999C8.88999 6.42857 8.39999 5.92857 8.39999 5.35714C8.39999 4.78571 8.88999 4.28571 9.44999 4.28571H13.65C14.21 4.28571 14.7 4.78571 14.7 5.35714C14.7 5.92857 14.21 6.42857 13.65 6.42857Z'
        fill='#39134C'
      />
      <path
        d='M13.65 10.7143H9.44999C8.88999 10.7143 8.39999 10.2143 8.39999 9.64286C8.39999 9.07143 8.88999 8.57143 9.44999 8.57143H13.65C14.21 8.57143 14.7 9.07143 14.7 9.64286C14.7 10.2143 14.21 10.7143 13.65 10.7143Z'
        fill='#39134C'
      />
      <path
        d='M22.05 2.14286H17.85C17.29 2.14286 16.8 1.64286 16.8 1.07143C16.8 0.5 17.29 0 17.85 0H22.05C22.61 0 23.1 0.5 23.1 1.07143C23.1 1.64286 22.61 2.14286 22.05 2.14286Z'
        fill='#39134C'
      />
      <path
        d='M22.05 6.42857H17.85C17.29 6.42857 16.8 5.92857 16.8 5.35714C16.8 4.78571 17.29 4.28571 17.85 4.28571H22.05C22.61 4.28571 23.1 4.78571 23.1 5.35714C23.1 5.92857 22.61 6.42857 22.05 6.42857Z'
        fill='#39134C'
      />
      <path
        d='M22.05 15H17.85C17.29 15 16.8 14.5 16.8 13.9286C16.8 13.3571 17.29 12.8571 17.85 12.8571H22.05C22.61 12.8571 23.1 13.3571 23.1 13.9286C23.1 14.5 22.61 15 22.05 15Z'
        fill='#39134C'
      />
      <path
        d='M32.06 9.35714C32.06 11.6429 33.32 12.8571 34.79 12.8571C36.12 12.8571 37.59 11.7143 37.59 9.35714C37.59 7 36.19 5.92857 34.86 5.92857C33.39 5.92857 32.06 7 32.06 9.35714ZM32.06 0V5.57143C32.69 4.57143 33.81 3.57143 35.7 3.57143C37.8 3.57143 40.32 5.35714 40.32 9.28571C40.32 13.3571 37.94 15.3571 35.49 15.3571C34.09 15.3571 32.9 14.7143 32.13 13.4286V15H29.4V0H32.06Z'
        fill='#39134C'
      />
      <path
        d='M44.31 9.42857C44.31 11.7857 45.71 12.8571 47.04 12.8571C48.51 12.8571 49.77 11.8571 49.77 9.42857C49.77 7.07143 48.51 5.92857 47.04 5.92857C45.78 5.92857 44.31 7 44.31 9.42857ZM41.65 9.28572C41.65 5.42857 44.1 3.5 46.55 3.5C47.88 3.5 49 4 49.84 5.21429V3.78571H52.5V15H49.84V13.4286C49.07 14.5 47.95 15.2857 46.2 15.2857C44.1 15.2857 41.65 13.4286 41.65 9.28572Z'
        fill='#39134C'
      />
      <path
        d='M57.75 8.64286V15H55.09V3.85714H57.75V5.5C58.52 4.07143 59.92 3.57143 61.11 3.57143C63 3.57143 64.82 4.57143 64.82 7.57143V15H62.16V8.57143C62.16 6.71428 61.25 6 60.06 6C58.73 6.07143 57.75 6.85714 57.75 8.64286Z'
        fill='#39134C'
      />
      <path
        d='M67.34 0V15H70V12.2857L71.47 10.4286L74.48 15H77.42L73.08 8.5L76.86 3.85714H73.85L70 9V0H67.34Z'
        fill='#39134C'
      />
      <path d='M82.74 0.5H80.01V15H82.74V0.5Z' fill='#39134C' />
      <path
        d='M90.65 0.5H85.47V15H90.65C94.5 15 97.72 12.6429 97.72 7.71429C97.72 2.85714 94.5 0.5 90.65 0.5ZM90.65 12.4286H88.2V3.07143H90.65C92.89 3.07143 94.78 4.57143 94.78 7.78571C94.78 10.9286 92.89 12.4286 90.65 12.4286Z'
        fill='#39134C'
      />
    </g>
    <defs>
      <clipPath id='clip0_430_82390'>
        <rect width='98' height='15' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
