import * as React from 'react'
import { FetchCampaignQuery } from '/~/types/graphql'
import { Heading, Link, Pane, Paragraph, Text, TextInputField, TickIcon } from 'evergreen-ui'
import { RadioGroup } from '/fiweb/components'
import { useBreakpoint } from '/fiweb/lib'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props {
  onAccountChange: (account: string) => void
  vpsAccount: string
  userHasVps: boolean
  option: FetchCampaignQuery['Campaign']['campaignOptions'][number]
  campaign: FetchCampaignQuery['Campaign']
  onHasVpsChange: (hasVps: boolean) => void
}

const VPSInputComponent = (props: Props) => {
  const {
    onAccountChange,
    vpsAccount,
    userHasVps,
    onHasVpsChange,
    option = {
      name: 'vpsAndAccountToggled',
      checked: false,
      text: null,
    },
  } = props
  const { spacing } = useCustomTheme()
  const [error, setError] = React.useState<string | null>(null)
  const { medium } = useBreakpoint()

  const setErrors = (newAccount: string) => {
    if (!newAccount) {
      setError(null)
    } else if (newAccount?.match(/^[0-9]*$/g) && newAccount.length === 12) {
      setError(null)
    } else {
      setError('VPS-konto må være 12 siffer')
    }
  }
  return (
    <Pane>
      <Pane display='flex' alignItems='flex-start'>
        <Heading is='h5' size={400}>
          VPS-konto
        </Heading>
        <Link
          marginLeft={spacing.xxs}
          href='https://hjelpesenter.folkeinvest.no/hva-er-en-vps-konto'
          target='_blank'
          rel='noopener'
        >
          Hva er VPS?
        </Link>
      </Pane>

      <Pane>
        {option.text.split('\n').map((line, i) => (
          <Paragraph key={`vps-line_${i}`}>{line || <br />}</Paragraph>
        ))}
      </Pane>

      <RadioGroup
        name='userHasNoVps'
        paddingTop='30px'
        label={<Text fontWeight={600}>Har du VPS konto?</Text>}
        layout={medium ? 'vertical' : 'horizontal'}
        onChange={(e) => onHasVpsChange(e.target.value === 'true')}
        value={userHasVps.toString()}
        options={[
          { label: 'Ja', value: 'true', string: true },
          { label: 'Nei (Vil kreve bekreftelse om opprettelse)', value: 'false', string: true },
        ]}
      />
      {userHasVps && (
        <>
          <Paragraph size={300}>NB! Aksjesparekonto er ikke gyldig VPS-konto.</Paragraph>

          <Pane display='flex' alignItems='center'>
            <TextInputField
              label={<Text fontWeight={600}>VPS-kontonummer</Text>}
              maxWidth={medium ? '100%' : 335}
              width='100%'
              inputHeight={50}
              autoComplete='off'
              required
              onBlur={() => setErrors(vpsAccount)}
              value={vpsAccount}
              onChange={(e) => onAccountChange(e.target.value)}
              isInvalid={error !== null}
            />
            <TickIcon color='success' marginLeft={spacing.xxs} opacity={vpsAccount.length === 12 && !error ? 1 : 0} />
          </Pane>

          {error && <Text color='danger'>{error}</Text>}
        </>
      )}
    </Pane>
  )
}

export default VPSInputComponent
