import * as React from 'react'
import { MustBeLoggedIn } from '/~/pages/InvestmentOfferPage/components/MustBeLoggedIn'
import { useAuthContext } from '/~/utils/AuthContext'
import { Message } from '/fiweb/lib/i18n/types'
import { useCustomTheme } from '/fiweb/lib/theme'
import { LockedSection } from './LockedSection'
import { usePublicInvestmentOfferData } from './Context/PublicInvestmentOfferData'

interface Props {
  titleId?: Message
  children?: React.ReactNode
}

export const LoginRequired: React.FC<Props> = ({ children }) => {
  const { user } = useAuthContext()
  const { spacing } = useCustomTheme()
  const { isReadonly } = usePublicInvestmentOfferData()

  if (!user) {
    if (!isReadonly) {
      return (
        <LockedSection>
          <MustBeLoggedIn
            title='investmentOfferPage.loginRequired'
            paneProps={{ alignItems: 'center', textAlign: 'center', gap: spacing.xs }}
          />
        </LockedSection>
      )
    }
  }
  return <>{children}</>
}
