import * as React from 'react'
import { useBreakpoint, breakpoints } from '/fiweb/lib'
import { Pane, Heading, IconButton, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled from 'styled-components'
import { useGetVisibleInvestorRelationsEventsQuery } from '/~/types/graphql'
import { EventCard } from './EventCard'
import { IconDirection, ManyDots, ThinChevron } from '/fiweb/components/Icons'

const imageBase = `${(window as any)._ENV.VITE_APP_PUBLIC_FILES_URL}`

const StyledImage = styled(Pane)`
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  height: 678px;
  background-image: linear-gradient(
      103.63deg,
      rgba(45, 51, 64, 0.25) 42.95%,
      rgba(45, 51, 64, 0.25) 62.47%
    ),
    url(${imageBase}/beautiful_fjord_landscape.jpg);
  width: 100%;
  position: relative;
`

const DotsWrapper = styled(Pane)`
  position: absolute;
  margin-left: 25px;
  margin-top: 30px;
  max-width: 464px;
  svg {
    circle {
      fill: #f5f5f5;
    }
  }
  @media ${breakpoints.large} {
    margin-left: 10px;
    margin-top: 10px;
  }
  @media ${breakpoints.medium} {
    max-width: 325px;
  }
`

const CustomIconButton = styled(IconButton)`
  color: white;
  border-color: white;
  margin: 10px;
  border-width: 2px;
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;
  &:hover {
    border-color: initial !important;
  }
  &:disabled {
    color: white;
    border-color: white;
    border-width: 2px;
    opacity: 0.5;
  }
`

const Wrapper = styled(Pane)`
  grid-column: 1;
  grid-row: 1;
`

export const Events = () => {
  const theme = useCustomTheme()
  const { medium, large } = useBreakpoint()

  const PAGE_SIZE = large ? 1 : 3

  const { data, loading, fetchMore } = useGetVisibleInvestorRelationsEventsQuery({
    variables: { pageIndex: 0, pageSize: 9 },
  })

  const query = data?.getVisibleInvestorRelationsEvents
  const totalCount = query?.totalCount
  const events = query?.events
  const pageIndex = query?.pageIndex

  const [offset, setOffset] = React.useState(0)

  const loadMore = () => {
    fetchMore({
      variables: { pageIndex: pageIndex + 1, pageSize: PAGE_SIZE },
      updateQuery: (prev, { fetchMoreResult: fmr }) => {
        if (!fmr) {
          return prev
        }
        const eventsList = [
          ...(prev.getVisibleInvestorRelationsEvents?.events ?? []),
          ...(fmr.getVisibleInvestorRelationsEvents?.events ?? []),
        ]
        return Object.assign({}, prev, {
          getVisibleInvestorRelationsEvents: {
            ...fmr.getVisibleInvestorRelationsEvents,
            events: eventsList,
          },
        })
      },
    })
    setOffset(offset + PAGE_SIZE)
  }

  const previousPage = () => {
    setOffset(offset - PAGE_SIZE)
  }

  const nextPage = () => {
    if (offset + PAGE_SIZE < events?.length) {
      setOffset(offset + PAGE_SIZE)
    } else {
      loadMore()
    }
  }

  const displayEvents = (events || []).slice(offset, offset + PAGE_SIZE)

  return (
    <StyledImage marginBottom={200}>
      <DotsWrapper>
        <ManyDots width='auto' height='auto' max-width={medium ? 325 : 'auto'} />
      </DotsWrapper>
      <Pane display='grid' width='100%' position='absolute' top={large ? 200 : 120}>
        <Wrapper
          display='flex'
          flexDirection='column'
          marginTop={large ? 0 : 100}
          justifySelf='center'
          paddingX='10px'
          paddingY='10px'
        >
          <Heading maxWidth={large ? 325 : 500} size={800} color='white' marginBottom={10}>
            Året for aksjonærer
          </Heading>
          <Pane display='flex' alignItems='center' flexDirection={large ? 'column' : 'row'} width={large ? 325 : 600}>
            <Heading margin={0} color='white'>
              Kommende hendelser og events
            </Heading>
            {totalCount !== 0 ? (
              <Pane marginLeft={50} alignSelf='flex-end'>
                <CustomIconButton
                  disabled={offset === 0}
                  onClick={() => previousPage()}
                  icon={<ThinChevron direction={IconDirection.LEFT} />}
                />
                <CustomIconButton
                  icon={<ThinChevron direction={IconDirection.RIGHT} />}
                  disabled={offset + PAGE_SIZE >= totalCount}
                  onClick={() => nextPage()}
                />
              </Pane>
            ) : null}
          </Pane>
          {totalCount === 0 || loading ? (
            <Pane display='flex' width={large ? '' : '1240px'}>
              <Paragraph marginTop={theme.spacing.s} color='white' size={400}>
                Ingen kommende hendelser
              </Paragraph>
            </Pane>
          ) : (
            <Pane display='grid' gridTemplateColumns={large ? '' : 'repeat(3, 400px)'} columnGap={large ? '' : '20px'}>
              {displayEvents.map((event) => (
                <EventCard key={event._id} event={event} />
              ))}
            </Pane>
          )}
        </Wrapper>
      </Pane>
    </StyledImage>
  )
}
