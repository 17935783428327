import React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Pane, Heading, Paragraph, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { LargeInfoCard } from '/~/components/Cards'
import { Caption } from '/fiweb/components'

const InvestmentRisk = () => {
  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()

  const scroll = (hash: string) => {
    const element = document.getElementById(hash.replace('#', ''))

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
    }
  }

  React.useEffect(() => {
    const { hash } = window.location
    if (hash) {
      const timer = setTimeout(scroll, 1000, hash)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [])

  return (
    <Pane display='flex' flexDirection='column' alignItems='center'>
      <Pane
        display='flex'
        flexDirection='column'
        alignItems='start'
        width='86%'
        maxWidth={large ? 700 : 800}
        justifyContent='center'
        marginTop={large ? 0 : theme.spacing.l}
        marginBottom={medium ? theme.spacing.l : theme.spacing.xl}
      >
        <Heading is='h1' size={medium ? 600 : large ? 700 : 800}>
          Investeringsrisiko
        </Heading>
        <Paragraph size={medium ? 400 : 500} marginBottom={medium ? theme.spacing.m : theme.spacing.l}>
          Her forklarer vi hvordan Folkeinvest jobber for å ivareta investorbeskyttelse – og hvordan vi systematisk
          arbeider for god risikostyring rundt hver enkelt utstederkunde.
          <br />
          <br />
          Det er risiko forbundet med å investere i unoterte aksjer. Det er spesielt høy risiko forbundet med
          investeringer i unge unoterte selskap og skaleringsbedrifter. Det er viktig at du som potensiell investor
          leser investeringstilbudet nøye og gjør deg opp egen vurdering av risikoen den eventuelle investeringen
          innebærer for deg.
          <br />
          <br />
          Som et grunnprinsipp bør du ikke investere mer enn du har råd til å tape.
        </Paragraph>
        <Heading size={600}>Investeringsrisiko</Heading>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.s : theme.spacing.m}>
          Her er informasjon du bør være oppmerksom på når du investerer via plattformen. Du finner også informasjon om
          hvordan Folkeinvest arbeider med risiko, samt hva du selv kan gjøre for å redusere din investeringsrisiko.
        </Paragraph>
        <Heading size={500}>Unoterte aksjer</Heading>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          Aksjene du kjøper på Folkeinvest er unoterte aksjer. Når aksjene er unotert, betyr det at selskapet ikke er
          regulert på en handelsplass som en børs. Når selskapene ikke er registrert på en handelsplass, kan det være
          vanskeligere å få solgt aksjene på kort sikt. Du er avhengig av å finne en kjøper selv, og selv om du som
          selger får solgt kan kjøperen være avhengig av å få transaksjonen godkjent av selskapets styre. 
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          Som eier av aksjer i et selskap har du rett til å møte på generalforsamling og eiermøter, og kan være med på å
          styre utviklingen av selskapet. Det er en spennende rolle – og flere selskap trenger nettopp din kunnskap.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          Investering i unoterte aksjer innebærer høy risiko. Det innebærer at du potensielt kan oppnå svært høy
          avkastning, men du kan også risikere å tape hele beløpet du har investert.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.m : theme.spacing.l}>
          Dette er en av årsakene til at flere som investerer i denne typen aksjer sprer risikoen gjennom å eie aksjer i
          flere selskap, og som representerer en spredning i risiko. Du kan likevel aldri tape mer enn det du har
          investert! 
        </Paragraph>
        <Pane
          width='100%'
          alignSelf='center'
          display='flex'
          justifyContent='center'
          marginBottom={medium ? theme.spacing.m : theme.spacing.l}
        >
          <iframe
            width={medium ? '100%' : large ? '700px' : '800px'}
            height={medium ? '400px' : '450px'}
            src={'https://www.youtube.com/embed/LfU51dUMG2Y'}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            title='Embedded youtube'
          />
        </Pane>
        <Heading size={500}>Generell risiko</Heading>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.s : theme.spacing.m}>
          Uforutsette hendelser kan skje i alle selskap. Dersom en utsteder ikke kan dekke sine forpliktelser og går
          konkurs, kan du tape hele eller deler av din investering.
        </Paragraph>
        <LargeInfoCard
          width={medium ? ' 100%' : large ? 700 : 800}
          paddingTop={medium ? 30 : 40}
          paddingLeft={medium ? 18 : 40}
          paddingRight={medium ? 18 : 88}
          marginBottom={medium ? theme.spacing.s : theme.spacing.m}
        >
          <Heading size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
            Risikoforhold som kan påvirke din investering*:
          </Heading>
          <Pane fontSize={16} lineHeight='24px' fontWeight={400}>
            <ul style={{ paddingLeft: `${medium ? '18px' : '25px'}`, margin: '0' }}>
              <li style={{ marginBottom: theme.spacing.xs }}>
                Økonomisk og finansiell (for eksempel risiko relatert til omsetning/prognoser, likviditet, konkurs,
                renter, valuta, etc.)
              </li>
              <li style={{ marginBottom: theme.spacing.xs }}>
                Kreditt (risiko for at motpart ikke overholder sine betalingsforpliktelser)
              </li>
              <li style={{ marginBottom: theme.spacing.xs }}>
                Marked (for eksempel risiko relatert til konkurrenter, råvarepriser, leverandører, distribusjon,
                pandemi, etc.)
              </li>
              <li style={{ marginBottom: theme.spacing.xs }}>
                Operasjonell (for eksempel risiko relatert til kompetanse, ledelse, etterlevelse av lover og regler,
                teknologi, sikkerhet, etc.)
              </li>
              <li style={{ marginBottom: theme.spacing.xs }}>
                Politisk (for eksempel risiko relatert til regulatoriske forhold med innvirkning på virksomheten)
              </li>
              <li>
                Omdømme (for eksempel risiko relatert til med tap av omdømme/tillit som følge av hendelse som involverer
                investeringsobjektet){' '}
              </li>
            </ul>
          </Pane>
          <Caption display='block' marginTop={theme.spacing.xs}>
            <i>* Ovenstående er en ikke-uttømmende liste over risikoforhold som kan påvirke din investering.</i>
          </Caption>
        </LargeInfoCard>
        <Heading id='investorbeskyttelse' size={500}>
          Våre undersøkelser og investorbeskyttelse
        </Heading>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          Folkeinvest gjennomfører en rekke kontroller og analyser før utsteder får publisere sitt investeringstilbud på
          plattformen. Disse tiltakene er implementert for å bidra til din investorbeskyttelse og er i tråd med vår
          undersøkelsesplikt. Vi gjennomfører undersøkelser av hver enkelt utsteder og tilhørende investeringstilbud for
          å bidra til at den informasjonen som presenteres deg som investor er balansert, klar og ikke villedende. Våre
          undersøkelser utføres i tråd med forholdsmessighetsprinsippet og god forretningsskikk. Vi benytter en
          kombinasjon av offentlige registre, tredjepartsløsninger og dokumentasjon direkte fra den respektive utsteder
          for å utføre våre kontroller. Vi gjør likevel deg som potensiell investor oppmerksom på at utsteder er
          ansvarlig for at investeringstilbudets innhold er gyldig og viser et riktig bilde av virksomheten.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          Før Folkeinvest inngår en avtale med en utsteder gjør vi undersøkelser for å redusere risiko. Vi gjennomfører
          sjekk av selskapets historikk, offentlige regnskap, personer tilknyttet selskapet, samt en ekstern
          kredittsjekk og sjekk av betalingsanmerkninger. I enkelte forholdsmessige tilfeller gjør vi
          tilleggsundersøkelser før avtaleinngåelse; for eksempel en grundigere regnskapsanalyse. I spesielle tilfeller,
          der vår risikoanalyse gir en såkalt «rød» restrisiko vil styret i Folkeinvest involveres for beslutning om
          avtaleinngåelse.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          Verdsettelse er et sentralt tema i forbindelse med investeringer i aksjer. Det kan være utfordrende å
          fastsette verdien av et selskap. Spesielt gjelder dette verdsettelse av tidligfaseselskap som har liten eller
          ingen historikk. For utstederselskap som har en indikativ prising på mer enn 50 MNOK, krever Folkeinvest at
          det utarbeides et mer omfattende verdsettelsesdokument. Folkeinvest tilbyr utarbeidelse av et slikt dokument
          for enkelte utstedere som en betalt tilleggstjeneste. Dette dokumentet legges ved investeringstilbudet. I
          andre tilfeller vil Folkeinvest utfordre utstederen dersom verdsettelsen av ulike årsaker virker urimelig,
          eller dersom forutsetninger for verdifastsettelseskonklusjonen ikke redegjøres for i tilstrekkelig grad.
          Folkeinvest er opptatt av at det i alle verdsettelsesdokument inntas setninger som tydeliggjør at det er
          forbundet usikkerhet med verdsettelsen, og det faktum at det er stor grad av usikkerhet knyttet til unoterte
          aksjers omsettelighet ettersom det ikke finnes et godt etablert annenhåndsmarked for dette.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          Før investeringstilbudet godkjennes for publisering på plattformen, gjør vi flere undersøkelser og kontroller.
          Folkeinvest krever at utsteder tilgjengeliggjør siste årsregnskap med noter, og avstemte perioderapporter i
          kontospesifiserte versjoner slik at vi kan kontrollere. Videre foretar vi en såkalt «runwayanalyse» av
          utstederselskapets egenkapital- og likviditetssituasjon i forbindelse med emisjonen. Vi legger
          regnskapsinformasjon, emisjonsintervall og utsteders budsjetter til grunn for denne analysen. Analysen har til
          hensikt å sikre at utstederselskapet henter tilstrekkelig med kapital for å sikre krav om forsvarlig
          egenkapital og likviditet, jfr. aksjeloven. Vi kvalitetsikrer videre investeringstilbudets innhold, og sjekker
          blant annet at utsteder beskriver risikoer i sitt investeringstilbud. Vi kontrollerer all
          underlagsdokumentasjon som har betydning for en investeringsbeslutning, og oppdaterer vår risikovurdering av
          utsteder med blant annet en ny kredittsjekk. Folkeinvest er opptatt av at det er utsteder selv som skal
          utarbeide sitt investeringstilbud ved hjelp av våre digitale verktøy, men påser at informasjonen er balansert,
          klar og ikke villedende – i tråd med god forretningsskikk og forholdsmessighetsprinsippet.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          For de utstedere som faller inn under kravene om «Nasjonalt prospekt» etter verdipapirhandelloven, vil et
          slikt prospekt også lastes opp som en del av underlaget. Dette er et innholdsmessig- og rekkefølgekrav for den
          informasjonen som skal inngå og registreres i Brønnøysundregistrene. Dette kravet gjelde for de selskap som
          skal hente mer enn 1 millioner euro i løpet av en 12 mnd periode.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          Den endelige godkjenningen av et investeringstilbud gjøres av vår godkjenningskomité. Etter en validering av
          at investeringstilbudet er balansert, klart og ikke villedende, godkjennes investeringstilbudet for å bli
          publisert og lesbart på folkienvest.no. Før det publiseres signerer utsteders styreleder en
          fullstendighetserklæring, og bekrefter med dette at investeringstilbudet er korrekt og fullstendig. Styreleder
          skal også redegjøre skriftlig for om utsteder, styremedlemmer og ledelse er eller har vært involvert i
          eventuelle domfellelser for økonomiske forhold, konkurser, bobehandlinger eller avviklinger,
          forvaltningsrettslige anklager eller sanksjoner.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.s : theme.spacing.m}>
          Vi understreker at det utsteder som er ansvarlig for at informasjonen som presenteres er korrekt og
          fullstendig. Det kan forekomme feil og mangler til tross for våre undersøkelser.
        </Paragraph>
        <LargeInfoCard
          width={medium ? ' 100%' : large ? 700 : 800}
          paddingY={medium ? 30 : 40}
          paddingLeft={medium ? 18 : 40}
          paddingRight={medium ? 18 : 50}
          marginBottom={theme.spacing.l}
        >
          <Heading size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
            Våre undersøkelser oppsummert*:
          </Heading>
          <Pane marginBottom={medium ? theme.spacing.s : theme.spacing.m}>
            <Pane fontSize={16} lineHeight='32px' fontWeight={400}>
              <ol style={{ paddingLeft: `${medium ? '18px' : '25px'}`, margin: '0' }}>
                <li style={{ paddingLeft: `${medium ? '0px' : '18px'}` }}>
                  Innledende undersøkelser og risikovurdering før avtaleinngåelse
                </li>
                <li style={{ paddingLeft: `${medium ? '0px' : '18px'}` }}>
                  Kontroll av investeringstilbudet med regnskapsunderlag og vesentlig dokumentasjon
                </li>
                <li style={{ paddingLeft: `${medium ? '0px' : '18px'}` }}>
                  Ny kredittvurdering og regnskapskontroll av perioderapporter ved behov
                </li>
                <li style={{ paddingLeft: `${medium ? '0px' : '18px'}` }}>
                  Formell godkjenning av investeringstilbudet av Folkeinvest
                </li>
                <li style={{ paddingLeft: `${medium ? '0px' : '18px'}` }}>
                  Signering for fullstendighet av utsteders styreleder
                </li>
              </ol>
            </Pane>
            <Caption display='block' marginTop={theme.spacing.xxs}>
              <i>* Oppsummeringen er en ikke-uttømmende oversikt over våre undersøkelser.</i>
            </Caption>
          </Pane>
          <Paragraph size={300}>
            På bakgrunn av våre kontrolltiltak forekommer det fra tid til annen at Folkeinvest avstår fra å inngå avtale
            med potensielle utstederkunder, og i enkelte tilfeller avbryter vi også samarbeid på bakgrunn av funn gjort
            under vår utøvelse av undersøkelsesplikten.
          </Paragraph>
        </LargeInfoCard>
        <Heading size={500}>Folkeinvests risikoreduserende tiltak</Heading>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          <Text size={400} fontWeight={600}>
            Krav om 30% sikring
          </Text>
          <br />
          Folkeinvest krever at utsteder selv sikrer minst 30% av kapitalbehovet, ved at utsteders eksisterende
          aksjonærer og/eller parter som har kjennskap til bransjen og utstederen forplikter forhåndstegninger til de
          betingelsene som er satt i investeringstilbudet. Dette er et tiltak vi mener bidrar til å redusere risiko for
          våre investorkunder. Denne modellen bidrar også til en viss grad å validere investeringsmuligheten, herunder
          verdsettelsen av utstederselskapet. Det betyr ikke at du skal stole på andre i din investeringsbeslutning. Du
          er selv ansvarlig for din investeringsbeslutning.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          <Text size={400} fontWeight={600}>
            Sperret emisjonskonto
          </Text>
          <br />
          Folkeinvest krever at utsteder oppretter en sperret emisjonskonto. Kapitalen fra emisjonen kan ikke benyttes
          før Folkeinvest får bekreftet fra Brønnøysund at emisjonen er registrert.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          <Text size={400} fontWeight={600}>
            Åpent diskusjonsforum
          </Text>
          <br />
          Folkeinvest har tilrettelagt for et diskusjonsforum der alle som har opprettet en profil på folkeinvest.no kan
          stille spørsmål direkte til utsteder. Utsteder er avtalemessig forpliktet til å besvare alle spørsmål i
          diskusjonsforumet på en klar og forståelig måte. Folkeinvest mener diskusjonsforumet bidrar til transparens og
          en åpen kollektiv kilde til informasjon.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          Alle som legger igjen en kommentar i diskusjonsforum må ha en bank-ID-verifisert brukerprofil.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          <Text size={400} fontWeight={600}>
            Hensiktsmessighetsvurdering
          </Text>
          <br />
          Alle som skal investere via folkeinvest.no må gjennomføre en hensiktsmessighetsvurdering, eller -test, som
          skal avdekke om du som investorkunde har de kunnskapene som behøves for å forstå den risikoen det å investere
          i unoterte aksjer innebærer. Dersom du feiler testen, vil du få en fraråding fra å investere. Du kan velge å
          gjennomføre testen på nytt, og vi oppfordrer i disse tilfeller å lese seg opp på den kunnskapen som skal til
          for fullt ut forstå risikoen investeringer i unoterte aksjer innebærer. Alle som oppretter en investorprofil
          på folkeinvest.no er myndige personer med elektronisk legitimasjon.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          <Text size={400} fontWeight={600}>
            Åpne tegningslister
          </Text>
          <br />
          Tegningslisten i en utsteders investeringstilbud er åpent tilgjengelig for alle med BankID-verifisert
          investorprofil på folkeinvest.no. Dette bidrar til transparens ved at det er synlig hvem som har tegnet seg i
          den respektive emisjonen.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          <Text size={400} fontWeight={600}>
            Avtalemessige forpliktelser
          </Text>
          <br />
          Kundeforholdet mellom utsteder og Folkeinvest er regulert gjennom en kundeavtale som juridisk forplikter
          utsteder til å sørge for at opplysninger i investeringstilbudet er korrekt, gis i forståelig form og på en
          slik måte at ikke-profesjonelle investorkunder har en mulighet til å forstå arten av og risikoen forbundet med
          investeringstilbudet.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.s : theme.spacing.m}>
          <Text size={400} fontWeight={600}>
            Kapitalkrav
          </Text>
          <br />
          Folkeinvest er underlagt kapitalkrav og regelmessig kapitaldekningsrapportering til Finanstilsynet. Til tross
          for at Folkeinvest ikke er mellommann i forbindelse med innbetalinger, såkalt betalingsforetak, sikrer et
          slikt kapitalkravsregime våre utstederkunder og investorkunder mot en eventuell konkurs i Folkeinvest ved at
          vi til enhver tid har kapital til å ferdigstille arbeidet med en pågående emisjoner.
        </Paragraph>
        <Heading size={500}>Slik kan du selv redusere din risiko</Heading>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          Folkeinvest gjør det vi kan for å holde deg informert som best mulig når du investerer i nye selskap. Det er
          imidlertid alltid opp til deg selv å bedømme om risikoen i et selskap er riktig for deg. Derfor bør du alltid
          sette deg skikkelig inn i selskapene du investerer i. Vi oppfordrer alle investorkunder om å benytte
          diskusjonsforumet dersom det er informasjon en har behov for utover den informasjonen som går frem av
          investeringstilbudet.
        </Paragraph>
        <Paragraph size={400} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          Selv “sunne” selskaper kan komme i uforutsette problemer, det er dermed ingen garanti for at selskapet du
          investerer i vil lykkes med sine ambisjoner og planer.
        </Paragraph>
        <Paragraph size={400}>
          Du kan gjøre deg selv mindre sårbar ved å spre dine investeringer. Jo flere selskap du investerer i, jo mindre
          betyr det for din samlede investering hvis et enkelt av dem gir et tap. Husk at investering i unoterte aksjer
          innebærer høy risiko, og at du bør sørge for at du har en total investeringsportefølje som også inkluderer
          andre og mindre risikofylte investeringer.
        </Paragraph>
      </Pane>
    </Pane>
  )
}

export default InvestmentRisk
