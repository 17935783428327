import * as React from 'react'
import { Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import PageContainer from './PageContainer'
import { Lock } from '/fiweb/components/Icons'
import { Link } from 'react-router-dom'

interface Props {
  pageTitle: string
}

const NotSignedIn = ({ pageTitle }: Props) => {
  const theme = useCustomTheme()

  return (
    <PageContainer>
      <Pane display='flex' flexDirection='column' width='100%' alignItems='center'>
        <Lock height='110px' width='110px' color={theme.colors.fiDark10} />
        <Paragraph>For å få mer informasjon om ‘{pageTitle}’, </Paragraph>
        <Paragraph>
          <Link to='/innlogging' state={{ redirectToAfterAuth: location.pathname }}>
            logg inn
          </Link>{' '}
          eller{' '}
          <Link to='/innlogging/registrering' state={{ redirectToAfterAuth: location.pathname }}>
            registrer deg
          </Link>
        </Paragraph>
      </Pane>
    </PageContainer>
  )
}

export default NotSignedIn
