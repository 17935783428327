import * as React from 'react'
import { useBreakpoint, breakpoints } from '/fiweb/lib'
import { Pane, Heading, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled, { css } from 'styled-components'
import { TextLink } from '/~/components'
import { employees } from './employees'
import { EmployeeCard } from '/fiweb/components/Cards'

const StyledPane = styled(Pane)`
  ${({ theme: { spacing } }) => css`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, 310px);
    grid-column-gap: ${spacing.m};
    justify-items: start;
    grid-row-gap: ${spacing.s};
    margin-bottom: ${spacing.l};
    @media ${breakpoints.large} {
      width: initial;
      grid-template-columns: repeat(2, 285px);
    }
    @media ${breakpoints.medium} {
      margin-bottom: ${spacing.m};
      grid-template-columns: 1fr;
    }
  `}
`

export const Contact = () => {
  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()
  return (
    <Pane
      display='flex'
      paddingX={large ? 25 : 0}
      width='100%'
      maxWidth={1010}
      flexDirection='column'
      justifyContent='space-between'
      alignItems={medium ? 'center' : 'flex-start'}
    >
      <Pane marginBottom={theme.spacing.m} width='100%'>
        <Heading size={medium ? 500 : 600}>Kontakt</Heading>
        <Paragraph size={medium ? 400 : 500}>
          For mer informasjon, send en e-post til{' '}
          <TextLink href='mailto:investor@folkeinvest.no'>investor@folkeinvest.no</TextLink>
        </Paragraph>
      </Pane>
      <StyledPane>
        {employees.map((employee) => (
          <EmployeeCard key={employee.name} {...employee} height={medium ? 356 : 339} width={medium ? 325 : 310} />
        ))}
      </StyledPane>
    </Pane>
  )
}
