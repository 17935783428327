import * as React from 'react'
import { useBreakpoint, breakpoints } from '/fiweb/lib'
import { Pane, Heading, Paragraph, Image } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { TextLink } from '/~/components'
import styled from 'styled-components'
import { config } from '/~/utils/config'

const imageBase = `${config.publicFilesUrl}`

const StyledImage = styled(Image)`
  max-width: 600px;
  height: 500px;
  border-radius: 10px 10px 30px 10px;
  object-fit: cover;
  @media ${breakpoints.large} {
    max-width: fit-content;
    width: 100%;
    height: fit-content;
  }
  @media ${breakpoints.small} {
    width: 100%;
    height: 270px;
  }
`

export const Media = () => {
  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()
  return (
    <Pane
      display='flex'
      paddingX={large ? 25 : 0}
      width='100%'
      maxWidth={1010}
      flexDirection={large ? 'column' : 'row'}
      justifyContent='space-between'
      alignItems={large ? 'flex-start' : 'center'}
      marginBottom={large ? theme.spacing.l : theme.spacing.xl}
    >
      <StyledImage src={`${imageBase}/podcast-hero-image.jpg`} />
      <Pane maxWidth={large ? '100%' : 350} marginTop={large ? theme.spacing.xs : theme.spacing.s}>
        <Heading size={medium ? 600 : 800}>Media og informasjon</Heading>
        <Paragraph size={medium ? 400 : 500}>
          På <TextLink href='https://podcast.folkeinvest.no/podcast'>podcastside</TextLink> finner du de siste
          episodene. Under <TextLink href='/presse'>presse</TextLink> finner du mediaomtale og pressemeldinger.
        </Paragraph>
      </Pane>
    </Pane>
  )
}
