export const numberFormatter = (number: any, maxDigits = 2, minDigits = 0) => {
  if (!Intl) {
    return `${Number(number).toFixed(maxDigits)}`
  }
  if (Number.isNaN(Number(number))) {
    return '-'
  }

  return new Intl.NumberFormat('nb-NO', {
    style: 'decimal',
    useGrouping: true,
    maximumFractionDigits: maxDigits,
    minimumFractionDigits: minDigits,
  }).format(number)
}
