import React from 'react'
import { Pane, Checkbox, Button, Heading, Spinner, Link, Paragraph, Label, Text, TextInput } from 'evergreen-ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { Controller, useForm } from 'react-hook-form'
import { useCustomTheme } from '/fiweb/lib/theme'
import { gtmEventLogin } from '/~/utils/googleTagManager'
import Logo from '/~/static/icons/Logo.svg'
import { LoginSubmitArgs } from '../Auth'
import { AUTH_CONTAINER_HEIGHT } from '../constants'
import { GoogleButton } from '../GoogleButton'
import { InternationalActions } from '../InternationalActions'

interface Props {
  onSubmit: (submitArgs: LoginSubmitArgs) => Promise<
    | undefined
    | {
        success: boolean
        message: any
      }
  >
}

interface FormValues {
  email?: string
  password?: string
  persistentLogin?: boolean
}

const Login = (props: Props) => {
  const [resetPassword, setResetPassword] = React.useState(false)
  const [googleLogin, setGoogleLogin] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [responseMessage, setResponseMessage] = React.useState<string | null>()
  const { spacing } = useCustomTheme()
  const { formatMessage } = useIntl()

  const { formState, handleSubmit, control, reset, register } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
      persistentLogin: true,
    },
  })

  const submit = async ({ email, password, persistentLogin }: FormValues) => {
    if (submitting) {
      return
    }

    setSubmitting(true)

    if (resetPassword) {
      await props.onSubmit({
        mode: 'resetPassword',
        email,
      })
    } else {
      const response = await props.onSubmit({
        mode: 'login',
        email,
        password,
        persistentLogin,
      })

      if (response && !response.success) {
        setResponseMessage(response.message)
      } else {
        gtmEventLogin('email')
      }
    }

    setSubmitting(false)
  }

  const handleGoogleLogin = async (token: string) => {
    const response = await props.onSubmit({
      mode: 'login',
      token,
    })

    if (response && !response.success) {
      setGoogleLogin(false)
      setResponseMessage(response.message)
    } else {
      gtmEventLogin('google')
    }
  }

  return (
    <Pane height={AUTH_CONTAINER_HEIGHT}>
      <Pane
        is='form'
        onSubmit={handleSubmit(submit)}
        display='flex'
        flexDirection='column'
        paddingX={60}
        alignItems='center'
      >
        <Pane width='100%' display='flex' flexDirection='column' alignItems='center'>
          <Pane marginBottom={spacing.m} marginTop={spacing.l}>
            <img height={32.46} src={Logo} alt='Folkeinvest logo' />
            {resetPassword && (
              <Heading size={500} paddingTop={30} textAlign='center' marginBottom={0}>
                <FormattedMessage id='auth.resetPassword' />
              </Heading>
            )}
          </Pane>

          {!googleLogin && (
            <>
              <Pane width='100%' marginBottom={spacing.xs}>
                <Pane marginBottom={formState.errors.email ? spacing.xs : 0} display='flex' flexDirection='column'>
                  <Text fontSize={16} color='danger' lineHeight='0px' position='relative' right={9} top={8}>
                    *
                  </Text>

                  <Pane marginBottom={spacing.xxs}>
                    <Label htmlFor='email-input'>
                      <FormattedMessage id='email' />
                    </Label>
                    <Text size={300}>
                      <FormattedMessage id='auth.isRequired' />
                    </Text>
                  </Pane>

                  <TextInput
                    id='email-input'
                    type='email'
                    height={50}
                    name='email'
                    width='100%'
                    {...register('email', {
                      required: {
                        message: formatMessage({ id: 'auth.invalidEmail' }),
                        value: true,
                      },
                      pattern: {
                        message: formatMessage({ id: 'auth.invalidEmail' }),
                        value: new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/, 'i'),
                      },
                    })}
                  />

                  {formState.errors.email && (
                    <Text textAlign='right' fontSize={14} color='danger' lineHeight='20px' paddingTop={6}>
                      {formState.errors.email.message}
                    </Text>
                  )}
                </Pane>
              </Pane>
              {!resetPassword && (
                <Pane width='100%' marginBottom={spacing.xs} display='flex' flexDirection='column'>
                  <Text fontSize={16} color='danger' lineHeight='0px' position='relative' right={9} top={8}>
                    *
                  </Text>

                  <Pane marginBottom={spacing.xxs}>
                    <Label htmlFor='password-input'>
                      <FormattedMessage id='password' />
                    </Label>
                    <Text size={300}>
                      <FormattedMessage id='auth.isRequired' />
                    </Text>
                  </Pane>

                  <TextInput
                    id='password-input'
                    type='password'
                    height={50}
                    name='password'
                    width='100%'
                    {...register('password', {
                      required: {
                        message: formatMessage({ id: 'auth.noPassword' }),
                        value: true,
                      },
                      minLength: {
                        message: formatMessage({ id: 'auth.passwordTooShort' }),
                        value: 3,
                      },
                    })}
                  />

                  {formState.errors.password && (
                    <Text textAlign='right' fontSize={14} color='danger' lineHeight='20px' paddingTop={6}>
                      {formState.errors.password.message}
                    </Text>
                  )}
                </Pane>
              )}
            </>
          )}
        </Pane>

        {googleLogin ? (
          <Spinner />
        ) : (
          <>
            <Pane display='flex' flexDirection='column' alignItems='center'>
              {!resetPassword && (
                <Pane display='flex' justifyContent='center' alignItems='center' marginBottom={spacing.xxs}>
                  <Controller
                    name='persistentLogin'
                    control={control}
                    render={({ field: { name: _name, value, onChange, ...field } }) => (
                      <Checkbox
                        appearance='default'
                        id='remember-me-stay-logged-in'
                        marginY={0}
                        marginRight={9}
                        checked={value}
                        onChange={onChange}
                        {...field}
                      />
                    )}
                  />
                  <Label htmlFor='remember-me-stay-logged-in' fontWeight={400} size={500}>
                    <FormattedMessage id='auth.rememberMe' />
                  </Label>
                </Pane>
              )}

              <Link
                fontSize={16}
                lineHeight='16px'
                marginBottom={resetPassword ? spacing.s : spacing.m}
                marginTop={resetPassword ? spacing.m : 0}
                onClick={() => {
                  reset()
                  setResetPassword(!resetPassword)
                }}
                cursor='pointer'
              >
                {resetPassword ? (
                  <FormattedMessage id='auth.goToLogin' />
                ) : (
                  <FormattedMessage id='auth.forgotPassword' />
                )}
              </Link>
            </Pane>

            <Pane display='flex' flexDirection='column' alignItems='center'>
              <Button
                disabled={submitting || Object.keys(formState.errors).length > 0}
                appearance='primary'
                size='small'
                width='100%'
                type='submit'
              >
                {resetPassword ? <FormattedMessage id='auth.resetPassword' /> : <FormattedMessage id='auth.login' />}
              </Button>

              {!resetPassword && (
                <GoogleButton
                  onButtonClicked={() => setGoogleLogin(true)}
                  onClose={() => setGoogleLogin(false)}
                  onError={() => setGoogleLogin(false)}
                  onSuccess={handleGoogleLogin}
                  disabled={submitting}
                  marginTop={spacing.xs}
                />
              )}
            </Pane>

            {responseMessage && (
              <Paragraph fontSize={14} color='danger' marginTop={spacing.xxs} lineHeight='20px'>
                {responseMessage || <span>&nbsp;</span>}
              </Paragraph>
            )}
          </>
        )}
      </Pane>
      <InternationalActions actionText={formatMessage({ id: 'auth.login' })} />
    </Pane>
  )
}

export default Login
