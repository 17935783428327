import React from 'react'
import { Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { GraphQLError } from 'graphql'
import { ApolloError } from '@apollo/client'

interface Props {
  error?: GraphQLError | ApolloError
}

const ErrorDataAuth = (props: Props) => {
  const theme = useCustomTheme()
  return (
    <Pane maxWidth='1400px' marginY={0} marginX='auto' padding={8}>
      <Pane marginY={theme.spacing.xs}>
        <Heading is='h4' size={500}>
          Error
        </Heading>
        <Heading is='h5' size={400}>
          Noe feil har skjedd
        </Heading>
        <Paragraph size={300}>{props.error ? (props.error.message ? props.error.message : null) : null}</Paragraph>
      </Pane>
    </Pane>
  )
}

export default ErrorDataAuth
