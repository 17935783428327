import * as React from 'react'
import { Button, Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props {
  resumeRegistration: () => void
  onClose: () => void
}

const UserReturned = ({ resumeRegistration, onClose }: Props) => {
  const theme = useCustomTheme()
  return (
    <Pane display='flex' flexDirection='column' maxWidth={380} marginTop={theme.spacing.l} alignItems='center'>
      <Heading is='h3'>Profil finnes allerede</Heading>

      <Paragraph size={300} textAlign='center'>
        Hvordan vil du fortsette?
      </Paragraph>

      <Button
        onClick={resumeRegistration}
        marginTop={theme.spacing.m}
        size='small'
        appearance='primary'
        maxWidth={244}
        width='100%'
      >
        Fullfør profil
      </Button>

      <Button onClick={onClose} marginTop={theme.spacing.xs} size='small' maxWidth={244} width='100%'>
        Lukk registreringen
      </Button>
    </Pane>
  )
}

export default UserReturned
