import React, { useEffect } from 'react'
import moment from 'moment'
import { Helmet } from '/~/components'
import { Loading } from '/fiweb/components'
import { useParams } from 'react-router-dom'
import { NewerCampaignExistsWarning } from '/~/components/campaign/NewerCampaignExistsWarning'
import {
  CampaignUpdatedDocument,
  CampaignUpdatedSubscription,
  CampaignUpdatedSubscriptionVariables,
  FetchCampaignQuery,
  ReservationsSummaryUpdatedForCampaignPageDocument,
  ReservationsSummaryUpdatedForCampaignPageSubscription,
  ReservationsSummaryUpdatedForCampaignPageSubscriptionVariables,
  useFetchCampaignQuery,
  useIncrementCampaignViewsMutation,
} from '/~/types/graphql'
import { SubscribeToMoreOptions } from '@apollo/client'
import Campaign from '/~/components/campaign/Campaign'
import { Page404 } from '/~/pages'
import { useGoogleTagManagerCampaignPageInView } from '/~/utils/googleTagManager'

type CampaignUpdatedSubToMoreOptions = SubscribeToMoreOptions<
  FetchCampaignQuery,
  CampaignUpdatedSubscriptionVariables,
  CampaignUpdatedSubscription
>
type ReservationsSummaryUpdatedSubToMoreOptions = SubscribeToMoreOptions<
  FetchCampaignQuery,
  ReservationsSummaryUpdatedForCampaignPageSubscriptionVariables,
  ReservationsSummaryUpdatedForCampaignPageSubscription
>

const CampaignPage = () => {
  const params = useParams<{ campaignSlug: string }>()
  const { data, error, subscribeToMore, loading, refetch } = useFetchCampaignQuery({
    variables: {
      slug: params?.campaignSlug,
    },
  })

  const [incViewCounter] = useIncrementCampaignViewsMutation()
  const campaign = data?.Campaign

  useGoogleTagManagerCampaignPageInView(campaign)

  useEffect(() => {
    if (campaign) {
      const unsub = subscribeToMore({
        document: ReservationsSummaryUpdatedForCampaignPageDocument,
        variables: { campaignId: campaign._id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }

          const oldRs = prev?.Campaign?.reservationsSummary
          const newRs = subscriptionData?.data?.reservationsSummaryUpdated

          if (newRs?._id === oldRs?._id) {
            return {
              Campaign: { ...prev.Campaign, reservationsSummary: newRs },
            }
          }

          return prev
        },
      } as ReservationsSummaryUpdatedSubToMoreOptions)
      return () => unsub()
    }
  }, [data])

  useEffect(() => {
    if (campaign) {
      const unsub = subscribeToMore({
        document: CampaignUpdatedDocument,
        variables: { campaignId: campaign._id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }
          return {
            Campaign: {
              ...prev.Campaign,
              ...subscriptionData.data.campaignUpdated,
            },
          }
        },
      } as CampaignUpdatedSubToMoreOptions)
      return () => unsub()
    }
  }, [data])

  const setTimer = () => {
    const start = moment(campaign.startDate)
    const close = moment(campaign.closeDate)
    const now = moment()
    const tenDaysFromNow = moment().add(10, 'days')
    if (start.diff(now) > 0 && start.diff(tenDaysFromNow) < 0) {
      return setTimeout(refetch, start.diff(moment()))
    }
    if (close.diff(now) > 0 && close.diff(tenDaysFromNow) < 0) {
      return setTimeout(refetch, close.diff(moment()))
    }
  }

  useEffect(() => {
    if (campaign) {
      const timer = setTimer()
      return () => clearTimeout(timer)
    }
  }, [campaign?.startDate, campaign?.publishDate, campaign?.closeDate])

  useEffect(() => {
    const slug = params?.campaignSlug
    if (slug) {
      try {
        let visitedCampaigns = {}
        const stored = window.localStorage.getItem('visitedCampaigns')
        if (stored) {
          visitedCampaigns = JSON.parse(stored)
        }
        const keys = Object.keys(visitedCampaigns)
        const alreadyVisited = !!keys.includes(slug)
        if (!alreadyVisited || moment(visitedCampaigns[slug]).add(1, 'days').isBefore()) {
          visitedCampaigns[slug] = moment().valueOf()
          incViewCounter({ variables: { slug } })
          window.localStorage.setItem('visitedCampaigns', JSON.stringify(visitedCampaigns))
        }
      } catch (_e) {
        /* Browser doesn't support localStorage */
      }
    }
  }, [])

  if (loading) {
    return <Loading />
  }

  if (!data?.Campaign || error) {
    return <Page404 archived={error?.message?.includes('ARCHIVED')} />
  }

  return (
    <div>
      <Helmet
        title={`${campaign.title} investeringstilbud | Folkeinvest `}
        description={campaign.caption}
        url={`${window.location.origin}/kampanje/${campaign.slug}`}
        imageUrl={campaign.coverImage?.url}
        hide={!!campaign.isOutdated}
      />

      <Campaign campaign={campaign} />

      <NewerCampaignExistsWarning campaignId={campaign._id} />
    </div>
  )
}

export default CampaignPage
