import moment from 'moment'

export const getServerTimeOffset = () => {
  return 0
}

const setOffset = (timestamp?: number) => {
  if (!timestamp || Number.isNaN(timestamp)) {
    return null
  }
  try {
    const offset = new Date(Number(timestamp)).getTime() - Date.now()

    moment.now = () => {
      return offset + Date.now()
    }
  } catch (e) {
    console.log('Errored?', e)
  }
}

export { setOffset }
