import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Button, Heading, Small, Pane, Paragraph, Strong, toaster } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import NavButtons from './NavButtonsComponent'
import StatusDoneComponent from './StatusDoneComponent'
import { useRequestEmailVerificationMutation } from '/~/types/graphql'
import { useAuthContext } from '/~/utils/AuthContext'

interface Props {
  goBack: () => void
  goForward: () => void
}

const VerifyEmailStep = ({ goBack, goForward }: Props) => {
  const { small } = useBreakpoint()
  const { user, startPolling, stopPolling } = useAuthContext()
  const theme = useCustomTheme()

  const [sendNewEmail] = useRequestEmailVerificationMutation()

  React.useEffect(() => {
    startPolling(1000)

    return () => stopPolling()
  }, [])

  React.useEffect(() => {
    if (user?.emailVerified) {
      stopPolling()
    }
  }, [user.emailVerified])

  const handleNewVerification = async () => {
    try {
      const { data } = await sendNewEmail()

      if (data?.requestEmailVerification) {
        toaster.success('Ny epost sendt')
      } else {
        throw new Error('Noe gikk galt')
      }
    } catch (_err) {
      toaster.danger('Noe gikk galt, oppfrisk siden og prøv igjen eller kontakt support')
    }
  }

  const renderNotVerified = () => {
    return (
      <>
        <Paragraph size={300}>Vi har sendt en epost til følgende adresse:</Paragraph>
        <Strong size={300}>{user.email}</Strong>
        <Paragraph size={300}>Følg instruksjonene i den, så er du i gang.</Paragraph>

        <Pane boxSizing='border-box' borderTop marginY={theme.spacing.s} width='100%' />

        <Heading is='h4' size={400}>
          Finner du ikke e-posten?
        </Heading>

        <Button size='small' marginY={theme.spacing.xs} onClick={handleNewVerification}>
          Send e-post på nytt
        </Button>

        <Paragraph size={300}>
          <Small>Sjekk om den har havnet i søppelpost</Small>
        </Paragraph>
        <Paragraph size={300}>
          <Small>Forsøk å sende e-posten på nytt</Small>
        </Paragraph>
      </>
    )
  }

  const renderVerified = () => {
    return (
      <Pane width='100%' marginTop={theme.spacing.s} paddingX={small ? 20 : 0}>
        <StatusDoneComponent hidden={!user?.emailVerified} title='E-post' description='Verifisert' />
      </Pane>
    )
  }

  return (
    <Pane
      display='flex'
      flexDirection='column'
      maxWidth={380}
      width='100%'
      marginTop={theme.spacing.l}
      alignItems='center'
      height='100%'
    >
      <Heading is='h3'>Verifiser e-post</Heading>

      {user?.emailVerified ? renderVerified() : renderNotVerified()}

      <NavButtons onSave={goForward} onBack={goBack} disabled={!user?.emailVerified} />
    </Pane>
  )
}

export default VerifyEmailStep
