import { Pane } from 'evergreen-ui'
import * as React from 'react'
import { PictureSun } from '/fiweb/components/Icons'
import { opacifyHexColor } from '/fiweb/lib/opacifyHexColor'
import { useCustomTheme } from '/fiweb/lib/theme'

export const MissingImage = () => {
  const { colors } = useCustomTheme()

  return (
    <Pane backgroundColor='white' width='100%'>
      <Pane
        width='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        style={{ aspectRatio: '360 / 225' }}
        background={`linear-gradient(180deg, ${opacifyHexColor(colors.blue500, 0.1)} 0%, ${opacifyHexColor(
          colors.blue500,
          0.05,
        )} 100%)`}
        border={`1px solid ${opacifyHexColor(colors.blue500, 0.2)}`}
        borderTopLeftRadius={10}
        borderTopRightRadius={10}
      >
        <PictureSun color={colors.campaignCard.missingImageIcon} height={46} width={46} />
      </Pane>
    </Pane>
  )
}
