import * as React from 'react'
import _ from 'lodash'
import { Pane, Paragraph, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { SelfreportItem as SelfreportItemType } from '/~/types/graphql'
import { RadioItem, RadioWithDescriptionItem, CheckboxItem, BooleanItem, NumberItem } from './SelfreportItems'

const items = {
  radio: RadioItem,
  radio_with_description: RadioWithDescriptionItem,
  checkbox: CheckboxItem,
  boolean: BooleanItem,
  number: NumberItem,
}
import { ItemComment } from './ItemComment'
interface SelfReportItemProps {
  item: SelfreportItemType
  no: number
}
export const SelfreportItem = ({ item, no }: SelfReportItemProps) => {
  const { type, name, description, comment, values } = item
  const theme = useCustomTheme()
  const Item = items[type]
  return (
    <Pane marginBottom={theme.spacing.xs}>
      <Paragraph fontWeight={600}>
        {no}. {name}
      </Paragraph>
      <Text size={300}>{description}</Text>
      <Pane fontStyle='italic'>
        <Item item={item} />
      </Pane>
      {comment && <ItemComment item={item} />}
      {!values && <Text>Svar ikke avgitt</Text>}
    </Pane>
  )
}
