import * as React from 'react'
import { breakpoints } from '/fiweb/lib'
import { Pane, PaneProps } from 'evergreen-ui'
import styled, { css } from 'styled-components'

const StyledPane = styled(Pane)`
  ${({ theme: { spacing }, width }) => css`
    position: relative;
    width: ${width || '617px'};
    min-height: 317px;
    order: 2;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @media ${breakpoints.large} {
      margin-bottom: ${spacing.m};
      width: 100%;
      min-height: 400px;
    }

    @media ${breakpoints.medium} {
      min-height: 300px;
    }

    @media ${breakpoints.small} {
      min-height: 182px;
    }
  `}
`

interface Props {
  url: string
  width?: number | string
  height?: number | string
  restProps?: PaneProps
}

export const IframeVideoContainer = ({ url, width, height, restProps }: Props) => {
  return (
    <StyledPane {...restProps} width={width} height={height}>
      <iframe
        src={url}
        title='video player'
        style={{ border: 0 }}
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </StyledPane>
  )
}
