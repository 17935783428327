import * as React from 'react'
import { NewApiFollowDialogWrapper } from './NewApiFollowDialogWrapper'
import { OldApiFollowDialogWrapper } from './OldApiFollowDialogWrapper'

interface Props {
  onClose: (didFollow?: boolean) => void
  open: boolean
}

type ProcessIdType =
  | {
      campaignId: string
      emissionProcessId?: never
    }
  | {
      campaignId?: never
      emissionProcessId: string
    }

export const FollowDialog = ({ campaignId, emissionProcessId, open, onClose }: Props & ProcessIdType) => {
  return campaignId ? (
    <OldApiFollowDialogWrapper open={open} onClose={onClose} campaignId={campaignId} />
  ) : emissionProcessId ? (
    <NewApiFollowDialogWrapper open={open} onClose={onClose} emissionProcessId={emissionProcessId} />
  ) : null
}
