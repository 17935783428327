import * as React from 'react'
import { Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import { FormattedMessage, useIntl } from 'react-intl'
import { StatisticsView } from '/~/components/StatisticsView'

export const JoinTheJourney = () => {
  const { spacing } = useCustomTheme()
  const { small } = useBreakpoint()
  const intl = useIntl()

  return (
    <Pane width='100%' marginTop={spacing.l} marginBottom={spacing.l}>
      <Heading is='h2' size={600}>
        <FormattedMessage id='entrepreneurLanding.statsHeading' />
      </Heading>
      <Paragraph size={small ? 400 : 500} marginBottom={spacing.m}>
        <FormattedMessage id='entrepreneurLanding.statsSubtitle' />
      </Paragraph>
      <Pane display='flex' justifyContent='center' width='100%'>
        <StatisticsView
          cardCustomMarginBottom='16px'
          cardCustomSize={{ medium: 325, small: 325 }}
          labels={{
            raised: intl.formatMessage({ id: 'entrepreneurLanding.raisedCapitalHeading' }),
            raisedCurrentYear: intl.formatMessage({
              id: 'entrepreneurLanding.raisedCapitalYTDHeading',
            }),
            investors: intl.formatMessage({ id: 'entrepreneurLanding.registeredInvestorsHeading' }),
            campaigns: intl.formatMessage({ id: 'entrepreneurLanding.totalCampaignsHeading' }),
            campaignsCurrentYear: intl.formatMessage({
              id: 'entrepreneurLanding.campaignYTDHeading',
            }),
            companies: intl.formatMessage({
              id: 'entrepreneurLanding.registeredInvestmentFirmsHeading',
            }),
          }}
        />
      </Pane>
    </Pane>
  )
}
