export interface IconProps extends React.SVGProps<SVGSVGElement> {
  title?: string
}

export enum IconDirection {
  UP = -90,
  DOWN = 90,
  LEFT = 180,
  RIGHT = 0,
  LEFT_UP = -135,
  RIGHT_UP = -45,
  LEFT_DOWN = 135,
  RIGHT_DOWN = 45,
}
