import * as React from 'react'
import { IconProps } from './types'

export const TwitterIcon = ({ title, ...rest }: IconProps) => (
  <svg viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path
      d='M28.017 9.04002C27.1345 9.44186 26.1845 9.71361 25.188 9.83502C26.2057 9.21059 26.9866 8.22044 27.3544 7.04094C26.4015 7.61913 25.3486 8.03975 24.2238 8.26525C23.3272 7.28377 22.046 6.6709 20.6279 6.6709C17.9089 6.6709 15.7029 8.93306 15.7029 11.7228C15.7029 12.1189 15.7452 12.5034 15.8297 12.8734C11.7349 12.6624 8.10524 10.6532 5.67372 7.5931C5.24943 8.34186 5.00698 9.21059 5.00698 10.1357C5.00698 11.8876 5.8767 13.4342 7.19888 14.3405C6.39259 14.3159 5.63142 14.0862 4.9661 13.7104V13.7725C4.9661 16.2211 6.66464 18.2636 8.91997 18.7262C8.50697 18.8446 8.0714 18.9039 7.62175 18.9039C7.3046 18.9039 6.99449 18.8736 6.69425 18.8142C7.32151 20.8205 9.13987 22.282 11.2965 22.3209C9.61067 23.6769 7.48502 24.4848 5.17754 24.4848C4.78004 24.4848 4.38677 24.4617 4.00195 24.4155C6.18257 25.8465 8.77337 26.6834 11.5545 26.6834C20.6181 26.6834 25.5728 18.9863 25.5728 12.3097C25.5728 12.09 25.5685 11.8702 25.5601 11.6549C26.5228 10.9423 27.3587 10.0533 28.017 9.04002Z'
      fill='currentColor'
    />
  </svg>
)
