import * as React from 'react'
import { IconProps } from './types'

export const Lock = ({ title, ...rest }: IconProps) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.0031 10.3323H16.5028V8.49781C16.5028 6.01097 14.4868 3.995 12 3.995C9.51318 3.995 7.4972 6.01097 7.4972 8.49781V10.3323H6.99689C6.26005 10.3323 5.66272 10.9296 5.66272 11.6665V18.6708C5.66272 19.4077 6.26005 20.005 6.99689 20.005H17.0031C17.74 20.005 18.3373 19.4077 18.3373 18.6708V11.6665C18.3373 10.9296 17.74 10.3323 17.0031 10.3323ZM10.6658 14.3348C10.6629 13.6866 11.1263 13.1301 11.7644 13.0156C12.4024 12.9011 13.0304 13.2618 13.253 13.8705C13.4756 14.4793 13.2285 15.1601 12.6671 15.4842V17.0031C12.6671 17.3715 12.3684 17.6702 12 17.6702C11.6316 17.6702 11.3329 17.3715 11.3329 17.0031V15.4842C10.9214 15.2475 10.6672 14.8095 10.6658 14.3348ZM12 5.6627C10.4342 5.6627 9.16491 6.93202 9.16491 8.49781V9.99874C9.16491 10.183 9.31424 10.3323 9.49845 10.3323H14.5016C14.6858 10.3323 14.8351 10.183 14.8351 9.99874V8.49781C14.8351 6.93202 13.5658 5.6627 12 5.6627Z'
      fill='currentColor'
    />
  </svg>
)
