import { Pane, PaneProps, Spinner } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import * as React from 'react'
import { useBreakpoint } from '../fiweb/lib'
import { StatisticCard } from '../pages/Statistic/StatisticCard'
import { useGetStatisticsPageDataFromIssuerApiQuery } from '../types/issuer-graphql'
import { client } from '../lib/issuer-client/client'

export interface StatsLabelsProps {
  raised: string
  raisedCurrentYear: string
  investors: string
  campaigns: string
  campaignsCurrentYear: string
  companies: string
}

interface Props {
  labels?: StatsLabelsProps
  cardCustomMarginBottom?: string
  cardCustomSize?: {
    small: number
    medium: number
  }
}

export const StatisticsView = ({ labels, cardCustomMarginBottom, cardCustomSize, ...rest }: Props & PaneProps) => {
  const { small, medium, large } = useBreakpoint()
  const theme = useCustomTheme()
  const { data, error, loading } = useGetStatisticsPageDataFromIssuerApiQuery({ client })
  const { raised, campaigns, companies, investors, campaignsThisYear, raisedThisYear } = data?.statisticsPageData || {}

  return (
    <Pane
      display='flex'
      flexDirection={small ? 'column' : 'row'}
      justifyContent='space-between'
      flexWrap='wrap'
      width={medium ? 325 : large ? 700 : '100%'}
      maxWidth={1010}
      {...rest}
    >
      <StatisticCard
        customMarginBottom={cardCustomMarginBottom}
        cardCustomSize={cardCustomSize}
        heading={labels?.raised || 'Tilrettelagt\nkapital:'}
        backgroundColor={theme.colors.fiBlue10}
        unit={error ? null : 'MNOK'}
      >
        {loading ? <Spinner /> : error ? '-' : Math.floor(raised / 1_000_000_00)}
      </StatisticCard>
      <StatisticCard
        customMarginBottom={cardCustomMarginBottom}
        cardCustomSize={cardCustomSize}
        heading={labels?.investors || 'Registrerte\ninvestorer:'}
        backgroundColor={theme.colors.fiGreen10}
      >
        {loading ? <Spinner /> : error ? '-' : investors}
      </StatisticCard>
      <StatisticCard
        customMarginBottom={cardCustomMarginBottom}
        cardCustomSize={cardCustomSize}
        heading={labels?.campaigns || 'Antall\nemisjoner:'}
        backgroundColor={theme.colors.fiBlue10}
      >
        {loading ? <Spinner /> : error ? '-' : campaigns}
      </StatisticCard>
      <StatisticCard
        customMarginBottom={cardCustomMarginBottom}
        cardCustomSize={cardCustomSize}
        heading={labels?.raisedCurrentYear || 'Tilrettelagt kapital\ninneværende år:'}
        backgroundColor={theme.colors.fiGreen10}
        unit={error ? null : 'MNOK'}
      >
        {loading ? <Spinner /> : error ? '-' : Math.floor(raisedThisYear / 1_000_000_00)}
      </StatisticCard>
      <StatisticCard
        customMarginBottom={cardCustomMarginBottom}
        cardCustomSize={cardCustomSize}
        heading={labels?.companies || 'Registrerte\ninvesteringsselskap:'}
        backgroundColor={theme.colors.fiBlue10}
      >
        {loading ? <Spinner /> : error ? '-' : companies}
      </StatisticCard>
      <StatisticCard
        customMarginBottom={cardCustomMarginBottom}
        cardCustomSize={cardCustomSize}
        heading={labels?.campaignsCurrentYear || 'Antall emisjoner\ninneværende år:'}
        backgroundColor={theme.colors.fiGreen10}
      >
        {loading ? <Spinner /> : error ? '-' : campaignsThisYear}
      </StatisticCard>
    </Pane>
  )
}
