import React, { useState } from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Button, Heading, Pane, Paragraph, Link } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { config } from '/~/utils/config'
import { useNavigate } from 'react-router'
import { BookMeetingDialog } from '/~/components/hubspot'

interface Props {
  title?: React.ReactNode
  description?: React.ReactNode
  buttonText?: React.ReactNode
  url?: string
}

export const InfoMeetingBooker = ({ title, description, buttonText }: Props) => {
  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()
  const navigate = useNavigate()
  const [showingBookingDialog, setShowingBookingDialog] = useState(false)

  return (
    <>
      <BookMeetingDialog open={showingBookingDialog} onClose={() => setShowingBookingDialog(false)} />
      <Pane
        display='flex'
        flexDirection={large ? 'column' : 'row'}
        backgroundColor='white'
        width='100%'
        height={large ? 611 : 438}
        justifyContent='center'
        alignItems='center'
      >
        <Pane
          is='div'
          borderRadius='50%'
          backgroundImage={`url(${config.publicFilesUrl}/aboutus/team/Maria.jpg)`}
          backgroundSize='cover'
          backgroundPosition='top'
          height='183px'
          width='183px'
        />
        <Pane
          display='flex'
          flexDirection='column'
          maxWidth={594}
          width='100%'
          alignItems={large ? 'center' : 'start'}
          marginLeft={large ? 0 : 30}
          marginTop={large ? theme.spacing.s : 0}
        >
          <Heading
            size={500}
            marginBottom={theme.spacing.xs}
            width={medium ? '86.67%' : 'fit-content'}
            textAlign={medium ? 'center' : 'start'}
          >
            {title || 'Ønsker du å hente penger med Folkeinvest?'}
          </Heading>
          <Paragraph
            size={400}
            marginBottom={theme.spacing.s}
            width={medium ? '86.67%' : 'fit-content'}
            textAlign={large ? 'center' : 'start'}
          >
            {description || (
              <>
                Book et møte med meg nedenfor eller fyll ut vårt{' '}
                <Link cursor='pointer' onClick={() => navigate('/bli-kontaktet?navOrigin=mariaSection')}>
                  kontaktskjema
                </Link>
                , så tar vi en prat om din bedrift. Sammen kan vi finne ut hvorvidt vår plattform er en god arena for
                dere!
              </>
            )}
          </Paragraph>
          <Button appearance='primary' width='auto' size='small' onClick={() => setShowingBookingDialog(true)}>
            {buttonText || 'Ta en prat med oss'}
          </Button>
        </Pane>
      </Pane>
    </>
  )
}

export default InfoMeetingBooker
