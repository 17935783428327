import * as React from 'react'
import { Button, Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'

interface Props {
  onSave?: () => void
  onBack: () => void
  disabled?: boolean
}

const NavButtons = ({ onSave, onBack, disabled }: Props) => {
  const { small } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Pane
      display='flex'
      flexFlow='row nowrap'
      width='100%'
      justifyContent='space-between'
      marginBottom={theme.spacing.l}
      marginTop='auto'
      paddingX={small ? 20 : 0}
    >
      <Button onClick={onBack} marginTop={theme.spacing.xxs} size='small' maxWidth={180} width='100%'>
        Tilbake
      </Button>

      <Button
        type='submit'
        onClick={onSave}
        marginY={theme.spacing.xxs}
        marginLeft={10}
        disabled={disabled}
        size='small'
        appearance='primary'
        maxWidth={180}
        width='100%'
      >
        Neste steg
      </Button>
    </Pane>
  )
}

export default NavButtons
