import * as React from 'react'
import { IconProps } from './types'

export const Underline30 = ({ title, ...rest }: IconProps) => (
  <svg width='44' height='5' viewBox='0 0 44 5' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path
      d='M44 3.98214C44 3.98214 12.1357 3.7763 0 5V0.72497C17.1821 -0.250612 26.8177 -0.232659 44 0.72497V3.98214Z'
      fill='currentColor'
      fillOpacity='0.5'
    />
  </svg>
)
