import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useBreakpoint } from '/fiweb/lib'
import { Campaign } from '/~/types/graphql'
import Logo from '/~/static/icons/Logo.svg'
import { Pane, Heading, Paragraph, Button, Text, Link } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'

type CampaignType = { __typename?: 'Campaign' } & Pick<Campaign, 'title' | '_id' | 'slug'>

interface Props {
  campaign: CampaignType
}

const PreorderLogin = ({ campaign }: Props) => {
  const { small } = useBreakpoint()
  const theme = useCustomTheme()

  React.useEffect(() => {
    if (localStorage && campaign) {
      const item = localStorage.getItem('approved_for_preorder')
      const list = item ? JSON.parse(item) : []
      if (!list.includes(campaign?._id)) {
        list.push(campaign?._id)
      }
      localStorage.setItem('approved_for_preorder', JSON.stringify(list))
    }
  }, [campaign])

  return (
    <Pane
      minHeight='100vh'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      backgroundColor={theme.colors.fiDark}
    >
      <Pane
        display='flex'
        flexDirection='column'
        paddingX={small ? 18 : 81}
        alignItems='center'
        width={small ? 325 : 500}
        backgroundColor='#FFFFFF'
        borderRadius={10}
      >
        <Pane
          width='100%'
          display='flex'
          flexDirection='column'
          alignItems='center'
          marginBottom={theme.spacing.m}
          marginTop={theme.spacing.l}
        >
          <img height={32.46} src={Logo} alt='Folkeinvest logo' />
          <Heading size={500} textAlign='center' marginTop={theme.spacing.m} marginBottom={theme.spacing.xs}>
            Forhåndstegning i
          </Heading>
          <Paragraph size={500} textTransform='uppercase' marginBottom={theme.spacing.xs}>
            {campaign?.title}
          </Paragraph>
          <hr style={{ width: '100%', border: `1px solid ${theme.colors.fiDark20}` }} />
          <Paragraph
            fontSize={14}
            lineHeight='20px'
            marginTop={theme.spacing.xs}
            marginBottom={small ? theme.spacing.xs : theme.spacing.m}
          >
            For å kunne forhåndstegne må du ha en fullført profil på folkeinvest.no
            <br />
            <br />
            Vennligst logg inn eller opprett profil - dersom du ønsker betenkningstid kan du{' '}
            <Link fontSize={14} lineHeight='20px' href={`/kampanje/${campaign?.slug}`}>
              ta en titt på investeringstilbudet
            </Link>{' '}
            før du oppretter profil.
            <br />
            <br />
            Etter du logger inn/oppretter profil, vil profilen automatisk være godkjent for forhåndstegning.
            <br />
            <br />
            <Text fontSize={14} fontWeight={600} lineHeight='20px'>
              Dine forhåndstegningsmuligheter vil alltid være synlige under “Invester nå” i hovedmenyen.
            </Text>
          </Paragraph>
          <Pane display='flex' flexDirection={small ? 'column' : 'row'} justifyContent='space-between' width='100%'>
            <Button
              is={RouterLink}
              to={{
                pathname: '/innlogging',
              }}
              state={{ redirectToAfterAuth: window.location.pathname }}
              size='small'
              minWidth={152}
              marginBottom={small ? theme.spacing.xs : 0}
            >
              Logg inn
            </Button>
            <Button
              is={RouterLink}
              to='/innlogging/registrering'
              state={{
                fromPreorder: campaign.slug,
              }}
              size='small'
              minWidth={168}
              appearance='primary'
            >
              Opprett profil
            </Button>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}

export default PreorderLogin
