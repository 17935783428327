import { Button, Checkbox, Dialog, Heading, Image, Pane, Paragraph, Text, TextInputField } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { verifyEmail } from '/~/utils/verifications'
import { useAuthContext } from '/~/utils/AuthContext'

interface Props {
  open: boolean
  onClose: (didFollow: boolean) => void
  onRegister: (email: string, acceptsPreorder: boolean) => Promise<void>
  onUnregister: () => Promise<void>
  isFollowing: boolean
  approvedForPreorder: boolean
  acceptsPreorderOffers: boolean
  title: string
  coverImageUrl?: string
}

export const ActualFollowDialog = ({
  open,
  onClose,
  acceptsPreorderOffers,
  approvedForPreorder,
  onRegister,
  onUnregister,
  isFollowing,
  title,
  coverImageUrl,
}: Props) => {
  const theme = useCustomTheme()
  const { medium } = useBreakpoint()
  const [preorderChecked, setPreorderChecked] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState(null)
  const [brokenImgUrl, setBrokenImgUrl] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const { user, loading } = useAuthContext()

  React.useEffect(() => {
    if (!loading) {
      setPreorderChecked(!!acceptsPreorderOffers)
      setEmail(user?.email || '')
    }
  }, [acceptsPreorderOffers, user, loading])

  const getError = (value?: string) => {
    const trimmedEmail = value?.trim()
    if (!trimmedEmail) {
      return 'Fyll inn epostadresse'
    }
    if (!verifyEmail(trimmedEmail)) {
      return 'Ugyldig epostadresse'
    }
    return null
  }

  const handleRegister = async () => {
    try {
      await onRegister(email, preorderChecked)
    } catch (_e) {
      setErrorMsg('En feil oppsto - kontakt support')
      return
    } finally {
      onClose(!user)
    }
  }

  const handleUnRegister = async () => {
    try {
      await onUnregister()
      onClose(false)
    } catch (_e) {
      setErrorMsg('En feil oppsto - kontakt support')
    }
  }

  const hasChangedPreorderStatus = () => {
    return user && isFollowing && acceptsPreorderOffers !== preorderChecked
  }

  return (
    <Dialog
      isShown={open}
      width={500}
      hasHeader={false}
      hasCancel={false}
      hasClose={false}
      preventBodyScrolling
      onCloseComplete={() => open && onClose(false)}
      hasFooter={false}
      containerProps={{
        borderRadius: 10,
        overflow: 'hidden',
      }}
      contentContainerProps={{
        padding: 0,
        position: 'relative',
        backgroundColor: 'none',
      }}
      {...(medium ? { topOffset: 0, sideOffset: 0 } : {})}
    >
      {brokenImgUrl ? (
        <Pane
          width='100%'
          height={238}
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
          backgroundColor={theme.colors.fiDark20}
        >
          <Paragraph>
            <i>Bilde utilgjengelig</i>
          </Paragraph>
        </Pane>
      ) : (
        <Image src={coverImageUrl} width='100%' style={{ objectFit: 'cover' }} onError={() => setBrokenImgUrl(true)} />
      )}
      <Pane paddingY={medium ? 20 : 40} paddingX={medium ? 25 : 50} height='100%'>
        <Heading is='h3' fontSize='24px' lineHeight='29px' fontWeight='bold'>
          {title}
        </Heading>
        <Paragraph
          fontSize='16px'
          lineHeight='24px'
          fontStyle='normal'
          fontWeight='normal'
          marginBottom={theme.spacing.s}
        >
          Registrer din interesse ved å sette en påminnelse for åpning av emisjonens tegningsvindu.
        </Paragraph>
        <Pane height={125}>
          <TextInputField
            label={
              <Text fontSize='16px' fontWeight={600}>
                <Text color='red' marginLeft={-11} marginRight={1}>
                  *
                </Text>
                Eposten vil bli sendt til følgende adresse:
              </Text>
            }
            placeholder='ola.nordmann@eksempel.no'
            inputHeight={50}
            width='100%'
            value={email}
            disabled={!!user}
            tabIndex={0}
            onChange={(e) => {
              setErrorMsg(null)
              setEmail(e.target.value)
            }}
            type='email'
            validationMessage={errorMsg}
          />
        </Pane>
        <Checkbox
          disabled={approvedForPreorder}
          checked={preorderChecked || approvedForPreorder}
          onChange={(e) => setPreorderChecked(e.target.checked)}
          display='flex'
          flexDirection='row'
          alignItems='baseline'
          label={
            <Paragraph marginLeft={5} fontSize='16px'>
              Jeg ønsker å bli kontaktet av selskapet for mulighet for forhåndstegning *
            </Paragraph>
          }
        />
        <Paragraph color='muted' fontSize='14px' marginTop={theme.spacing.s} lineHeight='20px'>
          * Selskapet vil få mulighet til å se navn og epost-adresse dersom denne eposten har en tilknyttet konto på
          folkeinvest.no
        </Paragraph>
        <Pane display='flex' flexDirection='row' justifyContent='flex-end' marginTop={theme.spacing.s}>
          <Button size='small' marginRight={20} onClick={() => onClose(false)}>
            Lukk
          </Button>
          <Button
            size='small'
            appearance='primary'
            disabled={!!errorMsg}
            onClick={() => {
              if (getError(email)) {
                setErrorMsg(getError(email))
                return
              }
              if (hasChangedPreorderStatus() || !isFollowing) {
                handleRegister()
              } else {
                handleUnRegister()
              }
            }}
          >
            {hasChangedPreorderStatus() ? 'Oppdater' : isFollowing ? 'Avregistrer' : 'Registrer'}
          </Button>
        </Pane>
      </Pane>
    </Dialog>
  )
}
