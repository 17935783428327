import * as React from 'react'

type BackToTopType = {
  ref?: HTMLElement
  setRef: (ref: HTMLElement) => void
  clearRef: () => void
}

export const context = React.createContext<BackToTopType>({} as BackToTopType)

interface Props {
  children: React.ReactNode
}

export const BackToTopProvider = ({ children }: Props) => {
  const [ref, setRef] = React.useState<HTMLElement | undefined>()

  const value = {
    ref,
    setRef: (ref: HTMLElement) => setRef(ref),
    clearRef: () => setRef(undefined),
  }

  return <context.Provider value={value}>{children}</context.Provider>
}

export const useBackToTop = () => {
  if (!context) {
    throw new Error('useBackToTop must be used within an BackToTopProvider')
  }
  const data = React.useContext(context)

  React.useEffect(() => data.clearRef, [])
  return data
}
