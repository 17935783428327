import * as React from 'react'
import { useBreakpoint, breakpoints } from '/fiweb/lib'
import { Pane, Heading, Paragraph, Link } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { SmallInfoCard } from '/~/components/Cards'
import styled, { css } from 'styled-components'
import { Helmet } from '/~/components'
import { config } from '/~/utils/config'

const GridContainer = styled(Pane)`
  ${({ theme: { spacing } }) => css`
    display: grid;
    width: 100%;
    max-width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 60px;
    justify-items: center;
    margin-bottom: ${spacing.l};
    @media ${breakpoints.large} {
      grid-column-gap: 20px;
    }
    @media ${breakpoints.medium} {
      grid-template-columns: 1fr;
      margin: 0 ${spacing.s};
      margin-bottom: ${spacing.xs};
    }
  `}
`

const StyledSmallInfoCard = styled(SmallInfoCard)`
  min-width: 100%;
  padding: 14% 14% 7% 14%;
  margin-bottom: ${({ theme: { spacing } }) => spacing.m};
  @media ${breakpoints.medium} {
    min-width: 325px;
    width: 325px;
    padding: 40px 26px 40px 40px;
  }
`

export const ContactUs = () => {
  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Pane display='flex' flexDirection='column' alignItems='center' paddingX={theme.spacing.s}>
      <Helmet
        title='Kontakt Folkeinvest i dag ved spørsmål'
        description='Kontakt oss gjerne i dag om du har spørsmål om finansiering eller hvordan investere.'
        url={`${window.location.origin}/kontakt-oss`}
        imageUrl={`${config.publicFilesUrl}/meta/contact-us.png`}
        keywords='Invester i aksjer, invester i startups, investere, hente kapital, finansiering av startups'
      />

      <Pane
        display='flex'
        flexDirection='column'
        alignItems='center'
        maxWidth={large ? 1060 : 1260}
        justifyContent='center'
        marginTop={large ? 0 : theme.spacing.l}
      >
        <Heading is='h1' size={medium ? 600 : large ? 700 : 800} marginBottom={theme.spacing.s}>
          Kontakt oss
        </Heading>
        <Paragraph
          textAlign='center'
          fontSize={medium ? 18 : 28}
          fontWeight={medium ? 400 : 500}
          lineHeight={medium ? '28px' : '39.06px'}
          width={medium ? '86.5%' : '79%'}
          marginBottom={medium ? theme.spacing.m : theme.spacing.l}
        >
          Vi er her for deg.{!medium && <br />}Lurer du på noe, sjekk vårt{' '}
          <Link href='https://hjelpesenter.folkeinvest.no/' fontSize='inherit' target='_blank'>
            hjelpesenter
          </Link>
          . Finner du ikke svar, ring oss på (+47) 462 80 188 eller send en e-post til{' '}
          <Link href='mailto: post@folkeinvest.no' fontSize='inherit' target='_blank'>
            post@folkeinvest.no
          </Link>
        </Paragraph>
        <GridContainer>
          <StyledSmallInfoCard order={1} heading='Søke kapital'>
            <Paragraph size={medium ? 400 : 500}>
              Ønsker du å hente kapital? Du kan lese mer om hvordan det fungerer{' '}
              <Link href='/slik-fungerer-det/selskap' size={medium ? 400 : 500} target='_blank'>
                her
              </Link>{' '}
              eller{' '}
              <Link href='mailto: post@folkeinvest.no' size={medium ? 400 : 500} target='_blank'>
                ta kontakt
              </Link>{' '}
              med oss hvis du lurer på noe mer.
            </Paragraph>
          </StyledSmallInfoCard>
          <StyledSmallInfoCard backgroundColor={theme.colors.fiBlue10} order={2} heading='Tekniske problemer?'>
            <Paragraph size={medium ? 400 : 500}>
              Da kan du ta kontakt via mail eller ta kontakt per telefon.
              <br />
              <br /> 
              <Link href='mailto: support@folkeinvest.no' size={medium ? 400 : 500} target='_blank'>
                support@folkeinvest.no
              </Link>
            </Paragraph>
          </StyledSmallInfoCard>
          <StyledSmallInfoCard
            backgroundColor={theme.colors.fiBlue10}
            order={medium ? 4 : 3}
            heading='Jobber du i media?'
          >
            <Paragraph size={medium ? 400 : 500}>
              Du kan lese våre pressemeldinger, samt finne informasjon og bilder i vårt{' '}
              <Link href='/presse' size={medium ? 400 : 500} target='_blank'>
                presserom.
              </Link>
              <br />
              <br />
              <Link href='mailto: presse@folkeinvest.no' size={medium ? 400 : 500} target='_blank'>
                presse@folkeinvest.no
              </Link>
            </Paragraph>
          </StyledSmallInfoCard>
          <StyledSmallInfoCard order={medium ? 3 : 4} heading='Fremtid i Folkeinvest?'>
            <Paragraph size={medium ? 400 : 500}>
              Vi liker bra folk, personlighet trumfer det meste!
              <br />
              <br />
              <Link href='mailto: post@folkeinvest.no' size={medium ? 400 : 500} target='_blank'>
                post@folkeinvest.no
              </Link>{' '}
            </Paragraph>
          </StyledSmallInfoCard>
        </GridContainer>
      </Pane>
    </Pane>
  )
}
