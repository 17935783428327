import * as React from 'react'
import { Tablist, Tab, toaster } from 'evergreen-ui'
import _ from 'lodash'
import { Route, Routes, NavLink, useNavigate, useLocation } from 'react-router-dom'
import Signup from './Signup'
import Login from './Login'
import {
  UserWithServiceExistsDocument,
  useResetPasswordMutation,
  useApproveUserForPreorderMutation,
} from '/~/types/graphql'
import { useApolloClient } from '@apollo/client'
import { AuthContainer } from '/~/components'
import { useAuthContext } from '/~/utils/AuthContext'
import { config } from '/~/utils/config'
import { auth, Service } from '/fiweb/lib'
import { StatusCodes } from 'http-status-codes'
import { SignicatEmailSignup, SignicatLoginCallback, SignicatSignupCallback } from './SignicatCallbacks'
import { FormattedMessage } from 'react-intl'

interface LoginArgs {
  mode: 'login'
  email?: string
  password?: string
  token?: string
  persistentLogin?: boolean
}

interface ResetPasswordArgs {
  mode: 'resetPassword'
  email: string
}

export type LoginSubmitArgs = LoginArgs | ResetPasswordArgs

export const Auth: React.FC<{ children?: React.ReactNode }> = () => {
  const [submitting, setSubmitting] = React.useState(false)
  const { user, refetch } = useAuthContext()
  const [resetPassword] = useResetPasswordMutation()
  const [approveUserForPreorder] = useApproveUserForPreorderMutation()
  const client = useApolloClient()

  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (user && !submitting) {
      redirectAfterLogin()
    }
  }, [user])

  const approvePreorder = async () => {
    // If the user has visited preorder-links, he whould be eligable for those after signing up
    // This function checks localStorage for added campaign id's
    // and adds the newly created user ID to the list
    let list = []
    if (localStorage) {
      const item = localStorage.getItem('approved_for_preorder')
      if (item) {
        list = JSON.parse(item)
        localStorage.removeItem('approved_for_preorder') // No need to keep the list in localStorage anymore
      }
    }
    for (const campaignId of list) {
      try {
        await approveUserForPreorder({ variables: { campaignId } })
      } catch (e) {
        console.log(e)
      }
    }
  }

  const redirectAfterLogin = async () => {
    approvePreorder()

    const { state, search } = location
    const searchParams = new URLSearchParams(search.replace(/^\?/g, ''))
    const searchRedirectAfterAuth = searchParams.get('redirectToAfterAuth')

    if (searchRedirectAfterAuth) {
      window.location.href = searchRedirectAfterAuth
      return
    }

    let link = '/'
    if (state?.redirectToAfterAuth) {
      link = state.redirectToAfterAuth
    } else {
      if (user?.hasCampaigns) {
        link = '/konto'
      } else {
        link = '/kampanjer'
      }
    }
    navigate(link, { replace: true })
  }

  const handleResetPassword = async ({ email }) => {
    try {
      const response = await resetPassword({
        variables: { email },
      })
      const { message } = response.data.passwordReset
      toaster.success(message)
    } catch (_e) {
      toaster.danger('Nullstilling av passord feilet. Kontakt support')
    }
  }

  const handleLogin = async (loginProps: {
    email: string
    password: string
    persistentLogin?: boolean
  }) => {
    try {
      const result = await auth(config.authHost).login(loginProps)

      if (result.status === StatusCodes.FORBIDDEN) {
        throw Error('Innlogging blokkert')
      }

      if (!result.ok) {
        return { success: false, message: 'Feil epost eller passord' }
      }

      toaster.success('Du er nå logget inn')

      await onAuth()
    } catch (err) {
      const loginErrorMessage = err?.message === 'Innlogging blokkert' ? err?.message : 'Innlogging feilet'

      toaster.danger(loginErrorMessage)
      return { success: false, message: loginErrorMessage }
    }
  }

  const onAuth = async () => {
    return await refetch()
  }

  const handleServiceAuth = async (service: Service, token: string) => {
    try {
      const result = await auth(config.authHost).loginWithService({ service, token })
      const { data: onAuthData } = await onAuth()

      if (result.status === StatusCodes.FORBIDDEN) {
        throw Error('Innlogging blokkert')
      }

      if (!result.ok) {
        throw new Error('Login failed')
      }

      if (onAuthData?.me) {
        toaster.success('Du er nå logget inn')
      } else {
        toaster.danger('Innlogging feilet')
      }
    } catch (e) {
      const loginErrorMessage =
        e.message === 'Innlogging blokkert' ? e.message : `Innlogging med ${service} feilet. Kontakt support`

      toaster.danger(loginErrorMessage)
      return { success: false, message: e.message }
    }
  }

  const handleServiceLogin = async (serviceName: Service, token: string) => {
    try {
      const {
        data: { userWithServiceExists },
      } = await client.query({
        query: UserWithServiceExistsDocument,
        variables: { serviceName, token },
      })

      if (!userWithServiceExists) {
        return {
          success: false,
          message: 'Det finnes ingen brukerprofil med denne e-posten, gå til registrering',
        }
      }

      return handleServiceAuth(serviceName, token)
    } catch (e) {
      toaster.danger('En feil skjedde. Kontakt support')
      return { success: false, message: e.message }
    }
  }

  const handleSubmit = async (args: LoginSubmitArgs) => {
    setSubmitting(true)

    switch (args.mode) {
      case 'login': {
        const { email, password, persistentLogin, token } = args

        if (token) {
          setSubmitting(false)
          return await handleServiceLogin(Service.GOOGLE, token)
        }

        setSubmitting(false)
        return await handleLogin({ email, password, persistentLogin })
      }

      case 'resetPassword': {
        setSubmitting(false)
        await handleResetPassword({ email: args.email })
        return
      }
    }
  }

  return (
    <AuthContainer>
      <Tablist display='flex' height='60px' alignItems='start' justifyContent='start'>
        <Tab
          key='login'
          appearance='primary'
          id='login'
          is={NavLink}
          to={{ pathname: '/innlogging/', search: location.search }}
          state={location.state}
          width='50%'
          height='100%'
        >
          <FormattedMessage id='auth.login' />
        </Tab>
        <Tab
          key='signup'
          appearance='primary'
          id='nyprofil'
          is={NavLink}
          to={{ pathname: '/innlogging/registrering', search: location.search }}
          state={location.state}
          width='50%'
          height='100%'
        >
          <FormattedMessage id='auth.newUser' />
        </Tab>
      </Tablist>

      <Routes>
        <Route path='signicat/:callback' Component={SignicatLoginCallback} />
        <Route path='registrering/signicat/:callback' Component={SignicatSignupCallback} />
        <Route path='/' element={<Login onSubmit={handleSubmit} />} />

        <Route path='registrering/epost-kreves' Component={SignicatEmailSignup} />
        <Route
          path='registrering'
          element={<Signup setSubmitting={setSubmitting} approvePreorder={approvePreorder} />}
        />
      </Routes>
    </AuthContainer>
  )
}
