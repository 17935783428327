import * as React from 'react'
import moment from 'moment'
import { nf } from '/~/utils/formatters'
import { useNavigate, useLocation } from 'react-router-dom'
import { formatGraphQLError } from '/~/utils/errorFormating'
import VPSInputComponent from './VPSInputComponent'
import ExpediencyStatus from './ExpediencyStatus'
import { FetchCampaignQuery, useReserveSharesMutation } from '/~/types/graphql'
import { Reservation } from './ReserveShares'
import {
  Button,
  Checkbox,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Pane,
  Paragraph,
  Text,
  toaster,
  UnorderedList,
  WarningSignIcon,
} from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import Divider from '/~/components/campaign/Campaign/Divider'
import { useAuthContext } from '/~/utils/AuthContext'
import { gtmEventPurchase } from '/~/utils/googleTagManager'

interface Props {
  reservation: Reservation
  campaign: FetchCampaignQuery['Campaign']
  preorderComplete: (preorderReservation: Reservation) => void
  preorder: boolean
  onCancel: () => void
}

const Datarow = ({ data: { label, value } }: { data: { label: string; value: React.ReactNode } }) => (
  <>
    <Text size={300} fontWeight={600}>
      {label}:
    </Text>
    <Text size={300}>{value}</Text>
  </>
)

const ConfirmReservation = ({ reservation, campaign, onCancel, preorderComplete }: Props) => {
  const { user } = useAuthContext()
  const theme = useCustomTheme()
  const { medium } = useBreakpoint()
  const [reserveShares] = useReserveSharesMutation()

  const navigate = useNavigate()
  const location = useLocation()

  const [submitting, setSubmitting] = React.useState(false)
  const [consentsAccepted, setConsentsAccepted] = React.useState<Record<string, boolean>>({})
  const [vpsInput, setVpsInput] = React.useState<{ vpsAccount: string; userHasVps: boolean }>({
    vpsAccount: '',
    userHasVps: false,
  })

  const { preorder, signee, numberOfShares, amount } = reservation

  if (!signee) {
    return null
  }

  const needsIgnoreExpediency = () => {
    const { expediency } = user
    if (!expediency) {
      return false
    }
    return expediency.signed && !expediency.result
  }

  const toggleCheckConsent = (consent: string, value: boolean) => {
    setConsentsAccepted({ ...consentsAccepted, [consent]: value })
  }

  const handleVpsInput = (account: string) => {
    setVpsInput({ ...vpsInput, vpsAccount: account })
  }

  const vpsValidOrNotNeeded = () => {
    const { vpsAccount, userHasVps } = vpsInput

    return !(campaign.hasVPS && userHasVps) || (vpsAccount?.match(/^[0-9]*$/g) && vpsAccount.length === 12)
  }

  const needsValidationCheck = () =>
    campaign.campaignOptions?.find((o) => o.name === 'validationRequired')?.checked &&
    campaign.reservationsSummary.shares < Math.ceil(campaign.raisingSharesMaximum * 0.3)

  const shouldSkipConsent = (consentName) => {
    return (
      (consentName === 'ignore_expediency' && !needsIgnoreExpediency()) ||
      (consentName === 'within_validation_amount' && !needsValidationCheck()) ||
      (consentName === 'create_vps_account' && vpsInput.userHasVps)
    )
  }

  const allConsentsAccepted = () => {
    const consents = campaign?.concentsRequired || []

    for (const consent of consents) {
      if (!consentsAccepted[consent.name]) {
        if (consent.name === 'within_validation_amount') {
          return !needsValidationCheck()
        }
        if (consent.name === 'ignore_expediency') {
          if (needsIgnoreExpediency()) {
            return false
          }
        } else if (consent.name === 'create_vps_account') {
          if (getCampaignOptionsField('vpsAndAccountToggled').checked && !vpsInput.userHasVps) {
            return false
          }
        } else {
          return false
        }
      }
    }

    return true
  }

  const getCampaignOptionsField = (name) => campaign?.campaignOptions?.find((o) => o.name === name) || {}

  const handleConfirm = async () => {
    const { vpsAccount, userHasVps } = vpsInput

    if (!(reservation && signee && user && campaign)) {
      return null
    }

    setSubmitting(true)

    const amountFormatted = Math.floor(numberOfShares * campaign.pricePerShare * 100) / 100

    try {
      // Check if the user passed the expediency test
      let expediencyResult = null
      let expediencyId = null

      const { expediency } = user

      if (expediency?.signed) {
        if (expediency.result) {
          expediencyResult = 'passed'
        } else {
          expediencyResult = 'failed'
        }

        expediencyId = expediency.testId
      } else {
        expediencyResult = 'failed'
      }

      const consents = campaign.concentsRequired.filter((c) => consentsAccepted[c.name]).map((c) => c.content)

      const variables = {
        campaignId: campaign._id,
        input: {
          signee,
          shares: numberOfShares,
          amount: amountFormatted,
          concents: consents,
          expediencyResult,
          expediencyId,
          vpsAccount: userHasVps ? vpsAccount : '',
        },
      }
      const res = await reserveShares({ variables })

      gtmEventPurchase({
        amount,
        id: res?.data?.reserveShares?._id,
        campaign,
        quantity: numberOfShares,
      })

      if (preorder) {
        toaster.success(`Du har forhåndsreservert ${res.data.reserveShares.shares} aksjer`)

        setSubmitting(false)

        preorderComplete({
          numberOfShares,
          amount: amountFormatted,
          signee,
          preorder: true,
        })
      } else {
        toaster.success(`Du har reservert ${res.data.reserveShares.shares} aksjer`)

        const search = location ? location.search : ''

        navigate(`/kampanje/${campaign.slug}/takk${search}`, {
          state: {
            reservationID: res?.data?.reserveShares?._id,
          },
        })
      }
    } catch (e) {
      toaster.danger(formatGraphQLError(e))
      setSubmitting(false)
    }
  }

  const reservationDatapoints = [
    {
      label: 'Emisjon',
      value: campaign.title,
    },
    {
      label: 'Antall aksjer',
      value: `${numberOfShares} stk`,
    },
    {
      label: 'Pris per aksje',
      value: `${nf(campaign.pricePerShare)} kr`,
    },
    {
      label: 'Beløp',
      value: `${nf(amount)} kr`,
    },
    {
      label: 'Tegnet på',
      value: `${signee.name}`,
    },
    {
      label: 'Tegners type',
      value: `${signee.type === 'business' ? 'Selskap' : 'Privatperson'}`,
    },
  ]

  const isConfirmDisabled = () => {
    const { expediency } = user

    if (submitting) {
      return true
    }

    if (!vpsValidOrNotNeeded()) {
      return true
    }
    if (!allConsentsAccepted()) {
      return true
    }
    if (!expediency?.signed) {
      return true
    }

    return false
  }

  return (
    <Pane>
      <Heading is='h3' size={500}>
        {preorder ? 'Bekreft forhåndstegning av aksjer' : 'Bekreft tegning av aksjer'}
      </Heading>
      <Pane background='rgba(13, 125, 194, 0.08)' borderRadius='5px' padding='30px' width='100%'>
        <Heading is='h5' size={400}>
          Info om din tegning
        </Heading>
        <Pane display='grid' width={medium ? '100%' : 'initial'} gridTemplateColumns='140px 1fr'>
          {reservationDatapoints.map((resDp, index) => (
            <Datarow data={resDp} key={index} />
          ))}
        </Pane>
      </Pane>
      <Pane paddingY='30px'>
        <Paragraph fontWeight={600}>Tegningen forplikter økonomisk, kan jeg angre meg?</Paragraph>

        <Paragraph marginTop={theme.spacing.xs}>
          Du kan kansellere tegningen inntil 48 timer før emisjonen avsluttes, etter dette er tegningen bindende.
        </Paragraph>

        {campaign.closeDate && (
          <Pane
            display='grid'
            marginTop={theme.spacing.s}
            width={medium ? '100%' : 'initial'}
            gridTemplateColumns='220px 1fr'
          >
            <Datarow
              data={{
                label: 'Tegningsvindu avsluttes',
                value: moment(campaign.closeDate).format('DD-MM-YYYY (HH:mm)'),
              }}
            />
            <Datarow
              data={{
                label: 'Siste frist',
                value: moment().isAfter(moment(campaign.closeDate).subtract(48, 'hours')) ? (
                  <Text color='danger'>Fristen for å trekke tegningen er utløpt</Text>
                ) : (
                  moment(campaign.closeDate).subtract(48, 'hours').format('DD-MM-YYYY (HH:mm)')
                ),
              }}
            />
          </Pane>
        )}

        <Paragraph fontWeight={600} marginTop={theme.spacing.s}>
          Ved kansellering krever vi en skriftlig begrunnelse på årsaken.
        </Paragraph>
        <Paragraph marginTop={theme.spacing.s}>
          <em>
            NB: Utstederen kan velge å forlenge tegningsvinduet, noe som medfører at fristen for kansellering vil
            utsettes.
          </em>
        </Paragraph>
        <Paragraph marginTop={theme.spacing.s}>
          <em>NBB: Styre, ansatte og tidligere aksjonærer i utstederselskapet kan ikke trekke tegningen sin.</em>
        </Paragraph>
      </Pane>

      {needsValidationCheck() && (
        <Pane>
          <Paragraph color='red'>
            <em>
              Emisjonen er for øyeblikket i sin valideringsfase og tegninger utført under denne fasen kan ikke trekkes.
              Det kreves en ekstra bekreftelse at dette er forstått for å kunne tegne.
            </em>
          </Paragraph>
        </Pane>
      )}

      <Divider />

      <Pane>
        <Heading is='h5' size={400}>
          Risiko
        </Heading>

        <Paragraph marginTop={theme.spacing.xs}>
          Investering i unoterte aksjer innebærer høy risiko. Det er viktig at du som investor leser
          investeringstilbudet nøye og gjør deg din egen formening om hvilken risiko den eventuelle investeringen
          innebærer for deg.
        </Paragraph>

        <Paragraph marginTop={theme.spacing.s}>Ikke invester mer enn det du har råd til å tape.</Paragraph>

        <Paragraph marginTop={theme.spacing.s}>
          Du kan lese mer om risiko{' '}
          <Link href='/risiko' target='_blank'>
            her.
          </Link>
        </Paragraph>
      </Pane>

      <Divider />

      <Pane marginTop={theme.spacing.s}>
        <Heading is='h5' size={400}>
          Hva skjer videre?
        </Heading>

        {preorder && (
          <>
            <Paragraph marginTop={theme.spacing.s}>
              Tegningen blir synlig på din profilside umiddelbart etter forhåndstegning.
            </Paragraph>

            <Paragraph>
              Emisjonens tegningsliste blir oppdatert når tegningsvinduet åpner
              {Boolean(campaign.startDate) && ` ${moment(campaign.startDate).format('DD-MM-YYYY (HH:mm)')}`}.
            </Paragraph>
          </>
        )}

        <Paragraph fontWeight={600} marginTop={theme.spacing.xs}>
          Emisjonen kan avsluttes med to utfall:
        </Paragraph>

        <Paragraph fontWeight={600} marginTop={theme.spacing.s}>
          a. Minimumsbeløp oppnådd:
        </Paragraph>

        <OrderedList>
          <ListItem>
            <Text>Du vil motta e-post med sluttresultatet av aksjetegning i emisjonen.</Text>
          </ListItem>

          <ListItem>
            <Text>
              Kapitalutvidelsen vedtas i selskapets generalforsamling og tildelte aksjer tegnes i
              generalforsamlingsprotokollen.
            </Text>
          </ListItem>

          <ListItem>
            <Text>
              Innbetalingsinformasjon til din registrerte e-post og blir synlig på din profilside på folkeinvest.no.
            </Text>
          </ListItem>

          <ListItem>
            <Text>Du betaler inn korrekt beløp ved ordinær bankoverføring.</Text>
          </ListItem>
        </OrderedList>

        <Paragraph fontWeight={600} marginTop={theme.spacing.xs}>
          b. Minimumsbeløp IKKE oppnådd:
        </Paragraph>

        <UnorderedList>
          <ListItem>
            <Text>Du vil motta epost om emisjonens status</Text>
          </ListItem>

          <ListItem>
            <Text>Emisjonen blir avbrutt og du skal ikke betale.</Text>
          </ListItem>
        </UnorderedList>
      </Pane>

      <Divider />

      <Pane marginTop={theme.spacing.s}>
        <ExpediencyStatus disabled={Boolean(consentsAccepted.ignore_expediency)} />
      </Pane>
      {campaign.hasVPS && (
        <>
          <Divider />
          <Pane marginTop={theme.spacing.s}>
            <VPSInputComponent
              campaign={campaign}
              vpsAccount={vpsInput.vpsAccount}
              option={getCampaignOptionsField('vpsAndAccountToggled')}
              userHasVps={vpsInput.userHasVps}
              onAccountChange={handleVpsInput}
              onHasVpsChange={(hasVps) => setVpsInput({ ...vpsInput, userHasVps: hasVps })}
            />
          </Pane>
        </>
      )}

      {!!getCampaignOptionsField('onReserveSharesAdminMessage').checked &&
        !!getCampaignOptionsField('onReserveSharesAdminMessage').text && (
          <>
            <Divider />
            <Pane marginTop={theme.spacing.s} borderRadius='5px' backgroundColor={theme.colors.fiRed10} padding='12px'>
              <Heading is='h5' size={400}>
                <WarningSignIcon color='danger' marginRight='12px' />
                Viktig melding!
              </Heading>
              {getCampaignOptionsField('onReserveSharesAdminMessage')
                .text?.split('\n')
                .map((line, i) => (
                  <Text key={i} size={300} fontStyle='italic'>
                    {line || <br />}
                  </Text>
                ))}
            </Pane>
          </>
        )}

      {!!getCampaignOptionsField('onReserveSharesGeneralMessage').checked &&
        !!getCampaignOptionsField('onReserveSharesGeneralMessage').text && (
          <>
            <Divider />
            <Pane marginTop={theme.spacing.s}>
              <Heading is='h5' size={400}>
                {getCampaignOptionsField('onReserveSharesGeneralMessage').labelText}
              </Heading>
              {getCampaignOptionsField('onReserveSharesGeneralMessage')
                .text?.split('\n')
                .map((line, i) => (
                  <Text key={i}>{line || <br />}</Text>
                ))}
            </Pane>
          </>
        )}

      <Divider />

      <Pane marginTop={theme.spacing.s}>
        <Heading is='h5' size={400}>
          Bekreftelser
        </Heading>
        <Paragraph color='muted' fontStyle='italic' size={300}>
          Alle punkter må bekreftes
        </Paragraph>

        {(campaign?.concentsRequired || []).map((consent) => {
          const checked = !!consentsAccepted[consent.name]
          if (shouldSkipConsent(consent.name)) {
            return null
          }

          return (
            <Checkbox
              key={consent.name}
              checked={checked}
              onChange={(e) => toggleCheckConsent(consent.name, e.target.checked)}
              value={consent.name.toString()}
              label={consent.content}
              display='flex'
              alignItems='baseline'
            />
          )
        })}
      </Pane>

      <Pane
        display='flex'
        marginTop={theme.spacing.m}
        marginBottom={theme.spacing.m}
        flexDirection={medium ? 'column-reverse' : 'row'}
        justifyContent='flex-end'
      >
        <Button onClick={onCancel}>Avbryt</Button>
        <Button
          appearance='primary'
          marginLeft={medium ? 0 : 18}
          marginBottom={medium ? theme.spacing.xs : 0}
          disabled={isConfirmDisabled()}
          onClick={handleConfirm}
        >
          Bekreft tegning
        </Button>
      </Pane>
    </Pane>
  )
}

export default ConfirmReservation
