import * as React from 'react'
import { isUserValid } from '/~/utils/checkUserValid'
import OnBehalfOf from './OnBehalfOf'
import VPSInfo from './VPSInfo'
import {
  FetchCampaignQuery,
  FetchSigneeMaxSharesDocument,
  FetchSigneeMaxSharesQuery,
  SigneeInput,
} from '/~/types/graphql'
import { useApolloClient } from '@apollo/client'
import { Button, Heading, InfoSignIcon, Link, Pane, Text, TextInputField } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment'
import SigningNotOpen from './SigningNotOpen'
import { Reservation } from './ReserveShares'
import CompleteProfileDialog from '/~/components/account/CompleteProfileDialog'
import { useAuthContext } from '/~/utils/AuthContext'
import { AuthContextType } from '/~/utils/AuthContext/AuthContextProvider'
import { gtmEventBeginCheckout } from '/~/utils/googleTagManager'
import { Caption } from '/fiweb/components'
import dayjs from 'dayjs'
import { useServerTimeSync } from '/~/utils/TimeSyncProvider'

type SigneeData = FetchSigneeMaxSharesQuery['fetchSigneeDataForCampaign']

interface Props {
  campaign: FetchCampaignQuery['Campaign']
  preorder: boolean
  onSharesSelected: (reservation: Reservation) => void
}

const getPepText = (user: AuthContextType['user']) =>
  user.pepData?.handledAt && !user.pepData.approved ? (
    <Text color='danger'>Kontoen din er stengt for tegning.</Text>
  ) : null

const ReserveShares = ({ campaign, preorder, onSharesSelected }: Props) => {
  const authContext = useAuthContext()
  const { serverTimeDayjsObject } = useServerTimeSync()
  const user = authContext.user
  const client = useApolloClient()
  const theme = useCustomTheme()
  const [shares, setShares] = React.useState(0)
  const [signee, setSignee] = React.useState<SigneeInput | null>(null)
  const [signeeData, setSigneeData] = React.useState<SigneeData | null>(null)
  const [loadingSignee, setLoadingSignee] = React.useState(false)
  const [maxReached, setMaxReached] = React.useState<string | null>(null)
  const [showProfileDialog, setShowProfileDialog] = React.useState(false)

  const isSigningOpen = React.useMemo(() => {
    if (!campaign) {
      return false
    }

    const now = moment()
    const start = moment(campaign.startDate)
    const close = moment(campaign.closeDate)
    const published = campaign.status === 'published'
    const preallocating = campaign.status === 'preallocating'
    const withinSigningDates = close.isAfter(now) && now.isAfter(start)

    if (preorder && (preallocating || (published && close.isAfter(now)))) {
      return true
    }
    return published && withinSigningDates
  }, [campaign, preorder])

  React.useEffect(() => {
    if (signeeData) {
      if (shouldIgnoreMinimumSharesRequirement()) {
        setShares(availableShares)
      } else {
        checkDisableSignee(signeeData)
      }
    }
  }, [signeeData])

  if (!campaign) {
    return null
  }

  const increaseDisabled = shares >= (signeeData?.maxSharesLeft || 0)

  const { pricePerShare, raisingSharesMaximum, reservationsSummary, minimumShareInvestment } = campaign

  const availableShares = (raisingSharesMaximum || 0) - (reservationsSummary.shares || 0)

  if (!isSigningOpen) {
    return <SigningNotOpen campaign={campaign} />
  }

  if (!user) {
    return (
      <Pane display='flex' justifyContent='center' paddingY='30px'>
        <Link is={RouterLink} to='/innlogging' state={{ redirectToAfterAuth: location.pathname }}>
          Logg inn for å tegne aksjer
        </Link>
      </Pane>
    )
  }

  const userPepText = getPepText(user)
  const pep = Boolean(userPepText)

  if (availableShares === 0) {
    return <Text textAlign='center'>Alle aksjer er tegnet for</Text>
  }

  const shouldIgnoreMinimumSharesRequirement = () => {
    const close = dayjs(campaign.closeDate)
    const limit = dayjs(campaign.closeDate).subtract(48, 'hours')
    const now = serverTimeDayjsObject
    return (
      campaign.closeDate &&
      close.isAfter(now) &&
      limit.isBefore(now) &&
      signeeData?.signeeCurrentShares > 0 &&
      availableShares < minimumShareInvestment &&
      signeeData.maxSharesLeft >= availableShares
    )
  }

  const handleCloseProfileDialog = () => {
    setShowProfileDialog(false)
    authContext.refetch()
  }

  const updateShares = (newShares: number) => {
    if (!signeeData) {
      return
    }

    const { signeeCurrentShares, maxSharesLeft } = signeeData

    if (!shouldIgnoreMinimumSharesRequirement() && newShares > maxSharesLeft) {
      const name = signee.type === 'person' ? 'Du' : signee.name
      const alreadyReserved = signeeCurrentShares !== 0

      const message = `${name} kan maksimalt tegne for ${maxSharesLeft}${alreadyReserved ? ' flere' : ''} aksjer.`

      setShares(maxSharesLeft)
      setMaxReached(message)
    } else {
      setShares(shouldIgnoreMinimumSharesRequirement() ? maxSharesLeft : newShares)
      setMaxReached(null)
    }
  }

  const isBuyButtonDisabled = () => {
    const userValid = isUserValid(user)
    const notPep = !pep

    const hasValidAmountOfShares =
      signeeData &&
      shares <= signeeData.maxSharesLeft &&
      (shouldIgnoreMinimumSharesRequirement() || shares >= signeeData.minPerSignee)

    return !(userValid && notPep && signee && hasValidAmountOfShares)
  }

  const setMinAmount = () => {
    updateShares(signeeData?.minPerSignee)
  }

  const addAmount = (amount: number) => {
    updateShares(Math.min(signeeData?.maxSharesLeft, (shares || 0) + Math.floor(amount / pricePerShare)))
  }

  const setAmount = (amount: number) => {
    const newShares = amount < pricePerShare ? 1 : Math.floor(amount / pricePerShare)
    updateShares(newShares)
  }

  const checkExceeded = (data: SigneeData) => {
    return {
      maxExceeded: data.maxSharesLeft < minimumShareInvestment,
      amountLeft: pricePerShare * data.maxSharesLeft,
    }
  }

  const checkDisableSignee = (data: SigneeData) => {
    const { maxExceeded } = checkExceeded(data)

    if (maxExceeded && availableShares >= minimumShareInvestment) {
      setMaxReached(
        `${
          signee?.type === 'person' ? 'Du' : signee?.name
        } kan ikke tegne for flere aksjer uten å gå over maksgrensen.`,
      )
    }
  }

  const updateSigneeData = async (signeeToFetch: SigneeInput) => {
    if (!signeeToFetch) {
      return null
    }
    setLoadingSignee(true)

    try {
      const res = await client.query({
        query: FetchSigneeMaxSharesDocument,
        variables: {
          campaignId: campaign._id,
          organizationNumber: signeeToFetch.type === 'business' ? signeeToFetch.number : null,
        },
        fetchPolicy: 'no-cache',
      })
      const data = res.data?.fetchSigneeDataForCampaign

      setSigneeData(data)
      updateShares(0)
    } catch (e) {
      console.log('Error: ', e)
    } finally {
      setLoadingSignee(false)
    }
  }

  const handleSigneeSelected = (newSignee: SigneeInput | null) => {
    if (!newSignee) {
      return null
    }

    if (signee && signee.type === newSignee.type && signee.number === newSignee.number) {
      return null
    }

    setSignee(newSignee)

    updateSigneeData(newSignee)
  }

  const renderErrorMessage = () => {
    if (!isUserValid(user)) {
      return (
        <>
          <Text size={300} color='danger'>
            Brukerprofilen mangler obligatoriske felt.
          </Text>
          <Button size='small' appearance='primary' onClick={() => setShowProfileDialog(true)}>
            Fullfør profil
          </Button>
        </>
      )
    }

    if (maxReached) {
      return (
        <Text color='danger' size={300}>
          {maxReached}
        </Text>
      )
    }

    if (userPepText) {
      return userPepText
    }

    return null
  }

  const signeeMissing = !signee || loadingSignee
  const errorText = renderErrorMessage()

  return (
    <Pane>
      <Heading is='h3' fontWeight={600} marginBottom={theme.spacing.xs}>
        {preorder ? 'Forhåndstegning av' : 'Kjøp'} aksjer
      </Heading>
      <Text>
        Før du bekrefter din tegning bør du forsikre deg om at du forstår risikoen dette innebærer. Ikke invester mer
        enn det du har råd til å tape.
      </Text>
      {Boolean(isUserValid(user)) && (
        <OnBehalfOf user={user} onSelect={handleSigneeSelected} disabled={!isUserValid(user) || pep} />
      )}

      <Pane
        display='grid'
        marginTop={theme.spacing.s}
        gridTemplateColumns='1fr 1fr'
        rowGap={theme.spacing.s}
        columnGap={18}
      >
        {(availableShares >= minimumShareInvestment || shouldIgnoreMinimumSharesRequirement()) && (
          <>
            <TextInputField
              margin={0}
              type='number'
              inputHeight={50}
              disabled={signeeMissing}
              label='Antall aksjer'
              min={signeeData?.minPerSignee || 0}
              max={signeeData?.maxSharesLeft || 0}
              value={shares}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateShares(Number.parseInt(e.target.value, 10))}
            />
            <Pane display='flex' alignItems='flex-end'>
              <TextInputField
                margin={0}
                marginRight={8}
                type='number'
                inputHeight={50}
                disabled={signeeMissing}
                step={pricePerShare}
                min={(signeeData?.minPerSignee || 0) * pricePerShare || 0}
                max={(signeeData?.maxSharesLeft || 0) * pricePerShare || 0}
                label='Beløp'
                value={shares * pricePerShare}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const amount = e.target.value ? Number.parseInt(e.target.value, 10) : 0
                  setAmount(Number.isNaN(amount) ? 0 : amount)
                }}
              />
              <Text size={300} marginBottom={theme.spacing.xxs}>
                kr
              </Text>
            </Pane>
          </>
        )}
        {!signeeMissing && (
          <>
            {shouldIgnoreMinimumSharesRequirement() ? (
              <Pane gridColumn='span 2' display='grid' gridTemplateColumns='25px auto' alignItems='start' gap={10}>
                <InfoSignIcon size={20} />
                <Caption>
                  Da du har tidligere tegnet deg i denne emisjonen, har du nå mulighet til å kjøpe de siste aksjene,
                  selv om minstebeløpet for enkelt-investering ikke nås.
                </Caption>
              </Pane>
            ) : !shouldIgnoreMinimumSharesRequirement() && availableShares < minimumShareInvestment ? (
              <Pane gridColumn='span 2' display='grid' gridTemplateColumns='25px auto' alignItems='start' gap={10}>
                <InfoSignIcon size={20} />
                <Caption color='danger'>
                  Aksjer som gjenstår er under minimumsgrensen, det er derfor ikke mulig å tegne aksjer
                </Caption>
              </Pane>
            ) : (
              <>
                <Button disabled={increaseDisabled} size='small' appearance='default' onClick={setMinAmount}>
                  Minimum
                </Button>
                <Button disabled={increaseDisabled} size='small' appearance='default' onClick={() => addAmount(10000)}>
                  + 10 000 kr
                </Button>
              </>
            )}
          </>
        )}
        {errorText}
      </Pane>

      <Button
        width='100%'
        marginTop={theme.spacing.m}
        marginBottom={theme.spacing.s}
        appearance='primary'
        onClick={() => {
          gtmEventBeginCheckout(campaign, shares)
          onSharesSelected({
            amount: shares * pricePerShare,
            numberOfShares: shares,
            preorder,
            signee,
          })
        }}
        disabled={isBuyButtonDisabled()}
      >
        Kjøp
      </Button>

      {campaign.hasVPS && (
        <Pane marginY={theme.spacing.xxs}>
          <VPSInfo />
        </Pane>
      )}

      <CompleteProfileDialog onClose={() => handleCloseProfileDialog()} open={showProfileDialog} />
    </Pane>
  )
}

export default ReserveShares
