import * as React from 'react'
import { Bell, BellOn } from '/fiweb/components/Icons'
import { useCustomTheme } from '/fiweb/lib/theme'
import { IconProps } from '/fiweb/components/Icons'

interface NotificationBellProps extends IconProps {
  subscribed?: boolean
}

export const NotificationBell = ({ subscribed, ...props }: NotificationBellProps) => {
  const theme = useCustomTheme()
  return subscribed ? (
    <BellOn color={theme.colors.fiDark} {...props} />
  ) : (
    <Bell color={theme.colors.fiDark40} {...props} />
  )
}
