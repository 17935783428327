import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Coins } from '/fiweb/components/Icons'
import { Pane, Heading, Paragraph, Button } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled, { css } from 'styled-components'

const Wrapper = styled(Pane)`
  ${({ theme: { colors, spacing } }) => css`
    display: flex;
    width: 100%;
    max-width: 1010px;
    height: 311px;
    background-color: ${colors.fiBlue10};
    justify-content: center;
    align-items: center;
    margin-bottom: ${spacing.l};
    border-radius: 10px 10px 30px 10px;
    padding: 40px 30px;
  `}
`

const StyledImage = styled(Pane)`
  svg {
    path {
      stroke: #2d3340;
    }
  }
`

export const Shares = () => {
  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()
  return (
    <Pane paddingX={large ? 25 : 0} width='100%' maxWidth={1010}>
      <Wrapper>
        <Pane
          maxWidth={medium ? '100%' : 546}
          marginTop={medium ? theme.spacing.xs : theme.spacing.s}
          marginRight={medium ? 0 : 26}
        >
          <Heading size={medium ? 500 : 600} marginBottom={theme.spacing.xs}>
            Kjøp eller salg av aksjer
          </Heading>
          <Paragraph size={medium ? 400 : 500} marginBottom={theme.spacing.s}>
            Er du interessert i å selge dine aksjer, eller kjøpe andre aksjonærers post i Folkeinvest?
          </Paragraph>
          <Button
            appearance='primary'
            size='small'
            is='a'
            target='_blank'
            href='https://forms.office.com/Pages/ResponsePage.aspx?id=S-JY-VSol0WcdDUjTrJXKtd79EHKHFlPs6kqFnmT-tpUMEdXSkFXTTNGWVdFN0pQSFc5MUJMVFc4TS4u'
          >
            Meld interesse
          </Button>
        </Pane>
        {!medium && (
          <StyledImage>
            <Coins />
          </StyledImage>
        )}
      </Wrapper>
    </Pane>
  )
}
