import * as React from 'react'
import { Pane, PaneProps, PlayIcon } from 'evergreen-ui'
import styled, { css } from 'styled-components'
import { useCustomTheme } from '../lib/theme'

const Wrapper = styled(Pane)<{ $heightConstraintByViewport: boolean }>`
  ${({ theme, $heightConstraintByViewport, height }) => css`
  box-sizing: border-box;
  cursor: pointer;
  height: ${$heightConstraintByViewport ? 'inherit' : height};
  
  &>video {
    object-fit: contain;
    background-color: black;
    border: 1px solid ${theme.colors.fiDark70};
    border-radius: 5px;
    max-height: ${$heightConstraintByViewport ? '80vh' : '100%'};
    width: 100%;
  }
`}`

const StyledPreviewPane = styled(Pane)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > * {
    color: white;
    opacity: 0.7;
    transition: 0.3s;
  }
  &:hover > * {
    transform: scale(1.3);
    opacity: 1;
  }
  
`

interface Props {
  html?: string
  alwaysShowControls?: boolean
  heightConstraintByViewport?: boolean
  onVideoLoaded?: (videoElement: HTMLVideoElement) => void
}

export const VideoWrapper = ({
  html,
  heightConstraintByViewport,
  alwaysShowControls,
  onVideoLoaded,
  ...paneProps
}: Props & PaneProps) => {
  const [showControls, setShowControls] = React.useState(!!alwaysShowControls)
  const [playInitiated, setPlayInitiated] = React.useState(false)
  const videoPaneRef = React.useRef<HTMLDivElement>()
  const { colors } = useCustomTheme()

  const getVideoElement = (): HTMLVideoElement | null => {
    if (videoPaneRef.current) {
      const videoElements = videoPaneRef.current?.getElementsByTagName('video')
      const videoEle = videoElements[0]
      return videoEle ?? null
    }
    return null
  }

  React.useEffect(() => {
    if (!alwaysShowControls && showControls) {
      const videoEle = getVideoElement()
      if (videoEle && !playInitiated) {
        setPlayInitiated(true)
        videoEle?.play()
      }
    }
  }, [showControls])

  React.useEffect(() => {
    const videoEle = getVideoElement()
    if (videoEle) {
      videoEle.onloadeddata = (e) => {
        onVideoLoaded?.(e.currentTarget as HTMLVideoElement)
      }
    }
  }, [])

  return (
    <Pane position='relative'>
      <Wrapper
        ref={videoPaneRef}
        dangerouslySetInnerHTML={{ __html: showControls ? html : html?.replace('controls', '') }}
        {...paneProps}
        $heightConstraintByViewport={!!heightConstraintByViewport}
      />
      {!showControls && (
        <StyledPreviewPane onClick={() => !showControls && setShowControls(true)} title='Spill av'>
          <PlayIcon size={60} padding={10} background={colors.fiDark} borderRadius='50%' />
        </StyledPreviewPane>
      )}
    </Pane>
  )
}
