import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Heading, Label, Pane, Paragraph, Strong, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { FetchCampaignQuery } from '/~/types/graphql'
import { nf } from '/~/utils/formatters'
import { Divider } from '/fiweb/components'

interface Props {
  campaign: FetchCampaignQuery['Campaign']
}

const Owners = ({ campaign }: Props) => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  const owners = [...(campaign?.owners || [])].filter((o) => o.name?.toLowerCase?.() !== 'andre aksjonærer')
  const max = campaign?.numberOfCurrentShares
  const tot = owners.reduce((acc, o) => {
    return acc + o.shares
  }, 0)
  let others = null
  if (max - tot > 0) {
    others = { name: 'Andre aksjonærer', shares: max - tot, pts: ((max - tot) / max) * 100 }
  }

  const getHeader = () => (
    <Pane width='100%' display='flex' flexWrap='wrap' justifyContent='space-between'>
      <Label size={400} width='33%' fontWeight={600}>
        Navn
      </Label>
      <Label size={400} width='33%' fontWeight={600} textAlign='center'>
        Aksjer
      </Label>
      <Label size={400} width='33%' fontWeight={600} textAlign='right'>
        Andel
      </Label>
    </Pane>
  )

  const getRows = () => {
    const list = owners.map((o) => ({ ...o, pts: (o.shares / max) * 100 }))

    if (medium) {
      return [...list, ...(others ? [others] : [])].map((owner, index) => (
        <Pane key={index} paddingX={4}>
          <Pane width='100%' display='flex' flexDirection='column' marginTop={theme.spacing.xs}>
            <Paragraph size={300} marginBottom={theme.spacing.xxs}>
              <Strong size={300}>{owner.name}</Strong>
            </Paragraph>

            <Pane display='flex' flexFlow='row nowrap' justifyContent='space-between'>
              <Paragraph size={300}>Aksjer</Paragraph>
              <Paragraph size={300}>{nf(owner.shares)}</Paragraph>
            </Pane>

            <Pane display='flex' flexFlow='row nowrap' justifyContent='space-between'>
              <Paragraph size={300}>Andel</Paragraph>
              <Paragraph size={300}>{nf(owner.pts)}%</Paragraph>
            </Pane>
          </Pane>

          <Divider marginTop={theme.spacing.xxs} />
        </Pane>
      ))
    }

    return [...list, ...(others ? [others] : [])].map((owner, index) => (
      <React.Fragment key={index}>
        <Pane
          height='60px'
          width='100%'
          display='flex'
          flexWrap='wrap'
          justifyContent='space-between'
          alignItems='center'
        >
          <Text size={300} width='33%'>
            {owner.name}
          </Text>
          <Text size={300} width='33%' textAlign='center'>
            {nf(owner.shares)}
          </Text>
          <Text size={300} width='33%' textAlign='right'>
            {nf(owner.pts)}%
          </Text>
        </Pane>
        <Divider />
      </React.Fragment>
    ))
  }

  return (
    <Pane>
      <Heading is='h3' marginTop={theme.spacing.m}>
        Eiere
      </Heading>

      {!medium && getHeader()}
      <Divider style={{ marginTop: theme.spacing.xxs }} />
      {getRows()}
    </Pane>
  )
}

export default Owners
