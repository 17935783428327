import * as React from 'react'
import { Route, Navigate, NavLink, Routes } from 'react-router-dom'
import { useBreakpoint } from '/fiweb/lib'
import { Heading, Pane, Tab, Tablist } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import MoreInformation from './MoreInformation'
import PressReleases from './PressReleases'
import MediaMentions from './MediaMentions'

const Press = () => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Pane
      width='100%'
      backgroundColor={theme.colors.fiDark10}
      display='flex'
      flexDirection='column'
      alignItems='center'
    >
      <Pane
        maxWidth={1260}
        width='87.5%'
        display='flex'
        flexDirection='column'
        alignItems={medium ? 'center' : 'start'}
        marginTop={medium ? theme.spacing.m : theme.spacing.l}
      >
        <Heading is='h1' size={medium ? 500 : 600} marginBottom={theme.spacing.xs}>
          Presse
        </Heading>

        <Tablist
          display='flex'
          alignItems='start'
          justifyContent={medium ? 'center' : 'flex-end'}
          width='100%'
          backgroundColor='none'
          marginBottom={theme.spacing.m}
          marginTop={medium ? 0 : -60}
        >
          <Tab is={NavLink} to='medieomtale' marginRight={medium ? '24px' : '44px'}>
            <Heading size={medium ? 300 : 500} fontSize={medium ? 18 : 20} marginBottom={0}>
              Medieomtale
            </Heading>
          </Tab>
          <Tab is={NavLink} to='pressemeldinger'>
            <Heading size={medium ? 300 : 500} fontSize={medium ? 18 : 20} marginBottom={0}>
              Pressemeldinger
            </Heading>
          </Tab>
        </Tablist>
        <Routes>
          <Route path='pressemeldinger' Component={PressReleases} />
          <Route path='medieomtale' Component={MediaMentions} />
          <Route path='' element={<Navigate to='medieomtale' replace />} />
        </Routes>
      </Pane>
      <MoreInformation />
    </Pane>
  )
}

export default Press
