const questions = [
  {
    title: 'Spørsmål 1',
    id: 'shares_can_be_hard_to_sell',
    label:
      'På folkeinvest.no selges unoterte aksjer. Det vil si aksjer som ikke handles på børs. Kan det være vanskelig å få solgt aksjer du har kjøpt på folkeinvest.no?',
    answer: true,
  },
  {
    title: 'Spørsmål 2',
    id: 'rights_to_general_assembly',
    label: 'Har du, som aksjonær, rett til å møte på generalforsamling?',
    answer: true,
  },
  {
    title: 'Spørsmål 3',
    id: 'can_lose_more_than_invested',
    label: 'Kan du som aksjonær stilles ansvarlig for Foretaket, og tape mer enn pengene du har investert? ',
    answer: false,
  },
  {
    title: 'Spørsmål 4',
    id: 'has_previous_experience',
    label: 'Har du tidligere erfaring med aksjer som ikke er notert på børs, såkalte unoterte aksjer?',
  },
  {
    title: 'Spørsmål 5a',
    label: 'Hva er riktig om din erfaring med unoterte aksjer?',
    id: 'traded_last_five_years',
    subTitle: 'Tidsrom (velg én)',
    trueLabel: 'Jeg har handlet i unoterte aksjer siste fem år',
    falseLabel: 'Det er mer enn fem år siden min forrige handel i unoterte aksjer',
  },
  {
    title: 'Spørsmål 5b',
    label: 'Hva er riktig om din erfaring med unoterte aksjer?',
    id: 'traded_more_than_three_times',
    subTitle: 'Hyppighet (velg én)',
    trueLabel: 'Jeg har handlet i unoterte aksjer mer enn tre ganger',
    falseLabel: 'Handlet i unoterte aksjer mindre enn tre ganger',
  },
  {
    title: 'Spørsmål 5c',
    label: 'Hva er riktig om din erfaring med unoterte aksjer?',
    id: 'traded_for_more_than_100k_nok',
    subTitle: 'Omfang (velg én)',
    trueLabel: 'Jeg har handlet i unoterte aksjer for mer enn 100 000kr',
    falseLabel: 'Jeg har handlet i unoterte aksjer for mindre enn 100 000kr',
  },
  {
    title: 'Spørsmål 6',
    id: 'has_the_knowledge_needed',
    label: 'Har du nødvendig kunnskap til å forstå risikoen ved investering i unoterte aksjer?',
    answer: true,
  },
  {
    title: 'Spørsmål 7',
    id: 'can_afford_to_lose_investment',
    label: 'Har du råd til å tape hele investeringsbeløpet du planlegger å investere for på folkeinvest.no?',
    answer: true,
  },
  {
    title: 'Spørsmål 8',
    id: 'has_relevant_education',
    label: 'Har du fullført høyere utdanning innen økonomi eller finans?',
  },
  {
    title: 'Spørsmål 9',
    id: 'has_relevant_work_experience',
    label: 'Har du relevant yrkeserfaring innenfor økonomi eller finans?',
  },
  {
    title: 'Spørsmål 10',
    id: 'has_steady_income',
    label: 'Har du fast inntekt?',
  },
]

const skip = ['traded_last_five_years', 'traded_more_than_three_times', 'traded_for_more_than_100k_nok']

const getQuestion = (key) => {
  return [...questions].filter((q) => q.id === key).pop()
}

const getList = (testResult) => {
  const list = {}
  questions.forEach((q) => {
    if (!(!testResult.has_previous_experience && skip.includes(q.id))) {
      list[q.id] = q
    }
  })
  return list
}

const isDone = (testResult) => {
  let result = true
  Object.keys(getList(testResult)).forEach((key) => {
    if (testResult[key] === undefined) {
      result = false
    }
  })
  return result
}

const hasPassed = (testResult) => {
  const ans = testResult // Just shortening the variable name a bit
  let result = true
  Object.keys(ans).forEach((key) => {
    const q = getQuestion(key).answer
    const a = ans[key]
    if (q !== undefined) {
      if (a !== q) {
        result = false
      }
    }
  })
  let checkMore = false
  if (ans.has_previous_experience) {
    if (!(ans.traded_last_five_years || ans.traded_more_than_three_times || ans.traded_for_more_than_100k_nok)) {
      checkMore = true
    }
  } else {
    checkMore = true
  }

  if (checkMore) {
    if (!(ans.has_relevant_education || ans.has_relevant_work_experience || ans.has_steady_income)) {
      result = false
    }
    if (!ans.has_steady_income) {
      result = false
    }
  }

  return result
}

export { isDone, getList, hasPassed }
