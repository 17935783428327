import * as React from 'react'
import dayjs from 'dayjs'
import {
  GetPushMessagesQuery,
  PushmessageUpdateDocument,
  PushmessageUpdateSubscription,
  PushmessageUpdateSubscriptionVariables,
  useGetPushMessagesQuery,
} from '/~/types/graphql'
import { SubscribeToMoreOptions } from '@apollo/client'
import { Pane, CrossIcon, Text, InfoSignIcon } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import styled, { keyframes } from 'styled-components'

type Message = GetPushMessagesQuery['getPushmessages'][number]

interface PushMessageProps {
  message: Message
  onHide: (msg: Message) => void
}

const TOP = '85px' // Navbar height

const slideIn = keyframes`
  0% {
    top: -100%;
  }
  100% {
    top: ${TOP};
  }
`
const AnimatedContainer = styled(Pane)`
  animation: ${slideIn} 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: fixed;
  width: 100%;
  top: ${TOP};
  z-index: 10;
  display: flex;
`
const PushMessage = ({ message, onHide }: PushMessageProps) => {
  const { colors, spacing } = useCustomTheme()
  const { content, startDate } = message
  const { medium } = useBreakpoint()

  return (
    <Pane
      background='rgba(255, 255, 255, 0.9)'
      minHeight={60}
      borderRadius={10}
      border={`3px solid ${colors.fiBlue}`}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      minWidth={medium ? '100%' : undefined}
      style={{ backdropFilter: 'blur(5px)' }}
    >
      <Pane width='100%' gap={2} flexDirection='column' display='flex' padding={spacing.xxs} paddingTop={5}>
        <Pane display='flex' alignItems='center' width='100%' gap={spacing.xxs}>
          <InfoSignIcon size={20} color={colors.fiBlue} />
          <Text fontStyle='italic' fontSize={14} fontWeight={400}>
            Driftsmelding
          </Text>
          <Text textTransform='capitalize' fontSize={14} fontWeight={600}>
            {dayjs(startDate).format('DD MMMM')}
          </Text>
          <Pane display='flex' justifyContent='flex-end' flex='1'>
            <CrossIcon key={`${message._id}_A`} onClick={onHide} cursor='pointer' size={30} />
          </Pane>
        </Pane>
        <Pane>
          <Text fontSize={14}>{content}</Text>
        </Pane>
      </Pane>
    </Pane>
  )
}

type SubToMoreOptions = SubscribeToMoreOptions<
  GetPushMessagesQuery,
  PushmessageUpdateSubscriptionVariables,
  PushmessageUpdateSubscription
>

const PushMessages = () => {
  const [hiddenMessages, setHiddenMessages] = React.useState<string[]>([])
  const { data, loading, subscribeToMore } = useGetPushMessagesQuery()
  const messages = loading ? [] : data?.getPushmessages || []
  const { spacing } = useCustomTheme()

  React.useEffect(() => {
    if (!loading) {
      try {
        const unsubscribe = subscribeToMore({
          document: PushmessageUpdateDocument,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) {
              return prev
            }
            const new_item = subscriptionData.data.pushmessageUpdate
            const idx = prev.getPushmessages.findIndex((x) => x._id === new_item._id)
            let pushmessages = []

            if (idx >= 0) {
              if (new_item.status === 'inactive') {
                pushmessages = prev.getPushmessages?.filter((pm) => pm._id !== new_item._id)
              } else {
                pushmessages = [...prev.getPushmessages]
                pushmessages[idx] = new_item
              }
            } else {
              pushmessages = [new_item, ...prev.getPushmessages]
            }
            return { getPushmessages: pushmessages }
          },
        } as SubToMoreOptions)
        return () => unsubscribe()
      } catch (_e) {}
    }
  }, [loading])

  const hideMessage = (msg: Message) => {
    setHiddenMessages([...hiddenMessages, msg._id])
  }

  const visibleMessages = messages.filter(
    (msg) =>
      !hiddenMessages.includes(msg._id) &&
      dayjs().isAfter(dayjs(msg.startDate)) &&
      !dayjs().isAfter(dayjs(msg.closeDate)),
  )

  return visibleMessages.length > 0 ? (
    <AnimatedContainer>
      <Pane
        display='flex'
        flexDirection='column'
        alignItems='flex-end'
        padding={spacing.xxs}
        gap={spacing.xxs}
        width='100%'
      >
        {visibleMessages.map((msg) => (
          <PushMessage key={msg._id} message={msg} onHide={() => hideMessage(msg)} />
        ))}
      </Pane>
    </AnimatedContainer>
  ) : null
}

export default PushMessages
