import * as React from 'react'
import { Button, Pane, Paragraph, Spinner, Strong, Small, Text, TextInput, toaster } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { verifyEmail } from '/~/utils/verifications'
import EmailSignupFormInput from './EmailSignupFormInput'

interface Props {
  working: boolean
  goBack: () => void
  registerUser: (email: string, password: string) => void
}

const EmailSignupForm = ({ working, goBack, registerUser }: Props) => {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const theme = useCustomTheme()

  const invalidEmail = () => !!email && !verifyEmail(email)
  const invalidPassword = () => !!password && password?.length < 6
  const invalidConfPassword = () => !!confirmPassword && password !== confirmPassword
  const disableSubmit = () =>
    !(email && password && confirmPassword) || invalidEmail() || invalidPassword() || invalidConfPassword()

  const handleRegister = () => {
    let error = null

    if (!email || invalidEmail()) {
      error = 'Epost må være utfylt'
    } else if (!password || invalidPassword()) {
      error = 'Passord må være utfylt'
    } else if (!confirmPassword || invalidConfPassword()) {
      error = 'Passord må være identiske'
    }

    if (error) {
      return toaster.danger(error)
    }

    registerUser(email, password)
  }

  return (
    <Pane display='flex' flexDirection='column' alignItems='center' width='100%'>
      <Pane width='100%'>
        <Paragraph size={300}>
          <Text color='danger'>
            <sup>*</sup>
          </Text>
          <Strong size={300}>E-post adresse</Strong> (påkrevd)
        </Paragraph>

        <TextInput
          width='98%'
          size='large'
          marginX={8}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
        />

        <Paragraph size={300} color='danger' marginLeft={10} opacity={invalidEmail() ? 1 : 0}>
          <Small>Ikke en gyldig e-post adresse!</Small>
        </Paragraph>
      </Pane>

      <Pane width='100%'>
        <Paragraph size={300}>
          <Text color='danger'>
            <sup>*</sup>
          </Text>
          <Strong size={300}>Passord</Strong> (påkrevd)
        </Paragraph>

        <EmailSignupFormInput onChange={(input) => setPassword(input)} value={password} name='password' />

        <Paragraph size={300} color='danger' marginLeft={10} opacity={invalidPassword() ? 1 : 0}>
          <Small>Passord må være minst 6 tegn.</Small>
        </Paragraph>
      </Pane>

      <Pane width='100%' marginBottom={theme.spacing.s}>
        <Paragraph size={300}>
          <Text color='danger'>
            <sup>*</sup>
          </Text>
          <Strong size={300}>Bekreft passord</Strong> (påkrevd)
        </Paragraph>

        <EmailSignupFormInput
          onChange={(input) => setConfirmPassword(input)}
          onEnter={handleRegister}
          value={confirmPassword}
          name='confirmPassword'
        />

        <Paragraph size={300} color='danger' marginLeft={10} opacity={invalidConfPassword() ? 1 : 0}>
          <Small>Passordene må være like.</Small>
        </Paragraph>
      </Pane>

      {working ? (
        <Spinner marginBottom={theme.spacing.l} />
      ) : (
        <Button
          size='small'
          appearance='primary'
          width={244}
          marginBottom={theme.spacing.xs}
          onClick={handleRegister}
          disabled={disableSubmit()}
        >
          Registrer
        </Button>
      )}

      <Button size='small' width={244} onClick={() => goBack()} marginBottom={theme.spacing.l}>
        Tilbake
      </Button>
    </Pane>
  )
}

export default EmailSignupForm
