import * as React from 'react'
import { Pane, StarEmptyIcon, StarIcon } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useSetReservationFeedbackScore } from './useSetScore.mutation'
import Comment from './Comment'

interface Props {
  reservationID: string
}

const Feedback = ({ reservationID }: Props) => {
  const [score, setScore] = React.useState(-1)
  const [hoverScore, setHoverScore] = React.useState(score)
  const theme = useCustomTheme()
  const { setReservationFeedbackScore } = useSetReservationFeedbackScore(reservationID)

  const handleSetScore = (val) => {
    setScore(val)
    setReservationFeedbackScore(val)
  }

  React.useEffect(() => setHoverScore(score), [score])

  return (
    <>
      <Pane display='flex' marginY={theme.spacing.xs}>
        {Array.from({ length: 5 }).map((_, i) => {
          const starScore = i + 1
          return (
            <Pane
              key={starScore}
              cursor='pointer'
              marginRight={12}
              onMouseOver={() => setHoverScore(starScore)}
              onClick={() => handleSetScore(starScore)}
            >
              {hoverScore >= starScore ? <StarIcon /> : <StarEmptyIcon />}
            </Pane>
          )
        })}
      </Pane>
      {score > -1 && <Comment score={score} reservationID={reservationID} />}
    </>
  )
}

export default Feedback
