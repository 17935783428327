import { Pane, Text } from 'evergreen-ui'
import * as React from 'react'
import { SelfreportItem } from '/~/types/graphql'

interface Props {
  item: SelfreportItem
}

export const NumberItem = ({ item }: Props) => {
  const { values } = item
  return <Pane>{values && <Text>Svar: {values?.[0]}</Text>}</Pane>
}
