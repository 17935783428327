import { Pane, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import * as React from 'react'
import { SelfreportItem } from '/~/types/graphql'
import { truncate } from 'lodash'

interface Props {
  item: SelfreportItem
}

export const ItemComment = ({ item }: Props) => {
  const theme = useCustomTheme()
  return (
    <Pane marginTop={theme.spacing.xxs}>
      <Text>
        Kommentar: <em>{item?.comment?.split(' ').map((word) => `${truncate(word, { length: 35 })} `)}</em>
      </Text>
    </Pane>
  )
}
