import { Pane, Paragraph, Text, TickIcon } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import * as React from 'react'
import { auth, Service } from '/fiweb/lib'
import { config } from '/~/utils/config'
import { StatusCodes } from 'http-status-codes'
import { UNKNOWN_ERROR } from '../constants'
import { SignicatCallback, SignicatCallbackProps } from './SignicatCallback'
import { getSignicatAuthError } from './utils'
import { Link } from 'react-router-dom'

export const SignicatBankIdVerificationCallback = () => {
  const [done, setDone] = React.useState(false)
  const { spacing } = useCustomTheme()
  const [redirectUrl, setRedirectUrl] = React.useState('')

  const handleWindowClosing = () => {
    if (!redirectUrl) {
      window.close()
    }
  }

  const onCallback: SignicatCallbackProps['onCallback'] = async (sessionId, callback) => {
    const verify = async () => {
      const res = await auth(config.authHost).verifyWithService({
        service: Service.SIGNICAT,
        token: sessionId,
      })

      if (!res.ok) {
        switch (res.status) {
          case StatusCodes.CONFLICT: {
            const resText = await res.text()
            const existingEmails = resText.split(', ')

            return {
              text: `Det eksisterer allerede en konto med denne bankID'en.
            Vi tillater kun én verifisert brukerprofil per person.
            Brukerprofil${existingEmails.length > 1 ? 'er' : ''} med samme personopplysninger:
            \n\n${existingEmails.join('\n')}\n\n
            Kontakt support via chattefunksjonen eller på support@folkeinvest.no for å løse dette.`,
            }
          }
          default: {
            const resError = await getSignicatAuthError(res)
            return { text: UNKNOWN_ERROR, provider: resError?.provider }
          }
        }
      }
      setDone(true)
      setTimeout(handleWindowClosing, 5000)
    }

    switch (callback) {
      case 'success':
      case 'error': {
        return verify()
      }

      case 'abort': {
        setTimeout(handleWindowClosing, 5000)
        break
      }
    }
  }

  React.useEffect(() => {
    if (redirectUrl) {
      const timer = setTimeout(() => {
        window.location.href = redirectUrl
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [redirectUrl])

  React.useEffect(() => {
    if (done) {
      try {
        const url = window.localStorage.getItem('current-bankid-window-path')
        if (url) {
          setRedirectUrl(url)
          window.localStorage.removeItem('current-bankid-window-path')
        }
      } catch {}
    }
  }, [done])

  if (done) {
    return (
      <Pane width='100%' padding={spacing.m} display='flex' flexDirection='column' alignItems='center' gap={spacing.xs}>
        <Pane display='flex' alignItems='center'>
          <TickIcon color='success' size={32} marginRight={spacing.xs} />
          <Text lineHeight='32px'>Din bruker er nå BankID verifisert</Text>
        </Pane>
        <Paragraph size={300} color='muted'>
          Dette vinduet vil lukke seg automatisk om 5 sekunder.
        </Paragraph>
        <Paragraph size={300} color='muted'>
          Hvis det ikke skjer noe innen 5 sekunder, kan du klikke <Link to={redirectUrl || '/konto/profil'}>her</Link>
        </Paragraph>
      </Pane>
    )
  }

  return <SignicatCallback onCallback={onCallback} />
}
