import * as React from 'react'
import { IconProps } from './types'

export const PictureSun = ({ color, title, ...rest }: IconProps) => (
  <svg width='49' height='49' viewBox='0 0 49 49' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <rect
      x='1.67334'
      y='1.67334'
      width='45.6535'
      height='45.6535'
      rx='1.5'
      stroke={color || 'currentColor'}
      strokeWidth='1.5'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.6308 21.4565C36.9927 21.4565 39.718 18.7312 39.718 15.3694C39.718 12.0075 36.9927 9.28223 33.6308 9.28223C30.269 9.28223 27.5437 12.0075 27.5437 15.3694C27.5437 18.7312 30.269 21.4565 33.6308 21.4565Z'
      stroke={color || 'currentColor'}
      strokeWidth='1.5'
    />
    <path
      d='M8.18872 30.5039C12.3893 28.11 17.4238 27.6631 21.9803 29.2798C26.5368 30.8964 30.1639 34.4164 31.9164 38.9224'
      stroke={color || 'currentColor'}
      strokeWidth='1.5'
    />
    <path
      d='M29.3333 34.479C32.6702 31.4373 37.7414 31.3289 41.2052 34.2253'
      stroke={color || 'currentColor'}
      strokeWidth='1.5'
    />
  </svg>
)
