import * as React from 'react'
import { Pane, Paragraph, Text } from 'evergreen-ui'
import { FetchCampaignQuery } from '/~/types/graphql'
import moment from 'moment'

interface Props {
  campaign: FetchCampaignQuery['Campaign']
}

const SigningNotOpen = ({ campaign }: Props) => {
  return (
    <Pane textAlign='center'>
      <Paragraph>Ikke åpen for tegning av aksjer</Paragraph>
      <Paragraph>Tegningsvindu:</Paragraph>
      {campaign.startDate && campaign.closeDate ? (
        <Text>
          {moment(campaign.startDate).format('DD MMM YYYY HH:mm')} -&nbsp;
          {moment(campaign.closeDate).format('DD MMM YYYY HH:mm')}
        </Text>
      ) : (
        <Text>Ikke satt enda</Text>
      )}
    </Pane>
  )
}

export default SigningNotOpen
