import { useEffect } from 'react'
import {
  GetReservationsSummaryQuery,
  SubscribeToReservationsSummaryDocument,
  SubscribeToReservationsSummarySubscription,
  SubscribeToReservationsSummarySubscriptionVariables,
  useGetReservationsSummaryQuery,
} from '/~/types/graphql'
import { SubscribeToMoreOptions } from '@apollo/client'

type SubToMoreOptions = SubscribeToMoreOptions<
  GetReservationsSummaryQuery,
  SubscribeToReservationsSummarySubscriptionVariables,
  SubscribeToReservationsSummarySubscription
>

const useSummarySubQuery = (campaignId, includePreorders) => {
  const query = useGetReservationsSummaryQuery({
    variables: { campaignId, includePreorders },
  })
  useEffect(() => {
    const type = includePreorders ? 'TOTAL' : 'ACTIVE'
    const unsub = query.subscribeToMore({
      document: SubscribeToReservationsSummaryDocument,
      variables: { campaignId, type },
      updateQuery: (prev, { subscriptionData }) => {
        const old = prev.getReservationsSummaryForCampaign
        const rs = subscriptionData?.data?.reservationsSummaryUpdated
        if (old?._id === rs?._id) {
          return { getReservationsSummaryForCampaign: rs }
        }
        return prev
      },
    } as SubToMoreOptions)
    return () => unsub()
  }, [])
  const summary = query?.data?.getReservationsSummaryForCampaign || {
    count: 0,
    total: 0,
    shares: 0,
  }
  return { summary, loading: !!query?.loading }
}

export default useSummarySubQuery
