import React from 'react'
import { Card, Heading, Paragraph, PaneProps } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'

interface Props extends PaneProps {
  children?: React.ReactNode
  heading?: string
  text?: string
  title?: string
}

export const SmallInfoCard = ({ children, heading, text, title, order, ...rest }: Props) => {
  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Card
      order={order}
      backgroundColor={theme.colors.fiGreen10}
      marginBottom={large ? theme.spacing.xs : 0}
      paddingBottom={medium ? theme.spacing.m : theme.spacing.s}
      paddingLeft={medium ? 21 : theme.spacing.s}
      paddingRight={medium ? 17 : theme.spacing.s}
      paddingTop={medium ? theme.spacing.m : 35}
      maxWidth={medium ? '325px' : '326px'}
      width='100%'
      borderBottomLeftRadius='10px'
      borderBottomRightRadius='30px'
      borderTopLeftRadius='10px'
      borderTopRightRadius='10px'
      position='relative'
      zIndex={2}
      {...rest}
    >
      {heading && <Heading size={medium ? 500 : 600}>{heading}</Heading>}
      {title && <Heading size={400}>{title}</Heading>}
      {text && <Paragraph size={400}>{text}</Paragraph>}
      {children}
    </Card>
  )
}
