import { TextInputProps } from 'evergreen-ui'
import * as React from 'react'
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { TextField } from './TextField'
import { CommonControlledProps, WithRequired } from './types'
import { Message } from '/fiweb/lib/i18n/types'

interface Props extends CommonControlledProps {
  onChange?: (val: string) => void
  placeholder?: Message
  prefix?: Message
  suffix?: Message
  textInputProps?: TextInputProps
  triggerOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const ControlledTextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  disabled,
  label,
  name,
  caption,
  onChange: customOnChange,
  placeholder,
  prefix,
  required,
  rules,
  triggerOnChange,
  suffix,
  textInputProps,
  wrapperProps,
  shouldUnregister,
}: Props &
  WithRequired<
    Pick<ControllerProps<TFieldValues, TName>, 'name' | 'rules' | 'shouldUnregister' | 'control'>,
    'control'
  >) => {
  const intl = useIntl()

  return (
    <Controller
      name={name}
      rules={{ required, ...rules }}
      control={control}
      shouldUnregister={shouldUnregister}
      render={({ field: { onChange, value, ref, name: fieldName } }) => (
        <TextField
          name={fieldName}
          wrapperProps={{
            prefix: prefix ? <FormattedMessage id={prefix} /> : undefined,
            suffix: suffix ? <FormattedMessage id={suffix} /> : undefined,
            label: label ? <FormattedMessage id={label} /> : undefined,
            caption: caption ? <FormattedMessage id={caption} /> : undefined,
            width: '100%',
            ...wrapperProps,
            required: !!required,
          }}
          textInputProps={{
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              const str = e.target.value
              customOnChange ? onChange(customOnChange(str)) : onChange(str)
              triggerOnChange?.(e)
            },
            width: '100%',
            placeholder: placeholder ? intl.formatMessage({ id: placeholder }) : undefined,
            disabled,
            ...textInputProps,
            value: value ?? '',
            ref,
          }}
        />
      )}
    />
  )
}
