import React from 'react'
import { Card, Heading, Paragraph, PaneProps, HeadingSize } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'

interface Props extends PaneProps {
  header?: React.ReactNode
  text?: React.ReactNode
  headerSize?: HeadingSize
  paragraphSize?: 300 | 400 | 500
}

export const LargeInfoCard = ({ children, header, headerSize, paragraphSize, text, ...rest }: Props) => {
  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Card
      backgroundColor={theme.colors.fiGreen10}
      width='100%'
      maxWidth={medium ? '100%' : large ? '768px' : '1010px'}
      paddingY={medium ? theme.spacing.m : theme.spacing.l}
      paddingX={medium ? theme.spacing.xs : theme.spacing.xl}
      borderRadius='10px'
      borderBottomRightRadius='30px'
      {...rest}
    >
      {header && <Heading size={headerSize ?? 500}>{header}</Heading>}
      {text && (
        <Paragraph size={paragraphSize ?? 500} marginBottom={medium ? theme.spacing.xs : theme.spacing.s}>
          {text}
        </Paragraph>
      )}
      {children}
    </Card>
  )
}
