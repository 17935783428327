import * as React from 'react'
import { useInViewRef } from 'rooks'

type InViewRefReturn = ReturnType<typeof useInViewRef>

export const context = React.createContext<InViewRefReturn>([] as unknown as InViewRefReturn)

interface Props {
  children: React.ReactNode
}

export const Provider = ({ children }: Props) => {
  // -100px is based on the NavBar height when the page is scrolled
  // There was no good way to extract this from the NavBar component
  const inView = useInViewRef(undefined, { rootMargin: '-100px 0px 0px 0px' })

  return <context.Provider value={inView}>{children}</context.Provider>
}

export const useIsActionBarInView = () => {
  return React.useContext(context)
}
