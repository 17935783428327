import { Button, Pane } from 'evergreen-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { usePublicInvestmentOfferFlags } from '../Context/PublicInvestmentOfferData'
import { KeyInformationDialog } from '../KeyInformation/KeyInformationDialog'
import { AlertOrCaption, CaptionText } from './AlertOrCaption'
import { PrimaryAction } from './PrimaryAction'
import { breakpoints, useBreakpoint } from '/fiweb/lib'

const ActionButtonBar = styled(Pane)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing.xs};
  position: sticky;
  bottom: 0px;

  @media ${breakpoints.medium} {
    flex-wrap: wrap;
    ${CaptionText} {
      order: 3;
      width: 100%;
      text-align: center;
    }
  }
`

const Buttons = styled(Pane)`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme: { spacing } }) => spacing.xs};
  flex: 1;
  button,
  a {
    flex: 1;
    max-width: 192px;
  }
`

interface Props {
  renderDialog?: boolean
  onlyButtons?: boolean
  onCancel?: () => void
}

export const ActionBar = ({ renderDialog = true, onlyButtons = false, onCancel }: Props) => {
  const location = useLocation()
  const [showKeyInfoOverlay, setShowKeyInfoOverlay] = React.useState(false)
  const { medium } = useBreakpoint()
  const { isPreview, isReadonly } = usePublicInvestmentOfferFlags() // PREVIEW-HACK

  React.useEffect(() => {
    setShowKeyInfoOverlay(false)
  }, [medium, location])

  const onSecondaryClick = () => {
    if (onCancel) {
      onCancel()
    } else {
      setShowKeyInfoOverlay(true)
    }
  }

  return (
    <>
      {renderDialog && (
        <KeyInformationDialog isShown={showKeyInfoOverlay} onClose={() => setShowKeyInfoOverlay(false)} />
      )}
      <ActionButtonBar>
        {!onlyButtons && <AlertOrCaption />}
        <Buttons>
          {medium && (
            <Button size='small' backgroundColor='white' onClick={onSecondaryClick}>
              <FormattedMessage id={`investmentOfferPage.${onCancel ? 'hideKeyInformation' : 'showKeyInformation'}`} />
            </Button>
          )}
          {/* PREVIEW-HACK */}
          {!(isPreview || isReadonly) && <PrimaryAction />}
        </Buttons>
      </ActionButtonBar>
    </>
  )
}
