import { Link, Pane } from 'evergreen-ui'
import styled, { css } from 'styled-components'

export const NavigationBackground = styled(Pane)`
  ${({ theme: { colors, spacing } }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, ${colors.fiTint} 45.31%, rgba(0, 0, 0, 0) 100%);
    padding: ${spacing.s} 0;
  `}
`

export const NavigationContainer = styled(Pane)`
  ${({ theme: { spacing } }) => css`
    width: 100%;
    max-width: 1260px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    margin: ${spacing.xxs} 0;
  `}
`

interface MenuLinkProps {
  $selected: boolean
  $submenu?: boolean
  $disabled?: boolean
}

export const MenuLink = styled(Link)<MenuLinkProps>`
  ${({ $disabled, $selected, $submenu, theme: { colors, fontWeights } }) => css`
    text-decoration: none;
    cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    color: ${$disabled ? colors.muted : colors.fiDark};
    font-weight: ${$submenu ? fontWeights.normal : fontWeights.semibold};
    border-bottom: ${`2px solid ${$selected ? colors.fiBlue : 'transparent'}`};
    &:hover {
      color: ${$disabled ? colors.muted : 'default'};
    }
  `}
`
