import { FullscreenIcon, MinimizeIcon, Pane, PaneProps, Paragraph, ParagraphProps } from 'evergreen-ui'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { useBreakpoint } from '/fiweb/lib'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useLiveInvestmentOfferUpdates } from './useLiveInvestmentOffferUpdates'

export const VerticalBar = (props: PaneProps) => {
  const { mobileLandscape } = useLiveInvestmentOfferUpdates()
  const { medium } = useBreakpoint()
  return (
    <Pane
      position='absolute'
      transform='translate(-100%, 0)'
      borderLeft={`${medium || mobileLandscape ? '3' : '5'}px solid white`}
      height={mobileLandscape ? 50 : 75}
      boxShadow='0 0 5px black'
      transition='3s'
      {...props}
    />
  )
}

export const LiveText: React.FC<ParagraphProps> = (props) => {
  const { mobileLandscape } = useLiveInvestmentOfferUpdates()
  const { medium } = useBreakpoint()
  return (
    <Paragraph
      size={medium || mobileLandscape ? 400 : 500}
      whiteSpace='nowrap'
      fontWeight={600}
      color='white'
      textShadow='0 0 5px black'
      {...props}
    />
  )
}

export const FullscreenButton = () => {
  const [isFullscreen, setIsFullscreen] = React.useState(false)
  const { spacing } = useCustomTheme()

  React.useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullscreen(Boolean(document.fullscreenElement))
    }
    document.addEventListener('fullscreenchange', onFullscreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange)
    }
  }, [])

  React.useEffect(() => {
    return () => {
      if (isFullscreen) {
        document.exitFullscreen().catch(() => undefined)
      }
    }
  }, [isFullscreen])

  return (
    <Pane
      position='absolute'
      top={spacing.xs}
      right={spacing.xs}
      cursor='pointer'
      color='white'
      onClick={() => {
        if (isFullscreen) {
          document.exitFullscreen()
        } else {
          document.body.requestFullscreen()
        }
      }}
      style={{
        mixBlendMode: 'difference',
      }}
    >
      {isFullscreen ? <MinimizeIcon size={20} /> : <FullscreenIcon size={20} />}
    </Pane>
  )
}

export const FloatingSideText = styled(Pane)`
  ${({ theme }) => css`
    padding-left: ${theme.spacing.xxs};
    padding-right: ${theme.spacing.xxs};
    position: absolute;
    display: flex;
    align-items: center;
`}`

export const FloatingTextContainer = ({ $flipped, ...rest }: PaneProps & { $flipped?: boolean }) => {
  const { mobileLandscape } = useLiveInvestmentOfferUpdates()
  const { spacing } = useCustomTheme()
  return (
    <Pane
      position='absolute'
      transition='3s'
      height={mobileLandscape ? 50 : 75}
      paddingX={spacing.xs}
      display='flex'
      flexDirection='column'
      justifyContent='flex-end'
      transform={$flipped ? 'unset' : 'translate(-100%, 0)'}
      align-items={$flipped ? 'flex-start' : 'flex-end'}
      {...rest}
    />
  )
}
