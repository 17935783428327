import { Button, ChevronDownIcon, ChevronUpIcon, CrossIcon, HomeIcon, MenuIcon, Pane } from 'evergreen-ui'
import * as React from 'react'
import { useCustomTheme } from '/fiweb/lib/theme'
import { navItems } from './navItems'
import { Link as RouterLink } from 'react-router-dom'
import NavLogo from './NavLogo'
import { NavBarAuth } from './NavBarAuth'
import { Divider, EnhancedDialog } from '/fiweb/components'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'react-intl'

const StyledPane = styled(Pane)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: ${theme.spacing.xxs};
`}`

const FixedFontSizeWrapper = styled(Pane)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-left: ${theme.spacing.m};
    padding-right: ${theme.spacing.m};
    flex-grow: 1;
    align-items: flex-start;
    justify-content: space-between;
    
    & button, a {
      font-size: 24px;
    }
`}`

type MenuKey = (typeof navItems)[number]['key']

export const NavBarMobileMenu = () => {
  const [open, setOpen] = React.useState(false)
  const { spacing } = useCustomTheme()
  const [menuOpen, setMenuOpen] = React.useState<MenuKey | null>(null)

  const toggleMenu = (name: MenuKey) => {
    setMenuOpen(menuOpen === name ? null : name)
  }

  React.useEffect(() => {
    if (!open) {
      setMenuOpen(null)
    }
  }, [open])

  return (
    <>
      <Pane padding={spacing.xxs} cursor='pointer' onClick={() => setOpen(true)}>
        <MenuIcon size={30} />
      </Pane>
      <EnhancedDialog
        preventBodyScrolling
        contentContainerProps={{
          padding: 0,
        }}
        isShown={open}
        onCloseComplete={() => setOpen(false)}
        fullscreen={true}
        hasFooter={false}
        hasHeader={false}
      >
        <Pane display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
          <Pane
            width='100%'
            display='flex'
            justifyContent='space-between'
            paddingX={spacing.s}
            height={80}
            alignItems='center'
          >
            <Pane width={30} overflow='hidden' is={RouterLink} onClick={() => setOpen(false)} to='/'>
              <NavLogo small />
            </Pane>
            <Pane onClick={() => setOpen(false)} padding={spacing.xxs}>
              <CrossIcon size={30} />
            </Pane>
          </Pane>
          <FixedFontSizeWrapper>
            <StyledPane>
              <Button iconBefore={HomeIcon} appearance='text' onClick={() => setOpen(false)} is={RouterLink} to='/'>
                <FormattedMessage id='clientNavbarLabels.frontPage' />
              </Button>
              <Divider width='100%' />
              <Pane display='flex' flexDirection='column' alignItems='flex-start' minWidth={300} gap={spacing.xxs}>
                {navItems.map((navItem) =>
                  'subMenus' in navItem ? (
                    <React.Fragment key={navItem.key}>
                      <Button
                        appearance='text'
                        onClick={() => toggleMenu(navItem.key)}
                        iconAfter={menuOpen === navItem.key ? ChevronUpIcon : ChevronDownIcon}
                      >
                        <FormattedMessage id={navItem.labelId} />
                      </Button>
                      {menuOpen === navItem.key &&
                        navItem.subMenus.map((item) => (
                          <Button
                            key={item.key}
                            marginLeft={spacing.s}
                            appearance='text'
                            is={RouterLink}
                            to={item.to}
                            fontWeight={400}
                            onClick={() => setOpen(false)}
                          >
                            <FormattedMessage id={item.labelId} />
                          </Button>
                        ))}
                    </React.Fragment>
                  ) : (
                    <Button
                      key={navItem.key}
                      appearance='text'
                      is={RouterLink}
                      to={navItem.to}
                      onClick={() => setOpen(false)}
                    >
                      <FormattedMessage id={navItem.labelId} />
                    </Button>
                  ),
                )}
              </Pane>
              <Divider width='100%' />
            </StyledPane>
            <Pane paddingY={spacing.s} alignItems='center' display='flex' flexDirection='column' width='100%'>
              <NavBarAuth onClose={() => setOpen(false)} />
            </Pane>
          </FixedFontSizeWrapper>
        </Pane>
      </EnhancedDialog>
    </>
  )
}
