import * as React from 'react'
import { TextInput, TextInputProps } from 'evergreen-ui'
import { InputWrapper, InputWrapperProps } from './InputWrapper'

interface Props {
  name: string
  wrapperProps: InputWrapperProps
  textInputProps: TextInputProps
}

export const TextField = ({ name, wrapperProps, textInputProps }: Props) => {
  const uid = React.useMemo(() => `text-field-label-${new Date().getTime()}`, [])

  return (
    <InputWrapper uid={uid} {...wrapperProps}>
      <TextInput name={name} isInvalid={!!wrapperProps?.error} aria-labelledby={uid} {...textInputProps} />
    </InputWrapper>
  )
}
