import * as React from 'react'
import MediaView from './MediaView'
import { useGetAllMediaMentionsQuery } from '/~/types/graphql'
import { Helmet } from '/~/components'
import { config } from '/~/utils/config'

const MediaMentions = () => {
  const { data, loading } = useGetAllMediaMentionsQuery()
  const list = data?.getAllMediaMentions || []
  return (
    <>
      <Helmet
        title='Siste nytt fra Folkeinvest'
        description='Les våre siste publiserte pressemeldinger og medieomtaler.'
        url={`${window.location.origin}/presse/medieomtale`}
        imageUrl={`${config.publicFilesUrl}/meta/media-mentions.png`}
        keywords='Invester i aksjer, invester i startups, investere, hente kapital, finansiering av startups'
      />

      <MediaView list={list} loading={loading} />
    </>
  )
}

export default MediaMentions
