import * as React from 'react'
import {
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  ErrorIcon,
  Heading,
  Pane,
  Paragraph,
  TickCircleIcon,
} from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import { hasPassed, getList } from './questions'
import { useAuthContext } from '/~/utils/AuthContext'

interface Props {
  onAbort: () => void
  testResult: Record<string, string | boolean>
  onSign: () => void
}

const SummaryDisplay = ({ testResult, onSign }: Props) => {
  const [resultsOpen, setResultsOpen] = React.useState(false)
  const { user } = useAuthContext()
  const multipleAttempts = user?.expediency?.totalAttempts > 1
  const theme = useCustomTheme()
  const { medium } = useBreakpoint()
  const questions = getList(testResult)

  return (
    <Pane>
      <Heading is='h5' size={400}>
        Konklusjon
      </Heading>
      {hasPassed(testResult) ? (
        <Paragraph>
          <TickCircleIcon color={theme.colors.fiGreen} marginRight={12} />
          Folkeinvest AS ser ingen grunn til å fraråde investering i unoterte aksjer på folkeinvest.no. Lykke til!
        </Paragraph>
      ) : (
        <Paragraph>
          <ErrorIcon color='danger' marginRight={12} />
          Folkeinvest AS fraråder {multipleAttempts && 'fortsatt '}investering i unoterte aksjer på folkeinvest.no.
        </Paragraph>
      )}

      <Pane marginTop={theme.spacing.xs}>
        <Button appearance='primary' size='small' onClick={() => setResultsOpen(!resultsOpen)}>
          Se over dine svar her {resultsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Button>
        {resultsOpen &&
          Object.keys(questions).map((key) => {
            const q = questions[key]
            let answer = testResult[key] ? 'Ja' : 'Nei'
            if (q.trueLabel && q.falseLabel) {
              answer = testResult[key] ? q.trueLabel : q.falseLabel
            }
            return (
              <Pane key={key} marginTop={theme.spacing.s}>
                <Heading is='h6' size={300}>
                  {q.title}
                </Heading>

                <Paragraph size={300}>
                  <em>
                    {q.label}{' '}
                    {q.subTitle ? (
                      <span>
                        <br />({q.subTitle})
                      </span>
                    ) : (
                      ''
                    )}
                  </em>
                </Paragraph>
                <Paragraph size={300} marginTop='12px'>
                  <b>Ditt svar:</b>
                  <br />
                  {answer}
                </Paragraph>
              </Pane>
            )
          })}
      </Pane>

      <Paragraph marginTop={theme.spacing.s}>
        Ved å trykke 'Fortsett' vil du godta konklusjonen av dette skjemaet.
      </Paragraph>
      <Pane
        display='flex'
        flexDirection={medium ? 'column-reverse' : 'row'}
        justifyContent='flex-end'
        marginTop={theme.spacing.xs}
        marginBottom={theme.spacing.xs}
      >
        <Button
          size='small'
          appearance='primary'
          onClick={onSign}
          marginLeft={medium ? 0 : 18}
          marginBottom={medium ? theme.spacing.xs : 0}
        >
          Fortsett
        </Button>
      </Pane>
    </Pane>
  )
}

export default SummaryDisplay
