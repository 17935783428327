export const footerMenuItems = {
  contact: [
    {
      title: 'Trondheim',
      link: 'https://goo.gl/maps/6PNgrCceUosgrwyy5',
    },
    {
      title: 'Oslo',
      link: 'https://maps.app.goo.gl/z1RKKeu8hMgQK5Vy8',
    },
    {
      title: 'Molde',
      link: 'https://goo.gl/maps/bixspCzMFHpBKdep9',
    },
  ],
  information: [
    {
      title: 'Hjelp',
      link: 'https://hjelpesenter.folkeinvest.no/',
    },
    {
      title: 'Investeringsrisiko',
      link: '/risiko',
    },
    {
      title: 'Statistikk',
      link: '/statistikk',
    },
  ],
  socialMedia: [
    {
      title: 'Facebook',
      link: 'https://www.facebook.com/Folkeinvest/',
    },
    {
      title: 'LinkedIn',
      link: 'https://www.linkedin.com/company/folkeinvest/',
    },
    {
      title: 'Instagram',
      link: 'https://www.instagram.com/folkeinvest.no/',
    },
    {
      title: 'Spotify',
      link: 'https://open.spotify.com/show/0BQqdyBmLgt3vzINUX6cht',
    },
    {
      title: 'YouTube',
      link: 'https://www.youtube.com/channel/UCIYIVCHTh13mZI-fa7GoviQ',
    },
    {
      title: 'Twitter',
      link: 'https://twitter.com/folkeinvest',
    },
  ],
}
