import * as React from 'react'
import anchorme from 'anchorme'
import _ from 'lodash'
import { FetchCampaignQuery } from '/~/types/graphql'
import { Heading, Pane, Paragraph, Link } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled, { css } from 'styled-components'
import MemberDialog from './MemberDialog'
import { useBreakpoint } from '/fiweb/lib'
import { Divider } from '/fiweb/components'
import { FacebookIcon, LinkedInIcon, TwitterIcon } from '/fiweb/components/Icons'
import { config } from '/~/utils/config'

export type MemberType = FetchCampaignQuery['Campaign']['partners'][number]
export type MemberTypeString = 'partner' | 'team' | 'board'

const MemberImage = styled.img`
  display: block;
  width: 285px;
  height: 312px;
  max-height: 312px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid ${({ theme: { colors } }) => colors.fiDark10};
  box-sizing: border-box;
`

export const BioContainer = styled(Paragraph)`
  ${({ theme: { colors } }) => css`
    color: ${colors.text.default};
    a[href],
    a[href]:visited {
      color: ${colors.fiBlue};
    }
    a[href]:hover {
      color: inherit;
    }
  `}
`

// Method to get the correct subheader
export const getSubheader = (memberType: MemberTypeString, member: MemberType) => {
  if (memberType === 'team') {
    if (member.title) {
      return member.title
    }
    return ''
  }
  if (memberType === 'board') {
    if (member.role) {
      return member.role
    }
    return 'Styremedlem'
  }
  return null
}

interface Props {
  member: MemberType
  memberType: MemberTypeString
  dialog?: boolean
}

const Member = ({ member, memberType }: Props) => {
  const { small } = useBreakpoint()
  const theme = useCustomTheme()
  const ref = React.createRef<HTMLDivElement>()
  const [overflow, setOverflow] = React.useState(false)
  const [showDialog, setShowDialog] = React.useState(false)
  const [showMore, setShowMore] = React.useState(false)

  React.useLayoutEffect(() => {
    if (ref.current.clientHeight < ref.current.scrollHeight - 39) {
      setOverflow(true)
    }
  }, [ref])

  const handleClick = () => {
    if (overflow) {
      if (!small) {
        setShowDialog(true)
      } else {
        setShowMore(!showMore)
      }
    }
  }

  const getParagraphProps = () => {
    if (showMore) {
      return { minHeight: 216, cursor: 'pointer' }
    }
    return {
      height: 216,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: overflow ? 'pointer' : 'default',
    }
  }

  const { name, picture, bio, linkedin, twitter, facebook } = member
  const role = getSubheader(memberType, member)
  const paragraphProps = getParagraphProps()

  return (
    <Pane width={285}>
      {picture?.url ? (
        <MemberImage alt='Kunne ikke laste bilde' src={picture.url} />
      ) : (
        <MemberImage alt='Fant ikke bilde, kontakt support' src={`${config.publicFilesUrl}/profile-default.png`} />
      )}

      <Heading
        is='h3'
        size={500}
        marginBottom={theme.spacing.xxs}
        marginTop={theme.spacing.xxs}
        textOverflow='ellipsis'
        whiteSpace='nowrap'
        overflow='hidden'
      >
        {name}
      </Heading>
      <Paragraph size={400} marginBottom={theme.spacing.xxs}>
        {role}
      </Paragraph>

      <Pane onClick={handleClick}>
        <Pane ref={ref} {...paragraphProps}>
          {bio?.split('\n').map((paragraph, i) => {
            if (!paragraph) {
              return <br key={`c${paragraph}k${i}`} />
            }
            const content = anchorme(paragraph, {
              truncate: 30,
              attributes: [{ name: 'target', value: '_blank' }],
            })

            const words = content
              .split(' ')
              .map((word) => {
                if (word.startsWith('href')) {
                  return word
                }
                return _.truncate(word, { length: 40, omission: '[...]' })
              })
              .join(' ')
            const html = { __html: words }
            return <BioContainer key={`c${paragraph}k${i}`} size={300} dangerouslySetInnerHTML={html} />
          })}
        </Pane>

        <Pane display='flex' visibility={overflow ? 'visible' : 'hidden'} cursor='pointer'>
          <Link size={300} lineHeight='18px' marginTop={12}>
            {showMore ? 'Skjul' : 'Les mer'}
          </Link>
        </Pane>
      </Pane>

      <Divider marginTop={18} marginBottom={12} />

      <Pane display='flex' columnGap={12}>
        {linkedin && (
          <a href={linkedin} target='_blank' rel='noreferrer'>
            <LinkedInIcon height={32} style={{ color: theme.colors.fiDark70 }} />
          </a>
        )}
        {twitter && (
          <a href={twitter} target='_blank' rel='noreferrer'>
            <TwitterIcon height={32} style={{ color: theme.colors.fiDark70 }} />
          </a>
        )}
        {facebook && (
          <a href={facebook} target='_blank' rel='noreferrer'>
            <FacebookIcon height={27} width={27} style={{ color: theme.colors.fiDark70, marginTop: 3 }} />
          </a>
        )}
      </Pane>

      <MemberDialog open={showDialog} member={member} memberType={memberType} onClose={() => setShowDialog(false)} />
    </Pane>
  )
}

export default Member
