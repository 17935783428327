import * as React from 'react'
import { Pane, PaneProps } from 'evergreen-ui'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { breakpoints } from '/fiweb/lib'
import { BackToTopButton } from './BackToTopButton'

const StyledPane = styled(Pane)`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 ${spacing.s};
    min-height: 100vh;
    @media ${breakpoints.medium} {
      margin: 0 ${spacing.xs};
      padding-left: ${spacing.xs};
      padding-right: ${spacing.xs};
    }
  `}
`

const ConstrainedPane = styled(StyledPane)`
  ${({ theme: { spacing } }) => css`
    max-width: 800px;
    @media ${breakpoints.medium} {
      padding-left: ${spacing.xs};
      padding-right: ${spacing.xs};
    }
  `}
`

export const InvestmentOfferPageContainer: React.FC<PaneProps> = ({ children, ...paneProps }) => {
  const location = useLocation()
  const unconstrainedPaths = ['beskrivelse', 'team-og-eiere']
  const reggy = new RegExp(`.*(${unconstrainedPaths.join('|')})(\/$|$)`, 'gi')
  const ignoreStyling = !!reggy.exec(location.pathname)

  return (
    <>
      {ignoreStyling ? (
        <StyledPane {...paneProps}>{children}</StyledPane>
      ) : (
        <ConstrainedPane {...paneProps}>{children}</ConstrainedPane>
      )}
      <BackToTopButton />
    </>
  )
}
