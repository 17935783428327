import * as React from 'react'
import { IconProps } from './types'

export const SwedishBankIdLogo = ({ title, ...rest }: IconProps) => (
  <svg width='63' viewBox='0 0 83 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path
      d='M2.25254 0H10.7062C14.3103 0 15.1848 1.82853 14.9198 3.49806C14.7078 4.84958 13.7803 5.85661 12.1902 6.51912C14.2043 7.28763 14.9993 8.48016 14.7078 10.3617C14.3368 12.7202 12.2962 14.4693 9.61967 14.4693H0L2.25254 0ZM7.84414 5.98911C9.48717 5.98911 10.2557 5.11459 10.4147 4.08107C10.5737 2.96805 10.0702 2.19954 8.42715 2.19954H6.96962L6.38661 5.98911H7.84414ZM6.94313 12.2167C8.63916 12.2167 9.61967 11.5277 9.85818 10.1232C10.0437 8.90416 9.35467 8.18865 7.71164 8.18865H6.06861L5.4326 12.2432H6.94313V12.2167Z'
      fill='#1D1D1B'
    />
    <path
      d='M26.5535 14.5753C24.3539 14.7343 23.2939 14.4958 22.7639 13.5418C21.5979 14.2573 20.2993 14.6283 18.9213 14.6283C16.4303 14.6283 15.5558 13.3298 15.7943 11.8987C15.9003 11.2097 16.2978 10.5472 16.9338 9.99069C18.3118 8.79816 21.7039 8.63916 23.0289 7.73814C23.1349 6.73113 22.7374 6.36012 21.4919 6.36012C20.0343 6.36012 18.8153 6.83713 16.7218 8.26815L17.2253 4.9821C19.0273 3.68357 20.7764 3.07407 22.7904 3.07407C25.3609 3.07407 27.64 4.13408 27.216 6.94313L26.7125 10.1232C26.527 11.2362 26.58 11.5807 27.8255 11.6072L26.5535 14.5753ZM22.7374 9.56668C21.5714 10.3087 19.3983 10.1762 19.1598 11.7132C19.0538 12.4287 19.5043 12.9587 20.2198 12.9587C20.9089 12.9587 21.7569 12.6672 22.4459 12.1902C22.3929 11.9252 22.4194 11.6602 22.4989 11.1567L22.7374 9.56668Z'
      fill='#1D1D1B'
    />
    <path
      d='M30.661 3.25956H35.0601L34.8216 4.71709C36.2261 3.52457 37.2861 3.07407 38.6642 3.07407C41.1287 3.07407 42.2682 4.58459 41.8707 7.04914L40.7312 14.4428H36.3321L37.2861 8.32116C37.4716 7.20814 37.1271 6.67813 36.2791 6.67813C35.5901 6.67813 34.9541 7.04913 34.3446 7.87065L33.3376 14.4163H28.9385L30.661 3.25956Z'
      fill='#1D1D1B'
    />
    <path
      d='M45.3158 0H49.7149L48.6018 7.10213L52.8154 3.25956H58.248L52.8419 8.02964L57.188 14.4428H51.6494L48.3103 9.24867H48.2573L47.4623 14.4163H43.0632L45.3158 0Z'
      fill='#1D1D1B'
    />
    <path d='M60.8716 0H65.9332L63.7072 14.4428H58.6456L60.8716 0Z' fill='#1D1D1B' />
    <path
      d='M68.3977 0H75.6324C81.224 0 82.8405 4.05457 82.3105 7.42014C81.807 10.7062 79.2099 14.4428 74.3073 14.4428H66.1452L68.3977 0ZM73.0883 10.9977C75.5529 10.9977 76.9044 9.77867 77.3019 7.20813C77.5934 5.30009 77.0104 3.41856 74.2808 3.41856H72.9293L71.7633 10.9977H73.0883Z'
      fill='#1D1D1B'
    />
  </svg>
)
