import React, { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import Comment from './Comment'
import SubmitComment from './SubmitComment'
import { useUpdateCommentSubscriptionMutation } from '/~/types/graphql'
import { Campaign, Comment as CommentType } from './types'
import { Button, Heading, IconButton, Pane, Text, toaster, Tooltip } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import Divider from '/~/components/campaign/Campaign/Divider'
import { useAuthContext } from '/~/utils/AuthContext'
import { NotificationBell } from '/~/components/common/NotificationBell'

interface Props {
  index: number
  comment: CommentType
  locked: boolean
  campaign: Campaign
}

const CommentThread = ({ index, comment, locked, campaign }: Props) => {
  const { user } = useAuthContext()
  const [expandComments, setExpandComments] = useState(index === 0)
  const [answerOpen, setAnswerOpen] = useState(false)
  const [updateSubscription] = useUpdateCommentSubscriptionMutation()
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  const handleSubscribe = async () => {
    if (!user) {
      toaster.notify('Du er ikke logget inn, oppfrisk siden for å oppdatere innhold')
      return null
    }
    if (!user.emailVerified) {
      toaster.notify('Du må verifisere din epost for å kunne benytte diskusjons-varsler')
      return null
    }
    try {
      const res = await updateSubscription({
        variables: {
          commentID: comment._id,
          subscribed: !comment.isSubscribed,
        },
      })

      const msg = res?.data?.updateCommentSubscription?.isSubscribed ? 'nå motta' : 'ikke lengre motta'
      toaster.notify(`Du vil ${msg} epost om aktivitet i denne diskusjonen`)
    } catch (e) {
      console.log(e)
      toaster.danger(`En feil oppsto. Ta kontakt med support@folkeinvest.no med denne beskjeden: ${e.message}`)
    }
  }

  const children = comment.children || []

  const title = comment?.title || 'Diskusjon'

  const readOnly = (user && !user.bankidverified) || locked

  const isOwner = campaign.user && user?._id === campaign.user._id

  return (
    <Pane border={`1px solid ${theme.colors.fiDark70}`} borderRadius='10px' marginTop={theme.spacing.s}>
      <Pane padding='30px' paddingBottom='18px'>
        <Pane display='flex' justifyContent='space-between' marginBottom={theme.spacing.xs}>
          <Pane display='flex' flexDirection={medium ? 'column' : 'row'} alignItems='baseline'>
            <Heading is='h4' size={400} marginBottom={theme.spacing.xxs}>
              {title}
            </Heading>
            <Text size={300} marginLeft={medium ? 0 : 18}>
              Opprettet {moment(comment.createdAt).format('DD.MM.YYYY')}
            </Text>
          </Pane>
          {!isOwner && (
            <Tooltip content='Epost-notifikasjon'>
              <IconButton
                size='small'
                appearance='minimal'
                onClick={() => handleSubscribe()}
                icon={<NotificationBell subscribed={comment?.isSubscribed} />}
              />
            </Tooltip>
          )}
        </Pane>
        <Comment comment={comment} />
        {children.length > 0 && (
          <>
            <Divider
              color={theme.colors.fiDark40}
              style={{ marginTop: theme.spacing.xs, marginBottom: theme.spacing.xs }}
            />
            <Pane
              display='flex'
              justifyContent='space-between'
              cursor='pointer'
              onClick={() => setExpandComments(!expandComments)}
            >
              <Text fontWeight={600}>
                {children.length} kommentar{children.length !== 1 && 'er'}
              </Text>
              <Text fontWeight={600} color={theme.colors.fiBlue}>
                {expandComments ? 'Skjul' : 'Vis'}
              </Text>
            </Pane>
          </>
        )}
        {children.length === 0 && !readOnly && !answerOpen && (
          <>
            <Divider color={theme.colors.fiDark40} />
            <Pane display='flex' justifyContent='flex-end'>
              <Button size='small' onClick={() => setAnswerOpen(true)}>
                Legg til kommentar
              </Button>
            </Pane>
          </>
        )}
      </Pane>
      {expandComments && children.length > 0 && (
        <Pane
          backgroundColor={theme.colors.fiDark10}
          padding='30px'
          borderBottomLeftRadius='10px'
          borderBottomRightRadius='10px'
        >
          {children.map((ch, chIndex) => (
            <React.Fragment key={ch._id}>
              <Comment indent comment={ch} />
              {chIndex !== children.length - 1 && (
                <Divider color={theme.colors.fiDark40} style={{ margin: `${theme.spacing.s} 0` }} />
              )}
            </React.Fragment>
          ))}
          <Divider color={theme.colors.fiDark40} />
          {expandComments && !readOnly && !answerOpen && (
            <Pane display='flex' justifyContent='flex-end' marginTop={theme.spacing.s}>
              <Button size='small' onClick={() => setAnswerOpen(true)}>
                Legg til kommentar
              </Button>
            </Pane>
          )}
        </Pane>
      )}
      {answerOpen && (
        <Pane
          backgroundColor={theme.colors.fiDark10}
          padding='30px'
          borderBottomLeftRadius='10px'
          borderBottomRightRadius='10px'
        >
          <SubmitComment campaign={campaign} onClose={() => setAnswerOpen(false)} parent={comment} />
        </Pane>
      )}
    </Pane>
  )
}

export default CommentThread
