const getBrowserClientDetails = () => {
  const nAgt = navigator.userAgent
  let browserName = navigator.appName
  let fullVersion = `${Number.parseFloat(navigator.appVersion)}`
  let majorVersion = Number.parseInt(navigator.appVersion, 10)
  let nameOffset = 0
  let verOffset = 0
  let ix = 0

  // In Opera, the true version is after "Opera" or after "Version"
  if (nAgt.indexOf('Opera') !== -1) {
    verOffset = nAgt.indexOf('Opera')
    browserName = 'Opera'
    fullVersion = nAgt.substring(verOffset + 6)
    if (nAgt.indexOf('Version') !== -1) {
      verOffset = nAgt.indexOf('Version')
      fullVersion = nAgt.substring(verOffset + 8)
    }
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if (nAgt.indexOf('MSIE') !== -1) {
    verOffset = nAgt.indexOf('MSIE')
    browserName = 'Microsoft Internet Explorer'
    fullVersion = nAgt.substring(verOffset + 5)
  }
  // In Chrome, the true version is after "Chrome"
  else if (nAgt.indexOf('Chrome') !== -1) {
    verOffset = nAgt.indexOf('Chrome')
    browserName = 'Chrome'
    fullVersion = nAgt.substring(verOffset + 7)
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if (nAgt.indexOf('Safari') !== -1) {
    verOffset = nAgt.indexOf('Safari')
    browserName = 'Safari'
    fullVersion = nAgt.substring(verOffset + 7)
    if (nAgt.indexOf('Version') !== -1) {
      verOffset = nAgt.indexOf('Version')
      fullVersion = nAgt.substring(verOffset + 8)
    }
  }
  // In Firefox, the true version is after "Firefox"
  else if (nAgt.indexOf('Firefox') !== -1) {
    verOffset = nAgt.indexOf('Firefox')
    browserName = 'Firefox'
    fullVersion = nAgt.substring(verOffset + 8)
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (nAgt.lastIndexOf(' ') + 1 < nAgt.lastIndexOf('/')) {
    nameOffset = nAgt.lastIndexOf(' ') + 1
    verOffset = nAgt.lastIndexOf('/')
    browserName = nAgt.substring(nameOffset, verOffset)
    fullVersion = nAgt.substring(verOffset + 1)
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if (fullVersion.indexOf('') !== -1) {
    ix = fullVersion.indexOf('')
    fullVersion = fullVersion.substring(0, ix)
  }
  if (fullVersion.indexOf(' ') !== -1) {
    ix = fullVersion.indexOf(' ')
    fullVersion = fullVersion.substring(0, ix)
  }

  majorVersion = Number.parseInt(`${fullVersion}`, 10)
  if (Number.isNaN(majorVersion)) {
    fullVersion = `${Number.parseFloat(navigator.appVersion)}`
    majorVersion = Number.parseInt(navigator.appVersion, 10)
  }

  return {
    browserName: majorVersion ? browserName.toString() : '',
    fullVersion: majorVersion ? fullVersion.toString() : '',
    majorVersion: majorVersion ? majorVersion.toString() : '',
    appName: navigator.appName,
    userAgent: navigator.userAgent,
  }
}

const getQueryDataFromURL = (string) => {
  if (!string) {
    return {}
  }
  const search = string.replace(/.*\?/g, '')
  if (!search) {
    return {}
  }
  const pairs = search.split('&')
  const data = {}
  pairs.forEach((pair) => {
    const keyValue = pair.split('=')
    if (keyValue.length > 1) {
      data[keyValue[0]] = keyValue[1]
    }
  })
  return data
}

export { getBrowserClientDetails, getQueryDataFromURL }
