import { Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import React from 'react'
import styled from 'styled-components'
import { CardDetailsType } from './types'

const CampaignCardText = styled(Paragraph)`
  font-size: clamp(12px, 1.07vw, 16px);
  height: 96px;
  line-height: 24px;

  @media screen and (max-width: 1193px) {
    font-size: 16px;
  }
  @media screen and (max-width: 938px) {
    font-size: clamp(12px, 1.63vw, 16px);
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
    height: 120px;
  }
`

type Props = Pick<CardDetailsType, 'title' | 'caption'> & { mobileView: boolean } & {
  textColor?: string
}

export const CardText = ({ title, caption, mobileView, textColor }: Props) => {
  const theme = useCustomTheme()
  return (
    <Pane display='flex' flexDirection='column' height='100%' justifyContent='flex-start' overflow='hidden'>
      <Pane>
        <Heading
          whiteSpace='nowrap'
          fontSize={mobileView ? 18 : 'clamp(18px, 1.5vw, 24px)'}
          lineHeight={mobileView ? '18px' : '24px'}
          is={mobileView ? 'h5' : 'h4'}
          marginBottom={mobileView ? theme.spacing.xxs : theme.spacing.xs}
          color={textColor || theme.colors.fiDark}
          fontWeight={600}
        >
          {title}
        </Heading>
        <CampaignCardText color={textColor || 'default'} title={caption}>
          {caption}
        </CampaignCardText>
      </Pane>
    </Pane>
  )
}
