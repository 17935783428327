import * as React from 'react'
import { IconProps } from './types'

export const LinkedInIcon = ({ title, ...rest }: IconProps) => (
  <svg viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.82875 10.3681H6.79486C5.10048 10.3681 4.00195 9.25959 4.00195 7.85444C4.00195 6.42196 5.13295 5.33667 6.86122 5.33667C8.58807 5.33667 9.64988 6.41923 9.68377 7.85035C9.68377 9.25548 8.58807 10.3681 6.82875 10.3681ZM28.017 26.6833H22.7094V19.1792C22.7094 17.215 21.8791 15.8742 20.0533 15.8742C18.657 15.8742 17.8804 16.779 17.5189 17.651C17.3995 17.9267 17.4017 18.298 17.4039 18.6806C17.4042 18.7323 17.4045 18.7842 17.4045 18.8362V26.6833H12.1463C12.1463 26.6833 12.214 13.3905 12.1463 12.1822H17.4045V14.4581C17.7152 13.4629 19.3954 12.0428 22.0767 12.0428C25.4033 12.0428 28.017 14.1287 28.017 18.6202V26.6833ZM9.28843 12.1818H4.60771V26.6829H9.28843V12.1818Z'
      fill='currentColor'
    />
  </svg>
)
