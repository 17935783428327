import { Paragraph, Size } from 'evergreen-ui'
import styled, { css } from 'styled-components'

const getLineHeightBySize = (size: Size | undefined) => {
  switch (size) {
    case 300:
      return '20px'
    case 500:
      return '24px'
    default:
      return '22.5px'
  }
}

export const MonoParagraph = styled(Paragraph)`
  ${({ lineHeight, size, theme: { fontFamilies } }) => css`
    font-family: ${fontFamilies.mono};
    line-height: ${lineHeight ?? getLineHeightBySize(size)};
  `}
`
