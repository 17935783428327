import { Pane, Paragraph, Heading, Link } from 'evergreen-ui'
import * as React from 'react'
import { useCustomTheme } from '/fiweb/lib/theme'
import { InvestorRelationsHighlight } from '/~/types/graphql'
import styled from 'styled-components'
import { TimelineConnector, TimelineDot } from '/fiweb/components/Icons'

interface Props {
  showConnector: boolean
  highlight: InvestorRelationsHighlight
}

const StyledParagraph = styled(Paragraph)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Highlight = ({ showConnector, highlight }: Props) => {
  const { title, description, linkUrl } = highlight
  const theme = useCustomTheme()
  return (
    <Pane display='flex' height={120} width={300}>
      <Pane display='flex' flexDirection='column' alignItems='center'>
        <TimelineDot />
        {showConnector ? <TimelineConnector height='110px' width='1px' /> : null}
      </Pane>
      <Pane width={263} marginLeft={theme.spacing.xs}>
        <Heading size={300} marginTop={0} marginBottom={0}>
          {title}
        </Heading>
        <StyledParagraph size={300}>{description}</StyledParagraph>
        <Paragraph size={300}>
          <Link cursor='pointer' href={linkUrl}>
            Les mer
          </Link>
        </Paragraph>
      </Pane>
    </Pane>
  )
}
