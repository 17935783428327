import * as React from 'react'
import { breakpoints, useBreakpoint } from '/fiweb/lib'
import { Link as RouterLink } from 'react-router-dom'
import { Pane, Heading, Paragraph, Button } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled from 'styled-components'
import { css } from 'styled-components'

const StyledPane = styled(Pane)`
  ${({ theme }) => css`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: ${theme.spacing.m};
  @media ${breakpoints.medium} {
    grid-template-columns: 1fr;
  }
`}`

export const AboutUsCareer = () => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Pane
      display='flex'
      flexDirection='column'
      alignItems='center'
      width='100%'
      marginBottom={medium ? theme.spacing.l : theme.spacing.xl}
      gap={theme.spacing.m}
    >
      <StyledPane>
        <Pane display='flex' flexDirection='column' alignItems='center'>
          <Heading size={medium ? 500 : 600}>Karriere eller samarbeid</Heading>
          <Paragraph size={500} textAlign='center'>
            Er dette en gjeng du vil jobbe med?
          </Paragraph>
        </Pane>
      </StyledPane>

      <Button
        appearance='primary'
        width={medium ? '100%' : 'fit-content'}
        maxWidth='325px'
        is={RouterLink}
        to='/kontakt-oss'
      >
        Ta kontakt
      </Button>
    </Pane>
  )
}
