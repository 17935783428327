import * as React from 'react'
import { FetchCampaignQuery } from '../types/graphql'
import { CampaignCardTemplateType, ClientCampaignCard } from '/fiweb/components/CampaignCard'
import { useInViewRef } from 'rooks'
import { CardSectionName } from '../components/AllCampaigns/CardSection'

declare global {
  interface Window {
    dataLayer?: any
  }
}

enum GTMEvents {
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_ITEM = 'view_item',
  PURCHASE = 'purchase',
  ADD_TO_WISHLIST = 'add_to_wishlist',
  ADD_TO_CART = 'add_to_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  SELECT_ITEM = 'select_item',
  REMOVE_FROM_CART = 'remove_from_cart',
}

type GtaItemListNameType =
  | 'Campaigns Ongoing'
  | 'Campaigns Coming Soon'
  | 'Campaigns Finished'
  | 'Frontpage Campaigns Ongoing'
  | 'Frontpage Coming Soon'
  | 'Campaigns Upcoming'

type ItemListID = 'CO1' | 'FCO1' | 'CF1' | 'FC' | 'CCS1' | 'CU1' | 'FCS1'

type VerificationMethod = 'email' | 'google'

const pushToDataLayer = (obj: Record<string, any>) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(obj)
}
const ecommerceEvent = (event: GTMEvents, ecommerceObj: Record<string, any>) => {
  pushToDataLayer({ event, ecommerce: ecommerceObj })
}
const viewItemList = (
  itemsArray: ClientCampaignCard[],
  item_list_name: GtaItemListNameType,
  item_list_id: ItemListID,
) => {
  const items =
    itemsArray?.map((c, i) => {
      return {
        item_name: c.title,
        item_id: c.id,
        item_list_name,
        item_list_id,
        index: i,
        quantity: 1,
      }
    }) || []
  ecommerceEvent(GTMEvents.VIEW_ITEM_LIST, { items })
}

type ViewItem = Pick<FetchCampaignQuery['Campaign'], 'title' | '_id' | 'pricePerShare'>

const viewItem = ({ title, _id, pricePerShare }: ViewItem) => {
  const item = {
    item_name: title,
    item_id: _id,
    price: pricePerShare,
    quantity: 1,
  }
  ecommerceEvent(GTMEvents.VIEW_ITEM, { items: [item] })
}

export const gtmEventSignup = (verificationMethod: VerificationMethod) => {
  pushToDataLayer({ event: 'registerUser', verification: verificationMethod })
}

export const gtmEventLogin = (verificationMethod: VerificationMethod) =>
  pushToDataLayer({ event: 'loginUser', verification: verificationMethod })

export const gtmEventLogout = () => pushToDataLayer({ event: 'logoutUser' })

export const gtmEventDiscussion = (newThread: boolean) => {
  pushToDataLayer({ event: newThread ? 'startDiscussion' : 'commentDiscussion' })
}

interface ReducedCampaign {
  title?: string
  _id?: string
  pricePerShare?: number
  status?: string
}

interface PurchaseEvent {
  amount: number
  id: string
  campaign: ReducedCampaign
  quantity: number
}

export const gtmEventPurchase = ({ amount, id, campaign, quantity }: PurchaseEvent) => {
  const item = {
    item_name: campaign.title,
    item_id: campaign._id,
    price: campaign.pricePerShare,
    item_category: campaign.status,
    quantity,
  }
  ecommerceEvent(GTMEvents.PURCHASE, {
    currency: 'NOK',
    value: amount,
    transaction_id: id,
    items: [item],
  })
}

export const gtmEventAddToCart = ({ title, _id, pricePerShare, status }: ReducedCampaign) => {
  const item = {
    item_name: title,
    item_id: _id,
    price: pricePerShare,
    item_category: status,
    quantity: 1,
  }
  ecommerceEvent(GTMEvents.ADD_TO_CART, {
    items: [item],
  })
}

interface gtmEventAddToWishListProps {
  title: string
  id: string
  pricePerShare?: number
  fromCampaignPage?: boolean
}

interface WishListItem {
  item_name: string
  item_id: string
  item_category: 'Follow campaign'
  item_category2?: 'Campaign page' | 'Campaign card'
  price?: number
  quantity: number
}

export const gtmEventAddToWishList = ({ title, id, pricePerShare, fromCampaignPage }: gtmEventAddToWishListProps) => {
  const item: WishListItem = {
    item_name: title,
    item_id: id,
    item_category: 'Follow campaign',
    quantity: 1,
  }
  if (fromCampaignPage) {
    item.price = pricePerShare
    item.item_category2 = 'Campaign page'
  } else {
    item.item_category2 = 'Campaign card'
  }
  ecommerceEvent(GTMEvents.ADD_TO_WISHLIST, { items: [item] })
}

export const gtmEventBeginCheckout = ({ title, _id, pricePerShare, status }: ReducedCampaign, quantity: number) => {
  const item = {
    item_name: title,
    item_id: _id,
    price: pricePerShare,
    item_category: status,
    quantity,
  }
  ecommerceEvent(GTMEvents.BEGIN_CHECKOUT, {
    items: [item],
  })
}

export const gtmEventSelectItem = ({ title, campaignId, emissionProcessId, cardType }: CampaignCardTemplateType) => {
  const item = {
    item_name: title,
    item_id: campaignId || emissionProcessId,
    item_category: cardType,
    quantity: 1,
  }
  ecommerceEvent(GTMEvents.SELECT_ITEM, {
    items: [item],
  })
}

interface RemoveFromCartProps {
  campaign?: ReducedCampaign
  shares?: number
}

export const gtmEventRemoveFromCart = ({ campaign, shares }: RemoveFromCartProps) => {
  const item = {
    item_name: campaign?.title,
    item_id: campaign._id,
    price: campaign.pricePerShare,
    item_category: campaign.status,
    quantity: shares,
  }
  ecommerceEvent(GTMEvents.REMOVE_FROM_CART, {
    items: [item],
  })
}

interface CardSection {
  name: GtaItemListNameType
  id: ItemListID
}

const cardSections: Record<CardSectionName, CardSection> = {
  comingSoon: {
    id: 'CCS1',
    name: 'Campaigns Coming Soon',
  },
  upcoming: {
    id: 'CU1',
    name: 'Campaigns Upcoming',
  },
  ongoing: {
    id: 'CO1',
    name: 'Campaigns Ongoing',
  },
  finished: {
    id: 'CF1',
    name: 'Campaigns Finished',
  },
  frontpageOngoing: {
    id: 'FCO1',
    name: 'Frontpage Campaigns Ongoing',
  },
  frontpageComingSoon: {
    id: 'FCS1',
    name: 'Frontpage Coming Soon',
  },
}

export const useGoogleTagManagerInView = (cards: Array<ClientCampaignCard>, name: CardSectionName) => {
  const [elementRef, inView] = useInViewRef()
  const [hasPushedGTM, setPushedGTM] = React.useState(false)

  React.useEffect(() => {
    if (inView && !hasPushedGTM) {
      const section = cardSections[name]
      viewItemList(cards, section.name, section.id)
      setPushedGTM(true)
    }
  }, [inView])

  return { elementRef }
}

export const useGoogleTagManagerCampaignPageInView = (campaign: ViewItem) => {
  const [pushedGTM, setPushedGTM] = React.useState(false)
  React.useEffect(() => {
    if (campaign && !pushedGTM) {
      viewItem(campaign)
      setPushedGTM(true)
    }
  }, [campaign, pushedGTM])
  return
}
