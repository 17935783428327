import { Pane } from 'evergreen-ui'
import * as React from 'react'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props {
  imageUrl: string
}

export const CoverImage = ({ imageUrl }: Props) => {
  const { colors } = useCustomTheme()
  return (
    <Pane
      display='flex'
      justifyContent='center'
      alignItems='center'
      width='100%'
      height='100%'
      marginX='auto'
      border={`1px solid ${colors.fiDark10}`}
      borderRadius='5px'
      overflow='hidden'
      borderBottomRightRadius='30px'
    >
      <img
        alt='Coverbilde for kampanje'
        src={imageUrl}
        style={{
          flexShrink: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />
    </Pane>
  )
}
