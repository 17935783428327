import { MitIdLogo, NorwegianBankIdLogo, SwedishBankIdLogo } from '/fiweb/components/Icons'
import { SignicatProvider, Service } from '/fiweb/lib/auth'

export interface ProviderError {
  text: string
  provider?: SignicatProvider
}

export interface ErrorResponse {
  field?: string
  value?: any
  service?: Service.GOOGLE | `${Service.SIGNICAT}.${SignicatProvider}`
}

export const getSignicatAuthError = async (res: Response) => {
  try {
    const error = (await res.json()) as ErrorResponse

    return {
      field: error.field,
      value: error.value,
      provider: error.service ? (error.service.replace(`${Service.SIGNICAT}.`, '') as SignicatProvider) : undefined,
    }
  } catch (_e) {
    return null
  }
}

export const getProviderIcon = (provider: SignicatProvider) => {
  switch (provider) {
    case SignicatProvider.NORWEGIAN_BANK_ID: {
      return NorwegianBankIdLogo
    }

    case SignicatProvider.SWEDISH_BANK_ID: {
      return SwedishBankIdLogo
    }

    case SignicatProvider.DANISH_MIT_ID: {
      return MitIdLogo
    }
  }
}
