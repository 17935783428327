import { config } from '/~/utils/config'

const imageBase = `${config.publicFilesUrl}/aboutus`

interface FolkeinvestEmployee {
  image: string
  name: string
  title: string
}

export const employees: FolkeinvestEmployee[] = [
  {
    image: `${imageBase}/board/finn-haugan.jpg`,
    name: 'Finn Haugan',
    title: 'Styreleder',
  },
  {
    image: `${imageBase}/team/oyvind-fries.jpg`,
    name: 'Øyvind Fries',
    title: 'Daglig leder',
  },
  {
    image: `${imageBase}/team/amalie-holt.jpg`,
    name: 'Amalie Holt',
    title: 'Kommunikasjonssjef',
  },
]
