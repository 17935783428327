import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from, fromPromise, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from 'apollo-utilities'
import { auth, maskTypename } from '/fiweb/lib'
import { StatusCodes } from 'http-status-codes'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { StrictTypedTypePolicies } from '/~/types/apollo-helpers'
import { config } from '/~/utils/config'

interface CreateClientProps {
  url: string
  wsUrl: string
  authUrl: string
  errorLoggingDisabled?: boolean
}

export const createGraphQLClient = ({ url, wsUrl, authUrl, errorLoggingDisabled }: CreateClientProps) => {
  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsUrl,
      retryAttempts: 60,
      shouldRetry: () => true,
    }),
  )

  const httpLink = new HttpLink({
    uri: url,
    credentials: 'include',
  })

  const splitLink = split(
    (q) => {
      const { query } = q
      const def = getMainDefinition(query)

      return def.kind === 'OperationDefinition' && def.operation === 'subscription'
    },
    wsLink,
    httpLink,
  )

  const typenameLink = new ApolloLink((operation, forward) => {
    operation.variables = maskTypename(operation.variables)
    return forward(operation)
  })

  const afterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((originalResponse) => {
      const { response } = operation.getContext()
      const shouldRefresh = response?.headers.get('x-should-refresh') === 'true'

      if (shouldRefresh) {
        auth(authUrl).obtainRefreshToken()
      }

      return originalResponse
    })
  })

  const errorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
    if (!errorLoggingDisabled) {
      if (graphQLErrors) {
        // Adding check for an annying console log when user is not logged in during dev.
        // This can be removed once we have proper auth flow
        if (graphQLErrors?.[0]?.message !== 'Missing userId') {
          console.log('GraphQLError: ', graphQLErrors)
        }
      } else {
        console.log('NetworkError', networkError)
      }
    }

    if (networkError && 'statusCode' in networkError && networkError.statusCode === StatusCodes.UNAUTHORIZED) {
      const { response } = operation.getContext()

      if (response?.headers) {
        const accessTokenExpired = response.headers.get('x-access-expired')
        if (accessTokenExpired) {
          return fromPromise(
            auth(authUrl)
              .obtainAccessToken()
              .catch((_) => auth(authUrl).logout()),
          )
            .filter((val) => Boolean(val))
            .flatMap(() => forward(operation))
        }
        auth(authUrl).logout()
      }
    }
  })

  const link = from([errorLink, afterwareLink, typenameLink, splitLink])

  const typePolicies: StrictTypedTypePolicies = {
    PublishedInvestmentOfferDocument: {
      keyFields: ['file', 'key'],
    },
  }

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache({ typePolicies }),
    name: 'issuer-for-client-webapp',
    credentials: 'include',
  })

  return client
}

const shouldCreateIssuerClient = !!config.issuerApiGraphQLUrl && !!config.issuerApiGraphQLSubscriptionUrl

export const client = shouldCreateIssuerClient
  ? createGraphQLClient({
      url: config.issuerApiGraphQLUrl,
      wsUrl: config.issuerApiGraphQLSubscriptionUrl,
      authUrl: config.authHost,
      errorLoggingDisabled: false,
    })
  : null
