import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import Logo from '/~/static/icons/Logo.svg'
import { Dialog, Pane, Heading, Button } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useAuthContext } from '../utils/AuthContext'
import { config } from '../utils/config'

const LoggedOutDialog = () => {
  const { refetch } = useAuthContext()
  const theme = useCustomTheme()
  const { medium } = useBreakpoint()

  const [open, setOpen] = React.useState(false)

  const handleStorageChange = (e: StorageEvent) => {
    if (e.key === config.loggedInKey) {
      const wasLoggedIn = e.oldValue === 'true'
      const loggedIn = e.newValue === 'true'

      setOpen(wasLoggedIn && !loggedIn)

      refetch()
    }
  }

  React.useEffect(() => {
    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  return (
    <Dialog
      isShown={open}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      hasHeader={false}
      hasFooter={false}
      width={medium ? 325 : 500}
      minHeightContent={521}
    >
      <Pane
        display='flex'
        flexDirection='column'
        alignItems='center'
        marginTop={medium ? theme.spacing.xs : theme.spacing.l}
      >
        <img src={Logo} alt='folkeinvest logo' width={220} />
        <Heading marginTop={theme.spacing.m} marginBottom={theme.spacing.l}>
          Du har blitt logget ut.
        </Heading>
        <Pane display='flex' flexDirection='column'>
          <Button
            appearance='primary'
            size='small'
            minWidth={244}
            marginBottom={theme.spacing.xs}
            onClick={() => {
              setOpen(false)
              window.location.href = '/innlogging'
            }}
          >
            Logg inn
          </Button>
          <Button size='small' minWidth={244} onClick={() => setOpen(false)}>
            Lukk
          </Button>
        </Pane>
      </Pane>
    </Dialog>
  )
}

export default LoggedOutDialog
