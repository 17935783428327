import * as React from 'react'

const hashIt = async (message: string) => {
  const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8) // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
  return hashHex
}

export const useAppVersionChecker = () => {
  const [mismatch, setMismatch] = React.useState(false)
  const [previousIndex, setpreviousIndex] = React.useState<string>()
  const [currentIndex, setCurrentIndex] = React.useState<string>()

  const fetchIndex = async () => {
    try {
      const fetchResult = await fetch(window.location.origin)
      const html = await fetchResult.text()
      const hash = await hashIt(html)
      setCurrentIndex(hash)
    } catch (_e) {}
  }

  React.useEffect(() => {
    if (currentIndex) {
      if (previousIndex && previousIndex !== currentIndex) {
        setMismatch(true)
      }
      setpreviousIndex(currentIndex)
    }
  }, [currentIndex])

  React.useEffect(() => {
    fetchIndex()
    const timer = setInterval(fetchIndex, 30000)
    return () => clearInterval(timer)
  }, [])

  return mismatch
}
