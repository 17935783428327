import React from 'react'
import styled, { css } from 'styled-components'
import { Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Divider } from '/fiweb/components'
import { CardText } from './CardText'
import CardData from './CardData'
import CoverImage from './CoverImage'
import { Sticker } from '/fiweb/components'
import { CampaignCardTemplateType, CardTypeEnum } from './types'
import { useBreakpoint } from '/fiweb/lib'

interface ClickableProps {
  $clickable?: boolean
}

const StyledComp = styled(Pane)<ClickableProps>`
  ${({ $clickable }) => {
    if ($clickable) {
      return `
        transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        text-decoration: none;
        color: inherit;
        box-shadow: 0 0 1px rgba(67, 90, 111, 0.4);
        &:hover {
          box-shadow: 0px 0px 20px 3px #00000012;
        }
      `
    }
  }}
`

export const CardPaddedPaneWrapper = styled(Pane)`
  background: ${({ theme: { colors } }) => colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid ${({ theme: { colors } }) => colors.fiTint};
`

interface CardPaddedPaneProps {
  $mobileView?: boolean
}

export const CardPaddedPane = styled(Pane)<CardPaddedPaneProps>`
  ${({ $mobileView }) => css`
    padding-left: ${$mobileView ? '24px' : '30px'};
    padding-right: ${$mobileView ? '24px' : '30px'};
  `}
`

export const CampaignCardTemplate = ({
  title,
  caption,
  imageUrl,
  stickerType,
  clickNavigatesTo,
  mobileView,
  onClick,
  standardClickableAreaProps,
  reducedClickableAreaProps,
  ...other
}: CampaignCardTemplateType) => {
  const theme = useCustomTheme()
  const { medium } = useBreakpoint()
  const isMobile = mobileView || medium
  const parentRef = React.useRef<HTMLInputElement>(null)

  const textProps = () => {
    return {
      title: title || 'EMISJONS-TITTEL',
      caption: caption?.trim?.() ? caption : 'Innsalgstekst vil vises her - Max 154 tegn.',
    }
  }

  return (
    <>
      <StyledComp
        ref={parentRef}
        width='100%'
        height='100%'
        border='none'
        borderRadius={10}
        $clickable={!!clickNavigatesTo}
        onClick={onClick}
        {...standardClickableAreaProps}
      >
        <Pane {...reducedClickableAreaProps}>
          <CoverImage url={imageUrl} />
        </Pane>
        <Pane
          paddingTop={isMobile ? 24 : 25}
          paddingLeft={isMobile ? 24 : 30}
          paddingRight={isMobile ? 24 : 30}
          paddingBottom={theme.spacing.xxs}
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
          {...reducedClickableAreaProps}
        >
          <Pane>
            <Pane height={isMobile ? 'auto' : 141}>
              <CardText
                {...textProps()}
                textColor={other.cardType === CardTypeEnum.FINISHED ? theme.colors.fiDark70 : undefined}
                mobileView={isMobile}
              />
            </Pane>
          </Pane>
        </Pane>
        <Pane paddingX={theme.spacing.xs}>
          <Divider />
        </Pane>

        <Pane id={`card_data-${other?.emissionProcessId}`} paddingTop={theme.spacing.xs}>
          <CardData mobileView={isMobile} {...other} parentRef={parentRef} />
        </Pane>
      </StyledComp>
      {stickerType && <Sticker type={stickerType} />}
    </>
  )
}
