const LOCAL_STORAGE_NAME = 'campaignRef'
const PARAM_NAME = 'folkeref'

const removeSearchParam = (search: string, name: string, redirect: boolean) => {
  let searchString: string

  if (search[0] === '?') {
    searchString = search.substring(1)
  } else {
    searchString = search
  }

  const parts = searchString.split('&')
  const res = []
  for (let i = 0; i < parts.length; i++) {
    const pair = parts[i].split('=')
    if (pair[0] === name) {
      continue
    }
    res.push(parts[i])
  }

  let newSearch = res.join('&')
  if (newSearch.length > 0) {
    newSearch = `?${newSearch}`
  }

  if (redirect) {
    window.location.href = window.location.href.split('?')[0] + newSearch
  }

  return newSearch
}

export const setCampaignReferral = (): null | undefined => {
  const param = new RegExp(`${PARAM_NAME}=([^&]*)`).exec(window.location.search.substring(1))
  if (!param) {
    return
  }

  const ref = decodeURIComponent(param[1])
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)

  localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify({ ref, exp: tomorrow }))

  removeSearchParam(window.location.search, PARAM_NAME, true)
}

export const removeCampaignReferral = () => {
  localStorage.removeItem(LOCAL_STORAGE_NAME)
}

export const getCampaignReferral = (): string | null => {
  const storage = localStorage.getItem(LOCAL_STORAGE_NAME)
  if (!storage) {
    return null
  }

  const { ref, exp } = JSON.parse(storage)
  if (new Date() < new Date(exp)) {
    return ref
  }

  removeCampaignReferral()
  return null
}
