import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { SmallInfoCard } from '/~/components/Cards'
import { Paragraph, Heading, Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props {
  children: React.ReactNode
  heading: string
  backgroundColor?: string
  unit?: string
  customMarginBottom?: string
  cardCustomSize?: {
    small: number
    medium: number
  }
}

export const StatisticCard = ({
  children,
  heading,
  backgroundColor,
  unit,
  customMarginBottom,
  cardCustomSize,
}: Props) => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <SmallInfoCard
      height={213}
      width={medium ? cardCustomSize?.medium ?? 325 : cardCustomSize?.small ?? 310}
      backgroundColor={backgroundColor}
      marginBottom={customMarginBottom ?? theme.spacing.m}
      padding={theme.spacing.m}
    >
      <Pane display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
        <Paragraph size={500} whiteSpace='pre'>
          {heading}
        </Paragraph>
        <Pane display='flex' alignItems='baseline'>
          <Heading size={600} marginBottom={0} marginRight={7}>
            {children}
          </Heading>
          {unit && (
            <Heading size={500} marginBottom={0}>
              {unit}
            </Heading>
          )}
        </Pane>
      </Pane>
    </SmallInfoCard>
  )
}
