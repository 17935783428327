import { InvestorRelationsEvent } from '/~/types/graphql'
import dayjs from 'dayjs'

export const getCalendarIcs = ({ dateOfEvent, endDateOfEvent, title, description }: InvestorRelationsEvent) => {
  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'CALSCALE:GREGORIAN',
    'BEGIN:VEVENT',
    'ORGANIZER;CN=Folkeinvest:mailto:investor@folkeinvest.no',
    `DTEND:${dayjs(endDateOfEvent).format('YYYYMMDDTHHmmss')}`,
    `SUMMARY:${title}`,
    `DTSTART:${dayjs(dateOfEvent).format('YYYYMMDDTHHmmss')}`,
    `DESCRIPTION:${description}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n')
}
