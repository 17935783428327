import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Loading } from '/fiweb/components'
import { Page404 } from '/~/pages'
import ErrorDataAuth from './ErrorDataAuth'
import Thanks from './Thanks'
import { useFetchCampaignQuery } from '/~/types/graphql'

const CampaignThanksPage = () => {
  const { campaignSlug } = useParams<{ campaignSlug: string }>()

  const { data, error, loading } = useFetchCampaignQuery({
    variables: { slug: campaignSlug },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorDataAuth error={error} />
  }

  if (!data.Campaign) {
    return <Page404 />
  }

  return <Thanks campaign={data.Campaign} />
}

export default CampaignThanksPage
