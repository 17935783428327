import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Heading, Link, Pane, Paragraph, Spinner, toaster } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import Terms from './Terms'
import NavButtons from './NavButtonsComponent'
import StatusDoneComponent from './StatusDoneComponent'
import { useAcceptTermsMutationMutation, useGetTermsQuery } from '/~/types/graphql'
import { useAuthContext } from '/~/utils/AuthContext'

interface Props {
  goBack: () => void
  goForward: () => void
}

const TermsStep = ({ goBack, goForward }: Props) => {
  const { small } = useBreakpoint()
  const { user } = useAuthContext()
  const theme = useCustomTheme()

  const [openTerms, setOpenTerms] = React.useState(false)
  const [acceptTerms] = useAcceptTermsMutationMutation()
  const { data, loading } = useGetTermsQuery({ variables: { latest: true } })

  const toggleTerms = () => {
    setOpenTerms(!openTerms)
  }

  const handleAcceptTerms = async () => {
    setOpenTerms(false)
    try {
      await acceptTerms({
        variables: { termsVersion: data?.getTerms?.version },
      })
    } catch (_err) {
      toaster.danger('Kunne ikke oppdatere profil. Prøv igjen eller kontakt support')
    }
  }

  const save = async () => {
    if (!user?.acceptedLatestTerms) {
      toaster.notify('Vilkår må være godtatt')
      return null
    }

    goForward()
  }

  if (!user) {
    return null
  }
  if (loading || !data?.getTerms) {
    return <Spinner />
  }

  return (
    <Pane
      display='flex'
      flexDirection='column'
      maxWidth={380}
      marginTop={theme.spacing.l}
      alignItems='center'
      height='100%'
    >
      <Heading is='h3'>Vilkår</Heading>

      <Paragraph size={300} textAlign='center' marginTop={theme.spacing.xxs} marginBottom={theme.spacing.s}>
        For å tegne aksjer eller søke kapital må du godta vilkår for bruk av folkeinvest.no
      </Paragraph>

      <Link onClick={toggleTerms} fontWeight={600} cursor='pointer'>
        Les vilkår
      </Link>

      <Pane width='100%' marginTop={theme.spacing.m} paddingX={small ? 20 : 0}>
        <StatusDoneComponent
          hidden={!user?.acceptedLatestTerms}
          title='Samtykker'
          description='Vilkår og rettigheter'
        />
      </Pane>

      <NavButtons onSave={save} onBack={goBack} disabled={!user?.acceptedLatestTerms} />

      <Terms open={openTerms} terms={data.getTerms} onClose={toggleTerms} acceptTerms={handleAcceptTerms} />
    </Pane>
  )
}

export default TermsStep
