import * as React from 'react'
import { Heading, Pane, Paragraph, Strong } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props {
  isDone: boolean
  underEighteen: boolean
}

const CompleteProfileInfo = ({ isDone, underEighteen }: Props) => {
  const theme = useCustomTheme()
  const completed = (
    <>
      <Heading is='h3' tabIndex={0}>
        Profil fullført
      </Heading>
      <Paragraph size={300} marginBottom={theme.spacing.xxs}>
        Du kan nå lukke denne dialogen og fortsette der du slapp
      </Paragraph>
    </>
  )

  const underaged = (
    <>
      <Heading is='h3' tabIndex={0}>
        Profil fullført
      </Heading>
      <Paragraph size={300} marginBottom={theme.spacing.xxs}>
        Profilen er fullført, men du må ha fylt 18 for å kunne gå videre. Vi ønsker deg velkommen tilbake!
      </Paragraph>
    </>
  )

  const incomplete = (
    <>
      <Heading is='h3' tabIndex={0}>
        Fullfør profilen din
      </Heading>
      <Paragraph size={300}>
        <Strong size={300}>Informasjon mangler på disse feltene</Strong>
      </Paragraph>
      <Paragraph size={300} marginBottom={theme.spacing.xxs}>
        Vær oppmerksom på at det er mulig å lagre underveis
      </Paragraph>
    </>
  )

  return (
    <Pane display='flex' flexDirection='column'>
      {underEighteen ? underaged : !isDone ? incomplete : completed}
    </Pane>
  )
}

export default CompleteProfileInfo
