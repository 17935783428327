import React, { useEffect, CSSProperties } from 'react'
import styled from 'styled-components'
import { minBreakpoints, maxBreakpoints } from '/fiweb/lib'

const StyledIFrame = styled.iframe`
@media ${maxBreakpoints.UNSAFE_mediumLarge} {
  min-width: 340px;
  width: 100%;
  height: 90%;
}

@media ${minBreakpoints.UNSAFE_mediumLarge} {
  width: 850px;
  /* margin-top: 12vh; */
}


min-height: 720px;
border: none;
margin: auto;
background: transparent;
`

interface MeetingBookerProps {
  formUrl?: string
  style?: CSSProperties
}

export const MeetingBooker = ({ formUrl: formId, style }: MeetingBookerProps) => {
  return (
    <StyledIFrame
      title='Book møte med Folkeinvest.'
      src={formId ?? 'https://meetings.hubspot.com/maria-folkeinvest/mote-booket-via-navbar?embed=true'}
      style={style}
    />
  )
}

interface BookMeetingDialogProps {
  formUrl?: string
  open: boolean
  onClose: () => void
}

export const BookMeetingDialog = ({ open, onClose, formUrl: formId }: BookMeetingDialogProps) => {
  // Avoid content layout shift when dialog is opened and closed:
  useEffect(() => {
    if (open) {
      const currentWidth = document.body.offsetWidth
      document.body.style.overflow = 'hidden'
      const scrollBarWidth = document.body.offsetWidth - currentWidth
      document.body.style.marginRight = `${scrollBarWidth}px`
    } else {
      document.body.style.overflow = 'auto'
      document.body.style.marginRight = ''
    }
  }, [open])

  if (!open) {
    return null
  }

  return (
    <dialog
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 20,
        width: '100vw',
        height: '100vh',
        border: 'none',
        padding: 0,
        borderRadius: 0,
        backgroundColor: 'rgba(0,0,0,0.3)',
      }}
      open={open}
      onClick={onClose}
    >
      <MeetingBooker
        style={{
          display: 'block',
          marginTop: '12vh',
        }}
        formUrl={formId}
      />
    </dialog>
  )
}
