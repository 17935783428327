import * as React from 'react'
import { IconProps } from './types'

// Just copy/pasted SVG code from Figma - it's quite bloated, might consider fixing/replacing it at some point

export const ManyDots = ({ title, ...rest }: IconProps) => (
  <svg width='445' height='446' viewBox='0 0 445 446' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <g opacity='0.7'>
      <circle
        opacity='0.8'
        cx='125.517'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 125.517 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='72.7296'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 72.7296 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='19.9417'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 19.9417 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='125.517'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 125.517 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='72.7296'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 72.7296 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='19.9417'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 19.9417 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='125.517'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 125.517 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='72.7296'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 72.7296 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='19.9417'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 19.9417 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='125.517'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 125.517 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='125.517'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 125.517 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='125.517'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 125.517 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.3' cx='125.517' cy='213.96' r='2.34612' transform='rotate(90 125.517 213.96)' fill='#6C7079' />
      <circle
        opacity='0.2'
        cx='125.517'
        cy='267.039'
        r='2.34612'
        transform='rotate(90 125.517 267.039)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='125.517'
        cy='302.304'
        r='2.34612'
        transform='rotate(90 125.517 302.304)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='72.7296'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 72.7296 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='72.7296'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 72.7296 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='72.7296'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 72.7296 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.5' cx='72.7296' cy='213.96' r='2.34612' transform='rotate(90 72.7296 213.96)' fill='#6C7079' />
      <circle
        opacity='0.3'
        cx='72.7296'
        cy='267.039'
        r='2.34612'
        transform='rotate(90 72.7296 267.039)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='72.7296'
        cy='302.304'
        r='2.34612'
        transform='rotate(90 72.7296 302.304)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='72.7296'
        cy='337.641'
        r='2.34612'
        transform='rotate(90 72.7296 337.641)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='72.9136'
        cy='372.979'
        r='2.34612'
        transform='rotate(90 72.9136 372.979)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='19.9417'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 19.9417 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='19.9417'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 19.9417 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='19.9417'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 19.9417 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.8' cx='19.9417' cy='213.96' r='2.34612' transform='rotate(90 19.9417 213.96)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='19.9417'
        cy='267.039'
        r='2.34612'
        transform='rotate(90 19.9417 267.039)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='19.9417'
        cy='302.304'
        r='2.34612'
        transform='rotate(90 19.9417 302.304)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='19.9417'
        cy='337.641'
        r='2.34612'
        transform='rotate(90 19.9417 337.641)'
        fill='#6C7079'
      />
      <circle opacity='0.2' cx='20.126' cy='372.979' r='2.34612' transform='rotate(90 20.126 372.979)' fill='#6C7079' />
      <circle opacity='0.1' cx='20.126' cy='408.316' r='2.34612' transform='rotate(90 20.126 408.316)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='125.517'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 125.517 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='125.517'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 125.517 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='125.517'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 125.517 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='125.517'
        cy='249.298'
        r='2.34612'
        transform='rotate(90 125.517 249.298)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='125.517'
        cy='231.556'
        r='2.34612'
        transform='rotate(90 125.517 231.556)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='125.517'
        cy='284.635'
        r='2.34612'
        transform='rotate(90 125.517 284.635)'
        fill='#6C7079'
      />
      <circle opacity='0.1' cx='125.517' cy='319.9' r='2.34612' transform='rotate(90 125.517 319.9)' fill='#6C7079' />
      <circle
        opacity='0.8'
        cx='72.7296'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 72.7296 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='72.7296'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 72.7296 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='72.7296'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 72.7296 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='72.7296'
        cy='249.298'
        r='2.34612'
        transform='rotate(90 72.7296 249.298)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='72.7296'
        cy='231.556'
        r='2.34612'
        transform='rotate(90 72.7296 231.556)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='72.7296'
        cy='284.635'
        r='2.34612'
        transform='rotate(90 72.7296 284.635)'
        fill='#6C7079'
      />
      <circle opacity='0.2' cx='72.7296' cy='319.9' r='2.34612' transform='rotate(90 72.7296 319.9)' fill='#6C7079' />
      <circle
        opacity='0.2'
        cx='72.7296'
        cy='355.237'
        r='2.34612'
        transform='rotate(90 72.7296 355.237)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='19.9417'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 19.9417 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='19.9417'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 19.9417 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='19.9417'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 19.9417 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='19.9417'
        cy='249.298'
        r='2.34612'
        transform='rotate(90 19.9417 249.298)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='19.9417'
        cy='231.556'
        r='2.34612'
        transform='rotate(90 19.9417 231.556)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='19.9417'
        cy='284.635'
        r='2.34612'
        transform='rotate(90 19.9417 284.635)'
        fill='#6C7079'
      />
      <circle opacity='0.3' cx='19.9417' cy='319.9' r='2.34612' transform='rotate(90 19.9417 319.9)' fill='#6C7079' />
      <circle
        opacity='0.3'
        cx='19.9417'
        cy='355.237'
        r='2.34612'
        transform='rotate(90 19.9417 355.237)'
        fill='#6C7079'
      />
      <circle opacity='0.2' cx='20.126' cy='390.575' r='2.34612' transform='rotate(90 20.126 390.575)' fill='#6C7079' />
      <circle opacity='0.1' cx='20.126' cy='425.912' r='2.34612' transform='rotate(90 20.126 425.912)' fill='#6C7079' />
      <circle
        opacity='0.8'
        cx='107.921'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 107.921 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='55.1339'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 55.1339 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='2.34602'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 2.34602 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='107.921'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 107.921 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='55.1339'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 55.1339 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='2.34602'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 2.34602 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='107.921'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 107.921 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='55.1339'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 55.1339 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='2.34602'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 2.34602 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='107.921'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 107.921 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='107.921'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 107.921 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='107.921'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 107.921 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.3' cx='107.921' cy='213.96' r='2.34612' transform='rotate(90 107.921 213.96)' fill='#6C7079' />
      <circle
        opacity='0.3'
        cx='107.921'
        cy='267.039'
        r='2.34612'
        transform='rotate(90 107.921 267.039)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='107.921'
        cy='302.304'
        r='2.34612'
        transform='rotate(90 107.921 302.304)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='107.921'
        cy='337.641'
        r='2.34612'
        transform='rotate(90 107.921 337.641)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='55.1339'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 55.1339 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='55.1339'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 55.1339 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='55.1339'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 55.1339 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.5' cx='55.1339' cy='213.96' r='2.34612' transform='rotate(90 55.1339 213.96)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='55.1339'
        cy='267.039'
        r='2.34612'
        transform='rotate(90 55.1339 267.039)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='55.1339'
        cy='302.304'
        r='2.34612'
        transform='rotate(90 55.1339 302.304)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='55.1339'
        cy='337.641'
        r='2.34612'
        transform='rotate(90 55.1339 337.641)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='55.3177'
        cy='372.979'
        r='2.34612'
        transform='rotate(90 55.3177 372.979)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='2.34602'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 2.34602 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='2.34602'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 2.34602 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='2.34602'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 2.34602 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.8' cx='2.34602' cy='213.96' r='2.34612' transform='rotate(90 2.34602 213.96)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='2.34602'
        cy='267.039'
        r='2.34612'
        transform='rotate(90 2.34602 267.039)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='2.34602'
        cy='302.304'
        r='2.34612'
        transform='rotate(90 2.34602 302.304)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='2.34602'
        cy='337.641'
        r='2.34612'
        transform='rotate(90 2.34602 337.641)'
        fill='#6C7079'
      />
      <circle opacity='0.3' cx='2.5301' cy='372.979' r='2.34612' transform='rotate(90 2.5301 372.979)' fill='#6C7079' />
      <circle opacity='0.3' cx='2.5301' cy='408.316' r='2.34612' transform='rotate(90 2.5301 408.316)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='107.921'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 107.921 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='107.921'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 107.921 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='107.921'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 107.921 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='107.921'
        cy='249.298'
        r='2.34612'
        transform='rotate(90 107.921 249.298)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='107.921'
        cy='231.556'
        r='2.34612'
        transform='rotate(90 107.921 231.556)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='107.921'
        cy='284.635'
        r='2.34612'
        transform='rotate(90 107.921 284.635)'
        fill='#6C7079'
      />
      <circle opacity='0.2' cx='107.921' cy='319.9' r='2.34612' transform='rotate(90 107.921 319.9)' fill='#6C7079' />
      <circle
        opacity='0.8'
        cx='55.1339'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 55.1339 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='55.1339'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 55.1339 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='55.1339'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 55.1339 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='55.1339'
        cy='249.298'
        r='2.34612'
        transform='rotate(90 55.1339 249.298)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='55.1339'
        cy='231.556'
        r='2.34612'
        transform='rotate(90 55.1339 231.556)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='55.1339'
        cy='284.635'
        r='2.34612'
        transform='rotate(90 55.1339 284.635)'
        fill='#6C7079'
      />
      <circle opacity='0.2' cx='55.1339' cy='319.9' r='2.34612' transform='rotate(90 55.1339 319.9)' fill='#6C7079' />
      <circle
        opacity='0.3'
        cx='55.1339'
        cy='355.237'
        r='2.34612'
        transform='rotate(90 55.1339 355.237)'
        fill='#6C7079'
      />
      <circle
        opacity='0.1'
        cx='55.3177'
        cy='390.575'
        r='2.34612'
        transform='rotate(90 55.3177 390.575)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='2.34602'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 2.34602 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='2.34602'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 2.34602 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='2.34602'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 2.34602 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='2.34602'
        cy='249.298'
        r='2.34612'
        transform='rotate(90 2.34602 249.298)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='2.34602'
        cy='231.556'
        r='2.34612'
        transform='rotate(90 2.34602 231.556)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='2.34602'
        cy='284.635'
        r='2.34612'
        transform='rotate(90 2.34602 284.635)'
        fill='#6C7079'
      />
      <circle opacity='0.5' cx='2.34602' cy='319.9' r='2.34612' transform='rotate(90 2.34602 319.9)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='2.34602'
        cy='355.237'
        r='2.34612'
        transform='rotate(90 2.34602 355.237)'
        fill='#6C7079'
      />
      <circle opacity='0.3' cx='2.5301' cy='390.575' r='2.34612' transform='rotate(90 2.5301 390.575)' fill='#6C7079' />
      <circle opacity='0.2' cx='2.5301' cy='425.912' r='2.34612' transform='rotate(90 2.5301 425.912)' fill='#6C7079' />
      <circle opacity='0.1' cx='2.5301' cy='443.654' r='2.34612' transform='rotate(90 2.5301 443.654)' fill='#6C7079' />
      <circle
        opacity='0.8'
        cx='90.3255'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 90.3255 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='37.5379'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 37.5379 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='90.3255'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 90.3255 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='37.5379'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 37.5379 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='90.3255'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 90.3255 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='37.5379'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 37.5379 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='90.3255'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 90.3255 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='90.3255'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 90.3255 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='90.3255'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 90.3255 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.5' cx='90.3255' cy='213.96' r='2.34612' transform='rotate(90 90.3255 213.96)' fill='#6C7079' />
      <circle
        opacity='0.3'
        cx='90.3255'
        cy='267.039'
        r='2.34612'
        transform='rotate(90 90.3255 267.039)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='90.3255'
        cy='302.304'
        r='2.34612'
        transform='rotate(90 90.3255 302.304)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='90.3255'
        cy='337.641'
        r='2.34612'
        transform='rotate(90 90.3255 337.641)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='37.5379'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 37.5379 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='37.5379'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 37.5379 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='37.5379'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 37.5379 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.5' cx='37.5379' cy='213.96' r='2.34612' transform='rotate(90 37.5379 213.96)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='37.5379'
        cy='267.039'
        r='2.34612'
        transform='rotate(90 37.5379 267.039)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='37.5379'
        cy='302.304'
        r='2.34612'
        transform='rotate(90 37.5379 302.304)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='37.5379'
        cy='337.641'
        r='2.34612'
        transform='rotate(90 37.5379 337.641)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='37.7218'
        cy='372.979'
        r='2.34612'
        transform='rotate(90 37.7218 372.979)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='37.7218'
        cy='408.316'
        r='2.34612'
        transform='rotate(90 37.7218 408.316)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='90.3255'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 90.3255 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='90.3255'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 90.3255 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='90.3255'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 90.3255 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='90.3255'
        cy='249.298'
        r='2.34612'
        transform='rotate(90 90.3255 249.298)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='90.3255'
        cy='231.556'
        r='2.34612'
        transform='rotate(90 90.3255 231.556)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='90.3255'
        cy='284.635'
        r='2.34612'
        transform='rotate(90 90.3255 284.635)'
        fill='#6C7079'
      />
      <circle opacity='0.2' cx='90.3255' cy='319.9' r='2.34612' transform='rotate(90 90.3255 319.9)' fill='#6C7079' />
      <circle
        opacity='0.1'
        cx='90.3255'
        cy='355.237'
        r='2.34612'
        transform='rotate(90 90.3255 355.237)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='37.5379'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 37.5379 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='37.5379'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 37.5379 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='37.5379'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 37.5379 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='37.5379'
        cy='249.298'
        r='2.34612'
        transform='rotate(90 37.5379 249.298)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='37.5379'
        cy='231.556'
        r='2.34612'
        transform='rotate(90 37.5379 231.556)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='37.5379'
        cy='284.635'
        r='2.34612'
        transform='rotate(90 37.5379 284.635)'
        fill='#6C7079'
      />
      <circle opacity='0.2' cx='37.5379' cy='319.9' r='2.34612' transform='rotate(90 37.5379 319.9)' fill='#6C7079' />
      <circle
        opacity='0.3'
        cx='37.5379'
        cy='355.237'
        r='2.34612'
        transform='rotate(90 37.5379 355.237)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='37.7218'
        cy='390.575'
        r='2.34612'
        transform='rotate(90 37.7218 390.575)'
        fill='#6C7079'
      />
      <circle cx='125.517' cy='2.34612' r='2.34612' transform='rotate(90 125.517 2.34612)' fill='#6C7079' />
      <circle cx='72.7296' cy='2.34612' r='2.34612' transform='rotate(90 72.7296 2.34612)' fill='#6C7079' />
      <circle cx='19.9417' cy='2.34612' r='2.34612' transform='rotate(90 19.9417 2.34612)' fill='#6C7079' />
      <circle cx='125.517' cy='19.9421' r='2.34612' transform='rotate(90 125.517 19.9421)' fill='#6C7079' />
      <circle cx='72.7296' cy='19.9421' r='2.34612' transform='rotate(90 72.7296 19.9421)' fill='#6C7079' />
      <circle cx='19.9417' cy='19.9421' r='2.34612' transform='rotate(90 19.9417 19.9421)' fill='#6C7079' />
      <circle cx='125.517' cy='37.5379' r='2.34612' transform='rotate(90 125.517 37.5379)' fill='#6C7079' />
      <circle cx='72.7296' cy='37.5379' r='2.34612' transform='rotate(90 72.7296 37.5379)' fill='#6C7079' />
      <circle cx='19.9417' cy='37.5379' r='2.34612' transform='rotate(90 19.9417 37.5379)' fill='#6C7079' />
      <circle cx='107.921' cy='2.34612' r='2.34612' transform='rotate(90 107.921 2.34612)' fill='#6C7079' />
      <circle cx='55.1339' cy='2.34612' r='2.34612' transform='rotate(90 55.1339 2.34612)' fill='#6C7079' />
      <circle cx='107.921' cy='19.9421' r='2.34612' transform='rotate(90 107.921 19.9421)' fill='#6C7079' />
      <circle cx='55.1339' cy='19.9421' r='2.34612' transform='rotate(90 55.1339 19.9421)' fill='#6C7079' />
      <circle cx='2.34602' cy='19.9421' r='2.34612' transform='rotate(90 2.34602 19.9421)' fill='#6C7079' />
      <circle cx='107.921' cy='37.5379' r='2.34612' transform='rotate(90 107.921 37.5379)' fill='#6C7079' />
      <circle cx='55.1339' cy='37.5379' r='2.34612' transform='rotate(90 55.1339 37.5379)' fill='#6C7079' />
      <circle cx='2.34602' cy='37.5379' r='2.34612' transform='rotate(90 2.34602 37.5379)' fill='#6C7079' />
      <circle cx='90.3255' cy='19.9421' r='2.34612' transform='rotate(90 90.3255 19.9421)' fill='#6C7079' />
      <circle cx='37.5379' cy='19.9421' r='2.34612' transform='rotate(90 37.5379 19.9421)' fill='#6C7079' />
      <circle cx='90.3255' cy='2.34612' r='2.34612' transform='rotate(90 90.3255 2.34612)' fill='#6C7079' />
      <circle cx='37.5379' cy='2.34612' r='2.34612' transform='rotate(90 37.5379 2.34612)' fill='#6C7079' />
      <circle cx='90.3255' cy='37.5379' r='2.34612' transform='rotate(90 90.3255 37.5379)' fill='#6C7079' />
      <circle cx='37.5379' cy='37.5379' r='2.34612' transform='rotate(90 37.5379 37.5379)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='178.305'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 178.305 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='178.305'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 178.305 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='178.305'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 178.305 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='178.305'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 178.305 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='178.305'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 178.305 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='178.305'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 178.305 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.2' cx='178.305' cy='213.96' r='2.34612' transform='rotate(90 178.305 213.96)' fill='#6C7079' />
      <circle
        opacity='0.1'
        cx='178.305'
        cy='267.039'
        r='2.34612'
        transform='rotate(90 178.305 267.039)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='178.305'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 178.305 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='178.305'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 178.305 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='178.305'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 178.305 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='178.305'
        cy='249.298'
        r='2.34612'
        transform='rotate(90 178.305 249.298)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='178.305'
        cy='231.556'
        r='2.34612'
        transform='rotate(90 178.305 231.556)'
        fill='#6C7079'
      />
      <circle opacity='0.5' cx='160.71' cy='55.1338' r='2.34612' transform='rotate(90 160.71 55.1338)' fill='#6C7079' />
      <circle opacity='0.5' cx='160.71' cy='72.7297' r='2.34612' transform='rotate(90 160.71 72.7297)' fill='#6C7079' />
      <circle opacity='0.5' cx='160.71' cy='90.3255' r='2.34612' transform='rotate(90 160.71 90.3255)' fill='#6C7079' />
      <circle opacity='0.5' cx='160.71' cy='107.922' r='2.34612' transform='rotate(90 160.71 107.922)' fill='#6C7079' />
      <circle opacity='0.5' cx='160.71' cy='143.285' r='2.34612' transform='rotate(90 160.71 143.285)' fill='#6C7079' />
      <circle opacity='0.3' cx='160.71' cy='178.623' r='2.34612' transform='rotate(90 160.71 178.623)' fill='#6C7079' />
      <circle opacity='0.2' cx='160.71' cy='213.96' r='2.34612' transform='rotate(90 160.71 213.96)' fill='#6C7079' />
      <circle opacity='0.2' cx='160.71' cy='267.039' r='2.34612' transform='rotate(90 160.71 267.039)' fill='#6C7079' />
      <circle opacity='0.5' cx='160.71' cy='125.518' r='2.34612' transform='rotate(90 160.71 125.518)' fill='#6C7079' />
      <circle opacity='0.3' cx='160.71' cy='160.881' r='2.34612' transform='rotate(90 160.71 160.881)' fill='#6C7079' />
      <circle opacity='0.3' cx='160.71' cy='196.218' r='2.34612' transform='rotate(90 160.71 196.218)' fill='#6C7079' />
      <circle opacity='0.2' cx='160.71' cy='249.298' r='2.34612' transform='rotate(90 160.71 249.298)' fill='#6C7079' />
      <circle opacity='0.3' cx='160.71' cy='231.556' r='2.34612' transform='rotate(90 160.71 231.556)' fill='#6C7079' />
      <circle opacity='0.2' cx='160.71' cy='284.635' r='2.34612' transform='rotate(90 160.71 284.635)' fill='#6C7079' />
      <circle
        opacity='0.8'
        cx='143.113'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 143.113 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.8'
        cx='143.113'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 143.113 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='143.113'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 143.113 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='143.113'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 143.113 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='143.113'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 143.113 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='143.113'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 143.113 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.3' cx='143.113' cy='213.96' r='2.34612' transform='rotate(90 143.113 213.96)' fill='#6C7079' />
      <circle
        opacity='0.3'
        cx='143.113'
        cy='267.039'
        r='2.34612'
        transform='rotate(90 143.113 267.039)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='143.113'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 143.113 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='143.113'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 143.113 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='143.113'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 143.113 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='143.113'
        cy='249.298'
        r='2.34612'
        transform='rotate(90 143.113 249.298)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='143.113'
        cy='231.556'
        r='2.34612'
        transform='rotate(90 143.113 231.556)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='143.113'
        cy='284.635'
        r='2.34612'
        transform='rotate(90 143.113 284.635)'
        fill='#6C7079'
      />
      <circle
        opacity='0.1'
        cx='143.113'
        cy='302.304'
        r='2.34612'
        transform='rotate(90 143.113 302.304)'
        fill='#6C7079'
      />
      <circle cx='178.305' cy='2.34612' r='2.34612' transform='rotate(90 178.305 2.34612)' fill='#6C7079' />
      <circle cx='178.305' cy='19.9421' r='2.34612' transform='rotate(90 178.305 19.9421)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='178.305'
        cy='37.5379'
        r='2.34612'
        transform='rotate(90 178.305 37.5379)'
        fill='#6C7079'
      />
      <circle cx='160.71' cy='2.34612' r='2.34612' transform='rotate(90 160.71 2.34612)' fill='#6C7079' />
      <circle cx='160.71' cy='19.9421' r='2.34612' transform='rotate(90 160.71 19.9421)' fill='#6C7079' />
      <circle opacity='0.5' cx='160.71' cy='37.5379' r='2.34612' transform='rotate(90 160.71 37.5379)' fill='#6C7079' />
      <circle cx='143.113' cy='2.34612' r='2.34612' transform='rotate(90 143.113 2.34612)' fill='#6C7079' />
      <circle cx='143.113' cy='19.9421' r='2.34612' transform='rotate(90 143.113 19.9421)' fill='#6C7079' />
      <circle cx='143.113' cy='37.5379' r='2.34612' transform='rotate(90 143.113 37.5379)' fill='#6C7079' />
      <circle
        opacity='0.8'
        cx='231.093'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 231.093 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='231.093'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 231.093 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='231.093'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 231.093 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='231.093'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 231.093 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='231.093'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 231.093 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='231.093'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 231.093 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.1' cx='231.093' cy='213.96' r='2.34612' transform='rotate(90 231.093 213.96)' fill='#6C7079' />
      <circle
        opacity='0.3'
        cx='231.093'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 231.093 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='231.093'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 231.093 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='231.093'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 231.093 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='213.497'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 213.497 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='213.497'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 213.497 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='213.497'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 213.497 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='213.497'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 213.497 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='213.497'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 213.497 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='213.497'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 213.497 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.2' cx='213.497' cy='213.96' r='2.34612' transform='rotate(90 213.497 213.96)' fill='#6C7079' />
      <circle
        opacity='0.3'
        cx='213.497'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 213.497 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='213.497'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 213.497 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='213.497'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 213.497 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='213.497'
        cy='231.556'
        r='2.34612'
        transform='rotate(90 213.497 231.556)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='195.901'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 195.901 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='195.901'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 195.901 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='195.901'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 195.901 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='195.901'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 195.901 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='195.901'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 195.901 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='195.901'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 195.901 178.623)'
        fill='#6C7079'
      />
      <circle opacity='0.3' cx='195.901' cy='213.96' r='2.34612' transform='rotate(90 195.901 213.96)' fill='#6C7079' />
      <circle
        opacity='0.3'
        cx='195.901'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 195.901 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='195.901'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 195.901 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='195.901'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 195.901 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='195.901'
        cy='231.556'
        r='2.34612'
        transform='rotate(90 195.901 231.556)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='195.901'
        cy='249.298'
        r='2.34612'
        transform='rotate(90 195.901 249.298)'
        fill='#6C7079'
      />
      <circle cx='231.093' cy='2.34612' r='2.34612' transform='rotate(90 231.093 2.34612)' fill='#6C7079' />
      <circle cx='231.093' cy='19.9421' r='2.34612' transform='rotate(90 231.093 19.9421)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='231.093'
        cy='37.5379'
        r='2.34612'
        transform='rotate(90 231.093 37.5379)'
        fill='#6C7079'
      />
      <circle cx='213.497' cy='2.34612' r='2.34612' transform='rotate(90 213.497 2.34612)' fill='#6C7079' />
      <circle cx='213.497' cy='19.9421' r='2.34612' transform='rotate(90 213.497 19.9421)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='213.497'
        cy='37.5379'
        r='2.34612'
        transform='rotate(90 213.497 37.5379)'
        fill='#6C7079'
      />
      <circle cx='195.901' cy='2.34612' r='2.34612' transform='rotate(90 195.901 2.34612)' fill='#6C7079' />
      <circle cx='195.901' cy='19.9421' r='2.34612' transform='rotate(90 195.901 19.9421)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='195.901'
        cy='37.5379'
        r='2.34612'
        transform='rotate(90 195.901 37.5379)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='283.881'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 283.881 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='283.881'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 283.881 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='283.881'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 283.881 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='301.476'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 301.476 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='301.476'
        cy='107.947'
        r='2.34612'
        transform='rotate(90 301.476 107.947)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='301.476'
        cy='125.616'
        r='2.34612'
        transform='rotate(90 301.476 125.616)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='319.072'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 319.072 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.1'
        cx='319.072'
        cy='107.947'
        r='2.34612'
        transform='rotate(90 319.072 107.947)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='319.072'
        cy='125.616'
        r='2.34612'
        transform='rotate(90 319.072 125.616)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='283.881'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 283.881 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='283.881'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 283.881 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.1'
        cx='283.938'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 283.938 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='283.938'
        cy='160.954'
        r='2.34612'
        transform='rotate(90 283.938 160.954)'
        fill='#6C7079'
      />
      <circle
        opacity='0.1'
        cx='301.606'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 301.606 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='266.285'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 266.285 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='266.285'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 266.285 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='266.285'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 266.285 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='266.285'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 266.285 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='266.285'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 266.285 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='266.285'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 266.285 178.623)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='266.285'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 266.285 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='266.285'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 266.285 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='248.689'
        cy='55.1338'
        r='2.34612'
        transform='rotate(90 248.689 55.1338)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='248.689'
        cy='72.7297'
        r='2.34612'
        transform='rotate(90 248.689 72.7297)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='248.689'
        cy='90.3255'
        r='2.34612'
        transform='rotate(90 248.689 90.3255)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='248.689'
        cy='107.922'
        r='2.34612'
        transform='rotate(90 248.689 107.922)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='248.689'
        cy='143.285'
        r='2.34612'
        transform='rotate(90 248.689 143.285)'
        fill='#6C7079'
      />
      <circle
        opacity='0.2'
        cx='248.689'
        cy='178.623'
        r='2.34612'
        transform='rotate(90 248.689 178.623)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='248.689'
        cy='125.518'
        r='2.34612'
        transform='rotate(90 248.689 125.518)'
        fill='#6C7079'
      />
      <circle
        opacity='0.3'
        cx='248.689'
        cy='160.881'
        r='2.34612'
        transform='rotate(90 248.689 160.881)'
        fill='#6C7079'
      />
      <circle
        opacity='0.1'
        cx='248.689'
        cy='196.218'
        r='2.34612'
        transform='rotate(90 248.689 196.218)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='283.881'
        cy='2.34612'
        r='2.34612'
        transform='rotate(90 283.881 2.34612)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='283.881'
        cy='19.9421'
        r='2.34612'
        transform='rotate(90 283.881 19.9421)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='283.881'
        cy='37.5379'
        r='2.34612'
        transform='rotate(90 283.881 37.5379)'
        fill='#6C7079'
      />
      <circle cx='266.285' cy='2.34612' r='2.34612' transform='rotate(90 266.285 2.34612)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='266.285'
        cy='19.9421'
        r='2.34612'
        transform='rotate(90 266.285 19.9421)'
        fill='#6C7079'
      />
      <circle
        opacity='0.5'
        cx='266.285'
        cy='37.5379'
        r='2.34612'
        transform='rotate(90 266.285 37.5379)'
        fill='#6C7079'
      />
      <circle cx='248.689' cy='2.34612' r='2.34612' transform='rotate(90 248.689 2.34612)' fill='#6C7079' />
      <circle cx='248.689' cy='19.9421' r='2.34612' transform='rotate(90 248.689 19.9421)' fill='#6C7079' />
      <circle
        opacity='0.5'
        cx='248.689'
        cy='37.5379'
        r='2.34612'
        transform='rotate(90 248.689 37.5379)'
        fill='#6C7079'
      />
      <circle opacity='0.3' cx='301.477' cy='55.1338' r='2.34612' fill='#6C7079' />
      <circle opacity='0.3' cx='319.072' cy='55.1338' r='2.34612' fill='#6C7079' />
      <circle opacity='0.3' cx='336.668' cy='55.1338' r='2.34612' fill='#6C7079' />
      <circle opacity='0.3' cx='354.264' cy='55.1338' r='2.34612' fill='#6C7079' />
      <circle opacity='0.2' cx='354.264' cy='72.7297' r='2.34612' fill='#6C7079' />
      <circle opacity='0.1' cx='354.264' cy='90.2789' r='2.34612' fill='#6C7079' />
      <circle opacity='0.1' cx='336.513' cy='107.947' r='2.34612' fill='#6C7079' />
      <circle opacity='0.3' cx='301.477' cy='72.7297' r='2.34612' fill='#6C7079' />
      <circle opacity='0.3' cx='319.072' cy='72.7297' r='2.34612' fill='#6C7079' />
      <circle opacity='0.3' cx='336.668' cy='72.7297' r='2.34612' fill='#6C7079' />
      <circle opacity='0.2' cx='336.668' cy='90.2789' r='2.34612' fill='#6C7079' />
      <circle opacity='0.5' cx='301.477' cy='2.34612' r='2.34612' fill='#6C7079' />
      <circle opacity='0.5' cx='319.072' cy='2.34612' r='2.34612' fill='#6C7079' />
      <circle opacity='0.5' cx='336.668' cy='2.34612' r='2.34612' fill='#6C7079' />
      <circle opacity='0.5' cx='354.264' cy='2.34612' r='2.34612' fill='#6C7079' />
      <circle opacity='0.2' cx='371.86' cy='2.34612' r='2.34612' fill='#6C7079' />
      <circle opacity='0.2' cx='389.456' cy='2.34612' r='2.34612' fill='#6C7079' />
      <circle opacity='0.2' cx='407.052' cy='2.34612' r='2.34612' fill='#6C7079' />
      <circle opacity='0.2' cx='424.425' cy='2.34612' r='2.34612' fill='#6C7079' />
      <circle opacity='0.2' cx='424.425' cy='20.0347' r='2.34612' fill='#6C7079' />
      <circle opacity='0.1' cx='442.094' cy='2.3659' r='2.34612' fill='#6C7079' />
      <circle opacity='0.5' cx='301.477' cy='19.9421' r='2.34612' fill='#6C7079' />
      <circle opacity='0.8' cx='319.072' cy='19.9421' r='2.34612' fill='#6C7079' />
      <circle opacity='0.5' cx='336.668' cy='19.9421' r='2.34612' fill='#6C7079' />
      <circle opacity='0.3' cx='354.264' cy='19.9421' r='2.34612' fill='#6C7079' />
      <circle opacity='0.3' cx='371.86' cy='19.9421' r='2.34612' fill='#6C7079' />
      <circle opacity='0.2' cx='371.86' cy='37.7034' r='2.34612' fill='#6C7079' />
      <circle opacity='0.2' cx='371.86' cy='54.9413' r='2.34612' fill='#6C7079' />
      <circle opacity='0.2' cx='371.86' cy='72.61' r='2.34612' fill='#6C7079' />
      <circle opacity='0.2' cx='389.456' cy='19.9421' r='2.34612' fill='#6C7079' />
      <circle opacity='0.2' cx='389.456' cy='37.7034' r='2.34612' fill='#6C7079' />
      <circle opacity='0.1' cx='389.456' cy='54.9413' r='2.34612' fill='#6C7079' />
      <circle opacity='0.1' cx='407.052' cy='19.9421' r='2.34612' fill='#6C7079' />
      <circle opacity='0.1' cx='407.052' cy='37.7034' r='2.34612' fill='#6C7079' />
      <circle opacity='0.5' cx='301.477' cy='37.5379' r='2.34612' fill='#6C7079' />
      <circle opacity='0.5' cx='319.072' cy='37.5379' r='2.34612' fill='#6C7079' />
      <circle opacity='0.5' cx='336.668' cy='37.5379' r='2.34612' fill='#6C7079' />
      <circle opacity='0.3' cx='354.264' cy='37.5379' r='2.34612' fill='#6C7079' />
    </g>
  </svg>
)
