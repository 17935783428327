import * as React from 'react'
import styled from 'styled-components'
import { Card, Heading, Link, Paragraph, DocumentIcon, Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { PublicDocument } from '/~/types/graphql'
import { DownloadCloud } from '/fiweb/components/Icons'
import dayjs from 'dayjs'

const StyledCard = styled(Card)`
  background-color: #ffffff;
  border-radius: 5px;
  filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.15));
  margin: 5px;
  padding: ${({ theme: { spacing } }) => spacing.s};
  width: 265px;
`

interface Props {
  report: PublicDocument
}

export const ReportCard = ({ report: { publishDate, title, fileUrl } }: Props) => {
  const theme = useCustomTheme()

  return (
    <StyledCard>
      <Paragraph color='danger' fontSize='14px' fontWeight={600} lineHeight='20px' marginBottom={theme.spacing.xxs}>
        {dayjs(publishDate).format('DD.MM.YYYY')}
      </Paragraph>
      <Pane display='flex' flexFlow='row nowrap' alignItems='center' marginBottom={theme.spacing.xxs}>
        <DocumentIcon
          marginRight={theme.spacing.xxs}
          style={{ width: '21px', height: '28px' }}
          color={theme.colors.fiDark70}
        />
        <Heading size={300} color={theme.colors.fiDark70} marginBottom={0}>
          {title}
        </Heading>
      </Pane>

      <Link size={300} fontWeight={600} href={fileUrl} target='_blank'>
        Last ned PDF <DownloadCloud style={{ marginBottom: '-4px' }} />
      </Link>
    </StyledCard>
  )
}
