import { Message } from '/fiweb/lib/i18n/types'

export interface NavItemBase {
  key: string
  labelId: Message
  hideWhenLoggedIn?: boolean
}

export type NavItem = NavItemBase &
  (
    | {
        to: string
        subMenus?: never
      }
    | {
        to?: never
        subMenus?: Array<NavItem>
      }
  )

export const navItems: Readonly<Array<NavItem>> = [
  {
    key: 'campaigns',
    labelId: 'clientNavbarLabels.campaigns',
    to: '/kampanjer',
  },
  {
    key: 'investor',
    labelId: 'clientNavbarLabels.investor',
    subMenus: [
      {
        key: 'becomeInvestor',
        labelId: 'clientNavbarLabels.investor.becomeInvestor',
        to: '/innlogging/registrering',
        hideWhenLoggedIn: true,
      },
      {
        key: 'howItWorksInvestor',
        labelId: 'clientNavbarLabels.investor.howItWorks',
        to: '/slik-fungerer-det/investor',
      },
    ],
  },
  {
    key: 'issuer',
    labelId: 'clientNavbarLabels.issuer',
    subMenus: [
      {
        key: 'seekCapital',
        labelId: 'clientNavbarLabels.issuer.seekCapital',
        to: '/sok-kapital-kontakt',
      },
      {
        key: 'bookMeeting',
        labelId: 'clientNavbarLabels.issuer.bookMeeting',
        to: '/book-intromote?origin=navbar',
      },
      {
        key: 'howItWorksIssuer',
        labelId: 'clientNavbarLabels.issuer.howItWorks',
        to: '/slik-fungerer-det/selskap',
      },
    ],
  },
  {
    key: 'mainAboutUs',
    labelId: 'clientNavbarLabels.aboutUs',
    subMenus: [
      {
        key: 'contactUs',
        labelId: 'clientNavbarLabels.aboutus.contactUs',
        to: '/kontakt-oss',
      },
      {
        key: 'aboutUs',
        labelId: 'clientNavbarLabels.aboutUs.aboutUs',
        to: '/om-oss',
      },
      {
        key: 'press',
        labelId: 'clientNavbarLabels.aboutUs.press',
        to: '/presse',
      },
      {
        key: 'blog',
        labelId: 'clientNavbarLabels.aboutUs.blog',
        to: '/blogg',
      },
      {
        key: 'podcast',
        labelId: 'clientNavbarLabels.aboutUs.podcast',
        to: '/podcast',
      },
      {
        key: 'career',
        labelId: 'clientNavbarLabels.aboutUs.career',
        to: '/karriere',
      },
    ],
  },
] as const
