import { Alert } from 'evergreen-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { usePublicInvestmentOfferFlags } from '../Context/PublicInvestmentOfferData'
import { Caption } from '/fiweb/components'
import { Message } from '/fiweb/lib/i18n/types'

export const CaptionText = styled(Caption)`
  color: ${({ theme: { colors } }) => colors.text.default};
`
export const AlertMessage = styled(Alert)`
  min-width: 100%;
  h4 {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }
`

enum MessageTypeEnum {
  ALERT = 'ALERT',
  CAPTION = 'CAPTION',
}

type FlagsData = ReturnType<typeof usePublicInvestmentOfferFlags>

interface MessageType {
  type: MessageTypeEnum
  message: Message
  show: keyof FlagsData | ((flags: FlagsData) => boolean)
}

const messages: Array<MessageType> = [
  {
    type: MessageTypeEnum.ALERT,
    message: 'investmentOfferPage.readonly',
    show: (flags) => flags.isReadonly || flags.isPreview,
  },
  {
    type: MessageTypeEnum.ALERT,
    message: 'investmentOfferPage.isClosed',
    show: 'isClosed',
  },
  {
    type: MessageTypeEnum.CAPTION,
    message: 'investmentOfferPage.followCaption',
    show: (flags) => flags.isPreorder && !flags.userCanPreorder,
  },
  {
    type: MessageTypeEnum.CAPTION,
    message: 'investmentOfferPage.riskWarning',
    show: () => true,
  },
]

/**
 * Find the first alert or caption message it should display
 */
export const AlertOrCaption = () => {
  const flags = usePublicInvestmentOfferFlags()

  const activeMessage = React.useMemo(() => {
    for (const message of messages) {
      if (typeof message.show === 'string' ? flags[message.show] : message.show(flags)) {
        return message
      }
    }
  }, [flags])

  return activeMessage.type === MessageTypeEnum.CAPTION ? (
    <CaptionText>
      <FormattedMessage id={activeMessage.message} />
    </CaptionText>
  ) : (
    <AlertMessage title={<FormattedMessage id={activeMessage.message} />} />
  )
}
