import * as React from 'react'
import { useLocation } from 'react-router-dom'

const exceptions = ['konto', 'kampanje']

const ScrollToTop = () => {
  const { pathname } = useLocation()

  const splitPathName = pathname.split(/\//)
  const subroute = splitPathName?.[1]
  const path = exceptions.includes(subroute) ? pathname : subroute

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' })
  }, [path])
  return null
}

export default ScrollToTop
