import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Pane, Heading } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Divider } from '/fiweb/components'
import { FinanceNorwayLogo } from '/fiweb/components/Icons'

export const AboutUsPartners = () => {
  const { medium, large } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Pane
      display='flex'
      justifyContent='center'
      paddingX={medium ? 25 : large ? 30 : 60}
      width='100%'
      minHeight={350}
      backgroundColor={theme.colors.fiDark}
    >
      <Pane display='flex' flexDirection='column' alignItems='center' maxWidth={1010} width='100%'>
        <Heading size={large ? 500 : 600} marginY={theme.spacing.l} color='#FFFFFF'>
          Våre samarbeidspartnere
        </Heading>

        <Pane
          display='flex'
          flexDirection={large ? 'column' : 'row'}
          width='50%'
          justifyContent='space-around'
          alignItems='center'
          marginBottom={large ? 0 : theme.spacing.l}
        >
          <a href='https://www.finansnorge.no/' target='_blank' rel='noreferrer'>
            <FinanceNorwayLogo
              width={large ? 162 : 252}
              height={large ? 23 : 37}
              color='#FFFFFF'
              style={{
                marginBottom: large ? theme.spacing.m : 0,
              }}
            />
          </a>
        </Pane>
        <Divider width='100%' color={theme.colors.fiDark70} />
      </Pane>
    </Pane>
  )
}
