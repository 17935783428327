import * as React from 'react'
import { Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Lock } from '/fiweb/components/Icons'

export const LockedSection: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { spacing, colors } = useCustomTheme()

  return (
    <Pane
      display='flex'
      width='100%'
      flexDirection='column'
      maxWidth={600}
      alignItems='center'
      paddingX={spacing.s}
      paddingTop={spacing.s}
      paddingBottom={spacing.m}
      background={colors.fiTint}
      boxShadow={`1px 1px 15px ${colors.fiDark20}`}
      borderRadius={6}
      gap={spacing.s}
    >
      <Lock color={colors.fiDark20} width={100} height={100} />
      {children}
    </Pane>
  )
}
