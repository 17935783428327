import { breakpoints } from '../fiweb/lib'
import { Pane } from 'evergreen-ui'
import styled from 'styled-components'

export default styled(Pane)`
  display: grid;
  width: 100%;
  grid-column-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(min(330px, 100%), 1fr));
  grid-row-gap: 30px;
  max-width: 100%;
  @media ${breakpoints.large} {
    grid-template-columns: repeat(2, minmax(min(300px, 100%), 1fr));
  }
  @media ${breakpoints.medium} {
    grid-template-columns: repeat(1, minmax(min(300px, 100%), 1fr));
  }
`
