import * as React from 'react'
import { Heading, Pane, toaster } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import NavButtons from './NavButtonsComponent'
import { useUpdateProfileMutationMutation } from '/~/types/graphql'
import { ProfilePhoneInput } from '/~/components/account/Profile'
import { FormProvider, useForm } from 'react-hook-form'
import { useAuthContext } from '/~/utils/AuthContext'

interface Props {
  goBack: () => void
  goForward: () => void
}
interface FormInput {
  phone?: string
}

const PhoneStep = ({ goBack, goForward }: Props) => {
  const { user } = useAuthContext()
  const theme = useCustomTheme()
  const formControl = useForm({
    defaultValues: {
      phone: user?.phone || '',
    },
  })
  const { handleSubmit, reset } = formControl
  React.useEffect(() => {
    if (user?.phone) {
      reset({ phone: user?.phone || '' })
    }
  }, [user?.phone])

  const [updateProfile] = useUpdateProfileMutationMutation()

  const save = async (input: FormInput, event: React.SyntheticEvent) => {
    event.preventDefault()
    try {
      await updateProfile({ variables: { input } })

      goForward()
    } catch (_err) {
      toaster.danger('Kunne ikke oppdatere profil. Prøv igjen eller kontakt oss på support@folkeinvest.no')
    }
  }

  return (
    <Pane
      display='flex'
      flexDirection='column'
      maxWidth={380}
      marginTop={theme.spacing.l}
      alignItems='center'
      height='100%'
      width='100%'
    >
      <FormProvider {...formControl}>
        <Heading>Telefonnummer</Heading>

        <form onSubmit={handleSubmit(save)} onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}>
          <Pane width='100%'>
            <ProfilePhoneInput />
          </Pane>
          <NavButtons onBack={goBack} disabled={false} />
        </form>
      </FormProvider>
    </Pane>
  )
}

export default PhoneStep
