import * as React from 'react'
import { Image, Pane, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { MissingImage } from './MissingImage'

interface Props {
  url: string
}

const CoverImage = ({ url }: Props) => {
  const theme = useCustomTheme()
  const [brokenUrl, setBrokenUrl] = React.useState(false)
  const [imageLoaded, setImageLoaded] = React.useState(false)

  const handleError = () => {
    setBrokenUrl(true)
  }

  return (
    <Pane width='100%' borderTopLeftRadius={10} borderTopRightRadius={10} overflow='hidden'>
      {url ? (
        !brokenUrl ? (
          <Pane position='relative' width='100%' style={{ aspectRatio: '360 / 225' }}>
            <Image
              src={url}
              width='100%'
              height='100%'
              alt='Omslagsbilde emisjonskort'
              onError={handleError}
              onLoad={() => setImageLoaded(true)}
            />
            {!imageLoaded && (
              <Pane position='absolute' top={0} bottom={0} left={0} right={0}>
                <MissingImage />
              </Pane>
            )}
          </Pane>
        ) : (
          <Pane
            width='100%'
            height='100%'
            backgroundColor={theme.colors.fiDark20}
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='center'
          >
            <MissingImage />
            <Text position='absolute'>
              <i>Bilde utilgjengelig</i>
            </Text>
          </Pane>
        )
      ) : (
        <Pane
          width='100%'
          height='100%'
          backgroundColor={theme.colors.fiDark20}
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
        >
          <MissingImage />
        </Pane>
      )}
    </Pane>
  )
}

export default CoverImage
