import * as React from 'react'
import { Button, Heading, Pane, Paragraph, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Link as RouterLink } from 'react-router-dom'
import { Reservation } from './ReserveShares'
import { FetchCampaignQuery } from '/~/types/graphql'
import { nf } from '/~/utils/formatters'
import { useBreakpoint } from '/fiweb/lib'

interface Props {
  campaign: FetchCampaignQuery['Campaign']
  reservation: Reservation
  onClose: () => void
}

const PreorderSummary = ({ campaign, reservation, onClose }: Props) => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()
  return (
    <Pane>
      <Heading is='h5' size={500}>
        Gratulerer med din forhåndstegning av aksjer i {campaign.title}
      </Heading>

      <Pane marginTop={theme.spacing.s}>
        <Paragraph>Du kan finne info om alle dine tegninger og forhåndstegninger på din profilside.</Paragraph>
        <Paragraph marginTop={theme.spacing.xs}>
          Tegningslisten vil bli oppdatert med din forhåndstegning når tegningsvinduet åpner. Frem til da vil den ikke
          være synlig på emisjonssiden.
        </Paragraph>
      </Pane>

      <Pane marginTop={theme.spacing.s}>
        <Heading is='h6' size={400}>
          Informasjon om din forhåndstegning
        </Heading>
        <Pane display='grid' gridTemplateColumns='200px 1fr'>
          <Text fontWeight={600}>Tegnet på:</Text>
          <Text>{reservation.signee.name}</Text>
          <Text fontWeight={600}>Aksjer:</Text>
          <Text>{reservation.numberOfShares}</Text>
          <Text fontWeight={600}>Tegningens verdi:</Text>
          <Text>{nf(reservation.amount)} kr</Text>
        </Pane>
      </Pane>
      <Pane
        display='flex'
        flexDirection={medium ? 'column-reverse' : 'row'}
        justifyContent='flex-end'
        marginTop={theme.spacing.s}
        marginBottom={theme.spacing.xs}
      >
        <Button size='small' onClick={onClose}>
          Tilbake til emisjon
        </Button>
        <Button
          size='small'
          is={RouterLink}
          to='/konto/profil'
          appearance='primary'
          marginLeft={medium ? 0 : 18}
          marginBottom={medium ? theme.spacing.xs : 0}
        >
          Din profilside
        </Button>
      </Pane>
    </Pane>
  )
}

export default PreorderSummary
