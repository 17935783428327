import * as React from 'react'

import { Paragraph, Small, Strong } from 'evergreen-ui'
import { useAuthContext } from '/~/utils/AuthContext'
import { BankIDVerification } from '/~/components/BankIDVerification/BankIDVerification'

const ProfileBankIdStatus = () => {
  const { user } = useAuthContext()

  if (!user?.email) {
    return (
      <>
        <Paragraph size={300}>
          <Strong size={300}>BankID</Strong>
        </Paragraph>
        <Paragraph size={300}>
          <Small>En feil forhindrer verifisering med BankID.</Small>
        </Paragraph>
        <Paragraph size={300}>
          <Small>Oppfrisk siden og prøv igjen eller kontakt support.</Small>
        </Paragraph>
      </>
    )
  }

  return (
    <>
      <Paragraph size={300}>
        <Strong size={300}>BankID</Strong>
      </Paragraph>
      <Paragraph size={300}>
        <Small>Bekreft identitieten din med BankID.</Small>
      </Paragraph>
      <Paragraph size={300}>
        <Small>Informasjonen sikrer regelverksetterlevelse.</Small>
      </Paragraph>

      <BankIDVerification centered />
    </>
  )
}

export default ProfileBankIdStatus
