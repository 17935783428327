import React from 'react'
import { useBreakpoint, breakpoints } from '/fiweb/lib'
import { Pane, Text, Heading, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled from 'styled-components'
import moment from 'moment'
import { FooterLink } from '/~/components'
import LogoFooter from '/~/static/icons/LogoFooter.svg'
import { footerMenuItems } from './footerMenuItems'
import { Arrow, IconDirection } from '/fiweb/components/Icons'

const StyledFooterWrapper = styled(Pane)`
  background-color: ${({ theme: { colors } }) => colors.fiDark};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledFooterContainer = styled(Pane)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  padding: 10px 25px 10px 25px;
  justify-content: space-between;
  max-width: 1060px;
  width: 100%;
  @media ${breakpoints.medium} {
    max-width: 400px;
  }
`

const StyledLogo = styled.img.attrs(() => ({
  src: LogoFooter,
  alt: 'Folkeinvest Logo',
}))`
  margin-top: 14px;
  height: 89.5px;
  width: 62.86px;
`

const StyledHeading = styled(Heading)`
  font-weight: 700;
  line-height: 33px;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 10px;
  @media ${breakpoints.medium} {
    margin-bottom: 2px;
  }
`

const Footer = () => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  if (medium) {
    return <MobileFooter theme={theme} />
  }

  return <DefaultFooter theme={theme} />
}

const MobileFooter = ({ theme }) => (
  <StyledFooterWrapper backgroundColor={theme.colors.fiDark}>
    <StyledFooterContainer marginTop={theme.spacing.m}>
      <Pane display='flex' justifyContent='space-between' width='100%'>
        <Pane display='flex' flexDirection='column'>
          <StyledHeading>Informasjon</StyledHeading>
          {footerMenuItems.information.map((item, index) => (
            <FooterLink href={item.link} target='_blank' key={`footerInformation${index}`}>
              {item.title}
            </FooterLink>
          ))}
        </Pane>
        <Pane display='flex' flexDirection='column' textAlign='right'>
          <StyledHeading>Kontakt</StyledHeading>
          {footerMenuItems.contact.map((item, index) => (
            <FooterLink href={item.link} target='_blank' key={`footerContact${index}`}>
              {item.title}
            </FooterLink>
          ))}
        </Pane>
      </Pane>
      <Pane display='flex' flexDirection='column' width='100%' marginTop={theme.spacing.m}>
        <StyledHeading>Sosiale medier</StyledHeading>
        <Pane display='grid' gridTemplateColumns='repeat(2, auto)' justifyContent='space-between'>
          {footerMenuItems.socialMedia.map((item, index) => (
            <FooterLink
              href={item.link}
              target='_blank'
              key={`footerSocialMedia${index}`}
              textAlign={index % 2 !== 0 ? 'right' : 'left'}
            >
              {item.title}
            </FooterLink>
          ))}
        </Pane>
      </Pane>
      <hr
        style={{
          width: '100%',
          border: `1px solid ${theme.colors.fiDark70}`,
          margin: `${theme.spacing.s} 0`,
          height: '0px',
        }}
      />
    </StyledFooterContainer>
    <StyledFooterContainer>
      <Pane display='flex' flexDirection='column' alignItems='center' width='100%'>
        <StyledLogo />
        <Paragraph fontSize='14px' color='#ABADB3' textAlign='center' marginTop={theme.spacing.s} maxWidth={250}>
          Folkeinvest er et uavhengig verdipapir-foretak under tilsyn av Finanstilsynet.
        </Paragraph>
      </Pane>
      <hr
        style={{
          width: '100%',
          border: `1px solid ${theme.colors.fiDark70}`,
          margin: `${theme.spacing.s} 0`,
          height: '0px',
        }}
      />
    </StyledFooterContainer>
    <StyledFooterContainer marginBottom={theme.spacing.l}>
      <Pane display='flex' justifyContent='space-between' width='100%'>
        <Paragraph fontSize='14px' color={theme.colors.fiDark40} marginTop={0} maxWidth={250}>
          © Folkeinvest {moment().year()} <br />
          Alle rettigheter reservert. <br />
          Organisasjonsnummer 916 545 061.{' '}
        </Paragraph>
        <FooterLink href='/terms' target='_blank'>
          Vilkår
        </FooterLink>
      </Pane>
    </StyledFooterContainer>
  </StyledFooterWrapper>
)

const DefaultFooter = ({ theme }) => (
  <StyledFooterWrapper backgroundColor={theme.colors.fiDark} paddingBottom={theme.spacing.l}>
    <StyledFooterContainer marginTop={theme.spacing.xl}>
      <StyledLogo />
      <Pane display='grid' gridTemplateColumns='repeat(3, auto)' columnGap='40px'>
        <Pane display='flex' flexDirection='column'>
          <StyledHeading>Kontakt</StyledHeading>
          {footerMenuItems.contact.map((item, index) => (
            <FooterLink href={item.link} target='_blank' key={`footerContact${index}`}>
              {item.title}
            </FooterLink>
          ))}
        </Pane>
        <Pane display='flex' flexDirection='column'>
          <StyledHeading>Informasjon</StyledHeading>
          {footerMenuItems.information.map((item, index) => (
            <FooterLink href={item.link} target='_blank' key={`footerInformation${index}`}>
              {item.title}
            </FooterLink>
          ))}
        </Pane>
        <Pane display='flex' flexDirection='column'>
          <StyledHeading>Sosiale medier</StyledHeading>
          <Pane display='grid' gridTemplateColumns='80px 80px' columnGap='20px'>
            {footerMenuItems.socialMedia.map((item, index) => (
              <FooterLink href={item.link} target='_blank' key={`footerSocialMedia${index}`}>
                {item.title}
              </FooterLink>
            ))}
          </Pane>
        </Pane>
      </Pane>
      <Pane display='flex' flexDirection='column' justifyContent='space-between'>
        <FooterLink onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <Arrow direction={IconDirection.UP} width='9px' height='11px' color='white' />
          <Text color='white' fontSize={16} lineHeight='36px' marginLeft={7}>
            Til toppen
          </Text>
        </FooterLink>
        <FooterLink alignSelf='flex-end' href='/terms' target='_blank'>
          Vilkår
        </FooterLink>
      </Pane>
      <hr
        style={{
          width: '100%',
          border: `1px solid ${theme.colors.fiDark70}`,
          margin: '30px 0',
          height: '0px',
          order: 6,
        }}
      />
    </StyledFooterContainer>
    <StyledFooterContainer>
      <Pane display='flex' justifyContent='space-between' alignItems='flex-end' width='100%'>
        <Paragraph fontSize='14px' color={theme.colors.fiDark40}>
          Folkeinvest er et uavhengig verdipapir- <br /> foretak under tilsyn av Finanstilsynet.
        </Paragraph>
        <Paragraph fontSize='14px' textAlign='right' color='#ABADB3'>
          © Folkeinvest {moment().year()} <br />
          Alle rettigheter reservert. <br />
          Organisasjonsnummer 916 545 061.
        </Paragraph>
      </Pane>
    </StyledFooterContainer>
  </StyledFooterWrapper>
)

export default Footer
