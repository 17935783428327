import { useMediaMatch } from 'rooks'

export const breakpoints = {
  small: '(max-width: 500px)',
  medium: '(max-width: 767px)',
  UNSAFE_mediumLarge: '(max-width: 945px)',
  large: '(max-width: 1100px)',
}

export { breakpoints as maxBreakpoints }

export const minBreakpoints = {
  small: '(min-width: 501px)',
  medium: '(min-width: 768px)',
  UNSAFE_mediumLarge: '(min-width: 945px)',
  large: '(min-width: 1101px)',
  max: '(min-width: 1261px)',
}

export const useBreakpoint = () => {
  const small = useMediaMatch(breakpoints.small)
  const medium = useMediaMatch(breakpoints.medium)
  const UNSAFE_mediumLarge = useMediaMatch(breakpoints.UNSAFE_mediumLarge)
  const large = useMediaMatch(breakpoints.large)
  const max = useMediaMatch(minBreakpoints.max)

  return { small, medium, UNSAFE_mediumLarge, large, max }
}
