import { Pane } from 'evergreen-ui'
import * as React from 'react'
import { FetchCampaignQuery } from '/~/types/graphql'
import { breakpoints, useBreakpoint } from '/fiweb/lib'
import KeyInformation from './KeyInformation'
import styled, { css } from 'styled-components'
import { CoverImage } from './CoverImage'

const SummaryContainer = styled(Pane)`
  ${({ theme }) => css`
    display: grid;
    padding-left: 20px;
    padding-right: 20px;
    grid-template-columns: 1fr 1fr;
    gap: ${theme.spacing.xs};
    width: 100%;
    max-width: 1260px;
    margin-bottom: ${theme.spacing.l};

    @media ${breakpoints.large} {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: ${theme.spacing.s};
      max-width: 630px;
    }
`}`

const KeyInformationContainer = styled(Pane)`
  ${({ theme }) => css`
    width: 100%;
  @media ${breakpoints.small} {
      max-width: 475px;
      padding-left: ${theme.spacing.xs};
      padding-right: ${theme.spacing.xs};
    } 
`}`

interface Props {
  campaign: FetchCampaignQuery['Campaign']
}

const Summary = ({ campaign }: Props) => {
  const { medium } = useBreakpoint()

  return (
    <SummaryContainer>
      <CoverImage imageUrl={campaign?.coverImage?.url} />
      <KeyInformationContainer>
        <KeyInformation campaign={campaign} showKeyInfoOnMount hideHeader={medium} />
      </KeyInformationContainer>
    </SummaryContainer>
  )
}

export default Summary
