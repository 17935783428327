import * as React from 'react'
import { Heading, Pane, Spinner } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { PublicDocumentSection, useGetPublicDocumentsBySectionQuery } from '/~/types/graphql'
import { useBreakpoint } from '/fiweb/lib'
import { ReportCard } from '/~/components/Cards/ReportCard/ReportCard'
import { ReportsNagivation } from './ReportsNavigation'

export const ReportsSection = () => {
  const theme = useCustomTheme()
  const { small, medium, large } = useBreakpoint()
  const [selectedReport, setSelectedReport] = React.useState<PublicDocumentSection>(
    PublicDocumentSection.QUARTERLYREPORT,
  )
  const [selectedYear, setSelectedYear] = React.useState<number>(2023)

  const { data, loading } = useGetPublicDocumentsBySectionQuery({
    variables: { section: selectedReport },
  })

  const reports = [...(data?.getPublicDocumentsBySection || [])].filter((rep) => rep.year === selectedYear)

  const availableYears = [...new Set((data?.getPublicDocumentsBySection || []).map((rep) => rep.year))]

  React.useEffect(() => {
    if (availableYears.length && !availableYears.includes(selectedYear)) {
      setSelectedYear(availableYears[0])
    }
  }, [availableYears])

  if (loading) {
    return <Spinner />
  }

  return (
    <Pane
      width='100%'
      backgroundColor={theme.colors.fiDark10}
      display='flex'
      flexDirection='column'
      alignItems='center'
      paddingY={medium ? theme.spacing.s : theme.spacing.l}
    >
      <Pane width='100%' maxWidth={1260} paddingX={small ? theme.spacing.xs : theme.spacing.s}>
        <Heading size={600}>Rapporter</Heading>

        <ReportsNagivation
          availableYears={availableYears}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
        />

        <Pane
          maxWidth='100%'
          display='grid'
          gridTemplateColumns='repeat(auto-fill, 270px)'
          columnGap='35px'
          rowGap='35px'
          justifyContent={large ? 'center' : 'left'}
        >
          {reports.map((report) => (
            <ReportCard key={report._id} report={report} />
          ))}
        </Pane>
      </Pane>
    </Pane>
  )
}
