import React from 'react'
import {
  useFetchDataForFollowDialogQuery,
  useRegisterEmailReminderCampaignMutation,
  useUnregisterEmailReminderCampaignMutation,
} from '/~/types/graphql'
import { ActualFollowDialog } from './ActualFollowDialog'
import { gtmEventAddToWishList } from '/~/utils/googleTagManager'
import { useLocation } from 'react-router-dom'

interface Props {
  open: boolean
  onClose: (didFollow: boolean) => void
  campaignId: string
}

export const OldApiFollowDialogWrapper = ({ open, onClose, campaignId }: Props) => {
  const { data, loading, refetch } = useFetchDataForFollowDialogQuery({ variables: { campaignId } })
  const [register] = useRegisterEmailReminderCampaignMutation()
  const [unRegister] = useUnregisterEmailReminderCampaignMutation()
  const location = useLocation()
  const { acceptsPreorderOffers, approvedForPreorder, hasEmailReminder } = data?.visitorSettings || {}
  const campaign = data?.Campaign
  const cs = data?.fetchComingSoonItem

  const title = cs?.title || campaign?.title
  const coverImageUrl = cs?.coverImage?.url || campaign?.coverImage?.url

  const handleRegister = async (email: string, preorderChecked: boolean) => {
    await register({
      variables: { acceptPreorderOffer: preorderChecked, email, campaignId },
    })
    gtmEventAddToWishList({
      title,
      id: campaignId,
      pricePerShare: campaign?.pricePerShare,
      fromCampaignPage: location.pathname.includes('/kampanje/'),
    })
    await refetch?.()
  }

  const handleUnRegister = async () => {
    await unRegister({ variables: { campaignId } })
    await refetch?.()
  }

  if (loading) {
    return null
  }

  return (
    <ActualFollowDialog
      acceptsPreorderOffers={acceptsPreorderOffers}
      approvedForPreorder={approvedForPreorder}
      isFollowing={hasEmailReminder}
      onClose={onClose}
      open={open}
      onRegister={handleRegister}
      onUnregister={handleUnRegister}
      title={title}
      coverImageUrl={coverImageUrl}
    />
  )
}
