import { Pane, Paragraph } from 'evergreen-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import { usePublicInvestmentOfferData } from '../Context/PublicInvestmentOfferData'
import { CurrentStats } from './CurrentStats'
import { Divider } from '/fiweb/components'
import { useCustomTheme } from '/fiweb/lib/theme'
import { breakpoints, convertFromOreToNokAndFormatCurrency, numberFormatter, useBreakpoint } from '/fiweb/lib'
import { useReservedSubscriptionsSummary } from '../Context/ReservedSubscriptionsSummary'

const InfoText = styled(Paragraph).attrs({ size: 300 })`
  @media ${breakpoints.medium} {
    width: 100%;
  }
`

const InfoPanes = styled(Pane)`
  @media ${breakpoints.medium} {
    margin-top: ${({ theme: { spacing } }) => spacing.s};
  }
`

const InfoPane = styled(Pane)`
  ${({ theme: { colors, spacing } }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 ${spacing.xs};

    ${InfoText}:first-child {
      font-weight: 600;
    }

    @media ${breakpoints.medium} {
      flex-wrap: wrap;
      row-gap: ${spacing.xxs};
      padding: ${spacing.xxs} 0;
      border-top: 1px solid ${colors.fiDark20};

      &:last-child {
        border-bottom: 1px solid ${colors.fiDark20};
      }
    }
  `}
`

export const KeyInformation = () => {
  const { medium } = useBreakpoint()
  const { spacing } = useCustomTheme()
  const { pricePerShare, maxSharesToIssue, maxSharesPerInvestor, minSharesPerInvestor, totalNumberOfShares } =
    usePublicInvestmentOfferData()

  const {
    summary: { totalSharesReserved },
  } = useReservedSubscriptionsSummary()

  const remainingShares = maxSharesToIssue - totalSharesReserved

  return (
    <>
      <CurrentStats />
      {!medium && <Divider marginTop={spacing.xxs} marginBottom={spacing.xs} />}
      <InfoPanes>
        <InfoPane>
          <InfoText>
            <FormattedMessage id='investmentOfferPage.keyInfo.companyValuationLabel' />:
          </InfoText>
          <InfoText>{convertFromOreToNokAndFormatCurrency(totalNumberOfShares * pricePerShare, false)} kr</InfoText>
        </InfoPane>
        <InfoPane>
          <InfoText>
            <FormattedMessage id='investmentOfferPage.keyInfo.sharesToIssueLabel' />:
          </InfoText>
          <InfoText>{numberFormatter(maxSharesToIssue)}</InfoText>
        </InfoPane>
        <InfoPane>
          <InfoText>
            <FormattedMessage id='investmentOfferPage.keyInfo.remainingSharesLabel' />:
          </InfoText>
          <InfoText>{numberFormatter(remainingShares)}</InfoText>
        </InfoPane>
        <InfoPane>
          <InfoText>
            <FormattedMessage id='investmentOfferPage.keyInfo.minSubscriptionLabel' />:
          </InfoText>
          <InfoText>
            <FormattedMessage
              id='investmentOfferPage.keyInfo.subscriptionNumberWithPrice'
              values={{
                shares: numberFormatter(minSharesPerInvestor),
                value: convertFromOreToNokAndFormatCurrency(minSharesPerInvestor * pricePerShare, false),
              }}
            />
          </InfoText>
        </InfoPane>
        <InfoPane>
          <InfoText>
            <FormattedMessage id='investmentOfferPage.keyInfo.maxSubscriptionLabel' />:
          </InfoText>
          <InfoText>
            <FormattedMessage
              id='investmentOfferPage.keyInfo.subscriptionNumberWithPrice'
              values={{
                shares: numberFormatter(maxSharesPerInvestor),
                value: convertFromOreToNokAndFormatCurrency(maxSharesPerInvestor * pricePerShare, false),
              }}
            />
          </InfoText>
        </InfoPane>
      </InfoPanes>
    </>
  )
}
