import * as React from 'react'
import { Button, Heading, Link, Pane, Paragraph, Spinner } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { getList } from './questions'
import { RadioGroup } from '/fiweb/components'
import { useBreakpoint } from '/fiweb/lib'

interface Props {
  onChange: (id: string, value: boolean) => void
  onAbort: () => void
  onPrevious: () => void
  onNext: () => void
  save: () => void
  testResult?: Record<string, boolean>
}

const QuestionsDisplay = ({ testResult, onChange, onNext, onPrevious, save }: Props) => {
  const [step, setStep] = React.useState(Math.max(Object.keys(testResult).length - 1, 0))
  const [submitting, setSubmitting] = React.useState(false)

  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  const getQuestionLength = () => {
    return Object.keys(getList(testResult)).length
  }

  const next = async () => {
    await save()

    setTimeout(() => {
      if (step === getQuestionLength() - 1) {
        onNext()
      } else {
        setStep(step + 1)
        setSubmitting(false)
      }
    }, 250)
  }

  const previous = () => {
    if (step === 0) {
      onPrevious()
    } else {
      setStep(step - 1)
    }
  }

  const handleChange = async (id: string, answerId: boolean) => {
    setSubmitting(true)

    await onChange(id, answerId)

    next()
  }

  const questions = getList(testResult)
  const list = Object.keys(questions)

  const currentId = list[step]
  const currentQuestion = questions[currentId]
  const currentResult = testResult[currentId]
  const completed = currentResult !== undefined

  const checkboxes = Boolean(currentQuestion.trueLabel || currentQuestion.falseLabel)

  return (
    <Pane>
      <Pane display='flex' alignItems='baseline'>
        <Heading is='h5' size={400}>
          {currentQuestion.title}
        </Heading>
        {submitting && <Spinner size={18} marginLeft={12} />}
      </Pane>
      <Paragraph>{currentQuestion.label}</Paragraph>
      {checkboxes ? (
        <Pane marginTop={theme.spacing.xs}>
          <RadioGroup
            name='radio-question'
            value={completed && currentResult ? (currentResult ? 'true' : 'false') : undefined}
            onConfirmValue={(val) => {
              // To be able to re-click if question is revisited
              if (!submitting) {
                handleChange(currentId, val === 'true')
              }
            }}
            onChange={(e) => {
              const value = e.target.value === 'true'

              if (!submitting) {
                handleChange(currentId, value)
              }
            }}
            label={currentQuestion.subTitle}
            options={[
              { value: 'true', label: currentQuestion.trueLabel, string: true },
              { value: 'false', label: currentQuestion.falseLabel, string: true },
            ]}
          />
        </Pane>
      ) : (
        <Pane display='grid' gridTemplateColumns='80px 80px' columnGap='18px' marginTop={theme.spacing.s}>
          <Button
            size='small'
            appearance={completed && !currentResult ? 'primary' : 'default'}
            onClick={() => {
              if (!submitting) {
                handleChange(currentId, false)
              }
            }}
          >
            Nei
          </Button>
          <Button
            size='small'
            appearance={completed && currentResult ? 'primary' : 'default'}
            onClick={() => {
              if (!submitting) {
                handleChange(currentId, true)
              }
            }}
          >
            Ja
          </Button>
        </Pane>
      )}
      <Pane
        display='flex'
        flexDirection={medium ? 'column-reverse' : 'row'}
        justifyContent='flex-end'
        alignItems='flex-end'
        marginTop={theme.spacing.s}
        marginBottom='18px'
      >
        <Link
          cursor='pointer'
          onClick={previous}
          marginLeft={medium ? 0 : 18}
          marginBottom={medium ? theme.spacing.xs : 0}
        >
          Tilbake
        </Link>
      </Pane>
    </Pane>
  )
}

export default QuestionsDisplay
