import * as React from 'react'
import { IconProps } from './types'

export const EmailIcon = ({ title, ...rest }: IconProps) => (
  <svg width='48' height='47' viewBox='0 0 48 47' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.1767 26.8391C24.5248 27.4755 23.4698 27.4755 22.8179 26.8391L9.89785 14.1856C9.71891 14.011 9.65602 13.7524 9.73539 13.5176C9.81476 13.2828 10.0228 13.112 10.2727 13.0764C10.4004 13.058 10.5293 13.0489 10.6583 13.049H37.3417C37.4698 13.049 37.5978 13.0582 37.7246 13.0764C37.9745 13.112 38.1826 13.2828 38.2619 13.5176C38.3413 13.7524 38.2784 14.011 38.0995 14.1856L25.1767 26.8391ZM39.5937 15.4932C39.6637 15.4245 39.7693 15.4044 39.8606 15.4423C39.9513 15.4789 40.0104 15.5657 40.01 15.6618V31.3382C40.01 32.7812 38.8153 33.951 37.3417 33.951H10.6583C9.18464 33.951 7.98999 32.7812 7.98999 31.3382V15.6618C7.99106 15.5667 8.04982 15.4814 8.13942 15.4449C8.23056 15.4062 8.33663 15.4265 8.40625 15.4958L16.6781 23.5954C16.808 23.7233 16.808 23.9299 16.6781 24.0578L9.94988 30.6458C9.57048 31.0301 9.57561 31.6412 9.9614 32.0193C10.3472 32.3974 10.9713 32.403 11.3641 32.0319L18.0923 25.4439C18.2229 25.3167 18.434 25.3167 18.5646 25.4439L21.4077 28.2225C22.8404 29.6245 25.1623 29.6245 26.5949 28.2225L29.4354 25.4413C29.498 25.3798 29.5829 25.3453 29.6715 25.3453C29.7601 25.3453 29.8451 25.3798 29.9077 25.4413L36.6359 32.0293C36.8872 32.2839 37.2593 32.3858 37.6091 32.296C37.9589 32.2062 38.232 31.9385 38.3234 31.5959C38.4148 31.2533 38.3103 30.8891 38.0501 30.6432L31.3219 24.0552C31.192 23.9273 31.192 23.7206 31.3219 23.5927L39.5937 15.4932Z'
      fill='currentColor'
    />
    <mask id='mask0' mask='alpha' maskUnits='userSpaceOnUse' x='7' y='13' width='34' height='21'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.1767 26.8391C24.5248 27.4755 23.4698 27.4755 22.8179 26.8391L9.89785 14.1856C9.71891 14.011 9.65602 13.7524 9.73539 13.5176C9.81476 13.2828 10.0228 13.112 10.2727 13.0764C10.4004 13.058 10.5293 13.0489 10.6583 13.049H37.3417C37.4698 13.049 37.5978 13.0582 37.7246 13.0764C37.9745 13.112 38.1826 13.2828 38.2619 13.5176C38.3413 13.7524 38.2784 14.011 38.0995 14.1856L25.1767 26.8391ZM39.5937 15.4932C39.6637 15.4245 39.7693 15.4044 39.8606 15.4423C39.9513 15.4789 40.0104 15.5657 40.01 15.6618V31.3382C40.01 32.7812 38.8153 33.951 37.3417 33.951H10.6583C9.18464 33.951 7.98999 32.7812 7.98999 31.3382V15.6618C7.99106 15.5667 8.04982 15.4814 8.13942 15.4449C8.23056 15.4062 8.33663 15.4265 8.40625 15.4958L16.6781 23.5954C16.808 23.7233 16.808 23.9299 16.6781 24.0578L9.94988 30.6458C9.57048 31.0301 9.57561 31.6412 9.9614 32.0193C10.3472 32.3974 10.9713 32.403 11.3641 32.0319L18.0923 25.4439C18.2229 25.3167 18.434 25.3167 18.5646 25.4439L21.4077 28.2225C22.8404 29.6245 25.1623 29.6245 26.5949 28.2225L29.4354 25.4413C29.498 25.3798 29.5829 25.3453 29.6715 25.3453C29.7601 25.3453 29.8451 25.3798 29.9077 25.4413L36.6359 32.0293C36.8872 32.2839 37.2593 32.3858 37.6091 32.296C37.9589 32.2062 38.232 31.9385 38.3234 31.5959C38.4148 31.2533 38.3103 30.8891 38.0501 30.6432L31.3219 24.0552C31.192 23.9273 31.192 23.7206 31.3219 23.5927L39.5937 15.4932Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0)' />
  </svg>
)
