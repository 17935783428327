import { Pane, PaneProps, Strong, Text } from 'evergreen-ui'
import * as React from 'react'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled, { css } from 'styled-components'
import { Caption } from '/fiweb/components'
import { breakpoints } from '/fiweb/lib'
import { Message } from '/fiweb/lib/i18n/types'
import { FormattedMessage } from 'react-intl'
import { CountUp } from 'use-count-up'
import { GetKeyStatisticFromIssuerApiQuery, useGetKeyStatisticFromIssuerApiQuery } from '/~/types/issuer-graphql'
import { client } from '/~/lib/issuer-client/client'

const StyledPane = styled(Pane)`
  ${({ theme }) => css`
    background-color: ${theme.colors.fiDark};
    width: 1260px;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    gap: ${theme.spacing.s};
    padding: ${theme.spacing.s} ${theme.spacing.xs};

    @media ${breakpoints.large} {
      grid-template-columns: repeat(2, 1fr);
    }
`}`

type StatKey = keyof Omit<GetKeyStatisticFromIssuerApiQuery['statisticsPageData'], '__typename'>

interface StatsItem {
  key: StatKey
  title: Message
  subtitle: Message
  unit?: string
}

const keys: Array<StatsItem> = [
  {
    key: 'raised',
    title: 'keyStatistics.raised',
    subtitle: 'keyStatistics.subtitle.throughFolkeinvest',
    unit: 'MNOK',
  },
  {
    key: 'investors',
    title: 'keyStatistics.investors',
    subtitle: 'keyStatistics.subtitle.onFolkeinvest',
  },
  {
    key: 'companies',
    title: 'keyStatistics.companies',
    subtitle: 'keyStatistics.subtitle.onFolkeinvest',
  },
  {
    key: 'fundedCampaigns',
    title: 'keyStatistics.fundedCampaigns',
    subtitle: 'keyStatistics.subtitle.throughFolkeinvest',
  },
]

export const KeyStatistics = (props: PaneProps) => {
  const { colors, spacing } = useCustomTheme()
  const { data } = useGetKeyStatisticFromIssuerApiQuery({ client })

  const stats = data?.statisticsPageData

  const getStatsForKey = (statsItem: StatsItem) => {
    if (!stats) {
      return (
        <Strong size={500} color='white'>
          -
        </Strong>
      )
    }
    let data = stats[statsItem.key]
    if (statsItem.key === 'raised') {
      data = Math.floor(data / 1_000_000_00)
    }
    return (
      <Pane display='flex' gap={spacing.xxs}>
        <Strong size={500} color='white'>
          <CountUp isCounting end={data} duration={3} />
        </Strong>
        <Text size={500} fontWeight={200} color='white'>
          {statsItem.unit}
        </Text>
      </Pane>
    )
  }

  return (
    <Pane background={colors.fiDark} width='100%' display='flex' justifyContent='center' {...props}>
      <StyledPane>
        {keys.map((item) => (
          <Pane key={item.key} display='flex' flexDirection='column' rowGap={spacing.xxs} maxWidth={150}>
            <Pane display='flex' flexDirection='column'>
              <Strong lineHeight='22px' size={400} color='white' display='block'>
                <FormattedMessage id={item.title} />
              </Strong>
              <Caption color={colors.fiDark40}>
                <FormattedMessage id={item.subtitle} />
              </Caption>
            </Pane>
            {getStatsForKey(item)}
          </Pane>
        ))}
      </StyledPane>
    </Pane>
  )
}
