import { Link as RouterLink } from 'react-router-dom'
import { Button } from 'evergreen-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { usePublicInvestmentOfferData, usePublicInvestmentOfferFlags } from '../Context/PublicInvestmentOfferData'
import { FollowDialog } from '/~/components/FollowDialog'
import { useBreakpoint } from '/fiweb/lib'

export const PrimaryAction = () => {
  const { medium } = useBreakpoint()
  const [followIsShown, setFollowIsShown] = React.useState(false)
  const { emissionProcessId } = usePublicInvestmentOfferData()
  const { isOpen, isClosed, isPreorder, userCanPreorder, isValidating, isFollowing } = usePublicInvestmentOfferFlags()

  if (isClosed && !medium) {
    return null
  }

  const showFollow = isPreorder && !userCanPreorder
  const reserveSharesDisabled = !(isOpen || userCanPreorder || isValidating) || isClosed

  return showFollow ? (
    <>
      <FollowDialog
        open={followIsShown}
        onClose={() => setFollowIsShown(false)}
        emissionProcessId={emissionProcessId}
      />
      <Button size='small' appearance='primary' onClick={() => setFollowIsShown(true)} marginLeft='auto'>
        <FormattedMessage id={isFollowing ? 'investmentOfferPage.isFollowing' : 'investmentOfferPage.follow'} />
      </Button>
    </>
  ) : (
    <Button
      size='small'
      appearance='primary'
      is={reserveSharesDisabled ? undefined : RouterLink}
      to={reserveSharesDisabled ? undefined : 'tegning/kjop-aksjer'}
      disabled={reserveSharesDisabled}
      marginLeft='auto'
    >
      <FormattedMessage id='investmentOfferPage.reserveShares' />
    </Button>
  )
}
