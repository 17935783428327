import * as React from 'react'
import { IconButton, Spinner } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled, { css } from 'styled-components'
import { Carousel } from 'react-responsive-carousel'
import { Testimonial as TestimonialType } from '/~/types/graphql'
import { LongTestimonial } from './LongTestimonial'
import { IconDirection, ThinChevron } from '/fiweb/components/Icons'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

interface Props {
  testimonials: TestimonialType[]
  isLightTheme?: boolean
}

interface CustomIconButtonProps {
  $isLightTheme?: boolean
}

const CustomIconButton = styled(IconButton)<CustomIconButtonProps>`
  ${({ $isLightTheme, theme: { colors } }) => css`
    position: absolute;
    bottom: 0;
    z-index: 3;
    color: ${$isLightTheme ? 'white' : 'initial'};
    border-color: ${$isLightTheme ? 'white' : 'initial'};
    &:hover {
      background-color: ${$isLightTheme ? colors.fiDark : 'initial'} !important;
      border-color: ${$isLightTheme ? 'white' : 'initial'} !important;
    }
  `}
`

export const TestimonialsCarousel = ({ testimonials, isLightTheme }: Props) => {
  const { spacing } = useCustomTheme()

  return (
    <>
      {!testimonials || testimonials.length === 0 ? (
        <Spinner alignSelf='center' marginY={spacing.xl} />
      ) : (
        <Carousel
          infiniteLoop
          autoPlay
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          interval={10000}
          renderArrowPrev={(onClickHandler) => (
            <CustomIconButton
              $isLightTheme={isLightTheme}
              onClick={onClickHandler}
              icon={<ThinChevron direction={IconDirection.LEFT} />}
              size='small'
              right='66px'
            />
          )}
          renderArrowNext={(onClickHandler) => (
            <CustomIconButton
              $isLightTheme={isLightTheme}
              onClick={onClickHandler}
              icon={<ThinChevron direction={IconDirection.RIGHT} />}
              size='small'
              right='0'
            />
          )}
        >
          {testimonials.map((testimonial) => (
            <LongTestimonial key={testimonial._id} testimonial={testimonial} isLightTheme={isLightTheme} />
          ))}
        </Carousel>
      )}
    </>
  )
}
