import { Message } from '/fiweb/lib/i18n/types'
import { config } from '/~/utils/config'

const BASE_URL = `${config.publicFilesUrl}/successful_campaign_images`

interface SuccessfulIssuesType {
  imageUrl: string
  client: string
  country: Message
  category: Message
  type: Message
  size: number
  year: number
  investors: number
}

export const successfulIssues: SuccessfulIssuesType[] = [
  {
    imageUrl: `${BASE_URL}/auk.png`,
    client: 'AUK AS',
    country: 'countries.norway',
    category: 'successfulIssues.categoryFoodTechnology',
    type: 'successfulIssues.typeEmission',
    size: 20,
    year: 2023,
    investors: 638,
  },
  {
    imageUrl: `${BASE_URL}/inseanergy.png`,
    client: 'Inseanergy AS',
    country: 'countries.norway',
    category: 'successfulIssues.categoryEnergy',
    type: 'successfulIssues.typeEmission',
    size: 30,
    year: 2022,
    investors: 601,
  },
  {
    imageUrl: `${BASE_URL}/sulten.png`,
    client: 'Sulten AS',
    country: 'countries.norway',
    category: 'successfulIssues.categoryTechApp',
    type: 'successfulIssues.typeEmission',
    size: 3.5,
    year: 2021,
    investors: 272,
  },
  {
    imageUrl: `${BASE_URL}/torghatten.png`,
    client: 'Torghatten Aqua AS',
    country: 'countries.norway',
    category: 'successfulIssues.categoryMaritime',
    type: 'successfulIssues.typeEmission',
    size: 80,
    year: 2023,
    investors: 797,
  },
  {
    imageUrl: `${BASE_URL}/777esport.png`,
    client: '777 Esport AS',
    country: 'countries.norway',
    category: 'successfulIssues.categoryGaming',
    type: 'successfulIssues.typeEmission',
    size: 80,
    year: 2023,
    investors: 797,
  },
  {
    imageUrl: `${BASE_URL}/alvb.png`,
    client: 'Alv B AS',
    country: 'countries.norway',
    category: 'successfulIssues.categoryMedical',
    type: 'successfulIssues.typeEmission',
    size: 9,
    year: 2023,
    investors: 590,
  },
  {
    imageUrl: `${BASE_URL}/wavefoil.png`,
    client: 'Wavefoil AS',
    country: 'countries.norway',
    category: 'successfulIssues.categoryMaritime',
    type: 'successfulIssues.typeEmission',
    size: 18,
    year: 2021,
    investors: 507,
  },
  {
    imageUrl: `${BASE_URL}/knitandnote.png`,
    client: 'Knit&Note AS',
    country: 'countries.norway',
    category: 'successfulIssues.categoryTechApp',
    type: 'successfulIssues.typeEmission',
    size: 5,
    year: 2022,
    investors: 474,
  },
  {
    imageUrl: `${BASE_URL}/skogluft.png`,
    client: 'Skogluft AS',
    country: 'countries.norway',
    category: 'successfulIssues.categoryNature',
    type: 'successfulIssues.typeEmission',
    size: 4,
    year: 2022,
    investors: 131,
  },
  {
    imageUrl: `${BASE_URL}/businessclass.png`,
    client: 'Businessclass.com AS',
    country: 'countries.norway',
    category: 'successfulIssues.categoryTurism',
    type: 'successfulIssues.typeEmission',
    size: 26,
    year: 2021,
    investors: 291,
  },
]
