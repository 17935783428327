import * as React from 'react'
import { Pane, TextInput, Icon, EyeOffIcon, EyeOpenIcon, Tooltip, Text } from 'evergreen-ui'

interface Props {
  value: string
  name: string
  onChange: (string) => void
  onEnter?: () => void
}

const EmailSignupFormInput = ({ value, name, onChange, onEnter }: Props) => {
  const [show, setShow] = React.useState(false)

  const handleEnter = (e) => {
    if (e.key === 'Enter' && onEnter) {
      onEnter()
    }
  }

  return (
    <Pane width='100%'>
      <TextInput
        width='98%'
        size='large'
        marginX={8}
        value={value}
        type={show ? 'text' : 'password'}
        name={name}
        onChange={({ target }) => onChange(target.value)}
        onKeyPress={handleEnter}
      />

      {/* Had to do this stupid position relative hack 'couse of evergreen */}
      <Pane position='relative' float='right' bottom={32} right={25}>
        <Tooltip
          content={
            <Text fontSize={14} lineHeight='14px' color='white'>
              {show ? 'Skjul passord' : 'Vis passord'}
            </Text>
          }
        >
          <Icon icon={show ? EyeOpenIcon : EyeOffIcon} size={25} onClick={() => setShow(!show)} cursor='pointer' />
        </Tooltip>
      </Pane>
    </Pane>
  )
}

export default EmailSignupFormInput
