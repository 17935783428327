import React from 'react'
import { StarIcon, LockIcon, Pane, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'

export type StickerType = 'full' | 'invite'
interface Props {
  type: StickerType
}

type IconType = typeof StarIcon

export const Sticker = ({ type }: Props) => {
  const theme = useCustomTheme()

  const generateSticker = (Icon: IconType, text: string) => {
    return (
      <Pane
        position='absolute'
        right={0}
        top={0}
        width={130}
        height={38}
        border={`1px solid ${theme.colors.fiBlue}`}
        borderTopRightRadius={10}
        display='flex'
        flexFlow='row nowrap'
        alignItems='center'
        background='white'
        padding={11}
        pointerEvents='none'
      >
        <Icon color='selected' marginRight={7} />
        <Text fontWeight={600} fontSize={18} lineHeight='24px' color='selected'>
          {text}
        </Text>
      </Pane>
    )
  }

  switch (type) {
    case 'invite':
      return generateSticker(StarIcon, 'Invitasjon')
    case 'full':
      return generateSticker(LockIcon, 'Fulltegnet')
    default:
      return null
  }
}
