import * as React from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import CampaignThanksPage from '/~/pages/CampaignThanksPage'
import CampaignPage from '/~/pages/CampaignPage'
import PreorderPage from '../PreorderPage'

const LivePageRedirect = () => {
  const { campaignSlug } = useParams<{ campaignSlug: string }>()
  return <Navigate to={`/sanntid/${campaignSlug}`} replace />
}

const CampaignRouter = () => {
  return (
    <Routes>
      <Route path=':campaignSlug/*'>
        <Route path='live' Component={LivePageRedirect} />
        <Route path='takk' Component={CampaignThanksPage} />
        <Route path='forhandstegning' Component={PreorderPage} />
        <Route path='*' Component={CampaignPage} />
      </Route>
    </Routes>
  )
}

export default CampaignRouter
