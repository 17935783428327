import * as React from 'react'
import { IconProps } from './types'

export const FinanceNorwayLogo = ({ title, ...rest }: IconProps) => (
  <svg width='252' height='37' viewBox='0 0 252 37' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path
      d='M36.2696 15.2648C36.2222 14.997 36.1945 14.7332 36.1352 14.4653C33.9303 4.49562 24.1542 -1.78042 14.2991 0.450176C4.44395 2.67677 -1.75995 12.5665 0.445002 22.5362C0.607015 23.2758 0.820397 23.9833 1.06539 24.6789C3.92235 20.9093 17.2627 3.03654 36.2696 15.2648Z'
      fill='currentColor'
    />
    <path
      d='M10.9476 35.4196C14.3933 36.9546 18.3251 37.4463 22.2806 36.5509C29.2353 34.9799 34.3565 29.5793 36.0003 23.0354C25.2008 2.7842 13.8519 23.5231 10.9476 35.4196Z'
      fill='currentColor'
    />
    <path
      d='M179.797 30.315C175.26 30.315 172.945 27.5408 172.945 21.5126C172.945 15.4844 175.26 12.7021 179.797 12.7021C184.333 12.7021 186.68 15.4924 186.68 21.5126C186.68 27.5328 184.337 30.315 179.797 30.315ZM179.797 15.1086C176.928 15.1086 175.659 16.8915 175.659 21.5086C175.659 26.1256 176.892 27.9085 179.797 27.9085C182.669 27.9085 183.965 26.1216 183.965 21.5086C183.965 16.8955 182.701 15.1086 179.797 15.1086Z'
      fill='currentColor'
    />
    <path
      d='M192.6 14.201V12.9218H189.976V30.0989H192.69V16.3876C194.903 14.8925 197.088 15.0764 197.598 15.0764V12.7019C195.484 12.6779 194.532 12.9218 192.6 14.201Z'
      fill='currentColor'
    />
    <path
      d='M107.306 12.7021C105.484 12.7021 104.097 13.1698 102.398 14.1372V12.918H99.6831V30.0991H102.398V16.2958C103.816 15.5763 105.271 15.0766 106.567 15.0766C108.914 15.0766 109.961 16.044 109.961 19.198V30.0991H112.676V19.198C112.676 15.0446 111.008 12.7021 107.306 12.7021Z'
      fill='currentColor'
    />
    <path
      d='M75.2073 12.7021C73.3857 12.7021 71.9987 13.1698 70.2995 14.1372V12.918H67.5848V30.0991H70.2995V16.2958C71.7181 15.5763 73.1723 15.0766 74.4684 15.0766C76.8156 15.0766 77.8628 16.044 77.8628 19.198V30.0991H80.5775V19.198C80.5775 15.0446 78.9099 12.7021 75.2073 12.7021Z'
      fill='currentColor'
    />
    <path
      d='M58.1842 9.34407V6.89362H45.2784V30.099H48.088V19.2618H55.9041V16.8273H48.088V9.34407H58.1842Z'
      fill='currentColor'
    />
    <path
      d='M136.156 12.7021C131.742 12.7021 129.304 15.2245 129.304 21.5086C129.304 27.7926 131.272 30.315 136.31 30.315C139.645 30.315 142.455 29.6274 142.455 29.6274V27.4608C142.455 27.4608 139.74 27.9405 136.31 27.9125C133.718 27.8925 132.019 27.125 132.019 22.384H142.945V20.0095C142.948 15.5763 140.661 12.7021 136.156 12.7021ZM132.019 20.2933C132.019 16.9195 133.5 15.1086 136.156 15.1086C138.874 15.1086 140.238 16.4957 140.198 20.2933H132.019Z'
      fill='currentColor'
    />
    <path d='M63.8111 12.9219H61.0964V30.0991H63.8111V12.9219Z' fill='currentColor' />
    <path
      d='M62.4518 10.3075C63.3844 10.3075 64.1391 9.544 64.1391 8.6006C64.1391 7.65719 63.3844 6.89368 62.4518 6.89368C61.5193 6.89368 60.7645 7.65719 60.7645 8.6006C60.7645 9.544 61.5232 10.3075 62.4518 10.3075Z'
      fill='currentColor'
    />
    <path
      d='M89.9466 12.7021C87.8484 12.7021 84.5133 13.1858 84.5133 13.1858V15.3484C84.5133 15.3484 87.5322 15.0526 89.9466 15.0446C93.1039 15.0366 93.3015 16.6516 93.2501 19.8216H90.9661C85.4695 19.8216 83.5254 21.4486 83.5254 25.0663C83.5254 28.684 85.3629 30.311 88.5557 30.311C90.2588 30.311 91.7841 29.6714 93.2462 28.8159V30.0911H95.9609V18.5744C95.9648 14.8887 94.238 12.7021 89.9466 12.7021ZM93.2501 26.8172C91.7683 27.6287 90.5038 28.0684 89.0812 28.0684C87.2912 28.0684 86.2401 27.5248 86.2401 25.0703C86.2401 22.5759 87.228 21.7924 90.9622 21.7924H93.2462V26.8172H93.2501Z'
      fill='currentColor'
    />
    <path
      d='M229.542 12.7021C227.444 12.7021 224.109 13.1858 224.109 13.1858V15.3484C224.109 15.3484 227.128 15.0526 229.542 15.0446C232.7 15.0366 232.897 16.6516 232.846 19.8216H230.562C225.065 19.8216 223.121 21.4486 223.121 25.0663C223.121 28.684 224.959 30.311 228.151 30.311C229.854 30.311 231.38 29.6714 232.842 28.8159V30.0911H235.557V18.5744C235.564 14.8887 233.834 12.7021 229.542 12.7021ZM232.846 26.8172C231.364 27.6287 230.1 28.0684 228.677 28.0684C226.887 28.0684 225.836 27.5248 225.836 25.0703C225.836 22.5759 226.824 21.7924 230.558 21.7924H232.842V26.8172H232.846Z'
      fill='currentColor'
    />
    <path
      d='M166.903 6.89368V25.9257C166.903 25.9257 157.059 7.03759 157.008 6.89368H153.38V30.099H156.19C156.19 29.8752 156.19 11.0431 156.19 11.0431L166.108 30.099H169.708V6.89368H166.903Z'
      fill='currentColor'
    />
    <path
      d='M221.446 12.9178L217.909 30.0909L213.803 30.0949L210.377 17.0112L206.951 30.0949H202.842L199.305 12.9218H202.15L205.003 27.8203L208.524 14.5167H212.219L215.744 27.8203L218.601 12.9218H221.446V12.9178Z'
      fill='currentColor'
    />
    <path
      d='M251.841 12.918L246.538 31.6541C245.582 35.028 244.459 37.2066 237.647 35.8835L237.643 33.7208C242.369 34.3404 243.693 34.5243 244.44 30.0951H242.341L237.402 12.918H240.231L244.191 27.6567H245.088L248.976 12.914H251.841V12.918Z'
      fill='currentColor'
    />
    <path
      d='M126.897 15.4284V13.1418C117.927 11.4069 115.845 14.7288 115.845 21.5085C115.845 28.2883 117.923 31.6142 126.897 29.8832V27.5967C120.306 28.756 118.544 27.9125 118.544 21.5085C118.544 15.1046 120.306 14.2691 126.897 15.4284Z'
      fill='currentColor'
    />
  </svg>
)
