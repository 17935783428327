import * as React from 'react'
import { IconProps } from './types'

export const Calendar = ({ title, ...rest }: IconProps) => (
  <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <rect x='1' y='2.49658' width='15.0094' height='13.0081' rx='1.5' stroke='#C5543C' strokeWidth='1.5' />
    <path d='M1 6.49902H16.0094' stroke='#C5543C' strokeWidth='1.5' />
    <path d='M5.00244 3.99755V0.495361' stroke='#C5543C' strokeWidth='1.5' />
    <path d='M12.0068 3.99755V0.495361' stroke='#C5543C' strokeWidth='1.5' />
  </svg>
)
