import * as React from 'react'
import { Button, Dialog, DialogProps, IconButton, Pane, Spinner } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { FetchCampaignQuery, SigneeInput } from '/~/types/graphql'
import KeyInformation from '../KeyInformation'
import { useBreakpoint } from '/fiweb/lib'
import SelectShares from './SelectShares'
import ConfirmReservation from './ConfirmReservation'
import { useCampaignStatus } from '../utils'
import PreorderSummary from './PreorderSummary'
import { Cross } from '/fiweb/components/Icons'
import { FollowDialog } from '/~/components/FollowDialog'
import { gtmEventAddToCart } from '/~/utils/googleTagManager'
import { useServerTimeSync } from '/~/utils/TimeSyncProvider'

export interface Reservation {
  preorder: boolean
  signee: SigneeInput
  numberOfShares: number
  amount: number
}

interface Props {
  campaign: FetchCampaignQuery['Campaign']
}

const ReserveShares = ({ campaign }: Props) => {
  const [isShown, setIsShown] = React.useState(false)
  const [followIsShown, setFollowIsShown] = React.useState(false)
  const [preorderReservation, setPreorderReservation] = React.useState<Reservation | null>(null)
  const [reservation, setReservation] = React.useState<Reservation | null>(null)
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()
  const { serverTimeDayjsObject } = useServerTimeSync()

  const dialogProps: DialogProps = medium
    ? { topOffset: 0, sideOffset: 0, minHeightContent: '100vh', contentContainerProps: { padding: 25 } }
    : {
        width: reservation ? 800 : undefined,
        contentContainerProps: { padding: 50, paddingY: 32 },
      }

  const { preorderApproved, acceptsPreorders, userHasEmailReminder, isClosed, loading } = useCampaignStatus({
    campaign,
    serverTimeDayjsObject,
  })
  const showFollow = acceptsPreorders && !preorderApproved

  const preorderComplete = (preRes: Reservation) => {
    setReservation(null)
    setPreorderReservation(preRes)
  }
  const ctaText = () => {
    if (isClosed) {
      return 'Vis aksjeinfo'
    }
    if (showFollow) {
      return userHasEmailReminder ? 'Fulgt' : 'Følg emisjon'
    }
    return 'Kjøp aksjer'
  }

  const handleClick = () => {
    if (showFollow) {
      setFollowIsShown(true)
    } else {
      setIsShown(true)
      gtmEventAddToCart(campaign)
    }
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Button appearance='primary' width={medium ? 200 : 'inherit'} size='small' onClick={() => handleClick()}>
        {ctaText()}
      </Button>
      <FollowDialog open={followIsShown} onClose={() => setFollowIsShown(false)} campaignId={campaign._id} />
      <Dialog
        isShown={isShown}
        onCloseComplete={() => {
          setReservation(null)
          setPreorderReservation(null)
          setIsShown(false)
        }}
        hasFooter={false}
        hasHeader={false}
        topOffset='80px'
        {...dialogProps}
      >
        {({ close }) => (
          <>
            <Pane width='100%' display='flex' justifyContent='flex-end'>
              <IconButton
                position='relative'
                right={medium ? -15 : -25}
                top={-10}
                size='small'
                appearance='minimal'
                icon={<Cross />}
                onClick={close}
              />
            </Pane>
            <Pane marginTop={-20}>
              {reservation && (
                <ConfirmReservation
                  reservation={reservation}
                  campaign={campaign}
                  preorder={preorderApproved}
                  preorderComplete={preorderComplete}
                  onCancel={() => setReservation(null)}
                />
              )}
              {preorderReservation && (
                <PreorderSummary campaign={campaign} reservation={preorderReservation} onClose={close} />
              )}
              {!(reservation || preorderReservation) && (
                <>
                  {medium && (
                    <Pane marginBottom={theme.spacing.xs}>
                      <KeyInformation campaign={campaign} />
                    </Pane>
                  )}
                  <SelectShares campaign={campaign} preorder={preorderApproved} onSharesSelected={setReservation} />
                </>
              )}
            </Pane>
          </>
        )}
      </Dialog>
    </>
  )
}

export default ReserveShares
