import { Button, Pane, Paragraph, Text, WarningSignIcon } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import * as React from 'react'
import { getProviderIcon } from './utils'
import { SignicatProvider } from '/fiweb/lib/auth'
import { Link as RouterLink } from 'react-router-dom'

interface Props {
  provider?: SignicatProvider
  errorText: string
  tryAgain?: () => Promise<void>
  goBack?: string
}

export const SignicatError = ({ provider, errorText, tryAgain, goBack }: Props) => {
  const [retrying, setRetrying] = React.useState(false)
  const { spacing } = useCustomTheme()
  const Icon = getProviderIcon(provider)

  const handleRetry = async () => {
    setRetrying(true)

    await tryAgain()

    setRetrying(false)
  }

  return (
    <Pane display='flex' flexDirection='column' width='100%'>
      <Pane display='flex' alignItems='center' justifyContent='center' columnGap={spacing.xs}>
        <WarningSignIcon color='warning' size={74} />
        {Icon && <Icon height={36} />}
      </Pane>
      <Text textAlign='center' size={300} marginTop={spacing.l}>
        {errorText.split('\n').map((txt, i) => (
          <Paragraph key={i}>{txt || <br />}</Paragraph>
        ))}
      </Text>
      {(tryAgain || goBack) && (
        <Pane width='100%' display='flex' justifyContent='space-between' marginTop={spacing.xl}>
          {tryAgain && (
            <Button appearance='primary' size='small' onClick={handleRetry} isLoading={retrying} width={200}>
              Prøv igjen
            </Button>
          )}
          {goBack && (
            <Button size='small' marginLeft='auto' is={RouterLink} to={goBack}>
              Gå tilbake
            </Button>
          )}
        </Pane>
      )}
    </Pane>
  )
}
