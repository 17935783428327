import * as React from 'react'
import { Button, Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props {
  isDone: boolean
  onClose: () => void
}

const CompleteProfileButtons = ({ isDone, onClose }: Props) => {
  const theme = useCustomTheme()
  if (isDone) {
    return (
      <Pane display='flex' flexFlow='row nowrap' justifyContent='flex-end'>
        <Button appearance='primary' size='small' onClick={onClose}>
          Lukk
        </Button>
      </Pane>
    )
  }

  return (
    <Pane display='flex' flexFlow='row nowrap' justifyContent='flex-end'>
      <Button size='small' onClick={onClose} marginRight={theme.spacing.xs}>
        Lukk
      </Button>

      <Button type='submit' appearance='primary' size='small'>
        Lagre
      </Button>
    </Pane>
  )
}

export default CompleteProfileButtons
