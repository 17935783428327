export const formatCurrency = (
  value?: number | null,
  showCurrencySign = true,
  minimumFractionDigits = 2,
  maximumFractionDigits?: number,
) => {
  if (value === undefined || value === null) {
    return value
  }
  if (Number.isNaN(Number(value))) {
    return '-'
  }
  const output = new Intl.NumberFormat('nb-NO', {
    currency: 'NOK',
    style: 'currency',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value)

  return showCurrencySign ? output : output.replace('kr', '').trim()
}

export const convertFromOreToNokAndFormatCurrency = (
  value?: number | null,
  showCurrencySign = true,
  minimumFractionDigits = 2,
  maximumFractionDigits?: number,
) => {
  if (value === undefined || value === null) {
    return value
  }
  if (Number.isNaN(Number(value))) {
    return '-'
  }
  return formatCurrency(value / 100, showCurrencySign, minimumFractionDigits, maximumFractionDigits)
}
