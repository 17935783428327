import * as React from 'react'
import Router from './Router'
import Tabs from './Tabs'
import Summary from './Summary'
import { Pane, Text, WarningSignIcon } from 'evergreen-ui'
import { useBreakpoint } from '/fiweb/lib'
import ReserveShares from './ReserveShares'
import { useLocation } from 'react-router-dom'

interface ComponentProps {
  campaign: any
}

type Props = ComponentProps

const Campaign = (props: Props) => {
  const { campaign } = props
  const scrollRef = React.useRef<HTMLDivElement | null>(null)
  const location = useLocation()
  const { medium } = useBreakpoint()

  React.useEffect(() => {
    if (campaign?.slug) {
      const homeRegex = new RegExp(`${campaign.slug}(\/$|$)`, 'gi')
      const isAtCampaignPageHome = homeRegex.test(location.pathname)
      if (scrollRef.current && !isAtCampaignPageHome) {
        window.scrollTo({
          top: window.scrollY - 100 + scrollRef.current.getBoundingClientRect().bottom,
          behavior: 'auto',
        })
      }
    }
  }, [location.pathname])

  if (!campaign) {
    return <div>No data</div>
  }

  return (
    <Pane display='flex' flexDirection='column' alignItems='center'>
      <Pane ref={scrollRef}>
        <Summary campaign={campaign} />
      </Pane>

      {campaign.status !== 'stopped' && medium && (
        <Pane
          display='flex'
          justifyContent='flex-end'
          position='sticky'
          width='100%'
          top='165px'
          padding='12px'
          height='40px'
          zIndex={9}
        >
          <ReserveShares campaign={campaign} />
        </Pane>
      )}
      {campaign.status === 'stopped' && (
        <Pane
          width='100%'
          backgroundColor='red'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          padding={5}
        >
          <WarningSignIcon size={20} color='white' />
          <Text color='white'>Dette tilbudet er for øyeblikket låst og det vil ikke være mulig å reservere aksjer</Text>
        </Pane>
      )}
      <Tabs slug={campaign.slug} />
      <Router campaign={campaign} />
    </Pane>
  )
}

export default Campaign
