import { Button, CrossIcon, Heading, IconButton, Pane, PaneProps } from 'evergreen-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import { Message } from '/fiweb/lib/i18n/types'

const Backdrop = styled(Pane)`
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  padding: 20px;
`
const DialogPane = styled(Pane)`
  z-index: 50;
  background: white;
  position: relative;
  margin: auto;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 100%;
  height: 100%;
  max-width: 1024px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 100px auto 100px;
`

const Header = styled(Pane)`
  ${({ theme: { spacing } }) => css`
    display: flex;
    margin-bottom: ${spacing.s};
    justify-content: center;
    width: 100%;
    position: relative;
    padding: ${spacing.m};
    padding-bottom: 0;
  `}
`

const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  border-radius: 50%;
  border: none;
  background: inherit;
  margin: ${({ theme: { spacing } }) => spacing.xs};
`

const Content = styled(Pane)`
  position: relative;
  height: 100%;
`

const Footer = styled(Pane)`
  ${({ theme: { spacing } }) => css`
    margin-top: ${spacing.m};
    width: 100%;
    padding-bottom: ${spacing.m};
    display: flex;
    justify-content: center;
  `}
`

interface Props {
  backdropProps?: PaneProps
  closeButtonMessageId?: Message
  contentProps?: PaneProps
  disableCloseOnBackdropClick?: boolean
  footerProps?: PaneProps
  headerProps?: PaneProps
  onClose: () => void
  open: boolean
  titleMessageId?: Message
  url?: string
}

export const IframeDialog = ({
  backdropProps,
  closeButtonMessageId,
  contentProps,
  disableCloseOnBackdropClick,
  footerProps,
  headerProps,
  onClose,
  open,
  titleMessageId,
  url,
}: Props) => {
  if (!open) {
    return null
  }

  return (
    <Backdrop onClick={disableCloseOnBackdropClick ? undefined : onClose} {...backdropProps}>
      <DialogPane onClick={(e: React.MouseEvent) => e.stopPropagation()}>
        <CloseIconButton icon={CrossIcon} iconSize={24} onClick={onClose} />

        <Header {...headerProps}>
          <Heading size={800}>{titleMessageId && <FormattedMessage id={titleMessageId} />}</Heading>
        </Header>
        <Content {...contentProps}>
          <iframe
            title='document'
            allowFullScreen={true}
            src={url}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          />
        </Content>
        <Footer {...footerProps}>
          <Button onClick={onClose} background='inherit' autoFocus>
            <FormattedMessage id={closeButtonMessageId || 'close'} />
          </Button>
        </Footer>
      </DialogPane>
    </Backdrop>
  )
}
