import * as React from 'react'
import _ from 'lodash'
import anchorme from 'anchorme'
import styled from 'styled-components'
import { Dialog, Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { MemberType, MemberTypeString, getSubheader, BioContainer } from './Member'
import CloseMenu from '/~/static/icons/CloseMenu.svg'
import { FacebookIcon, LinkedInIcon, TwitterIcon } from '/fiweb/components/Icons'
import { config } from '/~/utils/config'

const MemberImage = styled.img`
  display: block;
  width: 168px;
  height: 184px;
  max-height: 184px;
  object-fit: cover;
  border-radius: 5px;
  box-sizing: border-box;
`

interface Props {
  open: boolean
  member: MemberType
  memberType: MemberTypeString
  onClose: () => void
}

const MemberDialog = ({ open, member, memberType, onClose }: Props) => {
  const [showDialog, setShowDialog] = React.useState(false)
  const theme = useCustomTheme()

  React.useEffect(() => {
    if (!showDialog && open) {
      setShowDialog(true)
    }
  }, [open])

  const handleClose = () => {
    setShowDialog(false)
    onClose()
  }

  const { name, picture, bio, linkedin, twitter, facebook } = member
  const role = getSubheader(memberType, member)

  const getPicture = () => {
    if (picture?.url) {
      return <MemberImage alt='Kunne ikke laste bilde' src={picture.url} />
    }
    return <MemberImage alt='Fant ikke bilde, kontakt support' src={`${config.publicFilesUrl}/profile-default.png`} />
  }

  return (
    <Dialog
      isShown={showDialog}
      onCloseComplete={handleClose}
      hasHeader={false}
      hasFooter={false}
      width={630}
      preventBodyScrolling={true}
      contentContainerProps={{
        padding: 40,
        paddingRight: 25,
      }}
    >
      <Pane display='flex' flexFlow='row nowrap'>
        <Pane display='flex' flexDirection='column' marginRight={theme.spacing.s}>
          <Pane marginBottom={theme.spacing.xs} marginTop={4}>
            {getPicture()}
          </Pane>

          <Pane display='flex' justifyContent='space-between'>
            {linkedin && (
              <a href={linkedin} target='_blank' rel='noreferrer'>
                <LinkedInIcon height={32} style={{ color: theme.colors.fiDark70 }} />
              </a>
            )}
            {twitter && (
              <a href={twitter} target='_blank' rel='noreferrer'>
                <TwitterIcon height={32} style={{ color: theme.colors.fiDark70 }} />
              </a>
            )}
            {facebook && (
              <a href={facebook} target='_blank' rel='noreferrer'>
                <FacebookIcon height={27} width={27} style={{ color: theme.colors.fiDark70, marginTop: 3 }} />
              </a>
            )}
          </Pane>
        </Pane>

        <Pane display='flex' flexDirection='column'>
          <Heading
            is='h3'
            size={500}
            marginBottom={theme.spacing.xxs}
            textOverflow='ellipsis'
            whiteSpace='nowrap'
            overflow='hidden'
          >
            {name}
          </Heading>
          <Paragraph size={400} marginBottom={theme.spacing.xxs}>
            {role}
          </Paragraph>

          <Pane>
            {bio?.split('\n').map((paragraph, i) => {
              if (!paragraph) {
                return <br key={`c${paragraph}k${i}`} />
              }
              const content = anchorme(paragraph, {
                truncate: 30,
                attributes: [{ name: 'target', value: '_blank' }],
              })

              const words = content
                .split(' ')
                .map((word) => {
                  if (word.startsWith('href')) {
                    return word
                  }
                  return _.truncate(word, { length: 40, omission: '[...]' })
                })
                .join(' ')
              const html = { __html: words }
              return <BioContainer key={`c${paragraph}k${i}`} size={300} dangerouslySetInnerHTML={html} />
            })}
          </Pane>
        </Pane>

        <Pane marginTop={-20}>
          <img height='15px' src={CloseMenu} alt='Close dialog' onClick={handleClose} style={{ cursor: 'pointer' }} />
        </Pane>
      </Pane>
    </Dialog>
  )
}

export default MemberDialog
