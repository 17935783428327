import * as React from 'react'
import { Pane, PaneProps, Paragraph, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import { config } from '/~/utils/config'
import { Quotes } from '/fiweb/components/Icons'

const testiesPic = `${config.publicFilesUrl}/landingpage-testimonial-geir.png`

interface Props {
  text: string
  title: string
}

export const Testimonial = ({ text, title, ...props }: Props & PaneProps) => {
  const theme = useCustomTheme()
  const { small, large } = useBreakpoint()

  if (large) {
    return (
      <Pane position='relative' height={400} {...props}>
        <Pane
          width='100%'
          maxWidth={400}
          height={315}
          background={`url(${testiesPic})`}
          backgroundSize='cover'
          backgroundPosition='top center'
          backgroundRepeat='no-repeat'
          borderRadius={5}
          borderBottomRightRadius={30}
        />
        <Pane
          width={355}
          height={158}
          position='absolute'
          bottom={0}
          background='white'
          borderRadius={10}
          boxShadow='0pc 0pc 70px -10px #BCC2BA'
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          paddingLeft={25}
          paddingRight={14}
          paddingTop={28}
          paddingBottom={22}
          justifyContent='space-between'
        >
          <Paragraph fontFamily='mono' fontWeight={500} fontSize={small ? 16 : 18} maxWidth={317} lineHeight='127.2%'>
            {text}
          </Paragraph>
          <Text fontFamily='mono' fontWeight='bold' fontSize={small ? 16 : 18}>
            {title}
          </Text>
        </Pane>
      </Pane>
    )
  }
  return (
    <Pane position='relative' {...props}>
      <Pane
        width={365}
        height={332}
        background='#7AA668'
        backgroundPosition='center'
        backgroundSize='cover'
        borderRadius={5}
        borderTopRightRadius={30}
        opacity={0.15}
        position='absolute'
        right={0}
        top={127}
      />
      <Pane
        width={373}
        height={408}
        background={`url(${testiesPic})`}
        backgroundPosition='bottom left'
        backgroundSize='cover'
        borderRadius={5}
        borderBottomRightRadius={30}
        position='absolute'
        right={50}
        top={0}
      />
      <Pane
        width={355}
        height={158}
        position='absolute'
        right={165}
        top={324}
        background='white'
        borderRadius={10}
        boxShadow='0pc 0pc 70px -10px #BCC2BA'
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        paddingLeft={25}
        paddingRight={14}
        paddingTop={28}
        paddingBottom={22}
        justifyContent='space-between'
      >
        <Pane position='absolute' top={-22} left={-28} zIndex={2}>
          <Quotes height='50px' width='50px' color={theme.colors.fiGreen} />
        </Pane>

        <Paragraph fontFamily='mono' fontWeight={500} fontSize={18} maxWidth={317} lineHeight='127.2%'>
          {text}
        </Paragraph>
        <Text fontFamily='mono' fontWeight='bold' fontSize={18}>
          {title}
        </Text>
      </Pane>
    </Pane>
  )
}
