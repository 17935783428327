import * as React from 'react'
import { Heading, Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import { FormattedMessage } from 'react-intl'
import { useGetEntrepreneurTestimonialsQuery, Testimonial as TestimonialType } from '/~/types/graphql'
import { TestimonialsCarousel } from '/~/components/'
import { ManyDots } from '/fiweb/components/Icons'

const backupTestimonial: TestimonialType = {
  _id: 'backup',
  companyName: 'Sulten AS',
  imageUrl: 'https://folkeinvestmedia.blob.core.windows.net/public/slikfungererdet-selskap-profilbilde.png',
  jobTitle: 'Daglig leder',
  name: 'Karen Elene Thorsen',
  text: 'Gjennom Folkeinvest.no klarte vi å få inn hele emisjonen på 40 minutter. Folkeinvest.no gjorde det mulig for studenter og unge voksne å investere i Sulten på en trygg måte, noe vi ser på som utrolig viktig når vi får inn aksjonærer som aldri har investert før. Vi er evig takknemlig for innsatsen, servicen og rådene folka bak Folkenivest.no har gitt oss. Som et ungt selskap, hadde vi ikke klart oss uten!',
}

export const Testimonials = () => {
  const { colors, spacing } = useCustomTheme()
  const { small, medium, large } = useBreakpoint()

  const { data } = useGetEntrepreneurTestimonialsQuery()

  const testimonials = React.useMemo(
    () => (data?.getEntrepreneurTestimonials.length ? data.getEntrepreneurTestimonials : [backupTestimonial]),
    [data],
  )

  return (
    <Pane
      width='100%'
      backgroundColor={colors.fiDark}
      display='flex'
      flexDirection='column'
      alignItems={large ? 'initial' : 'center'}
      paddingX='20px'
      position='relative'
      marginTop={spacing.l}
      marginBottom={large ? spacing.m : spacing.l}
    >
      <ManyDots
        style={{
          position: 'absolute',
          left: '5px',
          top: '15px',
          width: small ? 'inherit' : 'initial',
        }}
      />
      <Pane maxWidth={1010} paddingY={medium ? spacing.m : '95px'}>
        <Heading is='h2' size={600} color={colors.fiDark10} marginBottom={spacing.m} position='relative'>
          <FormattedMessage id='investorLanding.testimonialsHeading' />
        </Heading>
        <Pane display='flex' flexDirection='column'>
          <TestimonialsCarousel testimonials={testimonials} isLightTheme />
        </Pane>
      </Pane>
    </Pane>
  )
}
