import React from 'react'
import moment from 'moment-timezone'
import Selfreport from './Selfreport'

import { FetchCampaignQuery } from '/~/types/graphql'
import { Heading, Pane, Paragraph, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { nf } from '/~/utils/formatters'
import Divider from '/~/components/campaign/Campaign/Divider'
import PageContainer from './PageContainer'
import { useBreakpoint } from '/fiweb/lib'

interface Props {
  campaign: FetchCampaignQuery['Campaign']
}

const CampaignData = ({ campaign }: Props) => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  // Get what we need from the campaign object
  const {
    startDate,
    closeDate,
    numberOfCurrentShares,
    pricePerShare,
    minimumShareInvestment,
    maximumShareInvestment,
    raisingAmountMinimum,
    raisingAmountMaximum,
  } = campaign

  // For older campaigns, some values aren't set like they are now. Check them
  // Company value
  let companyValue = campaign.companyValue
  if (!companyValue) {
    companyValue = Number(pricePerShare) * Number(numberOfCurrentShares)
  }

  // Minimum amount of shares to be raised during the campaign
  let raisingSharesMinimum = campaign.raisingSharesMinimum
  if (!raisingSharesMinimum) {
    raisingSharesMinimum = Math.floor(Number(raisingAmountMinimum) / Number(pricePerShare))
  }

  // Maximum amount of shares to be raised during the campaign
  let raisingSharesMaximum = campaign.raisingSharesMaximum
  if (!raisingSharesMaximum) {
    raisingSharesMaximum = Math.floor(Number(raisingAmountMaximum) / Number(pricePerShare))
  }

  // Calculate what percentages of the company gets sold
  let soldPerCentMin = null
  let soldPerCentMax = null
  if (numberOfCurrentShares) {
    soldPerCentMin = (raisingSharesMinimum * 100) / (raisingSharesMinimum + numberOfCurrentShares)
    soldPerCentMax = (raisingSharesMaximum * 100) / (raisingSharesMaximum + numberOfCurrentShares)
  }

  // Calculate the new company value
  let newCompanyValueMin = null
  let newCompanyValueMax = null
  if (companyValue) {
    newCompanyValueMin = `${nf(companyValue + raisingAmountMinimum)} kr`
    newCompanyValueMax = `${nf(companyValue + raisingAmountMaximum)} kr`
  }

  const minimumInvestment = minimumShareInvestment * pricePerShare
  const maximumInvestment = maximumShareInvestment * pricePerShare

  const keyInfoPoints = [
    [
      {
        label: 'Startdato',
        value: startDate && moment(startDate).format('YYYY.MM.DD HH:mm'),
      },
      {
        label: 'Sluttdato',
        value: closeDate && moment(closeDate).format('YYYY.MM.DD HH:mm'),
      },
      {
        label: 'Antall aksjer',
        value: numberOfCurrentShares && `${nf(numberOfCurrentShares)} aksjer`,
      },
      {
        label: 'Pris per aksje',
        value: pricePerShare && `${nf(pricePerShare)} kr`,
      },
    ],
    [
      {
        label: 'Minsteinvestering',
        value: minimumInvestment && `${nf(minimumInvestment)} kr`,
      },

      null,
      {
        label: 'Maksinvestering',
        value: maximumInvestment && `${nf(maximumInvestment)} kr`,
      },
      {
        label: <>Verdi før emisjon*</>,
        value: companyValue && `${nf(companyValue)} kr`,
      },
    ],
  ]

  const afterEmissionData = [
    {
      label: 'Innhentet kapital',
      min: raisingAmountMinimum && `${nf(raisingAmountMinimum)} kr`,
      max: raisingAmountMaximum && `${nf(raisingAmountMaximum)} kr`,
    },
    {
      label: 'Andel av selskap solgt',
      min: soldPerCentMin && `${nf(soldPerCentMin)}%`,
      max: soldPerCentMax && `${nf(soldPerCentMax)}%`,
    },
    {
      label: 'Nye aksjer',
      min: raisingSharesMinimum && nf(raisingSharesMinimum),
      max: raisingSharesMaximum && nf(raisingSharesMaximum),
    },
    {
      label: 'Ny selskapsverdi',
      min: newCompanyValueMin && newCompanyValueMin,
      max: newCompanyValueMax && newCompanyValueMax,
    },
  ]

  const renderDatapoint = (
    dp: { label: React.ReactNode; value: React.ReactNode } | null,
    key: string,
    noDivider?: boolean,
    column?: boolean,
  ) => (
    <React.Fragment key={key}>
      {!noDivider && <Divider />}
      <Pane display='flex' justifyContent='space-between' flexDirection={column ? 'column' : 'row'}>
        {dp ? (
          <>
            <Text size={300} fontWeight={600}>
              {dp.label}:
            </Text>
            <Text size={300}>{dp.value || <em>Ikke oppgitt</em>}</Text>
          </>
        ) : (
          <Text size={300} fontWeight={600} opacity='0'>
            -
          </Text>
        )}
      </Pane>
    </React.Fragment>
  )

  return (
    <PageContainer>
      <Heading is='h1' size={600}>
        Emisjonsinfo
      </Heading>
      <Pane
        backgroundColor='rgba(13, 125, 194, 0.08)'
        paddingY={40}
        paddingX={medium ? 20 : 40}
        borderRadius={medium ? 0 : 5}
        borderBottomRightRadius={medium ? 0 : 30}
        marginX={medium ? -20 : 0}
      >
        {medium ? (
          <>
            <Pane display='flex' flexWrap='wrap' justifyContent='space-between' width='100%'>
              <Pane width='100%'>
                {keyInfoPoints[0].map((dp, index) => renderDatapoint(dp, index.toString()))}
                <Divider />
              </Pane>
              <Pane width='100%'>
                {keyInfoPoints[1]
                  .filter((dp) => dp)
                  .map((dp, index) => renderDatapoint(dp, index.toString(), index === 0 && medium))}{' '}
                <Divider />
              </Pane>
            </Pane>
            <Text size={300}>
              <sup>*</sup> Se dokumenter for eventuelle uutløste opsjoner og konvertible lån
            </Text>
          </>
        ) : (
          <Pane display='grid' gridTemplateColumns='1fr 1fr 1fr' rowGap={24} marginBottom={theme.spacing.xxs}>
            {[...keyInfoPoints[0], ...keyInfoPoints[1]].map((dp, index) =>
              dp ? (
                <Pane key={index} display='flex' flexDirection='column'>
                  {renderDatapoint(dp, index.toString(), true, true)}
                </Pane>
              ) : (
                <Text key={index} fontSize={14} lineHeight='20px'>
                  <sup>*</sup> Se dokumenter for eventuelle uutløste opsjoner og konvertible lån
                </Text>
              ),
            )}
          </Pane>
        )}
        <Divider style={{ margin: `${theme.spacing.s} 0` }} />

        <Heading is='h3' size={500}>
          Etter emisjon
        </Heading>
        {medium ? (
          <Pane width='100%'>
            {afterEmissionData.map((aem, index) => (
              <React.Fragment key={index}>
                <Divider />
                <Paragraph size={300} fontWeight={600} marginBottom={theme.spacing.xs}>
                  {aem.label}
                </Paragraph>
                <Pane display='flex' justifyContent='space-between' width='100%'>
                  <Text fontWeight={500} fontSize='14px'>
                    Minimumgrense
                  </Text>
                  <Text size={300}>{aem.min}</Text>
                </Pane>
                <Pane display='flex' justifyContent='space-between' width='100%'>
                  <Text fontWeight={500} fontSize='14px'>
                    Maksimumgrense
                  </Text>
                  <Text size={300}>{aem.max}</Text>
                </Pane>
              </React.Fragment>
            ))}
          </Pane>
        ) : (
          <Pane width='100%'>
            <Pane width='100%' display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Text size={300} fontSize='14px' width='30%' fontWeight={600}>
                Etter emisjon
              </Text>
              <Text size={300} fontSize='14px' width='30%' fontWeight={600} textAlign='right'>
                Minimumgrense
              </Text>
              <Text size={300} fontSize='14px' width='40%' fontWeight={600} textAlign='right'>
                Maksimumgrense
              </Text>
            </Pane>
            <Divider style={{ marginTop: theme.spacing.xxs }} />
            {afterEmissionData.map((aem, index) => (
              <React.Fragment key={index}>
                <Pane width='100%' display='flex' flexWrap='wrap' justifyContent='space-between'>
                  <Text size={300} width='30%'>
                    {aem.label}
                  </Text>
                  <Text size={300} width='30%' textAlign='right'>
                    {aem.min}
                  </Text>
                  <Text size={300} width='40%' textAlign='right'>
                    {aem.max}
                  </Text>
                </Pane>
                <Divider />
              </React.Fragment>
            ))}
          </Pane>
        )}
      </Pane>
      <Selfreport campaignId={campaign._id} />
    </PageContainer>
  )
}

export default CampaignData
