import * as React from 'react'
import { Pane, Paragraph, Strong } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Testimonial as TestimonialType } from '/~/types/graphql'
import { useBreakpoint } from '/fiweb/lib'

interface Props {
  testimonial: TestimonialType
  isLightTheme?: boolean
}

export const LongTestimonial = ({ testimonial, isLightTheme }: Props) => {
  const theme = useCustomTheme()
  const { medium } = useBreakpoint()
  const imageSize = 200
  const backdropHeight = 180
  const containerSize = imageSize + 20

  let jobText = testimonial?.jobTitle ? `${testimonial.jobTitle}, ` : ''
  jobText += testimonial?.companyName || ''

  return (
    <Pane
      width='100%'
      display='flex'
      flexFlow={medium ? 'column' : 'row nowrap'}
      alignItems={medium ? 'center' : 'flex-start'}
    >
      <Pane marginTop={medium ? 0 : theme.spacing.xxs}>
        <Pane position='relative' width={containerSize} height={containerSize}>
          <Pane
            position='absolute'
            bottom={0}
            right={0}
            backgroundColor={theme.colors.fiGreen10}
            width={imageSize}
            height={backdropHeight}
            borderRadius='5px'
            borderTopRightRadius='30px'
            borderBottomLeftRadius='30px'
            zIndex={1}
          />

          <Pane
            position='absolute'
            top={0}
            left={0}
            borderRadius='5px'
            borderBottomRightRadius='30px'
            backgroundColor={theme.colors.fiDark10}
            width={imageSize}
            height={imageSize}
            overflow='hidden'
            zIndex={2}
          >
            {testimonial?.imageUrl && (
              <img
                src={testimonial.imageUrl}
                alt={testimonial.name}
                height='100%'
                width='100%'
                style={{
                  objectFit: 'cover',
                  objectPosition: 'top',
                  position: 'relative',
                }}
              />
            )}
          </Pane>
        </Pane>
      </Pane>

      <Pane
        display='flex'
        flexDirection='column'
        marginLeft={medium ? 0 : theme.spacing.m}
        marginTop={medium ? theme.spacing.s : theme.spacing.xxs}
        marginBottom={theme.spacing.l}
        maxWidth={medium ? 500 : 600}
        textAlign='left'
      >
        <Paragraph color={isLightTheme ? theme.colors.fiDark10 : 'initial'} marginBottom={theme.spacing.s}>
          {testimonial?.text || ''}
        </Paragraph>
        <Paragraph size={300}>
          <Strong color={isLightTheme ? theme.colors.fiDark10 : 'initial'} size={300}>
            {testimonial?.name || ''}
          </Strong>
        </Paragraph>
        <Paragraph size={300}>
          <Strong color={isLightTheme ? theme.colors.fiDark10 : 'initial'} size={300}>
            {jobText}
          </Strong>
        </Paragraph>
      </Pane>
    </Pane>
  )
}
