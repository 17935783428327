import React from 'react'
import { Helmet } from '/~/components'
import Press from './Press'

const PressPage = () => {
  return (
    <>
      <Helmet
        title='Presse'
        description='Folkeinvest AS tilrettelegger for folkets investering i oppstartsselskap.'
        url={`${window.location.origin}/presse`}
      />

      <Press />
    </>
  )
}

export default PressPage
