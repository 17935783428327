import * as React from 'react'
import { Pane } from 'evergreen-ui'
import styled from 'styled-components'
import {
  ProfileAddressStatus,
  ProfileBankIdStatus,
  ProfileEmailStatus,
  ProfilePepStatus,
  ProfilePhoneStatus,
  ProfileTermsStatus,
} from './StatusComponents'
import StatusDoneComponent from '/~/components/auth/Signup/Steps/StatusDoneComponent'
import { statusFields } from './completeProfileDialogUtils'
import { CheckedProfileField } from '/~/utils/checkUserValid'

interface Props {
  missingFields: CheckedProfileField[]
  completedFields: CheckedProfileField[]
}
const StatusWrapper = styled(Pane)`
  width: 100%;
  background-color: rgba(232, 112, 86, 0.15);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  border-color: rgba(197, 84, 60, 0.15);
  padding: ${({ theme: { spacing } }) => spacing.xs};
  margin-bottom: 18px;
`

const CompleteProfileContent = ({ missingFields, completedFields }: Props) => {
  const renderField = (name: CheckedProfileField) => {
    const fieldInfo = statusFields.find((f) => f.name === name)

    if (!fieldInfo) {
      return null
    }

    if (completedFields.includes(name)) {
      return <StatusDoneComponent key={name} title={fieldInfo?.label} description={fieldInfo?.doneDescr} />
    }
    switch (name) {
      case 'address':
        return <ProfileAddressStatus />
      case 'phone':
        return <ProfilePhoneStatus />
      case 'terms':
        return <ProfileTermsStatus />
      case 'pep':
        return <ProfilePepStatus />
      case 'email':
        return <ProfileEmailStatus />
      case 'bankid':
        return <ProfileBankIdStatus />
      default:
        return null
    }
  }

  return (
    <Pane display='flex' flexDirection='column'>
      {missingFields.map((fieldName) => {
        const comp = renderField(fieldName)
        if (!comp) {
          return null
        }
        return (
          <StatusWrapper key={fieldName} paddingLeft={10}>
            {comp}
          </StatusWrapper>
        )
      })}
    </Pane>
  )
}

export default CompleteProfileContent
