import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import CommentThread from './CommentThread'
import SubmitComment from './SubmitComment'
import { Loading } from '/fiweb/components'
import {
  useFetchCampaignDataForCommentsQuery,
  useFetchCommentsQuery,
  CommentUpdatedDocument,
  FetchCommentsQuery,
  CommentUpdatedSubscriptionVariables,
  CommentUpdatedSubscription,
} from '/~/types/graphql'
import { SubscribeToMoreOptions } from '@apollo/client'
import { Button, Heading, Pane, Paragraph, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import PageContainer from '../PageContainer'
import { Bell } from '/fiweb/components/Icons'
import { useAuthContext } from '/~/utils/AuthContext'

type SubToMoreOptions = SubscribeToMoreOptions<
  FetchCommentsQuery,
  CommentUpdatedSubscriptionVariables,
  CommentUpdatedSubscription
>

interface Props {
  campaignId: string
}

const CampaignComments = ({ campaignId }: Props) => {
  const { user } = useAuthContext()
  const campaignQuery = useFetchCampaignDataForCommentsQuery({
    variables: { _id: campaignId },
  })
  const commentQuery = useFetchCommentsQuery({
    variables: { _id: campaignId },
  })
  const [editingNewComment, setEditingNewComment] = useState(false)
  const theme = useCustomTheme()

  useEffect(() => {
    if (!commentQuery.loading) {
      const unsub = commentQuery.subscribeToMore({
        document: CommentUpdatedDocument,
        variables: { campaignId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }
          const nc = subscriptionData?.data?.commentUpdated
          let arr = [...(prev.allCommentsByCampaignId || [])]
          const index = arr.findIndex((c) => c._id === nc._id)
          if (index >= 0) {
            if (nc.deleted) {
              arr = arr.filter((c) => c._id !== nc._id)
            } else {
              arr[index] = { ...arr[index], ...nc }
            }
          } else {
            arr.push(nc)
          }
          return { allCommentsByCampaignId: arr }
        },
      } as SubToMoreOptions)
      return () => unsub()
    }
  }, [commentQuery?.loading])

  useEffect(() => {
    // Counter update inn the tabs, will only update if user mounts this component
    const arr = commentQuery?.data?.allCommentsByCampaignId
    if (arr) {
      commentQuery.client.writeFragment({
        id: `Campaign:${campaignId}`,
        fragment: gql`
          fragment CampFragment on Campaign {
            commentCount
          }
        `,
        data: { commentCount: arr?.length, __typename: 'Campaign' },
      })
    }
  }, [commentQuery.data])

  const commentList = commentQuery?.data?.allCommentsByCampaignId || []

  const parents = React.useMemo(() => {
    const grouped = commentList.reduce(
      (sum, next) => {
        if (next.parent) {
          sum.children.push(next)
        } else {
          sum.parents.push(next)
        }
        return sum
      },
      { parents: [] as typeof commentList, children: [] as typeof commentList },
    )

    const parentsWithChilds = grouped.parents.map((p) => ({
      ...p,
      children: grouped.children
        .filter((child) => child.parent && child.parent === p._id)
        .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)),
    }))

    return parentsWithChilds.sort((a, b) => {
      let tempA: number
      let tempB: number

      if (!_.isEmpty(a.children)) {
        tempA = a.children[a.children.length - 1].createdAt
      } else {
        tempA = a.createdAt
      }

      if (!_.isEmpty(b.children)) {
        tempB = b.children[b.children.length - 1].createdAt
      } else {
        tempB = b.createdAt
      }

      if (tempA > tempB) {
        return -1
      }
      return 1
    })
  }, [commentList])

  const toggleEditComment = (toggle: boolean) => setEditingNewComment(toggle)

  if (commentQuery.loading) {
    return <Loading />
  }
  if (commentQuery.error) {
    return <div>En feil oppsto. Last inn siden på nytt eller kontakt support</div>
  }

  const campaign = campaignQuery?.data?.Campaign
  if (!campaign) {
    return <div>Kunne ikke hente emisjons-data. Last inn siden på nytt eller kontakt support</div>
  }

  const now = moment()
  const locked = campaign.closeDate && now.isAfter(moment(campaign.closeDate).add(3, 'days'))

  const canComment = user?.bankidverified && !locked
  const showTip = campaign.user._id !== user?._id

  return (
    <PageContainer>
      <Heading is='h1' size={600}>
        Diskusjon
      </Heading>

      {locked && (
        <Paragraph color='danger' marginTop={theme.spacing.xxs}>
          Emisjonen er avsluttet og diskusjonsforumet er låst. For ytterlige spørsmål, ta kontakt direkte med selskapet.
        </Paragraph>
      )}
      {showTip && (
        <Paragraph marginTop={theme.spacing.xxs}>
          <b>Tips: </b>Du kan få epost-varsler om aktivitet i diskusjoner ved å klikke på bjelle-ikonet til høyre for
          tema/tittel. <Bell height='22px' width='22px' />
          {user && !user.bankidverified && (
            <Text fontStyle='italic'>
              <br />
              For å kunne delta i diskusjoner må du{' '}
              <Link to='/konto' style={{ color: 'inherit' }}>
                autentiseres med BankID
              </Link>
            </Text>
          )}
        </Paragraph>
      )}
      {canComment && (
        <Button
          size='small'
          marginTop={showTip ? theme.spacing.xs : 0}
          onClick={() => {
            if (!editingNewComment) {
              toggleEditComment(true)
            }
          }}
        >
          Start ny diskusjon
        </Button>
      )}

      {canComment && !!editingNewComment && (
        <Pane marginTop={theme.spacing.xxs}>
          <SubmitComment campaign={campaign} onClose={() => toggleEditComment(false)} />
        </Pane>
      )}
      {parents.map((comment, i) => (
        <CommentThread index={i} comment={comment} key={comment._id} locked={locked} campaign={campaign} />
      ))}
      {!parents.length && (
        <Pane marginTop={theme.spacing.xs}>
          <Heading is='h4'>Ingen diskusjoner opprettet</Heading>
          {canComment && <Paragraph>Vær den første ved å trykke "Start ny diskusjon"</Paragraph>}
        </Pane>
      )}
    </PageContainer>
  )
}

export default CampaignComments
