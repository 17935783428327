import { addressValid } from '/~/utils/checkUserValid'

export const getUserStep = (user: any) => {
  if (!user) {
    return 0
  }

  const { pep } = user
  if (!user.acceptedLatestTerms) {
    return 1
  }
  if (!(pep === true || pep === false)) {
    return 2
  }
  if (!addressValid(user)) {
    return 3
  }
  if (!user.phone) {
    return 4
  }
  if (!user.emailVerified) {
    return 5
  }
  if (!user.bankidverified) {
    return 6
  }

  return 7
}
