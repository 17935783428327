import React from 'react'
import Root from './Root'
import Providers from './Providers'
import { Helmet } from './components'
import { createBrowserRouter } from 'react-router-dom'
import { RouterProvider } from 'react-router'
import { ErrorBoundary } from './ErrorBoundary/ErrorBoundary'

const router = createBrowserRouter([{ path: '*', element: <Root />, errorElement: <ErrorBoundary /> }])

const App = () => {
  return (
    <div className='body'>
      <Providers>
        <Helmet title='Folkeinvest.no' />
        <RouterProvider router={router} />
      </Providers>
    </div>
  )
}

export default App
