import * as React from 'react'
import { Link } from 'react-router-dom'
import { Pane, Heading, Button, Spinner, Paragraph, Small } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useFetchEmailPreferencesQuery } from '/~/types/graphql'
import { useBreakpoint } from '/fiweb/lib'
import Logo from '/~/static/icons/Logo.svg'
import { CampaignNotificationsPreferences, NewsletterPreferences } from '/~/components/EmailPreferences'
import { useAuthContext } from '/~/utils/AuthContext'

export const UnsubscribeEmail = () => {
  const { small } = useBreakpoint()
  const theme = useCustomTheme()
  const authContext = useAuthContext()
  const token = window.location.search?.replace('?', '')

  const { data, loading } = useFetchEmailPreferencesQuery({ variables: { token } })

  if (loading || authContext.loading) {
    return <Spinner />
  }

  let user = {
    ...authContext?.user,
    ...data?.fetchEmailPreferences,
  }

  if (!user.email) {
    user = null
  }

  return (
    <Pane
      minHeight='100vh'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      backgroundColor={theme.colors.fiDark}
    >
      <Pane backgroundColor='white' borderRadius='10px' width={small ? '100%' : '500px'}>
        <Pane paddingX={small ? theme.spacing.s : theme.spacing.m} paddingY={theme.spacing.m}>
          <Pane display='flex' justifyContent='center' marginBottom={theme.spacing.m} marginTop={theme.spacing.xs}>
            <img src={Logo} alt='Folkeinvest logo' />
          </Pane>

          <Heading is='h3' marginBottom={theme.spacing.xs}>
            Tjenesteinnstillinger
          </Heading>

          <Pane paddingX={5} marginBottom={theme.spacing.s}>
            <CampaignNotificationsPreferences user={user} disabled={!user} enableFlashMessages />
          </Pane>

          <Heading is='h3' marginBottom={theme.spacing.xs}>
            Samtykke
          </Heading>

          <Pane paddingX={5} marginBottom={theme.spacing.xs}>
            <NewsletterPreferences user={user} disabled={!user} enableFlashMessages />
          </Pane>

          {!user && (
            <Pane marginTop={theme.spacing.xs}>
              <Paragraph size={300} color='danger'>
                <Small>
                  {!token
                    ? 'Du må være logget inn for å endre innstillinger. Logg inn og gå til din profilside.'
                    : 'Kunne ikke laste brukerinnstillinger, oppfrisk siden eller kontakt support'}
                </Small>
              </Paragraph>
            </Pane>
          )}

          <Pane
            width='100%'
            display='flex'
            justifyContent='center'
            marginTop={small ? theme.spacing.s : theme.spacing.m}
          >
            <Button is={Link} to={!(user || token) ? '/innlogging' : '/'} size='small' width={244}>
              {!(user || token) ? 'Til innlogging' : 'Til forsiden'}
            </Button>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}
