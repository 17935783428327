import { Pane, Text } from 'evergreen-ui'
import * as React from 'react'
import { SelfreportItem } from '/~/types/graphql'

interface Props {
  item: SelfreportItem
}

export const BooleanItem = ({ item }: Props) => {
  const { values } = item
  return (
    <Pane>
      <Text>Svar: {values?.[0] === 'true' ? 'Ja' : 'Nei'}</Text>
    </Pane>
  )
}
