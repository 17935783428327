import { ChevronRightIcon, Link as EverLink, Option, Pane, SelectMenu, Text, TextInput } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import * as React from 'react'
import { SigneeInput } from '/~/types/graphql'
import { AuthContextType } from '/~/utils/AuthContext/AuthContextProvider'

interface Props {
  user: AuthContextType['user']
  onSelect: (signee: SigneeInput | null) => void
  disabled?: boolean
}

const OnBehalfOf = ({ user, disabled, onSelect }: Props) => {
  const [isShown, setIsShown] = React.useState(false)
  const [selected, setSelected] = React.useState<string | null>(null)
  const theme = useCustomTheme()

  const companies = user.companies

  const isValidCompany = (orgNr: string) => {
    const company = companies.find((c) => c.organizationNumber === orgNr)
    return company && ['user', 'owner', 'admin'].indexOf(company.userStatus) > -1 && company.verified
  }

  const getSignee = (value: string) => {
    if (!user) {
      return null
    }

    const { name, birthdate, email, phone, addressFields } = user
    if (value === 'user') {
      return {
        name,
        number: birthdate,
        type: 'person',
        addressFields: {
          street: addressFields.street,
          postalCode: addressFields.postalCode,
          postalTown: addressFields.postalTown,
          countryCode: addressFields.countryCode,
        },
        email,
        phone,
      }
    }

    const company = companies.find((c) => c.organizationNumber === value)

    return !company
      ? null
      : {
          name: company.name,
          number: company.organizationNumber,
          type: 'business',
          addressFields: {
            street: company.address,
            postalCode: company.postcode,
            postalTown: company.city,
            countryCode: 'NO', // Needs to be changed when we allow non-norwegian companies
          },
          email,
          phone,
        }
  }

  const handleChangeSignee = (newSelected: string) => {
    setSelected(newSelected)

    const signeeData = getSignee(newSelected)

    onSelect(signeeData)

    setIsShown(false)
  }

  const selectedLabel = () => {
    if (selected === 'user') {
      return user.name
    }
    const company = companies.find((c) => c.organizationNumber === selected)
    return company?.name
  }

  return (
    <Pane marginTop={theme.spacing.s}>
      <Pane marginBottom={theme.spacing.xxs}>
        <Text size={300} color='danger'>
          <sup>*</sup>{' '}
        </Text>
        <Text size={300} fontWeight={600}>
          Tegn på vegne av{' '}
        </Text>
        <Text size={300}>(Påkrevd)</Text>
      </Pane>
      <SelectMenu
        onOpen={() => setIsShown(true)}
        isShown={isShown}
        hasTitle={false}
        hasFilter={false}
        width='320px'
        onSelect={(item) => handleChangeSignee(item.value as string)}
        options={[
          { label: `${user.name} (Privatperson)`, value: 'user' },
          ...companies.map((c) => ({
            label: c.name,
            value: c.organizationNumber,
          })),
          {
            value: 'new',
            label: 'Legg til nytt investeringsselskap',
          },
        ]}
        itemRenderer={(item) => {
          const isUser = item.key === 'user'
          const isNew = item.key === 'new'
          const isCompany = !(isUser || isNew)
          const isValid = isValidCompany(item.key as string)

          const isDisabled = isCompany && !isValid

          return isNew ? (
            <Option key={item.key}>
              <EverLink href='/konto/selskap'>
                {item.label}
                <ChevronRightIcon />
              </EverLink>
            </Option>
          ) : (
            <Option
              key={item.key}
              onClick={!isDisabled && item.onSelect}
              cursor={isDisabled ? 'not-allowed' : 'initial'}
              pointerEvents={isDisabled ? 'none' : 'initial'}
              backgroundColor={isDisabled ? theme.colors.fiDark : 'initial'}
            >
              <Pane width='100%' height='100%' display='flex' flexDirection='column'>
                <Text size={300}>{item.label}</Text>
                {isDisabled && (
                  <Text size={300} color='danger'>
                    Krever firmaattest
                  </Text>
                )}
              </Pane>
            </Option>
          )
        }}
      >
        <TextInput disabled={disabled} readOnly value={selectedLabel() || 'Velg person eller firma'} />
      </SelectMenu>
    </Pane>
  )
}

export default OnBehalfOf
