import * as React from 'react'
import { Link, Pane, Paragraph, Radio, Small, Strong } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useFormContext } from 'react-hook-form'

const ProfilePepStatus = () => {
  const theme = useCustomTheme()
  const formControl = useFormContext()

  return (
    <>
      <Paragraph size={300}>
        <Strong size={300}>Er du en politisk eksponert person?</Strong>
      </Paragraph>

      <Pane display='flex' flexFlow='row nowrap'>
        <Radio name='yes' label='Ja' value='yes' size={16} {...formControl.register('pepValue')} />
        <Radio label='Nei' value='no' marginLeft={theme.spacing.s} size={16} {...formControl.register('pepValue')} />
      </Pane>

      <Paragraph size={300}>
        <Small>Usikker på om du er en PEP?</Small>
      </Paragraph>
      <Link
        rel='external'
        href='https://lovdata.no/dokument/NL/lov/2018-06-01-23/KAPITTEL_1#%C2%A72'
        target='_blank'
        size={300}
      >
        <Small>Link til lovtekst ( § 2 f )</Small>
      </Link>
    </>
  )
}

export default ProfilePepStatus
