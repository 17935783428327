import * as React from 'react'
import { Button, ButtonProps, Pane, Text } from 'evergreen-ui'
import { InputWrapper, InputWrapperProps } from './InputWrapper'
import { FormattedMessage } from 'react-intl'
import { Message } from '../../lib/i18n/types'
import styled from 'styled-components'

export type RadioGroupButtonsOptions = Array<{ label: Message; value: any }>

interface ButtonGroupProps {
  options?: RadioGroupButtonsOptions
  onChange: (val: any) => void
}

export interface RadioGroupButtonsProps {
  wrapperProps: InputWrapperProps
  buttonGroupProps: ButtonGroupProps
  buttonProps?: ButtonProps
  disabled?: boolean
  value?: any
}

const StyledButton = styled(Button)<{ isActive: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.fiDark70};
  box-sizing: border-box;
  border-radius: 0px;
  background-color: ${({ disabled, isActive, theme }) =>
    isActive ? theme.colors.blue200 : disabled ? theme.colors.fiDark10 : 'white'};

  &:not([disabled]):hover {
    box-shadow: 2px 2px 2px -10px ${({ theme }) => theme.colors.fiDark70};
    background-color: ${({ theme }) => theme.colors.fiBlue10};
  }
`

export const RadioGroupButtonsField = React.forwardRef<HTMLDivElement, RadioGroupButtonsProps>(
  ({ value, wrapperProps, disabled, buttonGroupProps, buttonProps }, ref) => {
    const uid = React.useMemo(() => `radio-group-buttons-label-${new Date().getTime()}`, [])

    return (
      <InputWrapper uid={uid} {...wrapperProps}>
        <Pane ref={ref}>
          {buttonGroupProps.options?.map((opt, i) => (
            <StyledButton
              type='button'
              key={`${opt.value}_${i}`}
              size='small'
              isActive={value === opt.value}
              disabled={disabled}
              onClick={() => buttonGroupProps.onChange(opt.value)}
              {...buttonProps}
            >
              <Text fontSize={14} fontWeight={600}>
                <FormattedMessage id={opt.label} />
              </Text>
            </StyledButton>
          ))}
        </Pane>
      </InputWrapper>
    )
  },
)
