import { useSetReservationFeedbackCommentMutation } from '/~/types/graphql'

export function useSetReservationFeedbackComment(reservationID: string) {
  const [__setReservationFeedbackComment, { loading, data, error }] = useSetReservationFeedbackCommentMutation()

  const setReservationFeedbackComment = (comment: string) =>
    __setReservationFeedbackComment({ variables: { reservationID, comment } })

  return { setReservationFeedbackComment, loading, data, error }
}
