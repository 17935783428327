import * as React from 'react'
import { Link, Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { KeyboardArrow } from '/fiweb/components/Icons'
import LocalStorageNotSupported from './auth/LocalStorageNotSupported'

const WhiteLink = styled(Link)`
  color: white;
  border-color: white;
  &:hover {
    color: white;
    border-color: white;
  }
`

export const AuthContainer: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { small } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Pane
      minHeight='100vh'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      backgroundColor={theme.colors.fiDark}
    >
      <LocalStorageNotSupported />
      <Pane backgroundColor='white' borderRadius='10px' width={small ? '100%' : '500px'}>
        {children}
      </Pane>
      <WhiteLink is={RouterLink} to='/' display='flex' alignItems='center' marginTop={theme.spacing.xs}>
        <KeyboardArrow style={{ marginRight: 6 }} />
        Tilbake til Folkeinvest.no
      </WhiteLink>
    </Pane>
  )
}
