import * as React from 'react'
import { IconProps } from './types'

export const Quotes = ({ title, ...rest }: IconProps) => (
  <svg width='53' height='41' viewBox='0 0 53 41' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path
      d='M48.6924 0.0375977L53.0005 3.83887C50.241 6.26042 47.9884 8.59749 46.2427 10.8501C44.5532 13.0464 43.4832 14.7077 43.0327 15.834C42.5822 16.9603 42.1598 18.3118 41.7656 19.8887L42.1035 20.3955C45.8766 20.3955 48.6361 21.2121 50.3818 22.8452C52.1276 24.4784 53.0005 27.0125 53.0005 30.4478C53.0005 33.0946 52.0431 35.488 50.1284 37.6279C48.27 39.7679 45.9329 40.8379 43.1172 40.8379C39.9072 40.8379 37.2323 39.9368 35.0923 38.1348C32.9523 36.3327 31.8823 33.4325 31.8823 29.4341C31.8823 24.1405 33.5155 18.6779 36.7817 13.0464C40.1043 7.35856 44.0745 3.0223 48.6924 0.0375977ZM17.353 0.0375977L21.6611 3.83887C18.9017 6.26042 16.6772 8.56934 14.9878 10.7656C13.2983 12.9619 12.2002 14.6514 11.6934 15.834C11.2428 17.0166 10.8205 18.3682 10.4263 19.8887L10.7642 20.3955C14.5373 20.3955 17.2967 21.2121 19.0425 22.8452C20.7882 24.4784 21.6611 27.0125 21.6611 30.4478C21.6611 33.0946 20.7038 35.488 18.7891 37.6279C16.9307 39.7679 14.5936 40.8379 11.7778 40.8379C8.56787 40.8379 5.8929 39.9368 3.75293 38.1348C1.61296 36.3327 0.542969 33.4325 0.542969 29.4341C0.542969 24.1405 2.17611 18.6779 5.44238 13.0464C8.76497 7.35856 12.7352 3.0223 17.353 0.0375977Z'
      fill='currentColor'
      fillOpacity='0.39'
    />
  </svg>
)
