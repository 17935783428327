import * as React from 'react'
import { Text, PaneProps, Radio, Pane } from 'evergreen-ui'
import { InputWrapper, InputWrapperProps } from './InputWrapper'
import { Message } from '/fiweb/lib/i18n/types'
import { FormattedMessage } from 'react-intl'
import { useCustomTheme } from '/fiweb/lib/theme'

type Option = { label: Message; value: string } | { label: string; value: string; string: true }

interface ComponentProps {
  name: string
  value?: string
  options?: Option[]
  onChange: (e: React.ChangeEvent) => void
  onConfirmValue?: (v: any) => void
  required?: boolean
  disabled?: boolean
}

export const RadioGroup = ({
  name,
  label,
  caption,
  value,
  prefix,
  suffix,
  options = [
    { label: 'yes', value: 'true' },
    { label: 'no', value: 'false' },
  ],
  onChange,
  error,
  layout = 'vertical',
  onConfirmValue,
  required,
  disabled,
  ...paneProps
}: ComponentProps & PaneProps & InputWrapperProps) => {
  const uid = React.useMemo(() => `radio-group-label-${new Date().getTime()}`, [])

  const handleClick = (e: React.MouseEvent<HTMLLabelElement>) => {
    // Evergreen has wrong type for Radio's onClick handler
    const target: HTMLInputElement = e.target as any

    if (onConfirmValue && target.checked) {
      onConfirmValue(target.value)
    }
  }

  const { colors } = useCustomTheme()

  return (
    <InputWrapper
      uid={uid}
      error={error}
      label={label}
      required={required}
      caption={caption}
      layout={layout}
      prefix={prefix}
      suffix={suffix}
      {...{ ...paneProps, ref: undefined }}
    >
      <Pane
        role='group'
        aria-labelledby={uid}
        display='inline-flex'
        flexDirection={layout === 'vertical' ? 'column' : 'row'}
        columnGap={12}
      >
        {options.map((option) => (
          <Radio
            disabled={disabled}
            key={option.value}
            name={name}
            label={
              <Text fontSize='14px' fontWeight={600} marginLeft='-5px' color={colors.fiDark}>
                {'string' in option ? option.label : <FormattedMessage id={option.label} />}
              </Text>
            }
            value={option.value}
            checked={value === option.value}
            onChange={onChange}
            onClick={handleClick}
            size={16}
            isInvalid={true}
            display='flex'
            alignItems='center'
          />
        ))}
      </Pane>
    </InputWrapper>
  )
}
