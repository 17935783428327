import * as React from 'react'
import moment from 'moment'
import CommentAvatar from './CommentAvatar'
import { Comment } from './types'
import { Pane, Text, Tooltip } from 'evergreen-ui'
import { useBreakpoint } from '/fiweb/lib'

interface Props {
  comment: Comment
}

const role = (status: string) => {
  switch (status) {
    case 'owner': {
      return 'Eier'
    }
    case 'contributor': {
      return 'Bidragsyter'
    }
  }
}

const NameTag = ({ comment }: Props) => {
  const { medium } = useBreakpoint()

  const { user, createdAt, children } = comment

  const isTeamMember = ['owner', 'contributor'].includes(comment.userStatus)
  const admin = comment.userStatus === 'admin'
  const name = user?.name || null
  const lastActivity = moment(children && children.length > 0 ? children[children.length - 1].createdAt : createdAt)

  lastActivity.locale('shortrel')

  return (
    <Pane
      display='flex'
      justifyContent={medium ? 'center' : 'space-between'}
      alignItems={medium ? 'space-between' : 'center'}
      flexDirection={medium ? 'column' : 'row'}
    >
      <Pane display='flex' alignItems='center'>
        <CommentAvatar name={name ? name : '?'} logo={admin} />

        <Text fontSize='16px' lineHeight='24px' marginLeft='8px'>
          {admin ? (
            <b>Folkeinvest AS</b>
          ) : isTeamMember ? (
            <>
              <b>{name}</b> <span>({role(comment.userStatus)} av emisjonen)</span>
            </>
          ) : name ? (
            name
          ) : (
            'UKJENT'
          )}
        </Text>
      </Pane>

      <Tooltip content={moment(createdAt).format('DD.MM.YYYY - HH:mm')}>
        <Text size={300} color='muted'>
          Siste aktivitet: {lastActivity.fromNow()}
        </Text>
      </Tooltip>
    </Pane>
  )
}

export default NameTag
