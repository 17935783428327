import * as React from 'react'
import { ArrowUpIcon, IconButton, Pane } from 'evergreen-ui'
import { FormattedMessage } from 'react-intl'
import { useWindowScrollPosition } from 'rooks'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Caption } from '/fiweb/components'
import { useBackToTop } from '../Context/BackToTop'
import { useBreakpoint } from '/fiweb/lib'

interface ButtomElementProps {
  withText?: boolean
}

const ButtonElement = ({ withText }: ButtomElementProps) => {
  const { spacing } = useCustomTheme()

  return (
    <Pane display='flex' flexDirection='column' alignItems='center' gap={spacing.xxs}>
      <IconButton
        intent='info'
        iconSize={25}
        icon={ArrowUpIcon}
        size='small'
        background='white'
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      />
      {withText && (
        <Caption size={300} fontWeight={500}>
          <FormattedMessage id='backToTop' />
        </Caption>
      )}
    </Pane>
  )
}

export const BackToTopButton = () => {
  const { ref } = useBackToTop()
  const { scrollY } = useWindowScrollPosition()
  const bottomRef = React.useRef<HTMLDivElement>()
  const { large } = useBreakpoint()

  if (!ref) {
    return null
  }
  const rect = ref.getBoundingClientRect()

  const show = scrollY > rect.top && window.innerWidth - rect.right > 115

  const bottom = large || bottomRef.current?.getBoundingClientRect().top < window.innerHeight

  return (
    <>
      <Pane position='relative' opacity={bottom ? 1 : 0} transition='0.7s' paddingX={20} paddingY={20}>
        <ButtonElement withText />
      </Pane>
      <Pane
        position='sticky'
        opacity={show && !bottom ? 1 : 0}
        bottom={50}
        transition='0.4s'
        paddingX={20}
        paddingY={20}
        left={show ? rect.right : rect.right + 50}
      >
        <ButtonElement />
      </Pane>
      <Pane ref={bottomRef} />
    </>
  )
}
