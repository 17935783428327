import * as React from 'react'
import { Pane, PaneProps } from 'evergreen-ui'
import { useBreakpoint } from '/fiweb/lib'

interface Props extends PaneProps {
  children: React.ReactNode
}

const PageContainer = ({ children, ...props }: Props) => {
  const { medium, large } = useBreakpoint()

  return (
    <Pane
      width='100%'
      maxWidth={large ? 730 : 800}
      transition='padding .3s cubic-bezier(0.4, 0, 0.2, 1)'
      paddingX={medium ? '20px' : 0}
      paddingY='60px'
      {...props}
    >
      {children}
    </Pane>
  )
}

export default PageContainer
