import React from 'react'
import { Pane, Heading, Paragraph, Button, Image } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import Coins from '/~/static/images/Coins.svg'
import { useBreakpoint } from '/fiweb/lib'
import styled from 'styled-components'
import { BookMeetingDialog } from '/~/components/hubspot'

const SmileyBox = styled.div`
  height: 288px;
  width: 454px;
  background: linear-gradient(149.5deg, rgba(13, 125, 194, 0.2) 19.59%, rgba(13, 125, 194, 0.1) 62%),
    rgba(13, 125, 194, 0.07);
  opacity: 0.3;
  border-radius: 144px;
  border-bottom-right-radius: 0px;
  margin-left: 47%;
  margin-bottom: ${({ theme: { spacing } }) => spacing.m};
`

const FlippedSmileyBox = styled(SmileyBox)`
  position: absolute;
  top: 0;
  left: -380px;
  border-bottom-right-radius: 144px;
  border-top-left-radius: 0px;
  background: linear-gradient(
      149.5deg,
      rgba(122, 166, 104, 0.2) 19.59%,
      rgba(122, 166, 104, 0.1) 62%
    ),
    rgba(122, 166, 104, 0.07);
`

export const AdCard = () => {
  const [isShown, setIsShown] = React.useState(false)
  const theme = useCustomTheme()
  const { large } = useBreakpoint()

  return (
    <Pane
      backgroundColor='white'
      display='flex'
      flexDirection='column'
      alignItems='center'
      height='100%'
      paddingTop={large ? 46 : 84.97}
      borderRadius={10}
    >
      <Pane
        display='flex'
        flexDirection='row'
        alignItems='center'
        width='100%'
        height={120}
        paddingX={47}
        position='relative'
      >
        <Heading size={500}>
          Ønsker du
          <br />å hente kapital?
        </Heading>
        <Image
          src={Coins}
          alt='stack of coins'
          width={92}
          height={92}
          opacity='50%'
          position='absolute'
          top='45%'
          transform='translate(0, -50%)'
          right='5%'
        />
      </Pane>
      <Pane width='100%' position='relative' overflow='hidden'>
        <SmileyBox />
        <FlippedSmileyBox />
        <Pane
          paddingLeft={47}
          paddingRight={69}
          display='flex'
          flexDirection='column'
          width='100%'
          position='absolute'
          top={20}
          paddingTop={30}
        >
          <Paragraph size={400} color={theme.colors.fiDark} marginBottom={theme.spacing.m}>
            Avtal et møte med oss i dag
          </Paragraph>
          <Button width={148} size='small' appearance='primary' marginLeft={-3} onClick={() => setIsShown(true)}>
            Avtal møte
          </Button>
        </Pane>
      </Pane>
      <BookMeetingDialog
        open={isShown}
        onClose={() => setIsShown(false)}
        formUrl='https://meetings.hubspot.com/maria-folkeinvest/mote-booket-via-adcard?embed=true'
      />
    </Pane>
  )
}
