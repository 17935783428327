import * as React from 'react'
import { useParams } from 'react-router'
import {
  useInitialLivePageDataQuery,
  useLivePageCampaignSubscription,
  useLivePageReservationsSummarySubscriptionSubscription,
} from '/~/types/graphql'
import {
  useInitialLivePageDataForInvestmentOfferQuery,
  useLivePageDataForInvestmentOfferSubscription,
} from '/~/types/issuer-graphql'
import { client } from '/~/lib/issuer-client/client'
import dayjs from 'dayjs'
import { useBreakpoint } from '/fiweb/lib'

export type LivePageContextType = ReturnType<typeof getData> & { mobileLandscape: boolean }

const Context = React.createContext<LivePageContextType>({} as LivePageContextType)

const getData = (slug: string) => {
  const oldApiQuery = useInitialLivePageDataQuery({ variables: { slug } })
  const newApiQuery = useInitialLivePageDataForInvestmentOfferQuery({
    variables: { slug },
    client,
    skip: !!(oldApiQuery.loading || oldApiQuery.data?.Campaign),
  })
  useLivePageCampaignSubscription({
    variables: {
      campaignId: oldApiQuery?.data?.Campaign?._id,
    },
    skip: !oldApiQuery?.data?.Campaign?._id,
  })
  useLivePageReservationsSummarySubscriptionSubscription({
    variables: {
      campaignId: oldApiQuery?.data?.Campaign?._id,
    },
    skip: !oldApiQuery?.data?.Campaign?._id,
  })
  const { data: subscriptionData } = useLivePageDataForInvestmentOfferSubscription({
    variables: { slug },
    client,
    skip: !newApiQuery?.data?.publicInvestmentOfferBySlug,
  })

  const loading = oldApiQuery.loading || newApiQuery.loading

  const data = React.useMemo(() => {
    if (oldApiQuery.data?.Campaign) {
      const campaign = oldApiQuery.data.Campaign
      return {
        minimumAmount: campaign.raisingAmountMinimum,
        maximumAmount: campaign.raisingAmountMaximum,
        shares: campaign.reservationsSummary.shares,
        currentAmount: campaign.reservationsSummary.total,
        investors: campaign.reservationsSummary.investors,
        investments: campaign.reservationsSummary.count,
        openDate: campaign.startDate ? dayjs(campaign.startDate).toDate() : null,
        closeDate: campaign.closeDate ? dayjs(campaign.closeDate).toDate() : null,
        title: campaign.title,
      }
    }
    if (newApiQuery.data?.publicInvestmentOfferBySlug) {
      const subData = subscriptionData?.publicInvestmentOfferSummaryChanged
      const investmentOffer = newApiQuery.data.publicInvestmentOfferBySlug
      const pps = investmentOffer.pricePerShare
      const amountReserved = pps * (subData?.totalSharesReserved ?? investmentOffer.totalSharesReserved)
      return {
        minimumAmount: Math.floor((pps * investmentOffer.minSharesToIssue) / 100),
        maximumAmount: Math.floor((pps * investmentOffer.maxSharesToIssue) / 100),
        shares: subData?.totalSharesReserved ?? investmentOffer.totalSharesReserved,
        currentAmount: Math.floor(amountReserved / 100),
        investors: subData?.subscribersCount ?? investmentOffer.subscribersCount,
        investments: subData?.subscriptionsCount ?? investmentOffer.subscriptionsCount,
        openDate: investmentOffer.dates?.open ? dayjs(investmentOffer.dates.open).toDate() : null,
        closeDate: investmentOffer.dates?.close ? dayjs(investmentOffer.dates.close).toDate() : null,
        title: investmentOffer.title,
      }
    }
    return null
  }, [loading, oldApiQuery.data, newApiQuery.data, subscriptionData?.publicInvestmentOfferSummaryChanged])

  return { data, loading }
}

export const LivePageContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { large } = useBreakpoint()
  const mobileLandscape = large && screen.width > screen.height && screen.height < 450
  const { slug } = useParams<{ slug: string }>()
  const data = getData(slug)
  return <Context.Provider value={{ ...data, mobileLandscape }}>{children}</Context.Provider>
}

export const useLiveInvestmentOfferUpdates = () => React.useContext(Context)
