import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import { Link, LinkProps } from 'evergreen-ui'

const TextLink = ({ children, href = '#', ...rest }: LinkProps) => {
  const { medium } = useBreakpoint()
  return (
    <Link href={href} fontSize={medium ? 18 : 24} lineHeight={medium ? '28px' : '33.48px'} target='_blank' {...rest}>
      {children}
    </Link>
  )
}

export default TextLink
