import * as React from 'react'
import { IconProps } from './types'

export const SortDirectionArrow = ({ title, ...rest }: IconProps) => (
  <svg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <path
      d='M5.89989 7.4672C5.69989 7.73368 5.30011 7.73368 5.10011 7.4672L0.471386 1.30014C0.224007 0.970546 0.459175 0.5 0.87128 0.5L10.1287 0.499999C10.5408 0.499999 10.776 0.970545 10.5286 1.30014L5.89989 7.4672Z'
      fill='currentColor'
    />
  </svg>
)
