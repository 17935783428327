import * as React from 'react'
import { Group, Button, GroupProps, Text } from 'evergreen-ui'
import styled, { css } from 'styled-components'

export interface SegmentedControlWithBadgeOption {
  label: string
  value: string
  badgeCount: number
  hideBadge?: boolean
}

interface Props {
  options: SegmentedControlWithBadgeOption[]
  value: string
  clickHandler: (value: string) => void
}

const StyledButton = styled(Button).attrs({ size: 'small', appearance: 'minimal' })`
  ${({ theme: { colors } }) => css`
    border: 1px solid ${colors.fiDark10};
    border-radius: 0;
    background-color: ${colors.fiDark40};
    color: white;
    padding: 0 12px;

    &.active {
      background-color: white;
      color: ${colors.dark};
    }
  `}
`

export const SegmentedControlBadge = styled(Text).attrs({ size: 300 })`
  ${({ theme: { colors, fontWeights, radii, spacing } }) => css`
  background-color: white;
  color: ${colors.dark};
  border-radius: ${radii[5]};
  font-weight: ${fontWeights.semibold};
  margin-left: ${spacing.xxs};
  padding: 0 8px;
  
  &.active {
      background-color: ${colors.blue400};
      color: white;
    }
  `}
`

export const SegmentedControlWithBadge = ({ options, value, clickHandler, ...rest }: Props & GroupProps) => {
  return (
    <Group {...rest}>
      {options.map((option) => (
        <StyledButton
          key={option.value}
          className={option.value === value ? 'active' : ''}
          onClick={() => clickHandler(option.value)}
        >
          {option.label}
          {!option.hideBadge && (
            <span>
              <SegmentedControlBadge className={option.value === value && 'active'}>
                {option.badgeCount}
              </SegmentedControlBadge>
            </span>
          )}
        </StyledButton>
      ))}
    </Group>
  )
}
