import { Button, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import * as React from 'react'
import { useBreakpoint } from '/fiweb/lib'

interface Props {
  onNext: () => void
  onAbort: () => void
}

const InfoDisplay = ({ onNext }: Props) => {
  const { medium } = useBreakpoint()
  const theme = useCustomTheme()

  return (
    <Pane>
      <Paragraph>
        Alle investorer på folkeinvest.no regnes som <em>ikke-profesjonelle.</em>
      </Paragraph>
      <Paragraph marginTop={theme.spacing.xs}>
        Hensiktsmessighetstesten har til formål å avdekke om denne type investering er hensiktsmessig for deg.
      </Paragraph>
      <Paragraph marginTop={theme.spacing.xs}>
        Dersom kunnskapsnivået anses som utilstrekkelig frarådes du å handle aksjer på folkeinvest.no.
      </Paragraph>
      <Pane
        display='flex'
        flexDirection={medium ? 'column-reverse' : 'row'}
        justifyContent='flex-end'
        marginTop={theme.spacing.s}
        marginBottom={theme.spacing.xs}
      >
        <Button
          size='small'
          appearance='primary'
          onClick={onNext}
          marginLeft={medium ? 0 : 18}
          marginBottom={medium ? theme.spacing.xs : 0}
        >
          Ta testen
        </Button>
      </Pane>
    </Pane>
  )
}

export default InfoDisplay
